<template>
    <v-card elevation="3" :max-width="getMaxWidth" :loading="loadingComment" :disabled="loadingComment">
        <v-dialog max-width="400" v-model="deletecommentDialog" persistent>
            <v-card :loading="loadingComment" :disabled="loadingComment">
                <v-card-title>¿Desea eliminar el comentario?</v-card-title>
                <v-card-text>
                    ¿Estás seguro de querer eliminar este comentario?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deletecommentDialog=false">Cancelar</v-btn>
                    <v-btn text :color="$store.state.accentColor2" @click="ondeleteCommentCall">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-text>
            <v-list-item>
                <v-list-item-avatar size="35">
                    <v-icon>mdi-account-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="text-body-1 font-weight-regular">
                    Comentarios privados
                </v-list-item-content>
            </v-list-item>
            <v-list dense>
                <div v-for="(comentario,index) in comentarios" :key="index">
                    <v-card flat class="pb-0">
                        <v-card-title class="py-0">
                            <v-list-item class="pa-0">
                                <v-list-item-avatar size="35" :color="getColorAvatar(comentario)">
                                    <v-icon dark>person</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-body-2 wrap-text">
                                        {{comentario.comentador}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{getFechaComentario(comentario)}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{on}">
                                            <v-btn v-if="$store.state.personIdentity.idEstudiante==comentario.idComentador" icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                                        </template>
                                        <v-list class="pa-0" dense>
                                            <v-list-item @click="()=>{ondelete(comentario)}">
                                                <v-list-item-content>
                                                    <v-list-item-title>Eliminar</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item-action>
                            </v-list-item>
                        </v-card-title>
                        <v-card-text v-html="linkfy(comentario.comentario,{
                                target: {
                                    url: '_blank'
                                    }
                                }
                            )">
                        </v-card-text>
                    </v-card>
                    <v-divider></v-divider>
                </div>
            </v-list>
            <v-btn :block="styling" :large="styling" @click="oncommentActive" v-if="activeButton" text :color="$store.state.accentColor2" class="normal-text">
                Agregar un comentario a <br v-if="styling">
                catedrático
            </v-btn>
            <v-list-item v-if="activeInput" dense>
                <v-list-item-avatar size="35" :color="$store.state.accentColor2">
                    <v-icon color="white">person</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-textarea            
                        auto-grow
                        row-height="0"
                        no-resize
                        hide-details
                        outlined
                        :color="$store.state.accentColor2"
                        rounded
                        dense
                        @input="oninputcommentChange"
                        v-model="comentario"
                        >
                    </v-textarea>
                </v-list-item-content>
                <v-list-item-action>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn @click="onsendComment" v-on="on" icon :color="$store.state.accentColor2" :disabled="!getcolorSend()">
                                <v-icon>send</v-icon>
                            </v-btn>
                        </template>
                        <span>Enviar comentario</span>
                    </v-tooltip>
                </v-list-item-action>
            </v-list-item>
        </v-card-text>
    </v-card>
</template>
<script>
import GetReadableDate from '../../../../utils/timeUtils';
import linkfy from 'linkifyjs/lib/linkify-string';

export default {
    props:[
        "styling",
        "from",
        "actividad",
        "comentarios"
    ],
    computed:{
        getMaxWidth(){
            if(this.styling)
                return 300;
            else
                return 10000;
        },
        activeInput(){
            return this.activeinputComment||this.comentarios.length>0
        },
        activeButton(){
            return !this.activeinputComment&&this.comentarios.length==0
        }
    },
    watch:{
        comentarios(newval,oldva){
            oldva;
            if(newval.length==0)
                this.activeinputComment=false;
        }
    },
    data:function() {
        return({
            activeinputComment:false,
            onfocusComment:false,
            comentario:"",
            loadingComment:false,
            deletecommentDialog:false,
            selectedComentario:"",
            baseUrl:""
        })
    },
    methods:{
        linkfy,
        ondelete(comentario){
            this.selectedComentario=comentario;
            this.deletecommentDialog=true;
        },
        getColorAvatar(comentario){
            if(comentario.isProfesor){
                return "teal darken-1";
            }
            return this.$store.state.accentColor2;
        },
        getFechaComentario(comentario){
            if(comentario){
                return this.getHumanDate(comentario.fechaComentario);
            }

            return "";
        },
        getHumanDate: GetReadableDate,
        ondeleteComment(comentario){
            this.selectedComentario=comentario;
            this.deletecommentDialog=true;
        },
        async ondeleteCommentCall(){
            this.loadingComment=this.$store.state.accentColor2;

            await this.$axios.delete(`${this.baseUrl}${this.actividad.participante.idActividadMateriaBloqueParticipante}/comentarios/${this.selectedComentario.idComentario}/delete`).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });

            await this.onloadComments();
            this.deletecommentDialog=false;
            this.loadingComment=false;
        },
        async onsendComment(){
            this.loadingComment=this.$store.state.accentColor2;

            var comment={
                comentario:this.comentario
            }

            await this.$axios.post(`${this.baseUrl}${this.actividad.participante.idActividadMateriaBloqueParticipante}`,comment).then((response)=>{
                if(response.status==200)
                    if(response.data.code==1){
                        this.comentario="";
                        this.onfocusComment=false;
                    }else
                        this.$emit("onerror");
            }).catch((error)=>{
                error;
            });

            await this.onloadComments();
            this.loadingComment=false;
        },
        async onloadComments(){
            this.loadingComment=this.$store.state.accentColor2;

            await this.$axios.get(`${this.baseUrl}${this.actividad.participante.idActividadMateriaBloqueParticipante}/comentarios`).then((response)=>{
                if(response.status==200)
                    if(response.data.code==1)
                        this.$emit("onupdateComments",response.data.data);
                    else
                        this.$emit("onerror");
            }).catch((error)=>{
                error;
            });

            this.loadingComment=false;
        },
        oninputcommentChange(val){
            val;
            if(this.comentario!=""&&!/^[\s]+$/.test(this.comentario))
                this.onfocusComment=true;
            else{
                this.onfocusComment=false;
            }
        },
        getcolorSend(){
            if(this.onfocusComment)
                return true;
            return false;
        },
        oncommentActive(){
            this.activeinputComment=true;
        },
    },
    created(){
        if(this.from.includes("tarea"))
            this.baseUrl="api/estudiantil/actividades/tareas/entrega/";
        else if(this.from.includes("parcial"))
            this.baseUrl="api/estudiantil/actividades/parcial/entrega/";
        else
            this.baseUrl="api/estudiantil/actividades/examen-final/entrega/";
    }
}
</script>
<style scoped>
.wrap-text {
  white-space: unset !important;
}
</style>