<template>
    <v-dialog v-model="editinformacionDialog" max-width="600" persistent scrollable>
        <informacion
        :action="action"
        :newinfoPostSaved="informacionSaved"
        :archivosSaved="archivosSaved"
        @onchangecontentInfo="(change)=>{$emit('onchangecontentInfo',change)}"
        @onchangevideosInfo="(change)=>{$emit('onchangevideosInfo',change)}"
        @onchangearchivosexistInfo="(change)=>{$emit('onchangearchivosexistInfo',change)}"
        @onchangearchivosInfo="(change)=>{$emit('onchangearchivosInfo',change)}"
        @oncancelar="$emit('oncancelar')"
        @onpostResponse="(response)=>{$emit('onpostResponse',response)}"
        :idInformacion="idInformacion"
        @onrefresh="$emit('onrefresh')"
        @oncancelPost="$emit('oncancelPost')"
        >
        </informacion>
    </v-dialog>
</template>
<script>
import Informacion from './Informacion.vue'
export default {
  components: { Informacion },
    props:[
        "informacionSaved",
        "archivosSaved",
        "editinformacionDialog",
        "action",
        "idInformacion"
    ],
    data:function () {
        return({
        })
    },
    methods:{
    }
}
</script>
<style scoped>

</style>