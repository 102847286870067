<template>
    <div>
        <v-overlay :value="!allLoaded" opacity="0">
            <v-progress-circular
            :size="70"
            :width="7"
            :color="$store.state.accentColor"
            indeterminate
            ></v-progress-circular>
        </v-overlay>
        <tipos-pago-dialog :tipospagoDialog="tipospagoDialog" @onclose="tipospagoDialog=false;" :action="action" 
        @onsave="onsaveTipoPago" @onupdate="onupdateTipoPago" :tipoPagoToEdit="selectedTipoPago"></tipos-pago-dialog>

        <delete-dialog :show="deleteTipoPagoDialog" :context="'tipo de pago'" :content="`¿Quieres eliminar el tipo de pago '${selectedTipoPago.descripcion}'?`" 
        :idEntity="selectedTipoPago.idPago" @ondelete="ondeleteTipoPago" @onclose="deleteTipoPagoDialog=false;"></delete-dialog>

        <div v-if="allLoaded">
            <v-row class="pa-0 ma-0">
                <v-col v-for="tipoPago in tiposPagosData" :key="tipoPago.idPago" cols="12" xs="12" sm="6" md="4" lg="3" xl="2">
                    <tipo-pago-card :tipoPago="tipoPago" @ondelete="()=>{
                        deleteTipoPagoDialog=true;
                        selectedTipoPago=tipoPago;
                        }" @onupdate="()=>{
                            tipospagoDialog=true;
                            action='edit';
                            selectedTipoPago=tipoPago;
                        }"></tipo-pago-card>
                </v-col>
            </v-row>

            <add-button :tooltipText="'Agregar tipo de pago'" @onadd="()=>{
                tipospagoDialog=true;
                action='add';
                }"></add-button>
        </div>
    </div>
</template>
<script>
import AddButton from '../../../globalComponents/AddButton'
import DeleteDialog from '../../../globalComponents/DeleteDialog.vue';
import TiposPagoDialog from './TiposPagoDialog.vue';
import TipoPagoCard from './TipoPagoCard.vue'
export default {
    props:{selectedTab:Number},
    watch:{
        selectedTab:function(newval,oldval){
            oldval;
            if(newval==1){
                this.loadAll();
            }
        }
    },
    components:{AddButton,TiposPagoDialog, DeleteDialog,TipoPagoCard},
    data:()=>({
        tiposPagosData:[],
        allLoaded:false,
        tipospagoDialog:false,
        deleteTipoPagoDialog:false,
        action:"",
        selectedTipoPago:Object
    }),
    methods:{
        loadTiposPagos(){
            return this.$axios.get("api/tipospagos");
        },
        async loadAll(){
            this.allLoaded=false;
            this.$store.commit("setLoading",!this.allLoaded);

            await this.$axios.all([this.loadTiposPagos()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.tiposPagosData=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                console.log(error);
            });

            this.allLoaded=true;
            this.$store.commit("setLoading",!this.allLoaded);
        },
        onsaveTipoPago(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.tiposPagosData.push(response[1]);
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        onupdateTipoPago(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        ondeleteTipoPago(response){
            switch(response[0]){
                case 0:
                        this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.tiposPagosData.splice(this.tiposPagosData.indexOf(this.selectedTipoPago),1);

                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>

</style>