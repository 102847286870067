<template>
    <v-app id="colaboradores_colaboradores">
        <app-bar :allLoaded="allLoaded" :name="title"></app-bar>
        <colaborador-dialog :colaboradorDialog="colaboradorDialog" :action="action" @onclose="colaboradorDialog=false;"
        @onsave="onsaveColaborador" @onupdate="onupdateColaborador" :colaboradorToEdit="selectedColaborador"></colaborador-dialog>

        <delete-dialog :show="deleteColaboradorDialog" @onclose="deleteColaboradorDialog=false;" :content="`¿Quieres eliminar al colaborador '${selectedColaborador.nombres}'?`" context="colaborador"
        :idEntity="selectedColaborador.idColaborador" @ondelete="ondeleteColaborador"></delete-dialog>

        <grados-guiados :gradosguiadosDialog="gradosguiadosDialog" :colaborador="selectedColaborador.idColaborador"
        @onclose="gradosguiadosDialog=false;"
        ></grados-guiados>

        <asignar-clases-dialog :asignarClasesDialog="asignarClasesDialog" :colaborador="selectedColaborador" @onclose="asignarClasesDialog=false" @onassignMaterias="onassignMaterias"></asignar-clases-dialog>
        <div v-if="allLoaded">
            <v-row class="pa-0 ma-0" justify="center">
                <div class="text-h4 font-weight-bold">{{getCicloEscolar}}</div>
            </v-row>
            <br>
            <v-row justify="center" no-gutters>
                <v-radio-group
                mandatory
                v-model="selectedType"
                row
                >
                    <v-radio :color="$store.state.accentColor" label="Docentes" value="docente"></v-radio>
                    <v-radio :color="$store.state.accentColor" label="Otros" value="otros"></v-radio>
                </v-radio-group>
            </v-row>
            <v-row class="pa-0 ma-0">
                <v-col v-for="colaborador in colaboradoresDataFiltered" :key="colaborador.idColaborador" cols="12" xs="12" sm="6" xl="4">
                    <colaborador-card :colaborador="colaborador" @ondelete="()=>{
                        selectedColaborador=colaborador;
                        deleteColaboradorDialog=true;
                    }" @onedit="()=>{
                        selectedColaborador=colaborador;
                        colaboradorDialog=true;
                        action='edit';
                    }" @onaddclases="()=>{
                        selectedColaborador=colaborador;
                        asignarClasesDialog=true;
                        }" @onaddgrades="()=>{
                            selectedColaborador=colaborador;
                            gradosguiadosDialog=true;
                            }"
                        ></colaborador-card>
                </v-col>
            </v-row>
            <add-button v-if="allLoaded" :tooltipText="'Agregar colaborador'" @onadd="()=>{
            colaboradorDialog=true;
            action='add';
            }"></add-button>
        </div>
    </v-app>    
</template>
<script>
import AddButton from '../../globalComponents/AddButton.vue';
import AppBar from '../../globalComponents/Appbar'
import DeleteDialog from '../../globalComponents/DeleteDialog.vue';
import AsignarClasesDialog from './AsignarClasesDialog.vue';
import ColaboradorCard from './ColaboradorCard.vue';
import ColaboradorDialog from './ColaboradorDialog.vue';
import GradosGuiados from './GradosGuiados.vue';
export default {
    props:{
        title:String
    },
    watch:{
        selectedType(newval,oldval){
            oldval;
            if(newval){
                this.filterBy(newval);
            }
        }
    },
    computed:{
        getCicloEscolar(){
            if(this.$store.state.cicloEscolar)
                return `Ciclo escolar ${this.$store.state.cicloEscolar.cicloEscolar1}`;
            return "";
        },
    },
    components:{AppBar,AddButton,ColaboradorCard, ColaboradorDialog, DeleteDialog, AsignarClasesDialog,GradosGuiados},
    data:()=>({
        allLoaded:false,
        colaboradoresData:[],
        colaboradoresDataFiltered:[],
        colaboradorDialog:false,
        deleteColaboradorDialog:false,
        asignarClasesDialog:false,
        gradosguiadosDialog:false,
        selectedColaborador:Object,
        selectedType:"",
        action:"",
        unsubscribe:""
    }),
    methods:{
        onChangeCiclo(val){
            val;
            this.$store.commit("changeCiclo",val);
        },
        loadColaboradores(){
            return this.$axios.get(`api/colaboradores?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`);
        },
        async loadAll(){
            this.allLoaded=false;

            await this.$axios.all([this.loadColaboradores()]).then((responses)=>{
                if(responses[0].status==200){
                    if(responses[0].data.code==1){
                        this.colaboradoresData=responses[0].data.data;
                        this.filterBy("docente");
                    }
                }
            }).catch((errors)=>{
                errors;
            });

            this.allLoaded=true;
        },
        onsaveColaborador(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.loadAll();
        },
        ondeleteColaborador(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.loadAll();
        },
        onupdateColaborador(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.loadAll();
        },
        onassignMaterias(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.loadAll();
        },
        filterBy(filter){
            if(filter.includes("docente"))
                this.colaboradoresDataFiltered=this.colaboradoresData.filter(item=>item.colaboradoresCargos.some(item=>item.idCargoNavigation.nombre.toLowerCase()==filter));
            else
                this.colaboradoresDataFiltered=this.colaboradoresData.filter(item=>item.colaboradoresCargos.some(item=>item.idCargoNavigation.nombre.toLowerCase()!="docente"));
        }
    },
    created(){
        this.loadAll();
        this.unsubscribe=this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'changeCiclo') {
                state;
                this.loadAll();
            }
        });
    },
    beforeDestroy(){
        this.unsubscribe();
    }
}
</script>
<style lang="stylus" scoped>

</style>