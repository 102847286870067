<template>
    <v-card outlined class="rounded-xl card-text" ripple @click="onclickParcial">
            <v-list-item>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                    <v-icon size="30" :color="$store.state.accentColor2">{{entregaParcial.examenFinal.actividad.tipoActividadNavigation.icono}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2 wrap-text">
                        {{getParcialTitle}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption">
                        {{getReadableDate(entregaParcial.examenFinal.actividad.fechaCreada)}}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-row no-gutters>
                        <v-col align="center">
                            <div :class="statusEntrega(1)">{{statusEntrega()}}</div>
                            <div :class="punteoEntrega(1)">{{punteoEntrega()}}</div>
                        </v-col>
                    </v-row>
                </v-list-item-action>
            </v-list-item>
    </v-card>
</template>
<script>
import {Months} from '../../../../../utils/timeUtils';
export default {
    props:[
        "entregaParcial",
    ],
    computed:{
        getParcialTitle(){
            if(this.entregaParcial.examenFinal.title.length>30){
                return `Parcial: ${this.entregaParcial.examenFinal.title.substring(0,30)}...`;
            }
            return `Parcial: ${this.entregaParcial.examenFinal.title}`;
        }
    },
    data:function(){
        return({
            currentDate:"",
            lastDate:"",
            options:[
                "Eliminar",
                "Editar"
            ]
        })
    },
    methods:{
        statusEntrega(isClass){
            if(this.entregaParcial.participante){
                if(isClass){
                    var baseClass="text-subtitle-2";
                    switch(this.entregaParcial.participante.idEstadoActividad){
                        case 1:
                            if(this.lastDate)
                                if(this.currentDate>this.lastDate)
                                    return baseClass+=" red--text";
                        return baseClass;
                        case 2: return baseClass;
                        case 3: return baseClass;
                        case 4: return baseClass+=" red--text";
                        case 5: return baseClass+=" red--text";
                    }

                    return baseClass;
                }else{
                    switch(this.entregaParcial.participante.idEstadoActividad){
                        case 1:
                            if(this.lastDate)
                                if(this.currentDate>this.lastDate){
                                    return "Examen no entregado"
                                }
                        return "Pendiente de realizar";
                        case 2: return "Entregado";
                        case 3: return "Calificado";
                        case 4: return "Anulado";
                        case 5: return "Rechazado";
                    }
                    return ""
                }
            }
            return "";
        },
        punteoEntrega(isClass){
            if(this.entregaParcial.participante){
                if(isClass){
                    switch(this.entregaParcial.participante.idEstadoActividad){
                        case 3: 
                        var punteo=this.entregaParcial.participante.actividadesMateriasBloquesEntregas.calificacion;
                        var valor=this.entregaParcial.examenFinal.actividad.valorNeto;
                        if(punteo>(valor/2))
                            return "text-subtitle-2 green--text";
                        else if(punteo<(valor/2))
                            return "text-subtitle-2 red--text";

                        return "text-subtitle-2";
                    }
                    return ""
                }else{
                    switch(this.entregaParcial.participante.idEstadoActividad){
                        case 3: return `${this.entregaParcial.participante.actividadesMateriasBloquesEntregas.calificacion}/${this.entregaParcial.examenFinal.actividad.valorNeto}`;
                    }
                    return ""
                }
            }
            return "";
        },
        onclickParcial(){
            this.$emit("ondetails");
        },
        getReadableDate(date){
            var datedate=new Date(date);

            var splittedTime=date.split("T")[1];
            var hourminute=splittedTime.split(".")[0];
            var time=hourminute.substring(0,(hourminute.length-1)-2);
            var month=Months[datedate.getMonth()].substring(0,3);

            return `${datedate.getDate()} ${month} ${datedate.getFullYear()} ${time}`;
        }
    },
    created(){
        this.currentDate=this.$store.state.currentRealDate;

        if(this.entregaParcial.examenFinal.actividad.examenFechaHoraFinalizado)
            this.lastDate=new Date(this.entregaParcial.examenFinal.actividad.examenFechaHoraFinalizado);
    }
}
</script>
<style scoped>

</style>