<template>
    <vue-editor v-model="localContent" :editorToolbar="customToolbar" @input="onchangeInput"></vue-editor>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
    components:{VueEditor},
    props:[
        "content"
    ],
    watch:{
        content(newval,oldval){
            oldval;
            this.localContent=newval;
        }
    },
    data:function () {
        return({
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ color: [] }, { background: [] }],
                ["clean"]
            ],
            localContent:""
        });
    },
    methods:{
        onchangeInput(){
            this.$emit("onchangeContent",this.localContent);
        }
    },
    created(){
        this.localContent=this.content;
    }
}
</script>
<style scoped>

</style>