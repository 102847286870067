<template>
    <v-dialog fullscreen persistent v-model="dialogNuevos">
        <pagos-estudiante :pagosEstudiante="pagosestudianteDialog" @onclose="pagosestudianteDialog=false;" 
        :estudianteGrado="getAsignacion" :estudiante="getEstudiante"
        ></pagos-estudiante>
        <calificaciones-estudiante :calificacionesDialog="calificacionesDialog" @onclose="calificacionesDialog=false;"
        :estudianteGrado="getAsignacion" :estudiante="getEstudiante"
        ></calificaciones-estudiante>

        <v-card>
            <v-app>
                <app-bar-2 name="Alumnos nuevos"></app-bar-2>
                <v-container class="body">
                    <v-row no-gutters justify="center">
                        <v-list>
                            <v-list-item v-for="alumno in alumnosInsolventes" :key="alumno.idEstudianteGrado">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{getNombreAlumno(alumno)}}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-tooltip left>
                                        <template v-slot:activator="{on}">
                                            <v-btn v-on="on" icon :color="'primary'" @click="()=>{onSelectAlumno(alumno)}">
                                                <v-icon>fact_check</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver pagos</span>
                                    </v-tooltip>
                                </v-list-item-action>
                                <v-list-item-action>
                                    <v-tooltip right>
                                        <template v-slot:activator="{on}">
                                            <v-btn v-on="on" icon :color="'amber'" @click="onSelectCalificaciones(alumno)">
                                                <v-icon>grade</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver calificaciones</span>
                                    </v-tooltip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-row>
                </v-container>
            </v-app>
        </v-card>
    </v-dialog>    
</template>
<script>
import AppBar2 from '../../globalComponents/AppBar2.vue';
import PagosEstudiante from '../estudiantes/PagosEstudiante.vue';
import CalificacionesEstudiante from '../estudiantes/CalificacionesEstudiante.vue';

export default {
    components:{AppBar2,PagosEstudiante,CalificacionesEstudiante},
    props:["dialogNuevos"],
    computed:{
        getAsignacion(){
            if(this.selectedAlumno)
                return this.selectedAlumno.asignacion;
            return "";
        },
        getEstudiante(){
            if(this.selectedAlumno)
                return this.selectedAlumno.estudiante.idEstudiante;
            return "";
        }
    },
    data:function(){
        return ({
            alumnosInsolventes:[],
            pagosestudianteDialog:false,
            calificacionesDialog:false,
            selectedAlumno:""
        });
    },
    methods:{
        onSelectAlumno(alumno){
            this.selectedAlumno=alumno;
            this.pagosestudianteDialog=true;
        },
        onSelectCalificaciones(alumno){
            this.selectedAlumno=alumno;
            this.calificacionesDialog=true;
        },
        getNombreAlumno(alumno){
            if(alumno.estudiante)
                return `${alumno.estudiante.nombre1} ${alumno.estudiante.nombre2} ${alumno.estudiante.apellido1} ${alumno.estudiante.apellido2}`;
            return "";
        },
        getAlumnosNuevos(){return this.$axios.get(`api/estudiantes/${this.$store.state.cicloEscolar.cicloEscolar1}/nuevos-lista`)},
        async loadAll(){
            this.$store.commit("setLoading",true);

            await this.$axios.all([this.getAlumnosNuevos()]).then((response)=>{
                if(response[0].status==200)
                    if(response[0].data.code==1)
                        this.alumnosInsolventes=response[0].data.data;
            }).catch((error)=>{
                console.log(error);
            });

            this.$store.commit("setLoading",false);
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>
.body{
    padding-top: 60px;
}
</style>