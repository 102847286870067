<template>
    <v-col>
        <delete-dialog :show="deleteactivityDialog" :content="deletecontent" :context="deletecontext" :idEntity="getpostIdentity"
            @ondelete="ondeletePost" @onclose="deleteactivityDialog=false"
            ></delete-dialog>
        <v-list-item>
            <v-list-item-avatar>
                <v-avatar :color="getColorBloque()" size="20">
                </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title :class="getBloqueStyle">
                    Bloque {{bloque.bloque.bloque}}
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-row>
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-icon v-on="on" :color="$vuetify.theme.dark?'white':$store.state.accentColor2">info</v-icon>
                        </template>
                        <span class="text-caption">
                            {{getInicioFinText(bloque.bloque)}}
                            <br>
                            {{getFinText(bloque.bloque)}}
                            <br v-if="getFinText(bloque.bloque)">
                            <div v-if="getInicioFinText(bloque.bloque)!='No iniciado'">{{getPunteosText}}</div>
                        </span>
                    </v-tooltip>
                    <div class="mr-3"></div>
                    <v-menu offset-y>
                        <template v-slot:activator="{on: menu}">
                            <v-btn icon x-large v-on="{...menu}"><v-icon :color="$vuetify.theme.dark?'white':$store.state.accentColor2">more_horiz</v-icon></v-btn>
                        </template>
                        <v-list dense class="pa-0">
                            <div v-for="(opcion,index) in opciones" :key="index">
                                <v-list-item v-if="validateItem(opcion)" @click="()=>{$emit('onopcion',opcion)}">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{opcion}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-list>
                    </v-menu>
                </v-row>
            </v-list-item-action>
        </v-list-item>
        <v-divider :color="$vuetify.theme.dark?'white':'black'"></v-divider>
        <br>
        <v-list>
            <v-list-item v-for="actividad in bloque.actividades" :key="actividad.idActividadMateriaBloque">
                <v-row>
                    <v-col cols="12">
                        <tarea-card v-if="onlyTarea(actividad)" :tarea="actividad"
                        @ondelete="deleteActividad(actividad)"
                        @onupdate="updateTarea(actividad)"
                        @ondetails="detailsTarea(actividad)"
                        ></tarea-card>
                        <material-card 
                        @ondelete="deleteActividad(actividad)"
                        @onupdate="updateMaterial(actividad)"
                        @ondetails="detailsMaterial(actividad)"
                        v-if="onlyMaterial(actividad)" :material="actividad"
                        ></material-card>
                        <tarea-extra-card
                            v-if="onlyActividadExtra(actividad)" :tarea="actividad"
                            @ondelete="deleteActividad(actividad)"
                            @onupdate="updateTareaExtra(actividad)"
                            @ondetails="detailsTareaExtra(actividad)"
                        >

                        </tarea-extra-card>
                        <examen-final-card
                            v-if="onlyExamenFinal(actividad)" :examenfinal="actividad"
                            @ondelete="deleteActividad(actividad)"
                            @onupdate="updateExamenFinal(actividad)"
                            @ondetails="detailsExamenFinal(actividad)"
                        ></examen-final-card>
                        <parcial-card
                        v-if="onlyParcial(actividad)" :parcial="actividad"
                            @ondelete="deleteActividad(actividad)"
                            @onupdate="updateParcial(actividad)"
                            @ondetails="detailsParcial(actividad)">

                        </parcial-card>
                        <parcial-extra-card
                            v-if="onlyParcialExtra(actividad)" :parcial="actividad"
                            @ondetails="()=>{detailsParcialExtra(actividad)}"
                            @ondelete="()=>{deleteActividad(actividad)}"
                            @onupdate="()=>{updateParcialExtra(actividad)}">
                                </parcial-extra-card>
                        <examen-final-extra-card
                        v-if="onlyExamenFinalExtra(actividad)" :examenfinal="actividad"
                        @ondetails="()=>{ondetailsExamenFinalExtra(actividad)}"
                        @ondelete="()=>{ondeleteActividad(actividad)}"
                        @onupdate="()=>{updateExamenFinalExtra(actividad)}">

                        </examen-final-extra-card>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-list>
    </v-col>
</template>
<script>
import TareaCard from '../common/tarea/TareaCard.vue';
import DeleteDialog from '../../../globalComponents/DeleteDialog.vue';
import MaterialCard from '../common/material/MaterialCard.vue';
import TareaExtraCard from '../common/extra/tarea/TareaExtraCard.vue';
import ExamenFinalCard from '../common/examenFinal/ExamenFinalCard.vue';
import ParcialCard from '../common/parcial/ParcialCard.vue';
import ParcialExtraCard from '../common/extra/parcial/ParcialExtraCard.vue';
import ExamenFinalExtraCard from '../common/extra/examenfinal/ExamenFinalExtraCard.vue';
export default {
  components: { TareaCard,DeleteDialog,MaterialCard, TareaExtraCard,ExamenFinalCard,ParcialCard, ParcialExtraCard, ExamenFinalExtraCard},
    props:[
        "bloque"
    ],
    computed:{
        getBloqueStyle(){
            /*var style="{font_color} font-weight-medium text-h6 text-xs-h6 text-sm-h5 text-md-h5 text-lg-h4 text-xl-h4";

            if(this.$vuetify.theme.dark)
                style=style.replace("{font_color}","white--text");
            else
                style=style.replace("{font_color}","teal--text text--darken-1");*/

            return "font-weight-medium text-h6 text-xs-h6 text-sm-h5 text-md-h5 text-lg-h4 text-xl-h4";
        },
        getPunteosText(){
            if(this.bloque){
                if(this.bloque.bloque){
                    return `Pts zona usados: ${this.currentzonaPuntos}, Pts exam usados: ${this.currentexamenPuntos}`;
                }
            }
            return "";
        },
        getpostIdentity(){
            if(this.selectedActivity){
                return this.selectedActivity.actividad.idActividadMateriaBloque;
            }
            return "";
        }
    },
    data:function(){
        return ({
            opciones:[
                "Iniciar",
                "Finalizar",
                "Tarea extra",
                "Examen final extra",
                "Parcial extra"
            ],
            deleteactivityDialog:false,
            selectedActivity:"",
            deletecontent:"",
            deletecontext:"",
            currentzonaPuntos:0,
            currentexamenPuntos:0
        });
    },
    methods:{
        onlyExamenFinalExtra(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Examen final extra')
                    return true;
            return false;
        },
        onlyParcialExtra(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Parcial extra')
                    return true;
            return false;
        },
        onlyParcial(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Parcial')
                    return true;
            return false;
        },
        onlyExamenFinal(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Examen final')
                    return true;
            return false;
        },
        onlyTarea(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Tarea')
                    return true;
            return false;
        },
        onlyActividadExtra(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Tarea extra')
                    return true;
            return false;
        },
        onlyMaterial(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Material')
                    return true;
            return false;
        },
        updateExamenFinalExtra(actividad){
            this.$emit("onupdateexamenfinalextra",actividad);
        },
        ondetailsExamenFinalExtra(actividad){
            this.$emit("ondetailsexamenfinalextra",actividad);
        },
        updateParcialExtra(actividad){
            this.$emit("onupdateparcialextra",actividad);
        },
        detailsParcialExtra(actividad){
            this.$emit("ondetailsparcialextra",actividad);
        },
        updateParcial(actividad){
            this.$emit("onupdateparcial",actividad);
        },
        detailsParcial(actividad){
            this.$emit("ondetailsparcial",actividad);
        },
        updateExamenFinal(actividad){
            this.$emit("onupdateexamenfinal",actividad);
        },
        detailsExamenFinal(actividad){
            this.$emit("ondetailsexamenfinal",actividad);
        },
        updateTarea(actividad){
            this.$emit("onupdatetarea",actividad);
        },
        detailsTarea(actividad){
            this.$emit("ondetailstarea",actividad);
        },
        updateTareaExtra(actividad){
            this.$emit("onupdatetareaextra",actividad);
        },
        detailsTareaExtra(actividad){
            this.$emit("ondetailstareaextra",actividad);
        },
        updateMaterial(actividad){
            this.$emit("onupdatematerial",actividad);
        },
        detailsMaterial(actividad){
            this.$emit("ondetailsmaterial",actividad);
        },
        deleteActividad(actividad){
            switch(actividad.actividad.tipoActividadNavigation.nombre){
                case "Parcial":
                    this.deletecontext="parcial";
                    var tituloparcial=actividad.title;
                    if(actividad.title.length>20){
                        tituloparcial=`${tituloparcial.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el parcial con título "${tituloparcial}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Examen final":
                    this.deletecontext="examen final";
                    var tituloexamenfinal=actividad.title;
                    if(actividad.title.length>20){
                        tituloexamenfinal=`${tituloexamenfinal.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el exámen final con título "${tituloexamenfinal}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Tarea":
                    this.deletecontext="tarea";
                    var titulo=actividad.title;
                    if(actividad.title.length>20){
                        titulo=`${titulo.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar la tarea con título "${titulo}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Material":
                    this.deletecontext="material";
                    var titulomaterial=actividad.title;
                    if(actividad.title.length>20){
                        titulomaterial=`${titulomaterial.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar la tarea con título "${titulomaterial}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Tarea extra":
                    this.deletecontext="tarea extra";
                    var tituloExtra=actividad.title;
                    if(actividad.title.length>20){
                        tituloExtra=`${tituloExtra.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar la tarea extra con título "${tituloExtra}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Parcial extra":
                    this.deletecontext="parcial extra";
                    var tituloExtraParcial=actividad.title;
                    if(actividad.title.length>20){
                        tituloExtraParcial=`${tituloExtraParcial.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el parcial extra con título "${tituloExtraParcial}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
            }
        },
        ondeletePost(response){
            this.$emit("ondeletepost",response);
        },
        getColorBloque(){
            var color=this.$vuetify.theme.dark?`white`:`black`;

            if(this.bloque.bloque.fechaIniciado){
                color="green";
            }
            if(this.bloque.bloque.fechaIniciado&&this.bloque.bloque.fechaTerminado){
                color="primary";
            }

            return color;
        },
        validateItem(opcion){
            switch(opcion.toLowerCase()){
                case "iniciar":
                    if(this.bloque.bloque.fechaIniciado){
                        return false;
                    }
                    
                    return true;
                case "finalizar":
                    if(!this.bloque.bloque.fechaIniciado){
                        return false;
                    }

                    if(this.bloque.bloque.fechaTerminado){
                        return false;
                    }
                    return true;
                case "examen final extra":case "parcial extra":case "tarea extra":
                    if(this.bloque.bloque.fechaIniciado&&this.bloque.bloque.fechaTerminado){
                        return true;
                    }
                    return false;
            }
        },
        getInicioFinText(bloque){
            var text=`No iniciado`;

            if(bloque.fechaIniciado){
                text=`Iniciado: ${bloque.fechaIniciado.split("T")[0]}`;
            }

            return text;
        },
        getFinText(bloque){
            var text="";

            if(bloque.fechaTerminado){
                text+=`Finalizado: ${bloque.fechaTerminado.split("T")[0]}`;
            }

            return text;
        }
    },
    created(){
        var zonasAct=this.bloque.actividades.filter(item=>item.actividad.tipoActividad==3||item.actividad.tipoActividad==1);
        var examAct=this.bloque.actividades.filter(item=>item.actividad.tipoActividad==2);

        zonasAct.forEach(item=>{
            if(item.actividad.valorNeto)
                this.currentzonaPuntos+=item.actividad.valorNeto;
        });

        examAct.forEach(item=>{
            if(item.actividad.valorNeto)
                this.currentexamenPuntos+=item.actividad.valorNeto;
        });
    }
}
</script>
<style scoped>

</style>