<template>
    <v-app-bar flat :color="$vuetify.theme.dark?'':'white'" outlined fixed>
        <v-btn icon @click="$router.go(-1);"><v-icon>{{iconString}}</v-icon></v-btn>
        <v-toolbar-title>
            {{name}}
        </v-toolbar-title>
        <v-spacer/>

        <v-tooltip bottom>
            <template v-slot:activator="{on,attr}">
                <v-btn icon v-on="on" v-bind="attr"><v-icon>more_vert</v-icon></v-btn>
            </template>
            <span>Opciones</span>
        </v-tooltip>
        <v-progress-linear indeterminate bottom absolute v-if="allLoaded==0" :color="accentColorString"></v-progress-linear>
    </v-app-bar>    
</template>
<script>
export default {
    props:{
        allLoaded:Number,
        name:String,
        icon:String,
        accentColor:String
    },
    computed:{
        iconString(){
            if(this.icon){
                return this.icon;
            }

            return "arrow_back";
        },
        accentColorString(){
            if(this.accentColor){
                return this.$store.state.accentColor2;
            }
            return this.$store.state.accentColor;
        }
    }
}
</script>
<style scoped>

</style>