var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('delete-dialog',{attrs:{"show":_vm.deletePagoDialog,"context":'pago de nivel',"content":`¿Quieres eliminar el pago '${_vm.nombrePago}' del nivel '${_vm.selectedNivelEstudiantil.nombre}'?`,"idEntity":_vm.selectedPago.idPagoNivelEstudiantil},on:{"ondelete":_vm.ondeletePago,"onclose":function($event){_vm.deletePagoDialog=false;}}}),_c('delete-dialog',{attrs:{"show":_vm.deletepagocarreraDialog,"context":'pago de carrera',"content":_vm.getTextDeleteDialogPagoCarrera(),"idEntity":_vm.selectedPago.idPagoNivelEstudiantilCarrera},on:{"ondelete":_vm.ondeletePagoCarrera,"onclose":function($event){_vm.deletepagocarreraDialog=false;}}}),_c('pago-nivel-dialog',{attrs:{"pagonivelDialog":_vm.pagonivelDialog,"nivelestudiantil":_vm.selectedNivelEstudiantil,"pagoToEdit":_vm.selectedPago,"action":_vm.action},on:{"onclose":function($event){_vm.pagonivelDialog=false;}}}),_c('pago-carrera-dialog',{attrs:{"pagocarreraDialog":_vm.pagocarreraDialog,"carrera":_vm.selectedCarrera,"pagoToEdit":_vm.selectedPago,"action":_vm.action},on:{"onclose":function($event){_vm.pagocarreraDialog=false;}}}),(_vm.allLoaded)?_c('div',[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"justify":"center"}},[_c('div',{staticClass:"text-h4 font-weight-bold"},[_vm._v(_vm._s(_vm.getCicloEscolar))])]),_c('br'),_c('v-row',{staticClass:"pa-0 ma-0"},[_vm._l((_vm.nivelesData),function(nivel){return _c('v-col',{key:nivel.codigoNivelEstudiantil,attrs:{"cols":"12","xs":"12","sm":"6","md":"4","lg":"4","xl":"3"}},[_c('pagos-niveles-card',{attrs:{"nivel":nivel},on:{"onaddpagos":()=>{
                _vm.selectedNivelEstudiantil=nivel;
                _vm.action='add';
                _vm.pagonivelDialog=true;
                },"ondeletePago":(item)=>{
                    _vm.selectedPago=item;
                    _vm.nombrePago=item.idPagoNavigation.descripcion;
                    _vm.selectedNivelEstudiantil=nivel;
                    _vm.deletePagoDialog=true;
                },"onupdatePago":(item)=>{
                    _vm.selectedNivelEstudiantil=nivel;
                    _vm.selectedPago=item;
                    _vm.action='edit';
                    _vm.pagonivelDialog=true;
                    }}})],1)}),_vm._l((_vm.carrerasData),function(carrera){return _c('v-col',{key:carrera.idCarrera,attrs:{"cols":"12","xs":"12","sm":"6","md":"4","lg":"4","xl":"3"}},[_c('pagos-carreras-card',{attrs:{"carrera":carrera},on:{"onaddpagos":()=>{
                    _vm.selectedCarrera=carrera;
                    _vm.action='add'
                    _vm.pagocarreraDialog=true;
                    },"ondeletePago":(item)=>{
                        _vm.selectedPago=item;
                        _vm.nombrePago=item.idPagoNavigation.descripcion;
                        _vm.selectedCarrera=carrera;
                        _vm.deletepagocarreraDialog=true;
                    },"onupdatePago":(item)=>{
                        _vm.selectedCarrera=carrera;
                        _vm.selectedPago=item;
                        _vm.action=`edit`;
                        _vm.pagocarreraDialog=true;
                        }}})],1)})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }