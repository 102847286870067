import Vue from 'vue';
import store from '../store/store';

let notificationsWorker="";
//let interval=180000;
let interval=300000;
//let interval=7000;

function loadAllPendientes(){return Vue.prototype.$axios.get(`api/estudiantil/actividades/pendientes/${store.state.cicloEscolar.cicloEscolar1}`)}
async function CallGetNotifications(){
    if(!store.state.isLoadingPendientes){
        store.commit("setIsLoadingPendientes",true);
        await loadAllPendientes().then((response)=>{
            if(response.status==200)
                if(response.data.code==1)
                    store.commit("setNotificationsPendientes",response.data.data);
        }).catch((error)=>{
            error;
        });
        store.commit("setIsLoadingPendientes",false);
    }
}

function InitializeSyncronizer(){
    notificationsWorker=setInterval(async ()=>{
        await CallGetNotifications();
    },interval);
}

async function Initialize(){
    await CallGetNotifications();
    InitializeSyncronizer();
}

function StopWorker(){
    clearInterval(notificationsWorker);
}

export {Initialize,StopWorker,CallGetNotifications};