<template>
    <v-dialog fullscreen transition="scale-transition" v-model="show" persistent no-click-animation>
        <v-card tile>
            <v-app-bar fixed flat :color="$vuetify.theme.dark?'':'white'">
                <v-btn icon @click="closeDialog()"><v-icon>arrow_back</v-icon></v-btn>
                <v-toolbar-title>Detalles de parcial extra</v-toolbar-title>
                <v-progress-linear background-color="transparent" indeterminate bottom absolute v-if="isLoading" :color="$store.state.accentColor2"></v-progress-linear>
            </v-app-bar>
                <v-container fluid class="body">
                    <video-player :videoplayerDialog="videoplayerDialog" @onclose="videoplayerDialog=false;"
                    :video="selectedVideo"
                    ></video-player>

                    <video-attach-dialog :from="'estudiantes'" :videoDialog="videoDialog" @onclose="videoDialog=false;" @onaddVideo="onaddVideo"></video-attach-dialog>
                    <file-attach-dialog :fileDialog="fileDialog" @onclose="fileDialog=false;" @onaddFile="onaddFile"></file-attach-dialog>
                    <texto-dialogo :actiontext="actiontext" :texttoEdit="mitrabajo.contenido" :contenidoDialog="contenidoDialog" @onclose="contenidoDialog=false;" @onagregar="onagregarcontenido"></texto-dialogo>
                    

                    <v-dialog persistent max-width="400" v-model="anularentregaDialog">
                        <v-card :loading="isLoading" :disabled="isLoading">
                            <v-card-title>
                                Confirmación
                            </v-card-title>
                            <v-card-text>
                                ¿Deseas anular la entrega de tu parcial?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="anularentregaDialog=false">Cancelar</v-btn>
                                <v-btn text @click="onanularEntregaCall" :color="$store.state.accentColor2">Anular</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent max-width="400" v-model="startstopDialog">
                        <v-card :loading="isLoadingDialog" :disabled="isLoadingDialog">
                            <v-card-title>
                                Confirmación
                            </v-card-title>
                            <v-card-text>
                                {{startstopText}}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="startstopDialog=false">Cancelar</v-btn>
                                <v-btn text @click="onstartstopCall" :color="$store.state.accentColor2">{{startstopAction}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent width="350" v-model="completarDialog">
                        <v-card  :loading="isLoading" :disabled="isLoading">
                            <v-card-title>
                                Completar parcial
                            </v-card-title>
                            <v-card-text>
                                ¿Desea marcar el parcial como completado sin entregar nada?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="completarDialog=false">Cancelar</v-btn>
                                <v-btn text @click="oncompleteCall" :color="$store.state.accentColor2">Completar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent width="350" v-model="entregarDialog">
                        <v-card :loading="isLoading" :disabled="isLoading">
                            <v-card-title>
                                Entregar parcial
                            </v-card-title>
                            <v-card-text>
                                ¿Desea entregar su trabajo y terminar el parcial?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="entregarDialog=false">Cancelar</v-btn>
                                <v-btn text @click="onentregarExamenCall" :color="$store.state.accentColor2">Entregar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-row no-gutters justify="center">
                        <div style="max-width:1500px;width:1000px">
                            <v-row no-gutters v-if="!isLoading">
                                <v-col>
                                    <v-list-item class="pa-0">
                                        <v-list-item-avatar :color="$store.state.accentColor2" v-if="$vuetify.breakpoint.smAndUp">
                                            <v-icon color="white">
                                                {{getIconText}}
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title :class="getTitleStyle">
                                                {{entregaParcial.examenFinal.title}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="text-subtitle-2 wrap-text">
                                                {{getCatedraticoNombres}} - {{getFechaCreada}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-subtitle class="text-subtitle-2 font-weight-bold mb-5">
                                                <br>
                                                <v-row class="mb-1" no-gutters>
                                                    {{getValorNeto}} puntos <v-spacer></v-spacer> Fecha de examen: {{getFechaLimite}}
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-spacer></v-spacer>
                                                    Duración: {{getDuracion}} min
                                                </v-row>
                                            </v-list-item-subtitle>
                                            <v-divider :color="getDividerColor"></v-divider>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                            
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-body-2 wrap-text" v-html="linkfy(parcial.instructions,{
                                            target: {
                                                url: '_blank'
                                                }
                                            }
                                        )">
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0" v-if="parcial.videos.length>0">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                            
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <br>
                                            <v-row no-gutters class="pb-5">
                                                <v-col v-for="(video,index) in parcial.videos" :key="index" cols="12">
                                                    <video-card :videoInfo="video" :action="'get'" @onclickvideo="playvideo(video)"></video-card>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0" v-if="parcial.archivos.length>0">
                                        <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <br>
                                            <v-row no-gutters class="py-0 card-text">
                                                <v-col cols="12" xs="12" v-for="(file,index) in parcial.archivos" :key="index">
                                                    <v-card outlined ripple :href="file.url" target="_blank">
                                                        <v-list-item>
                                                            <v-list-item-icon>
                                                                <v-icon>insert_drive_file</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="text-subtitle-2 wrap-text">{{getFileName(file.name)}}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <br>
                                    <v-list-item class="pa-0">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-avatar>
                                        <v-list-item-content class="pa-2">
                                            <v-card v-if="isToPlay">
                                                <v-card-text>
                                                    <div class="text-caption">Nota: Para ver el contenido de tu parcial debes iniciar antes el parcial.</div>
                                                    <br>
                                                    <v-row no-gutters justify="center">
                                                        <v-btn @click="startExamen" v-if="isToPlay" outlined depressed :color="$store.state.accentColor2" dark>
                                                            <v-icon>play_arrow</v-icon>
                                                            Iniciar parcial
                                                        </v-btn>
                                                    </v-row>
                                                    <br>
                                                </v-card-text>
                                            </v-card>
                                            <v-card class="rounded-xl" elevation="5" v-if="!isToPlay">
                                                <v-card-text>
                                                    <div>
                                                        <v-row no-gutters justify="center" class="text-h4 text-sm-h3 font-weight-bold">
                                                            {{playCountdownText}}
                                                        </v-row>
                                                    </div>
                                                    <br>
                                                    <v-divider :color="getDividerColor"></v-divider>
                                                    <v-row no-gutters class="py-1"></v-row>
                                                    <v-row no-gutters class="text-subtitle-2">
                                                        Tu entrega
                                                        <v-spacer></v-spacer>
                                                        <div :class="statusEntregaClass">{{getStatusEntrega}}</div>
                                                    </v-row>
                                                    <br>
                                                    <div v-if="isEntregable">
                                                        <v-row no-gutters class="mb-5">
                                                            <v-menu offset-y>
                                                                <template v-slot:activator="{on}">
                                                                    <v-btn v-on="on" class="normal-text" text outlined block :color="$store.state.accentColor2">
                                                                        <v-icon>add</v-icon> Agregar
                                                                    </v-btn>
                                                                </template>
                                                                <v-list class="pa-0" dense>
                                                                    <v-list-item v-for="(item,index) in attachments" :key="index" @click="onselectOption(index)">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{item}}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </v-row>
                                                        <v-row no-gutters v-if="!hasEntrega">
                                                            <v-btn @click="oncompleteExamen" class="normal-text" dark block :color="$store.state.accentColor2">Marcar como completada</v-btn>
                                                        </v-row>
                                                        <v-row no-gutters v-if="hasEntrega" class="mb-5">
                                                            <v-btn @click="onentregarExamen" class="normal-text" dark block :color="$store.state.accentColor2">Entregar</v-btn>
                                                        </v-row>
                                                        <v-row no-gutters v-if="hasEntrega">
                                                            <v-btn @click="onborrarMitrabajo" small class="normal-text" dark block :color="$store.state.accentColor2" text>Borrar mi trabajo</v-btn>
                                                        </v-row>
                                                    </div>
                                                    <v-container v-if="isDescriptible" fill-height style="height:100px;" class="pa-0 ma-0">
                                                        <v-row no-gutters align-content="center">
                                                            <v-col align="center">
                                                                <div :class="descriptiveStatusClass">{{getDescriptiveStatus}}</div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                    <div v-if="isAnulable">
                                                        <v-row no-gutters class="mb-5">
                                                            <v-btn @click="onanularEntrega" class="normal-text" dark block :color="$store.state.accentColor2" outlined>Anular entrega</v-btn>
                                                        </v-row>
                                                    </div>
                                                    <v-divider v-if="isDescriptible" :color="getDividerColor"></v-divider>
                                                    <div  v-if="mitrabajo.contenido">
                                                        <br>
                                                        <p class="text-body-2 text-justify ma-0" 
                                                        v-html="linkfy(mitrabajo.contenido,{
                                                                target: {
                                                                    url: '_blank'
                                                                    }
                                                                }
                                                            )">{{mitrabajo.contenido}}</p>
                                                        <div v-if="isEntregable">
                                                            <v-btn x-small rounded dark outlined :color="$store.state.accentColor2" @click="oneditText" class="mr-2">Editar</v-btn>
                                                            <v-btn x-small rounded dark outlined :color="$store.state.accentColor2" @click="ondeleteText">Borrar</v-btn>
                                                        </div>
                                                    </div>
                                                    <div v-if="mitrabajo.videos.length>0||mitrabajo.archivos.length>0||archivos.length>0">
                                                        <br>
                                                        <v-row no-gutters class="pb-5">
                                                            <v-col v-for="(video,index) in mitrabajo.videos" :key="index" cols="12">
                                                                <video-card :videoInfo="video" :action="getactionVideo" @ondelete="ondeleteVideo" @onclickvideo="playvideo(video)"></video-card>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters v-if="isEntregable">
                                                            <v-col v-for="(file,index) in archivos" :key="index" cols="12">
                                                                <file-card :file="file"  @ondelete="ondeleteFile"></file-card>
                                                            </v-col>
                                                            <v-col v-for="(file,index) in mitrabajo.archivos" :key="index" cols="12">
                                                                <file-card :file="file" @ondelete="ondeletesavedFile"></file-card>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters v-if="!isEntregable">
                                                            <v-col cols="12" v-for="(file,index) in mitrabajo.archivos" :key="index">
                                                                <file-card :file="file" :publico="true"></file-card>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0 ma-0"
                                    v-if="parcial.hasData">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-avatar>
                                        <v-list-item-content class="pa-2">
                                            <comments-privados @onerror="onerror" 
                                            @onupdateComments="(comments)=>{onupdateComments(comments)}" 
                                            :comentarios="entregaParcial.comentarios" 
                                            from="parcial" 
                                            :actividad="entregaParcial"></comments-privados>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0"
                                    v-if="parcial.hasData">
                                        <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-divider></v-divider>
                                            <comments-publicos @onerror="onerror"
                                            @onupdateComments="(comments)=>{onupdatePublicComments(comments)}" 
                                            :comentarios="parcial.comentarios" 
                                            from="parcial" 
                                            :actividad="parcial"></comments-publicos>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </div>
                    </v-row>
                </v-container>
        </v-card>
    </v-dialog>    
</template>
<script>
import TextoDialogo from '../../tarea/TextoDialogo.vue';
import GetReadableDate from '../../../../../../utils/timeUtils';
import VideoPlayer from '../../../../../colaborador/escritorio/common/VideoPlayer.vue';
import linkfy from 'linkifyjs/lib/linkify-string';
import VideoAttachDialog from '../../../../../colaborador/escritorio/common/VideoAttachDialog.vue';
import FileAttachDialog from '../../../../../colaborador/escritorio/common/FileAttachDialog.vue';

import VideoCard from '../../../../../colaborador/escritorio/common/VideoCard.vue';
import FileCard from '../../../../../colaborador/escritorio/common/FileCard.vue';
import CommentsPrivados from '../../CommentsPrivados.vue';
import CommentsPublicos from '../../CommentsPublic.vue';

//import AppBar2 from '../../../../globalComponents/AppBar2.vue';
export default {
    components:{TextoDialogo,VideoPlayer,VideoAttachDialog,VideoCard,FileAttachDialog,FileCard,CommentsPublicos,CommentsPrivados},
  //components: { AppBar2 },
    props:[
        //"tarea"
    ],
    computed:{
        getDividerColor(){return this.$vuetify.theme.dark?'white':'black';},
        getactionVideo(){
            if(this.entregaParcial.participante)
                if(this.entregaParcial.participante.idEstadoActividad==1)
                    return "add";
            return "get";
        },
        hasEntrega(){
            if(this.mitrabajo.contenido||this.mitrabajo.videos.length>0||this.mitrabajo.archivos.length>0||this.archivos.length>0)
                return true;
            return false;
        },
        isEntregable(){
            if(this.entregaParcial.participante){
                if(this.entregaParcial.participante.idEstadoActividad==1){
                    if(this.currentdate<=this.lastdate)
                        return true;
                }
            }

            return false;
        },
        isAnulable(){
            if(this.entregaParcial.participante.idEstadoActividad==2)
                if(this.entregaParcial.examenFinal.actividad.examenFechaHoraIniciado)
                    if(this.currentdate<=this.lastdate)
                        return true;
            return false;
        },
        isDescriptible(){
            if(this.entregaParcial.participante){
                if(this.entregaParcial.participante.idEstadoActividad!=1)
                    return true;
                else if(this.currentdate>this.lastdate)
                    return true;
            }
            return false;
        },
        statusEntregaClass(){
            var baseClass=`text-subtitle-2 {color} font-weight-medium`

            if(this.entregaParcial.participante){
                switch(this.entregaParcial.participante.idEstadoActividad){
                    case 1:
                        if(this.lastdate)
                            if(this.currentdate>this.lastdate)
                                return baseClass.replace("{color}","red--text");
                        return baseClass;
                    case 2:return baseClass.replace("{color}","");
                    case 3:return baseClass.replace("{color}","green--text");
                    case 4:return baseClass.replace("{color}","red--text");
                    case 5:return baseClass.replace("{color}","red--text");
                }
            }

            return baseClass.replace("{color}","red--text");
        },
        getStatusEntrega(){
            if(this.entregaParcial.participante){
                switch(this.entregaParcial.participante.idEstadoActividad){
                    case 1:
                        if(this.lastdate)
                            if(this.currentdate>this.lastdate)
                                return "No entregado";
                        return "Pendiente de realizar"
                    case 2: return "Entregado";
                    case 3: return "Calificado";
                    case 4: return "Anulado";
                    case 5: return "Rechazado";
                }
            }

            return "Sin estado";
        },
        getDescriptiveStatus(){
            if(this.entregaParcial.participante){
                switch(this.entregaParcial.participante.idEstadoActividad){
                    case 1:return "Parcial no entregado";
                    case 2:return "Calificarán pronto tu parcial";
                    case 3:return `${this.entregaParcial.participante.actividadesMateriasBloquesEntregas.calificacion}/${this.entregaParcial.examenFinal.actividad.valorNeto}`;
                    case 4:return "Parcial anulado";
                    case 5:return "Parcial rechazado";
                }
            }

            return "";
        },
        descriptiveStatusClass(){
            var baseClass=`text-{size} font-weight-bold`

            if(this.entregaParcial.participante){
                switch(this.entregaParcial.participante.idEstadoActividad){
                    case 1:return baseClass.replace("{size}","h6").concat(" red--text");
                    case 2:return baseClass.replace("{size}","h6");
                    case 3:
                        var punteo=this.entregaParcial.participante.actividadesMateriasBloquesEntregas.calificacion;
                        if(punteo>(this.entregaParcial.examenFinal.actividad.valorNeto/2))
                            baseClass+=" green--text";
                        else if(punteo<(this.entregaParcial.examenFinal.actividad.valorNeto/2))
                            baseClass+=" red--text";
                        return baseClass.replace("{size}","h4")
                    case 4:return baseClass.replace("{size}","h6").concat(" red--text");
                    case 5:return baseClass.replace("{size}","h6").concat(" red--text");
                }
            }

            return "";
        },
        isToPlay(){
            if(this.entregaParcial.participante.examenFechaHoraIniciado){
                return false;
            }else{
                if(this.entregaParcial.participante.idEstadoActividad!=1)
                    return false;
                else
                    return true;
            }
        },
        playCountdownText(){
            if(this.isToPlay){
                return "Parcial no iniciado";
            }else{
                var isenCurso=this.entregaParcial.participante.examenEnCurso;

                if(isenCurso==false){
                    return "Parcial finalizado";
                }else{
                    if(this.entregaParcial.participante.examenFechaHoraFinalizado)
                        var horaFinalizado=new Date(this.entregaParcial.participante.examenFechaHoraFinalizado);
                    var horaFinalizadoGlobal=new Date(this.entregaParcial.examenFinal.actividad.examenFechaHoraFinalizado);

                    if(horaFinalizado)
                        if(new Date()>horaFinalizadoGlobal||new Date()>horaFinalizado)
                            return "Parcial finalizado";
                            
                    if(new Date()>horaFinalizadoGlobal)
                        return "Parcial finalizado";

                    if(this.getDescriptiveStatus!="Parcial no entregado")
                        return "Parcial finalizado";

                    return this.timer;
                }
            }
        },
        getDuracion(){
            if(this.entregaParcial.examenFinal)
                return this.entregaParcial.examenFinal.actividad.examenTiempoMaximo;
            return "";
        },
        getTitleStyle(){
            return `text-h5 text-sm-h4 text-md-h4 ${this.$store.state.accentColor2}--text wrap-text`;
        },
        getIconText(){
            if(this.entregaParcial.examenFinal)
                return this.entregaParcial.examenFinal.actividad.tipoActividadNavigation.icono;
            return "";
        },
        getFechaCreada(){
            if(this.entregaParcial.examenFinal){
                return this.getHumanDate(this.entregaParcial.examenFinal.actividad.fechaCreada);
            }

            return "";
        },
        getFechaLimite(){
            if(this.entregaParcial.examenFinal){
                var nohour=this.getHumanDate(this.entregaParcial.examenFinal.actividad.fechaLimite);
                nohour=nohour.substring(0,nohour.length-6);
                return nohour;
            }

            return "";
        },
        getCatedraticoNombres(){
            if(this.entregaParcial.examenFinal){
                if(this.entregaParcial.examenFinal.catedratico)
                    return this.entregaParcial.examenFinal.catedratico.nombres;
            }
            return "Catedrático";
        },
        getValorNeto(){
            if(this.entregaParcial.examenFinal){
                return this.entregaParcial.examenFinal.actividad.valorNeto;
            }
            return "";
        },
        getpostIdentity(){
            if(this.entregaParcial.examenFinal){
                return this.entregaParcial.examenFinal.actividad.idActividadMateriaBloque;
            }
            return "";
        }
    },
    data:function(){
        return ({
            currentdate:"",
            lastdate:"",
            show:false,
            startstopDialog:false,
            startstopText:"",
            startstopAction:"Iniciar",
            videoplayerDialog:false,
            anularentregaDialog:false,
            selectedVideo:"",
            entregaParcial:"",
            parcial:"",
            isLoading:true,
            isLoadingDialog:false,
            timer:"",
            countdown:"",
            attachments:[
                "Archivos",
                "Video YouTube",
                "Texto"
            ],
            mitrabajo:{
                videos:[],
                archivos:[],
                contenido:"",
            },
            archivos:[],
            actiontext:"add",
            completarDialog:false,
            entregarDialog:false,
            contenidoDialog:false,
            videoDialog:false,
            fileDialog:false,
        })
    },
    methods:{
        onerror(){
            this.loadAll();
        },
        onupdatePublicComments(comments){
            this.parcial.comentarios=comments;
        },
        onupdateComments(comments){
            this.entregaParcial.comentarios=comments;
        },
        linkfy,
        getHumanDate: GetReadableDate,
        onselectOption(option){
            switch(option){
                case 0:
                    this.fileDialog=true;
                    break;
                case 1:
                    this.videoDialog=true;
                    break;
                case 2:
                this.contenidoDialog=true;
                    break;
            }
        },
        onaddFile(files){
            var currentAmountFiles=this.mitrabajo.archivos.length+this.archivos.length;

            if(currentAmountFiles<=5){
                if((currentAmountFiles+files.length)<=5){
                    this.archivos.push(...files);
                    this.fileDialog=false;

                    return;
                }
            }
            this.fileDialog=false;
            alert("No se pueden agregar más de 5 archivos a la publicación.");
        },
        ondeletesavedFile(fileItem){
            var index=this.mitrabajo.archivos.indexOf(fileItem);
            this.mitrabajo.archivos.splice(index,1);
        },
        ondeleteFile(fileItem){
            var index=this.archivos.indexOf(fileItem);
            this.archivos.splice(index,1);
        },
        onaddVideo(videoInfo){
           if(!this.mitrabajo.videos.some(item=>item.id==videoInfo.id)){
                this.mitrabajo.videos.push(videoInfo);
                this.videoDialog=false;
            }
        },
        onagregarcontenido(contenido){
            this.mitrabajo.contenido=contenido;
        },
        ondeleteVideo(videoItem){
            var index=this.mitrabajo.videos.indexOf(videoItem);
            this.mitrabajo.videos.splice(index,1);
        },
        ondeleteText(){
            this.mitrabajo.contenido="";
        },
        oneditText(){
            this.actiontext="edit";
            this.contenidoDialog=true;
        },
        onborrarMitrabajo(){
            this.mitrabajo={
                videos:[],
                archivos:[],
                contenido:"",
            };

            this.videos=[];
            this.archivos=[];
        },
        oncompleteExamen(){
            this.completarDialog=true;
        },
        onentregarExamen(){
            this.entregarDialog=true;
        },
        async onstartstopCall(){
            clearInterval(this.countdown);

            switch(this.startstopAction){
                case "Iniciar":
                    await this.startCall();
                    break;
                case "Entregar":
                    await this.stopCall();
                    break;
            }

            this.startstopDialog=false;
        },
        startExamen(){
            this.startstopAction="Iniciar";
            this.startstopText="¿Deseas iniciar tu parcial?"
            this.startstopDialog=true;
        },
        stopExamen(){
            this.startstopAction="Entregar";
            this.startstopText="¿Deseas entregar tu parcial?"
            this.startstopDialog=true;
        },
        getFileName(name){
            if(name.length>30){
                var extensionSplitted=name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${name.substring(0,30)}....${extension}`;
            }
            return name;
        },
        playvideo(video){
            this.selectedVideo=video;
            this.videoplayerDialog=true;
        },
        async startCall(){
            this.isLoadingDialog=this.$store.state.accentColor2;

            await this.$axios.get(`api/estudiantil/actividades/parcial/${this.$route.params.idparcial}/iniciar`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        response;
                    }else{
                        this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                    }
                }
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoadingDialog=false;
        },
        onanularEntrega(){
            this.anularentregaDialog=true;
        },
        async onanularEntregaCall(){
            this.isLoading=this.$store.state.accentColor2;

            await this.$axios.put(`api/estudiantil/actividades/parcial/${this.entregaParcial.participante.idActividadMateriaBloqueParticipante}/anular`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 1:
                            this.$store.commit("showMessage",{text:"La entrega de tu parcial ha sido anulada.",color:"green",time:4000});
                            break;
                        default:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            break;
                    }
                }

            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });
            await this.loadAll();

            this.anularentregaDialog=false;
            this.isLoading=false;
        },
        async onentregarExamenCall(){
            var data=new FormData();

            this.archivos.forEach((item)=>{
                data.append("archivos",item);
            });

            data.append("entrega",JSON.stringify(this.mitrabajo));

            this.isLoading=this.$store.state.accentColor2;
            await this.$axios.put(`api/estudiantil/actividades/parcial/${this.$route.params.idparcial}/entregar`,
            data
            ).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 1:
                            this.$store.commit("showMessage",{text:"¡¡Felicidades!! Tu parcial ha sido entregado.",color:"green",time:4000});
                            break;
                        default:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            break;
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });
            await this.loadAll();

            this.entregarDialog=false;
            this.isLoading=false;
        },
        async oncompleteCall(){
            this.isLoading=this.$store.state.accentColor2;
            await this.$axios.put(`api/estudiantil/actividades/parcial/${this.$route.params.idparcial}/completar`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 1:
                            this.$store.commit("showMessage",{text:"¡¡Felicidades!! Tu parcial ha sido entregado.",color:"green",time:4000});
                            break;
                        default:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            break;
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });

            await this.loadAll();

            this.completarDialog=false;
            this.isLoading=false;
        },
        loadParcial(){return this.$axios.get(`api/estudiantil/actividades/parcial/${this.$route.params.idparcial}`);},
        loadCurrentDate(){return this.$axios.get(`api/estudiantil/currentdate`)},
        async loadAll(){
            this.isLoading=true;

            await this.$axios.all([this.loadParcial(),this.loadCurrentDate()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1){
                        this.entregaParcial=response[0].data.data;
                        this.parcial=this.entregaParcial.examenFinal;
                        this.currentdate=new Date(response[1].data);
                        
                        if(this.entregaParcial.examenFinal.actividad.examenFechaHoraFinalizado)
                            this.lastdate=new Date(this.entregaParcial.examenFinal.actividad.examenFechaHoraFinalizado);

                        this.initializeEntrega();
                        clearInterval(this.countdown);
                        this.timerFunction();
                    }else{
                        this.$store.commit("showMessage",{text:response[0].data.message,color:"",time:5000});
                        this.$emit("onloadtareaerror");
                        this.$router.go(-1);
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        initializeEntrega(){
            this.archivos=[];
            if(this.entregaParcial.contenido)
                this.mitrabajo.contenido=this.entregaParcial.contenido;
            if(this.entregaParcial.videos)
                this.mitrabajo.videos=this.entregaParcial.videos;
            if(this.entregaParcial.archivos)
                this.mitrabajo.archivos=this.entregaParcial.archivos;
        },
        timerFunction(){
            var horaLimite=new Date((this.entregaParcial.examenFinal.actividad.examenFechaHoraFinalizado));

            if(this.entregaParcial.participante.examenEnCurso){
                if(this.entregaParcial.participante.examenEnCurso==true){
                    var millisecondsLimite=horaLimite.getTime()-1000;
                    if(new Date().getTime()>=millisecondsLimite)
                        return;
                    if(this.getDescriptiveStatus!="Parcial no entregado")
                        return;
                }else{
                    return;
                }
            }else if(!this.entregaParcial.participante.examenEnCurso){
                return;
            }

            var inicial=(horaLimite-new Date());
            inicial=(inicial/1000)/60;

            var seconds=inicial*60;
            inicial=Math.floor(inicial);

            seconds=Math.round(seconds);
            var restant=Math.floor(seconds/60);
            var currentSecond=seconds-(restant*60);

            if(currentSecond==0)
                inicial=restant;

            if(currentSecond<10)
                currentSecond=`0${currentSecond}`;

            this.timer=`${inicial}:${currentSecond}`;
            this.countdown=setInterval(()=>{
                seconds-=1;

                if(seconds==0){
                    clearInterval(this.countdown);
                    this.loadAll();
                }console.log("countdown");

                var restant=Math.floor(seconds/60);
                if(restant>=1){
                    var currentSecond=seconds-(restant*60);

                    if(currentSecond<10)
                        currentSecond=`0${currentSecond}`;

                    this.timer=`${restant}:${currentSecond}`;
                }else{
                    if(seconds<10)
                        seconds=`0${seconds}`;
                    this.timer=`${restant}:${seconds}`;
                }
            },1000);
        },
        closeDialog(){
            if(this.$router.currentRoute.name=="examen_final_docente_entrega"){
                this.$router.go(-2)
                return;
            }

            this.$router.go(-1)
        },
        onclose(){
            this.show=false;
            this.$router.go(-1);
        },

    },
    created(){
        this.show=true;
        this.loadAll();
    },
    beforeDestroy(){
        clearInterval(this.countdown);
    }
}
</script>
<style scoped>

</style>