<template>
    <v-tooltip left>
        <template v-slot:activator="{on}">
            <v-btn v-on="on" fab bottom fixed right :color="$store.state.accentColor" elevation="0" @click="$emit('onadd')" v-if="$currentYearValidation()">
                <v-icon>add</v-icon>
            </v-btn>
        </template>
        <span>{{tooltipText}}</span>
    </v-tooltip>  
</template>
<script>
export default {
    props:{
        tooltipText:String
    }
}
</script>
<style scoped>

</style>