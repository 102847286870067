<template>
    <v-dialog v-model="gradosguiadosDialog" width="600" persistent scrollable>
        <delete-dialog :context="'grado a cargo'" :content="deleteseccionContent" :show="deleteseccionDialog"
        :idEntity="selectedSeccion.idMaestroGuiaGradoSeccion" @onclose="deleteseccionDialog=false;" @ondelete="onGradoSeccionNotification">

        </delete-dialog>
        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title>Grados a cargo</v-card-title>
            <v-card-text>
                <v-row justify="center">
                    <v-col cols="12" sm="6" md="6">
                        <v-select
                        outlined
                        rounded
                        label="Seleccione grado"
                        :color="$store.state.accentColor"
                        :items="gradosData"
                        item-text="nombre"
                        item-value="idGrado"
                        v-model="grado"
                        @change="onchangeGrado"
                        :item-color="$store.state.accentColor"
                        :loading="isLoadingSelect"
                        :disabled="isLoadingSelect"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-if="grado">
                        <v-select
                        outlined
                        rounded
                        label="Seleccione sección"
                        :color="$store.state.accentColor"
                        :items="seccionesData"
                        :item-text="(item)=>`${item.idSeccionNavigation.seccion}`"
                        item-value="idGradoSeccion"
                        v-model="gradosSecciones"
                        multiple
                        :item-color="$store.state.accentColor"
                        :loading="isLoadingSelect"
                        :disabled="isLoadingSelect"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                    <v-btn rounded outlined :disabled="isEnable" @click="onsaveGrado()">Agregar</v-btn>
                </v-row>
                <br>
                <v-row>
                    <v-col cols="12" sm="6" v-for="(grado,index) in viewgradosseccioensData" :key="index">
                        <v-card outlined>
                            <v-card-title>
                                {{grado.nombre}}
                            </v-card-title>
                            <v-card-text>
                                <div>
                                    <v-chip @click:close="onclicDelete(seccion)" close small class="mr-2" v-for="(seccion,index) in grado.gradosSecciones" :key="index">
                                        Sección {{seccion.idGradoSeccionNavigation.idSeccionNavigation.seccion}}
                                    </v-chip>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :color="$store.state.accentColor" @click="$emit('onclose')">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import DeleteDialog from '../../globalComponents/DeleteDialog.vue';
export default {
  components: { DeleteDialog },
    props:[
        "gradosguiadosDialog",
        "colaborador"
    ],
    computed:{
        isEnable(){
            if(this.gradosSecciones.length>0)
                return false;
            return true;
        }
    },
    watch:{
        gradosguiadosDialog(newval,oldval){
            oldval;
            if(newval){
                this.loadAll();
            }else{
                this.gradosData=[];
                this.seccionesData=[];
                this.gradosSecciones=[];
                this.gradosseccionesData=[];
                this.viewgradosseccioensData=[];
                this.grado="";
            }
        }
    },
    data:function(){
        return ({
            isLoading:false,
            isLoadingSelect:false,
            deleteseccionDialog:false,
            deleteseccionContent:"",
            selectedSeccion:"",
            gradosData:[],
            seccionesData:[],
            gradosseccionesData:[],
            viewgradosseccioensData:[],
            grado:"",
            gradosSecciones:[]
        });
    },
    methods:{
        async onGradoSeccionNotification(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            await this.loadAll();
        },
        onclicDelete(seccion){
            this.deleteseccionContent=`¿Desea eliminar la sección "${seccion.idGradoSeccionNavigation.idSeccionNavigation.seccion}" de los grados a cargo?`;
            this.selectedSeccion=seccion;
            this.deleteseccionDialog=true;
            console.log(seccion);
        },
        onchangeGrado(item){
            this.loadSeccionesCall(item);
        },
        async onsaveGrado(){
            var arraytosave=[];
            this.gradosSecciones.forEach(item=>{
                var newgradoasignado={
                    idColaborador:this.colaborador,
                    idGradoSeccion:item,
                    idCicloEscolar:this.$store.state.cicloEscolar.idCicloEscolar
                };

                arraytosave.push(newgradoasignado);
            });

            this.isLoading=this.$store.state.accentColor;

            var response=await this.$axios.post(`api/colaboradores/${this.colaborador}/grados-guias/create`,
            arraytosave).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        return [1,response.data.message];
                    }
                    return [0,response.data.message];
                }
                return [2];
            }).catch((error)=>{
                return [2,error];
            });
            await this.onGradoSeccionNotification(response);

            this.grado="";
            this.gradosSecciones=[];

            this.isLoading=false;
        },
        async loadSeccionesCall(grado){
            console.log(grado);
            this.isLoadingSelect=true;
            await this.$axios.get(`api/secciones/grado/${grado}`).then((response)=>{
                if(response.status==200)
                    if(response.data.code==1)
                        this.seccionesData=response.data.data;
            }).catch((error)=>{
                error;
            });
            this.isLoadingSelect=false;
        },
        loadGradosGuiados(){return this.$axios.get(`api/colaboradores/${this.colaborador}/grados-guias/?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`)},
        loadGrados(){return this.$axios.get(`api/grados/?type=partial`)},
        async loadAll(){
            this.isLoading=this.$store.state.accentColor;
            await this.$axios.all([this.loadGrados(),this.loadGradosGuiados()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.gradosData=response[0].data.data;
                        this.gradosseccionesData=response[1].data.data;

                        this.viewgradosseccioensData=this.gradosseccionesData.reduce((acumulador,currentValue)=>{
                            if(!acumulador.some(item=>item.idGrado==currentValue.idGradoSeccionNavigation.idGrado)){
                                var newval={
                                    idGrado:currentValue.idGradoSeccionNavigation.idGrado,
                                    nombre:currentValue.idGradoSeccionNavigation.idGradoNavigation.nombre,
                                    gradosSecciones:[currentValue]
                                };

                                acumulador.push(newval);
                            }else{
                                var index=acumulador.findIndex(item=>item.idGrado==currentValue.idGradoSeccionNavigation.idGrado);
                                acumulador[index].gradosSecciones.push(currentValue);
                            }
                            return acumulador;
                        },[]);
                    }
                }
            }).catch((error)=>{
                error;
            })

            this.isLoading=false;
        }
    },
    created(){
        
    }
}
</script>
<style scoped>

</style>