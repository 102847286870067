<template>
    <v-dialog v-model="gradoDialog" max-width="400" persistent>
        <v-card :loading="loading" :disabled="loading" outlined>
            <v-card-title>
                {{titleText}}
            </v-card-title>
            <v-card-text>
                <v-form ref="newGrado">
                    <v-select
                    :color="$store.state.accentColor"
                    label="Nivel estudiantil"
                    :rules="grado.nivelRules"
                    v-model="grado.nivel"
                    :items="nivelesData"
                    item-text="nombre"
                    item-value="codigoNivelEstudiantil"
                    outlined rounded
                    return-object
                    item-color=""
                    @change="onnivelChange"
                    ></v-select>
                    <v-select label="Carreras"
                    v-if="carrerasData.length>0"
                    :color="$store.state.accentColor" 
                    v-model="grado.idCarrera"
                    :rules="grado.idCarreraRules"
                    :items="carrerasData" 
                    :item-text="item =>`${item.idCarreraNavigation.nombre}`"
                    item-value="idCarrera"
                    item-color=""
                    outlined rounded
                    @change="oncarreraChange"
                    ></v-select>
                    <v-select label="Materias" 
                    :color="$store.state.accentColor" 
                    v-model="grado.gradosMaterias"
                    :items="materiasData" 
                    item-text="nombre"
                    item-value="idMateria"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    return-object
                    item-color=""
                    outlined rounded
                    ></v-select>
                    <v-text-field counter
                    maxlength="50" label="Nombre" autocomplete="off" :color="$store.state.accentColor" :rules="grado.nombreRules" v-model="grado.nombre" outlined rounded></v-text-field>
                    <v-select label="Secciones" v-if="false"
                    :color="$store.state.accentColor" 
                    :rules="grado.seccionRules"
                    v-model="grado.seccion"
                    :items="seccionesData" 
                    item-text="seccion"
                    item-value="idSeccion"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    return-object
                    item-color=""
                    outlined rounded></v-select>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose');$refs.newGrado.reset();">Cancelar</v-btn>
                <v-btn text color="green" @click="save(action)">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:[
        "gradoDialog",
        "gradoToEdit",
        "action"
    ],
    watch:{
        gradoDialog: async function(newVal,oldVal){
            oldVal;
            if(newVal){
                if(this.action=='edit'){
                    //this.grado.idGrado=this.gradoToEdit.idGrado;
                    this.grado.nombre=this.gradoToEdit.nombre;
                    this.grado.seccion=this.gradoToEdit.gradosSecciones.map(e=>e.idSeccionNavigation);
                    this.grado.idCarrera=this.gradoToEdit.idCarrera;
                    this.grado.idCarreraNavigation=this.gradoToEdit.idCarreraNavigation;
                    this.grado.gradosMaterias=this.gradoToEdit.gradosMaterias;
                    this.grado.nivel=this.gradoToEdit.idNivelEstudiantilNavigation;
                    this.grado.fechaCreado=this.gradoToEdit.fechaCreado;
                    
                    if(this.grado.nivel.codigoNivelEstudiantil=="03-12-0029-46"){
                        this.materiasData=[];

                        await this.loadCarreras(this.grado.nivel);
                        await this.loadMaterias(this.grado.nivel,this.grado.idCarrera);
                    }else{
                        this.carrerasData=[];

                        await this.loadMaterias(this.grado.nivel);
                    }

                    this.titleText=`Edición de grado`;
                }else{
                    this.titleText="Nuevo grado";

                    delete this.grado.fechaCreado;
                    delete this.grado.idGrado;
                    delete this.grado.seccion;
                }

                await this.loadAll();
            }
        },
    },
    computed:{

    },
    data:()=>({
        titleText:"Nuevo grado",
        seccionesData:[],
        nivelesData:[],
        materiasData:[],
        carrerasData:[],
        grado:{
            nombre:"",
            nombreRules:[
                v => !!v || 'Nombre es requerido.',
                v => ( v &&v.length <= 50) || 'Nombre debe ser de tamaño 50 o menos.',
                v=>/^[(\w)|(\s)]*$/.test(v)||'Nombre debe ser alfanumérico.',
                v=>!/^\s+$/.test(v)||'Nombre no debe ser un espacio en blanco.'
            ],
            idCarrera:"",
            idCarreraRules:[
                v => (!!v && v.length > 0) || 'Carrera es requerida si se selecciona Diversificado.',
            ],
            seccion:"",
            seccionRules:[
                v => (!!v && v.length > 0) || 'Seccion es requerida.',
            ],
            nivel:"",
            nivelRules:[
                v => !!v || 'Nivel estudiantil es requerido.',
            ],
            gradosMaterias:""
        },
        loading:false
    }),
    methods:{   
        async onnivelChange(val){
            if(val.codigoNivelEstudiantil=="03-12-0029-46"){
                this.materiasData=[];
                await this.loadCarreras(val);
            }else{
                this.carrerasData=[];
                this.grado.idCarrera="";

                this.loadMaterias(val);
            }
        },
        async oncarreraChange(val){
            this.loadMaterias(this.grado.nivel,val);
        },
        async save(action){
            this.loading=this.$store.state.accentColor;

            if(action=="edit"){
                await this.updateCall();
            }else{
                await this.saveCall();
            }

            this.loading=false;
        },
        async saveCall(){
            if(this.$refs.newGrado.validate()){
                let newGrado={
                    nombre:this.grado.nombre,
                    idNivelEstudiantil:this.grado.nivel.codigoNivelEstudiantil,
                    idCarrera:this.grado.idCarrera,
                    gradosSecciones: this.grado.seccion,
                    gradosMaterias:this.grado.gradosMaterias
                }

                newGrado.gradosMaterias.forEach(element => {
                    element.idCicloEscolar=this.$store.state.cicloEscolar.idCicloEscolar;
                });

                if(newGrado.gradosSecciones){
                    newGrado.gradosSecciones.forEach(element=>{
                        element.idCicloEscolar=this.$store.state.cicloEscolar.idCicloEscolar;
                    });
                }

                await this.$axios.post(`api/grados?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`,
                    newGrado
                ).then((response)=>{
                    if(response.data.code==1){
                        this.$emit('onsave',[1,response.data.data]);
                        this.$refs.newGrado.reset();
                    }else{
                        this.$emit('onsave',[0,response.data.message]);
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onsave",[2,error.response.data.message]);
                        }else{
                            this.$emit("onsave",[2]);
                        }
                    }
                });

                this.$emit("onclose");
            }
        },
        async updateCall(){
            if(this.$refs.newGrado.validate()){
                let newGrado={
                    idGrado:this.gradoToEdit.idGrado,
                    nombre:this.grado.nombre,
                    idCarrera:this.grado.idCarrera,
                    idNivelEstudiantil:this.grado.nivel.codigoNivelEstudiantil,
                    gradosSecciones: this.grado.seccion,
                    gradosMaterias:this.grado.gradosMaterias,
                    fechaCreado:this.grado.fechaCreado
                }

                newGrado.gradosMaterias.forEach(element => {
                    element.idCicloEscolar=this.$store.state.cicloEscolar.idCicloEscolar;
                });

                newGrado.gradosMaterias.forEach(element=>{
                    var temp=this.gradoToEdit.gradosMaterias.find(item=>item.idMateria==element.idMateria);

                    if(temp){
                        element.idGradoMateria=temp.idGradoMateria;
                    }
                });


                await this.$axios.put(`api/grados/edit/${newGrado.idGrado}?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`,
                    newGrado
                ).then((response)=>{
                    if(response.data.code==1){
                        this.$emit('onupdate',[1,response.data.data,response.data.message]);
                        this.$updateChanges(this.gradoToEdit,response.data.data);

                        this.$refs.newGrado.reset();
                    }else{
                        this.$emit('onupdate',[0,response.data.message]);
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onupdate",[2,error.response.data.message]);
                        }else{
                            this.$emit("onupdate",[2]);
                        }
                    }
                });

                this.$emit("onclose");
            }
        },
        /*loadSecciones(){
            return this.$axios.get("api/secciones");
        },*/
        loadNiveles(){
            return this.$axios.get("api/nivelesestudiantiles");
        },
        async loadCarreras(nivel){
            this.loading=this.$store.state.accentColor;

            await this.$axios.get(`api/carreras/nivel/${nivel.codigoNivelEstudiantil}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.carrerasData=response.data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.loading=false;
        },
        async loadMaterias(nivel,carrera){
            this.loading=this.$store.state.accentColor;
            var url="";

            if(carrera){
                url=`api/materias/nivel/${nivel.codigoNivelEstudiantil}?carrera=${carrera}`;
            }else{
                url=`api/materias/nivel/${nivel.codigoNivelEstudiantil}`;
            }
            
            await this.$axios.get(url).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.materiasData=response.data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.loading=false;
        },
        async loadAll(){
            this.loading=this.$store.state.accentColor;

            await this.$axios.all([this.loadNiveles()]).then((responses)=>{
                if(responses[0].status==200){
                    if(responses[0].data.code==1){
                        this.nivelesData=responses[0].data.data;
                    }
                }
            }).catch((errors)=>{
                errors;
            });

            this.loading=false;
        }
    },
    created(){
        //this.loadAll();
    },
    mounted(){
        //this.loadAll();
    }
}
</script>
<style scoped>

</style>
