import axios from 'axios'
import store from '../store/store'
import routes from '../utils/routerHandler'

axios.defaults.withCredentials=true;
axios.defaults.baseURL=["localhost","192.168.0.10"].some(item=>item.includes(window.location.hostname))?`http://localhost:54631/`:"https://colegiocristianokairos.edu.gt/";
axios.defaults.headers.common['Accept']="text/plain, application/json";

const urlsHeader=[
  "api/estudiantil/",
  "api/docentes/"
];

axios.interceptors.response.use((response)=>{
  return response;
},(error)=>{
  if(error.response.status==401){
    if(store.state.isLoggued){
      if(store.state.profileInfo.isEstudiante){
        store.commit("setIsLoggued",false);
        window.location.href="/login/estudiante";
      }else{
        store.commit("setIsLoggued",false);
        window.location.href="/login/colaborador";
      }
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

axios.interceptors.request.use((request)=>{
  if(urlsHeader.some(item=>request.url.includes(item)))
    if(routes.currentRoute.params.ciclo)
      request.headers.common["Ciclo"]=routes.currentRoute.params.ciclo;
  return request;
},(error)=>{
  console.log(error);

  return Promise.reject(error);
})


export async function verifyJWTs(){
  return await axios.get(`api/authentication`).then((response)=>{
    if(response.status==200){
      if(response.data.code==1){
        if(store.state.profileInfo==""){
      
          if(!response.data.data.cargos){
            return false;
          }
          response.data.data.profileData.cargos=response.data.data.cargos;
          response.data.data.profileData.Ed=response.data.data.profileData.ed;
          response.data.data.profileData.isColaborador=true;
    
    
          store.commit("setProfileInfo",response.data.data.profileData);
        }
    
        store.commit("setIsLoggued",true);

        return true;
      }
    }
    return false;
  }).catch((error)=>{
    error;
    return false;
  });
}

export async function verifyStudentJWTs(){
  return await axios.get(`api/authentication/estudiante`).then((response)=>{
    if(response.status==200){
      //if(response.data.code==1){
        var profileData={
          isEstudiante:true
        };
  
        store.commit("setProfileInfo",profileData);

        store.commit("setIsLoggued",true);
  
        return true;
      //}
    }
    return false;
  }).catch((error)=>{
    error;
    return false;
  });
}

export default axios;
