<template>    
    <v-app id="materias">
        <app-bar :allLoaded="allLoaded" :name="title"></app-bar>
        <materia-dialog :materiaDialog="materiaDialog" :action="action" @onclose="materiaDialog=false;" @onsave="onsaveMateria" @onupdate="onupdateMateria" :materiaToEdit="selectedMateria"></materia-dialog>
        <delete-dialog :show="deleteMateriaDialog" @onclose="deleteMateriaDialog=false;" :content="`¿Quieres eliminar la materia '${selectedMateria.nombre}'?`" context="materia"
        :idEntity="selectedMateria.idMateria" @ondelete="ondeleteMateria"></delete-dialog>
        <div v-if="allLoaded">
            <v-row class="pa-0 ma-0">
                <v-col v-for="(materia) in materiasData" :key="materia.idMateria" cols="12" xs="12" sm="6" lg="4" xl="4">
                    <materia-card @ondelete="deleteMateriaDialog=true;selectedMateria=materia;" @onedit="materiaDialog=true;action='update';selectedMateria=materia" 
                    :materia="materia" ></materia-card>
                </v-col>
            </v-row>
            <add-button tooltipText="Agregar materia" @onadd="()=>{action='add';materiaDialog=true;}"></add-button>
        </div>
    </v-app>
</template>
<script>
import AppBar from '../../../globalComponents/Appbar'
import AddButton from '../../../globalComponents/AddButton'
import MateriaDialog from './MateriaDialog.vue';
import MateriaCard from './MateriaCard.vue';
import DeleteDialog from '../../../globalComponents/DeleteDialog.vue';
export default {
    props:{
        title:String
    },
  components: { AppBar,AddButton,MateriaDialog, MateriaCard, DeleteDialog },
  data:()=>({
      allLoaded:true,
      materiaDialog:false,
      deleteMateriaDialog:false,
      action:"",
      materiasData:[],
      selectedMateria:Object,
      unsubscribe:"",
      intializated:false
  }),
  methods:{
      loadMaterias(){
          return this.$axios.get(`api/materias/nivel/${this.$store.state.currentNivelEstudiantil}`);
      },
      async loadAll(){
          this.allLoaded=false;

          await this.$axios.all([this.loadMaterias()]).then((responses)=>{
              if(responses[0].status==200){
                  if(responses[0].data.code==1){
                      this.materiasData=responses[0].data.data;
                  }
              }
          }).catch((error)=>{
              this.intializated=false;
              error;
          });

          this.allLoaded=true;
      },
      onsaveMateria(response){
          switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.materiasData.push(response[1]);
                    this.$store.commit("showMessage",{text:"Materia agregada correctamente.",color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
          }
      },
      ondeleteMateria(response){
          switch(response[0]){
              case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                break;
            case 1:
                this.materiasData.splice(this.materiasData.indexOf(this.selectedMateria),1);

                this.$store.commit("showMessage",{text:"Materia eliminada correctamente.",color:"green",time:4000});
                break;
            case 2:
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                break;
          }
      },
      onupdateMateria(response){
          switch(response[0]){
            case 0:
                this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                break;
            case 1:
                this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                break;
            case 2:
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                break;
          }
      }
  },
  created(){
      this.loadAll();
      
        this.unsubscribe=this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'setCurrentNivel') {
                state;

                this.loadAll();
            }
        });
    },
    beforeDestroy(){
        this.unsubscribe();
    }
}
</script>
<style scoped>
.delete_button{
    position:absolute;right:0;top:0;
    border-color: red;
}
</style>