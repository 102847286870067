<template>
    <v-dialog fullscreen transition="scale-transition" v-model="show" persistent no-click-animation>
        <v-card tile>
            <v-app-bar fixed flat :color="$vuetify.theme.dark?'':'white'" outlined>
                <v-btn icon @click="closeDialog()"><v-icon>arrow_back</v-icon></v-btn>
                <v-toolbar-title>Tarea</v-toolbar-title>
                <v-progress-linear background-color="transparent" indeterminate bottom absolute v-if="isLoading" :color="$store.state.accentColor2"></v-progress-linear>
            </v-app-bar>
                <v-container fluid class="body">
                    <v-row no-gutters justify="center">
                        <div style="max-width:1500px;width:1000px">
                            <video-player :videoplayerDialog="videoplayerDialog" @onclose="videoplayerDialog=false;"
                            :video="selectedVideo"
                            ></video-player>
                            <v-row no-gutters v-if="!isLoading">
                                <v-col>
                                    <v-list-item class="pa-0">
                                        <v-list-item-avatar :color="$store.state.accentColor2" v-if="$vuetify.breakpoint.smAndUp">
                                            <v-icon color="white">
                                                {{getIconText}}
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title :class="getTitleStyle">
                                                {{tarea.title}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="text-subtitle-2 wrap-text">
                                                {{getCatedraticoNombres}} - {{getFechaCreada}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-subtitle class="text-subtitle-2 font-weight-bold mb-5">
                                                <br>
                                                <v-row no-gutters>
                                                    {{getValorNeto}} puntos <v-spacer></v-spacer> Fecha de entrega: {{getFechaLimite}}
                                                </v-row>
                                            </v-list-item-subtitle>
                                            <v-divider :color="getDividerColor"></v-divider>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                            
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-body-2 wrap-text" v-html="linkfy(tarea.instructions,{
                                            target: {
                                                url: '_blank'
                                                }
                                            }
                                        )">
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0" v-if="tarea.videos.length>0">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                            
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <br>
                                            <v-row no-gutters class="pb-5">
                                                <v-col v-for="(video,index) in tarea.videos" :key="index" cols="12">
                                                    <video-card :videoInfo="video" :action="'get'" @onclickvideo="playvideo(video)"></video-card>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    
                                    <v-list-item class="pa-0" v-if="tarea.archivos.length>0">
                                        <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <br>
                                            <v-row no-gutters class="py-0 card-text">
                                                <v-col cols="12" v-for="(file,index) in tarea.archivos" :key="index">
                                                    <file-card :file="file" :publico="true"></file-card>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0" v-if="$vuetify.breakpoint.smAndDown">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp"></v-list-item-avatar>
                                        <v-list-item-content class="px-1">
                                            <cuadro-entrega @onclickvideo="(video)=>{playvideo(video)}" @onsuccessentrega="onsuccessentrega" :mientrega="minuevaentrega" @onnewTrabajo="onnewTrabajo" :tarea="tarea" :entregaTarea="entregaTarea"></cuadro-entrega>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0" 
                                    v-if="$vuetify.breakpoint.smAndDown&&tarea.hasData">
                                        <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp"></v-list-item-avatar>
                                        <v-list-item-content class="px-1">
                                            <comments-privados 
                                            @onerror="onerror"
                                            @onupdateComments="(comments)=>{onupdateComments(comments)}" 
                                            :comentarios="entregaTarea.comentarios" 
                                            styling="aside" from="tarea" 
                                            :actividad="entregaTarea"></comments-privados>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pa-0"
                                        v-if="tarea.hasData">
                                        <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-divider></v-divider>
                                            <comments-publicos 
                                            @onerror="onerror"
                                            @onupdateComments="(comments)=>{onupdatePublicComments(comments)}" 
                                            :comentarios="tarea.comentarios" 
                                            from="tarea" 
                                            :actividad="tarea"></comments-publicos>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                                <aside class="ml-7" v-if="$vuetify.breakpoint.mdAndUp">
                                    <cuadro-entrega @onclickvideo="(video)=>{playvideo(video)}" @onsuccessentrega="onsuccessentrega" :mientrega="minuevaentrega" @onnewTrabajo="onnewTrabajo" :tarea="tarea" :entregaTarea="entregaTarea"></cuadro-entrega>
                                    <br>
                                    <comments-privados 
                                    v-if="tarea.hasData"
                                    @onerror="onerror" 
                                    @onupdateComments="(comments)=>{onupdateComments(comments)}" 
                                    :comentarios="entregaTarea.comentarios" 
                                    styling="aside" from="tarea" 
                                    :actividad="entregaTarea"></comments-privados>
                                </aside>
                            </v-row>
                        </div>
                    </v-row>
                </v-container>
        </v-card>
    </v-dialog>    
</template>
<script>
import GetReadableDate from '../../../../../utils/timeUtils';
import VideoCard from '../../../../colaborador/escritorio/common/VideoCard.vue';
import VideoPlayer from '../../../../colaborador/escritorio/common/VideoPlayer.vue';
import linkfy from 'linkifyjs/lib/linkify-string';
import CuadroEntrega from './CuadroEntrega.vue';
import CommentsPrivados from '../CommentsPrivados.vue';
import CommentsPublicos from '../CommentsPublic.vue';
import FileCard from '../../../../colaborador/escritorio/common/FileCard.vue';

export default {
  components: { VideoCard,VideoPlayer, CuadroEntrega,CommentsPrivados,CommentsPublicos,FileCard },
    props:[
    ],
    computed:{
        getDividerColor(){return this.$vuetify.theme.dark?'white':'black'},
        getTitleStyle(){
            return `text-h5 text-sm-h4 text-md-h4 ${this.$store.state.accentColor2}--text wrap-text`;
        },
        getIconText(){
            if(this.tarea)
                return this.tarea.actividad.tipoActividadNavigation.icono;
            return "";
        },
        getTextClass(){
            return this.$vuetify.theme.dark?'text-h4':`text-h4 teal-darken-1--text`;
        },
        getFechaCreada(){
            if(this.tarea){
                return this.getHumanDate(this.tarea.actividad.fechaCreada);
            }

            return "";
        },
        getFechaLimite(){
            if(this.tarea){
                return this.getHumanDate(this.tarea.actividad.fechaLimite);
            }

            return "";
        },
        getCatedraticoNombres(){
            if(this.tarea){
                if(this.tarea.catedratico)
                    return this.tarea.catedratico.nombres;
            }
            return "Catedrático";
        },
        getValorNeto(){
            if(this.tarea){
                return this.tarea.actividad.valorNeto;
            }
            return "";
        },
    },
    data:function(){
        return ({
            show:false,
            videoplayerDialog:false,
            selectedVideo:"",
            tarea:"",
            entregaTarea:"",
            isLoading:true,
            minuevaentrega:{
                contenido:"",
                videos:[],
                archivos:[],
                newArchivos:[]
            },
        })
    },
    methods:{
        onerror(){
            this.loadAll();
        },
        onupdatePublicComments(comments){
            this.tarea.comentarios=comments;
        },
        onupdateComments(comments){
            this.entregaTarea.comentarios=comments;
        },
        linkfy,
        getHumanDate: GetReadableDate,
        onsuccessentrega(){
            this.loadAll();
        },
        onnewTrabajo(newtrabajo){
            this.minuevaentrega.contenido=newtrabajo.contenido;
            this.minuevaentrega.archivos=newtrabajo.archivos;
            this.minuevaentrega.videos=newtrabajo.videos;
            this.minuevaentrega.newArchivos=newtrabajo.newArchivos;
        },
        closeDialog(){
            this.$router.go(-1)
        },
        onclose(){
            this.show=false;
            this.$router.go(-1);
        },
        getFileName(name){
            if(name.length>30){
                var extensionSplitted=name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${name.substring(0,30)}....${extension}`;
            }
            return name;
        },
        playvideo(video){
            this.selectedVideo=video;
            this.videoplayerDialog=true;
        },
        loadTarea(){return this.$axios.get(`api/estudiantil/actividades/tareas/${this.$route.params.idtarea}`);},
        async loadAll(){
            this.isLoading=true;

            await this.$axios.all([this.loadTarea()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.tarea=response[0].data.data;
                        this.entregaTarea=this.tarea;
                        this.tarea=this.tarea.tarea;

                        this.getCopyEntrega();
                    }else{
                        this.$store.commit("showMessage",{text:response[0].data.message,color:"",time:5000});
                        this.$emit("onloadtareaerror");
                        this.$router.go(-1);
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        getCopyEntrega(){
            this.minuevaentrega.newArchivos=[];

            if(this.entregaTarea.contenido)
                this.minuevaentrega.contenido=this.entregaTarea.contenido;
            if(this.entregaTarea.videos)
                this.minuevaentrega.videos=this.entregaTarea.videos;
            if(this.entregaTarea.archivos)
                this.minuevaentrega.archivos=this.entregaTarea.archivos;
        }
    },
    created(){
        this.show=true;
        this.loadAll();
    }
}
</script>
<style>
.body{
    padding-top: 60px;
}

.v-divider{
    flex: 1 0 100% !important;
}
</style>