<template>
    <v-overlay :value="$store.state.isLoading" opacity="0.5" z-index="100">
        <v-card :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark" outlined :width="dynamicWidth" class="rounded-xl">
            <v-card-title>Cargando...</v-card-title>
            <v-card-text>
                <v-row no-gutters justify="center">
                    <v-progress-circular
                    :size="70"
                    :width="7"
                    :color="getColor()"
                    indeterminate
                    ></v-progress-circular>
                </v-row>
            </v-card-text>
            <v-card-actions></v-card-actions>
        </v-card>
    </v-overlay>
</template>
<script>
export default {
    props:[
        "isLoading",
        "accentColor"
    ],
    computed:{
        dynamicWidth(){
            if(this.$vuetify.breakpoint.xsOnly)
                return 250;
            return 350;
        }
    },
    methods:{
        getColor(){
            if(this.accentColor){
                return this.$store.state.accentColor2;
            }

            return this.$store.state.accentColor;
        }
    }
}
</script>
<style scoped>

</style>