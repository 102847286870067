<template>
    <v-card outlined class="rounded-xl card-text" ripple @click="onclickMaterial">
            <v-list-item>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                    <v-icon size="30" :color="$store.state.accentColor2">{{material.actividad.tipoActividadNavigation.icono}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2 wrap-text">
                        {{getMaterialTitle}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption">
                        {{getReadableDate(material.actividad.fechaCreada)}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
    </v-card>
</template>
<script>
import {Months} from '../../../../../utils/timeUtils';
export default {
    props:[
        "material",
        "from"
    ],
    computed:{
        getMaterialTitle(){
            if(this.material.title.length>30){
                return `Material: ${this.material.title.substring(0,30)}...`;
            }
            return `Material: ${this.material.title}`;
        }
    },
    data:function(){
        return({
            options:[
                "Eliminar",
                "Editar"
            ]
        })
    },
    methods:{
        onclickMaterial(){
            this.$emit("ondetails");
        },
        getReadableDate(date){
            var datedate=new Date(date);

            var splittedTime=date.split("T")[1];
            var hourminute=splittedTime.split(".")[0];
            var time=hourminute.substring(0,(hourminute.length-1)-2);
            var month=Months[datedate.getMonth()].substring(0,3);

            return `${datedate.getDate()} ${month} ${datedate.getFullYear()} ${time}`;
        }
    }
}
</script>
<style scoped>

</style>