<template>
    <div>
        <div v-if="$router.currentRoute.name=='materias_docente'">
            <v-app-bar app flat :color="!$vuetify.theme.dark?'white':''">
                <v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="logoutDialog=true" text outlined rounded :color="$store.state.accentColor2">Cerrar sesión</v-btn>
            </v-app-bar>
            <logout-dialog :logoutDialog="logoutDialog" @onclose="logoutDialog=false"></logout-dialog>
            <generic-header :text="'Mis cursos'"></generic-header>
            <v-container>
                <v-row justify="center">
                    <v-col v-for="gradoMateria in assignmentsData" :key="gradoMateria.idGradoSeccionMateriaColaborador" cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                        <outlined-color-class :gradoMateria="gradoMateria" @ongotoClassGrade="(idGrade)=>gotoClassGrade(idGrade)"></outlined-color-class>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <transition name="scroll-y-transition" mode="out-in">
            <router-view />
        </transition>
    </div>    
</template>
<script>
import GenericHeader from '../../../globalComponents/GenericHeader';
import LogoutDialog from '../../../globalComponents/LogoutDialog.vue';
import OutlinedColorClass from '../../../common/OutlinedColorClass.vue';
export default {
  components: { GenericHeader, LogoutDialog,OutlinedColorClass},
    data:function(){
        return ({
            isLoading:false,
            materiasData:[],
            assignmentsData:[],
            unsubscribe:"",
            logoutDialog:false
        });
    },
    methods:{
        gotoClassGrade(idAsignacion){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);
            
            this.$router.push({name:"escritorio_docente",params:{idassignment:idAsignacion}},()=>{
                this.isLoading=false;
                this.$store.commit("setLoading",this.isLoading);
            });
        },
        getAssignments(){
            return this.$axios.get(`api/docentes/docentes/materias?ciclo=${this.$route.params.ciclo}`);
        },
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.getAssignments()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.assignmentsData=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.loadAll();
    },
}
</script>
<style scoped>

</style>