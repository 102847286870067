<template>
    <v-app id="grados_colaborador">
        <app-bar :allLoaded="allLoaded" :name="title"/>
        <dialogo-grado :gradoDialog="gradoDialog" @onclose="gradoDialog=false;" @onsave="onsave" @onupdate="onupdate" :action="action" :gradoToEdit="selectedGrade"/>
        <delete-dialog :show="deleteGradoDialog" @onclose="()=>{deleteGradoDialog=false;}" @ondelete="ondelete" :content="`¿Quieres eliminar el grado '${selectedGrade.nombre}'?`" context="grado"
            :idEntity="selectedGrade.idGrado"/>

        <estudiante-dialog :estudianteDialog="estudianteDialog" @onclose="estudianteDialog=false;" 
        :action="action"
        @onsavefromgrade="onsavestudentfromGrade"
        ></estudiante-dialog>
        <asignar-estudiante :asignacionDialog="asignarEstudianteDialog" @onclose="asignarEstudianteDialog=false;" 
        :student="selectedStudent" :grado="selectedGrade" @onenrollment="onenrollment"></asignar-estudiante>

        <asignar-estudiantes :asignarestudiantesDialog="asignarEstudiantesDialog" 
        :selectedGrade="selectedGrade" @oncancel="asignarEstudiantesDialog=false"  @onenrollment="onenrollment"></asignar-estudiantes>
        <div v-if="allLoaded">
            <v-row class="pa-0 ma-0" justify="center">
                <div class="text-h4 font-weight-bold">{{getCicloEscolar}}</div>
            </v-row>
            <br>
            <v-row justify="center" no-gutters>
                <v-radio-group
                mandatory
                v-model="selectedNivel"
                row
                >
                    <v-radio :color="$store.state.accentColor" label="Preprimaria" value="preprimaria"></v-radio>
                    <v-radio :color="$store.state.accentColor" label="Primaria" value="primaria"></v-radio>
                    <v-radio :color="$store.state.accentColor" label="Básicos" value="basicos"></v-radio>
                    <v-radio :color="$store.state.accentColor" label="Diversificado" value="diversificado"></v-radio>
                </v-radio-group>
            </v-row>
            <v-row class="pa-0 ma-0">
                <v-col v-for="grado in filteredgradesData" :key="grado.idGrado" cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                    <card-grade :grado="grado" @ondelete="deleteGradoDialog=true;selectedGrade=grado;" @onedit="action='edit';gradoDialog=true;selectedGrade=grado;"
                    @onnewstudent="estudianteDialog=true;action='add';selectedGrade=grado;" @onstudentselected="(values)=>{onStudentSelected(values,grado);}"
                    @ondetails="()=>{
                        $router.push({ name:'Grado detalle', params: { id: grado.idGrado } });
                    }" @onaddcicloanterior="onaddcicloanterior(grado)"
                    />
                </v-col>
            </v-row>
            <add-button tooltipText="Agregar grado" @onadd="gradoDialog=true;action='add'"></add-button>
        </div>        
        <router-view @onclose="()=>{}"/>
    </v-app>
</template>
<script>
import AppBar from '../../../globalComponents/Appbar'
import DialogoGrado from './DialogoGrado'
import DeleteDialog from '../../../globalComponents/DeleteDialog'
import CardGrade from './CardGrade'
import AddButton from '../../../globalComponents/AddButton'
import EstudianteDialog from '../../estudiantes/EstudianteDialog'
import AsignarEstudiante from './AsignarEstudiante'
import AsignarEstudiantes from './AsignarEstudiantes.vue'
export default {
    props:{
        title:String
    },
    components:{
        AppBar,
        DialogoGrado,
        DeleteDialog,
        CardGrade,
        AddButton,
        EstudianteDialog,
        AsignarEstudiante,
        AsignarEstudiantes
    },
    computed:{
        getCicloEscolar(){
            if(this.$store.state.cicloEscolar)
                return `Ciclo escolar ${this.$store.state.cicloEscolar.cicloEscolar1}`;
            return "";
        }
    },
    watch:{
        selectedNivel:function(newval,oldval){
            oldval;
            this.filterNiveles(newval);
        },
        asignarEstudiantesDialog(newval,oldval){
            oldval;
            if(!newval)
                this.gradosSeccionesAnteriores=[];
        },
        "gradoseccionAnterior.toInscribir":function(newval,oldval){
            console.log(newval);
            oldval;
        }
    },
    data:()=>({
        selectedNivel:null,
        allLoaded:false,
        isLoadingCicloAnterior:false,
        gradesData:[],
        filteredgradesData:[],
        gradosSeccionesAnteriores:[],
        selectedGrade:Object,
        selectedStudent:Object,
        gradoDialog:false,
        deleteGradoDialog:false,
        estudianteDialog:false,
        asignarEstudianteDialog:false,
        asignarEstudiantesDialog:false,
        action:"",
        unsubscribe:"",
        years:[]
    }),
    methods:{
        onaddcicloanterior(grado){
            this.selectedGrade=grado;
            this.asignarEstudiantesDialog=true;
        },
        onChangeCiclo(val){
            val;
            this.$store.commit("changeCiclo",val);
        },
        onStudentSelected(student,grado){
            this.selectedGrade=grado;
            this.selectedStudent=student;
            this.asignarEstudianteDialog=true;
        },
        loadGrades(){
            return this.$axios.get(`api/grados?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`);
        },
        loadCiclos(){
            return this.$axios.get("api/ciclos");
        },
        async loadAll(){
            this.allLoaded=false;

            await this.$axios.all([this.loadGrades()]).then((responses)=>{
                if(responses[0].status==200){
                    if(responses[0].data.code==1){
                        this.gradesData=responses[0].data.data;

                        if(this.selectedNivel)
                            this.filterNiveles(this.selectedNivel);
                        else
                            this.filterNiveles("preprimaria");
                    }
                }
            }).catch((errors)=>{
                errors;
            });

            this.allLoaded=true;
        },
        onsavestudent(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:"Estudiante agregado correctamente.",color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte..",color:"red",time:4000});
                    break;
            }
        },
        onsavestudentfromGrade(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:"Estudiante agregado correctamente.",color:"green",time:4000});
                    this.onStudentSelected(response[1],this.selectedGrade);
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte..",color:"red",time:4000});
                    break;
            }
        },
        onsave(response){

            switch (response[0]) {
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.gradesData.push(response[1]);
                    this.$store.commit("showMessage",{text:"Grado agregado correctamente.",color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            
                default:
                    break;
            }
        },
        ondelete(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.gradesData.splice(this.gradesData.indexOf(this.selectedGrade),1);
                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        onupdate(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        onenrollment(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        filterNiveles(nivel){
            switch(nivel){
                case "preprimaria":
                    this.filteredgradesData=this.gradesData.filter(item=>item.idNivelEstudiantil=="03-12-0030-42");
                    break;
                case "primaria":
                    this.filteredgradesData=this.gradesData.filter(item=>item.idNivelEstudiantil=="03-12-0016-43");
                    break;
                case "basicos":
                    this.filteredgradesData=this.gradesData.filter(item=>item.idNivelEstudiantil=="03-12-0029-45");
                    break;
                case "diversificado":
                    this.filteredgradesData=this.gradesData.filter(item=>item.idNivelEstudiantil=="03-12-0029-46");
                    break;
            }
        }
    },
    created(){
        this.loadAll();
        
        this.unsubscribe=this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'changeCiclo') {
                state;
                this.loadAll();
            }
        });
    },
    beforeDestroy(){
        this.unsubscribe();
    }
}
</script>
<style scoped>

</style>