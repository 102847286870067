import { render, staticRenderFns } from "./GradosCalificaciones.vue?vue&type=template&id=cd7297ea&scoped=true"
import script from "./GradosCalificaciones.vue?vue&type=script&lang=js"
export * from "./GradosCalificaciones.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd7297ea",
  null
  
)

export default component.exports