<template>
    <v-dialog fullscreen transition="scale-transition" v-model="show" persistent no-click-animation>
        <delete-dialog :show="deleteactivityDialog" :content="deletecontent" :context="deletecontext" :idEntity="getpostIdentity"
            @ondelete="ondeleteMaterial" @onclose="deleteactivityDialog=false"
            ></delete-dialog>

        <video-player :videoplayerDialog="videoplayerDialog" @onclose="videoplayerDialog=false;"
        :video="selectedVideo"
        ></video-player>
        <v-card tile>
            <v-app-bar fixed flat :color="$vuetify.theme.dark?'':'white'">
                <v-btn icon @click="$router.go(-1)"><v-icon>arrow_back</v-icon></v-btn>
                <v-toolbar-title>Detalles de material</v-toolbar-title>
            </v-app-bar>

                <v-container class="body custom-container">
                    <v-row no-gutters v-if="!isLoading">
                        <v-list-item class="pa-0">
                            <v-list-item-avatar :color="$store.state.accentColor2" v-if="$vuetify.breakpoint.smAndUp">
                                <v-icon color="white">
                                    {{getIconText}}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title :class="'text-h5 text-md-h4 wrap-text'">
                                    {{material.title}}
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-subtitle-2 wrap-text">
                                    {{getCatedraticoNombres}} - {{getFechaCreada}}
                                </v-list-item-subtitle>
                                <br>
                                <br>
                                <v-divider :color="getDividerColor"></v-divider>
                            </v-list-item-content>
                            <v-menu offset-y>
                                <template v-slot:activator="{on:menu}">
                                    <v-btn v-on="menu" icon><v-icon :color="$store.state.accentColor2">more_vert</v-icon></v-btn>
                                </template>
                                <v-list class="pa-0" dense>
                                    <v-list-item v-for="(option,index) in options" :key="index" @click="onselectOption(option)">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{option}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item>
                        <v-list-item class="pa-0">
                            <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-body-2" v-html="linkfy(material.contenido,{
                                target: {
                                    url: '_blank'
                                    }
                                }
                            )">
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="pa-0" v-if="material.videos.length>0">
                            <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-subtitle-2">
                                    Videos
                                </v-list-item-title>
                                <br>
                                <v-row no-gutters class="pb-5">
                                    <v-col v-for="(video,index) in material.videos" :key="index" cols="12">
                                        <video-card :videoInfo="video" :action="'get'" @onclickvideo="playvideo(video)"></video-card>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-action>
                        </v-list-item>
                        
                        <v-list-item class="pa-0" v-if="material.archivos.length>0">
                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-subtitle-2">
                                    Archivos
                                </v-list-item-title>
                                <br>
                                <v-row class="py-0 card-text">
                                    <v-col cols="12" xs="12" v-for="(file,index) in material.archivos" :key="index">
                                        <file-card :file="file" :publico="true"></file-card>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="pa-0" v-if="material.hasData">
                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-divider></v-divider>
                                <comments-publicos @onerror="onerror" 
                                    @onupdateComments="(comments)=>{onupdateComments(comments)}" 
                                    :comentarios="material.comentarios" 
                                    from="material" 
                                    :actividad="material"></comments-publicos>
                            </v-list-item-content>
                            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-action>
                        </v-list-item>
                    </v-row>
                </v-container>
        </v-card>
    </v-dialog>    
</template>
<script>
import GetReadableDate from '../../../../../utils/timeUtils';
import DeleteDialog from '../../../../globalComponents/DeleteDialog';
import VideoCard from '../VideoCard.vue';
import VideoPlayer from '../VideoPlayer.vue';
import linkfy from 'linkifyjs/lib/linkify-string';
import CommentsPublicos from '../CommentsPublic.vue'
import FileCard from '../FileCard.vue'
//import AppBar2 from '../../../../globalComponents/AppBar2.vue';
export default {
  components: { DeleteDialog,VideoCard,VideoPlayer,CommentsPublicos,FileCard },
  //components: { AppBar2 },
    props:[
        
    ],
    computed:{
        getDividerColor(){return this.$vuetify.theme.dark?'white':'black';},
        getIconText(){
            if(this.material)
                return this.material.actividad.tipoActividadNavigation.icono;
            return "";
        },
        getTextClass(){
            return this.$vuetify.theme.dark?'text-h4':`text-h4 teal-darken-1--text`;
        },
        getFechaCreada(){
            if(this.material){
                return this.getHumanDate(this.material.actividad.fechaCreada);
            }

            return "";
        },
        getCatedraticoNombres(){
            if(this.material){
                if(this.material.catedratico)
                    return this.material.catedratico.nombres;
            }
            return "";
        },
        getValorNeto(){
            if(this.material){
                return this.material.actividad.valorNeto;
            }
            return "";
        },
        getpostIdentity(){
            if(this.material){
                return this.material.actividad.idActividadMateriaBloque;
            }
            return "";
        }
    },
    data:function(){
        return ({
            options:[
                "Eliminar",
                "Editar"
            ],
            deleteactivityDialog:false,
            deletecontent:"",
            deletecontext:"",
            videoplayerDialog:false,
            selectedVideo:"",
            isLoading:true,
            material:"",
            show:false
        })
    },
    methods:{
        onerror(){
            this.loadAll();
        },
        onupdateComments(comments){
            this.material.comentarios=comments;
        },
        onclose(){
            this.show=false;
            this.$router.go(-1);
        },
        ondeleteMaterial(response){
            if(response[0]==1){
                this.$emit("ondeletematerial",response);
                this.$router.go(-1);
                return;
            }
            this.$emit("ondeletematerial",response);
        },
        linkfy,
        getHumanDate: GetReadableDate,
        onselectOption(option){
            if(option=="Eliminar"){
                this.deletecontext="material";
                var titulo=this.material.title;
                if(this.material.title.length>20){
                    titulo=`${titulo.substring(0,15)}...`;
                }
                this.deletecontent=`¿Desea eliminar el material con título "${titulo}"?`;
                this.deleteactivityDialog=true;
            }else{
                this.$emit("onupdatematerialCall",this.material);
            }
        },
        getFileName(name){
            if(name.length>30){
                var extensionSplitted=name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${name.substring(0,30)}....${extension}`;
            }
            return name;
        },
        playvideo(video){
            this.selectedVideo=video;
            this.videoplayerDialog=true;
        },
        loadMaterial(){return this.$axios.get(`api/docentes/actividades/material/${this.$route.params.idmaterial}`);},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadMaterial()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.material=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.show=true;
        this.loadAll();
    }
}
</script>
<style scoped>

</style>