var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"diversificado_materias"}},[_c('app-bar',{attrs:{"name":_vm.title,"allLoaded":_vm.allLoaded}}),_c('carrera-dialog',{attrs:{"carreraDialog":_vm.carreraDialog,"action":_vm.action,"carreraToEdit":_vm.selectedCarrera},on:{"onclose":function($event){_vm.carreraDialog=false;},"onsave":_vm.onsaveCarrera,"onupdate":_vm.onupdateCarrera}}),_c('delete-dialog',{attrs:{"show":_vm.deleteCarreraDialog,"context":'carrera',"content":_vm.carreraNombre,"idEntity":_vm.selectedCarrera.idCarrera},on:{"onclose":function($event){_vm.deleteCarreraDialog=false;},"ondelete":_vm.ondeleteCarrera}}),_c('materia-dialog',{attrs:{"materiaDialog":_vm.materiaDialog,"action":_vm.action,"carrera":_vm.selectedCarrera,"materiaToEdit":_vm.selectedMateria},on:{"onclose":function($event){_vm.materiaDialog=false;},"onsave":_vm.onsaveMateria,"onupdate":_vm.onupdateMateria}}),_c('delete-dialog',{attrs:{"show":_vm.deleteMateriaDialog,"context":'materia',"content":_vm.materiaNombre,"idEntity":_vm.selectedMateria.idMateria},on:{"onclose":function($event){_vm.deleteMateriaDialog=false;},"ondelete":_vm.ondeleteMateria}}),(_vm.allLoaded)?_c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('h1',{staticClass:"text-h6 font-weight-light"},[_vm._v("Carreras")])]),_c('v-row',{staticClass:"pa-0 ma-0"},_vm._l((_vm.carrerasData),function(carrera){return _c('v-col',{key:carrera.idCarrera,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('carrera-card',{attrs:{"carrera":carrera},on:{"ondelete":()=>{_vm.selectedCarrera=carrera;_vm.deleteCarreraDialog=true;},"onupdate":()=>{
                        _vm.action='edit';
                        _vm.selectedCarrera=carrera;
                        _vm.carreraDialog=true;
                    },"onaddmateria":()=>{
                        _vm.action='add';
                        _vm.selectedCarrera=carrera;
                        _vm.materiaDialog=true;
                    },"ondeleteMateria":(materia)=>{
                        _vm.selectedCarrera=carrera;
                        _vm.selectedMateria=materia.idMateriaNavigation;
                        _vm.deleteMateriaDialog=true;
                    },"onupdateMateria":(materia)=>{
                        _vm.action='edit';
                        _vm.selectedMateria=materia.idMateriaNavigation;
                        _vm.selectedCarrera=carrera;
                        _vm.materiaDialog=true;
                    }}})],1)}),1),_c('add-button',{attrs:{"tooltipText":"Agregar carrera"},on:{"onadd":()=>{_vm.action='add';_vm.carreraDialog=true;}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }