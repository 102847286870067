<template>
    <v-dialog v-model="iniciarmateriaDialog" persistent max-width="400">
        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title>
                Iniciar clase
            </v-card-title>
            <v-card-text>
                ¿Desea iniciar esta clase?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose')">Cancelar</v-btn>
                <v-btn text color="green" @click="oniniciarMateria">Iniciar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    props:[
        "iniciarmateriaDialog",
        "asignacionData"
    ],
    data:function(){
        return ({
            isLoading:false
        })
    },
    methods:{
        async oniniciarMateria(){
            this.isLoading=this.$store.state.accentColor;

            var result=await this.$axios.put(`api/docentes/asignaciones/${this.asignacionData.idGradoSeccionMateriaColaborador}/iniciar`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        switch(response.data.code){
                            case 1:
                                this.$store.commit("showMessage",{text:"Materia iniciada correctamente.",color:"green",time:4000});
                                return [response.data.code,response.data.data];
                            default:
                                this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                                return [response.data.code];
                        }
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                return [2];
            });

            this.$emit("onstart",result);
            this.$emit("onclose");

            this.isLoading=false;
        }
    }
}
</script>
<style scoped>

</style>