<template>
    <div>
        <v-divider></v-divider>

        <video-player :videoplayerDialog="videoplayerDialog" @onclose="videoplayerDialog=false;"
        :video="selectedVideo"
        ></video-player>
        <loader :isLoading="isLoading" :accentColor="2"></loader>

        <v-card flat :loading="isLoading" :disabled="isLoading">
            <template slot="progress">
                <v-progress-linear indeterminate :color="$store.state.accentColor2" :background-color="'transparent'"></v-progress-linear>
            </template>
            <v-row class="ma-0 pa-3" align="center">
                <v-menu offset-y>
                    <template v-slot:activator="{on:menu}">
                        <v-btn v-on="{...menu}" :dark="isDarkOptions" small :disabled="disableOptions" :color="$store.state.accentColor2">
                            Opciones
                        </v-btn>
                    </template>
                    <v-list class="pa-0" dense>
                        <v-list-item v-for="accion in filteredAcciones" :key="accion" @click="onclickAction(accion)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{accion}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <div class="px-2"></div>
                <v-btn @click="deselectAll" small :disabled="disableCancelar">
                    Cancelar
                </v-btn>
                <div class="px-1"></div>
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn icon v-on="on" @click="onRefresh"><v-icon>refresh</v-icon></v-btn>
                    </template>
                    <span>
                        Refrescar
                    </span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-chip small :color="$store.state.accentColor2" dark>{{tareaData.actividad.valorNeto}} puntos</v-chip>
            </v-row>
            <v-row class="ma-0">
                <v-col class="pa-0" cols="12" md="5" lg="4" xl="3" v-if="firstPanelValidation()" :style="getHeight">
                    <v-row class="ma-0 pa-3">
                        <p class="text-h6">Calificadas</p>
                    </v-row>
                    <v-list class="pa-0">
                        <v-row no-gutters
                            v-for="participante in calificadas" 
                            :key="participante.idActividadMateriaBloqueParticipante"
                            align-content="center"
                            >
                            <v-col align="center" cols="2">
                                <v-checkbox :color="$store.state.accentColor2" class="ml-5"
                                    @change="onselectCalificada($event,participante)" 
                                    v-model="participante.selected"></v-checkbox>
                            </v-col>
                            <v-col cols="7" align-self="center">
                                <v-list-item class="pa-0" two-line @click="goEntrega(participante)">
                                    <v-avatar class="mr-2" :color="$store.state.accentColor2" size="30">
                                        <v-icon color="white">person</v-icon>
                                    </v-avatar>
                                    <v-list-item-content ripple>
                                        <v-list-item-title>
                                            {{participanteData(participante)}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="px-2" align-self="center">
                                <v-text-field
                                single-line
                                :rules="punteoRules" 
                                dense 
                                :suffix="`/${getMaxPunteo}`" 
                                :color="$store.state.accentColor2"
                                maxlength="4"
                                v-model.number="participante.actividadesMateriasBloquesEntregas.borradorCalificacion"
                                autocomplete="off"
                                @change="onchangevalueCalificada($event,participante)"
                                @input="oninputchangeCalificada($event,participante)"
                                :loading="participante.isLoadingBorradorCalificada"
                                :disabled="disableinputPunteo(participante.isLoadingBorradorCalificada)"
                                @focus="onfocusvalueCalificada($event,participante)"
                                type="number">

                                </v-text-field>
                                <div v-if="vIfBorradorTextCalificada(participante)" class="text-caption grey--text" align="center">{{BorradorText(participante)}}</div>
                            </v-col>
                        </v-row>
                    </v-list>
                    <br>
                    <v-row class="ma-0 pa-3">
                        <p class="text-h6">Pendientes de entregar</p>
                    </v-row>
                    <v-list class="pa-0">
                        <v-row no-gutters
                            v-for="participante in pendientes" 
                            :key="participante.idActividadMateriaBloqueParticipante"
                            align-content="center"
                            >
                            <v-col align="center" cols="2">
                                <v-checkbox :color="$store.state.accentColor2" class="ml-5"
                                    @change="onselectPendiente($event,participante)" 
                                    v-model="participante.selected"></v-checkbox>
                            </v-col>
                            <v-col cols="7" align-self="center">
                                <v-list-item class="pa-0" two-line @click="goEntrega(participante)">
                                    <v-avatar class="mr-2" :color="$store.state.accentColor2" size="30">
                                        <v-icon color="white">person</v-icon>
                                    </v-avatar>
                                    <v-list-item-content ripple>
                                        <v-list-item-title>
                                            {{participanteData(participante)}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="px-2" align-self="center">
                                <v-text-field
                                single-line
                                :rules="punteoRules" 
                                dense 
                                :suffix="`/${getMaxPunteo}`" 
                                :color="$store.state.accentColor2"
                                maxlength="4"
                                v-model.number="participante.actividadesMateriasBloquesEntregas.borradorCalificacion"
                                autocomplete="off"
                                @change="onchangevaluePendiente($event,participante)"
                                @input="oninputchangePendiente($event,participante)"
                                :loading="participante.isLoadingBorradorPendiente"
                                :disabled="disableinputPunteo(participante.isLoadingBorradorPendiente)"
                                @focus="onfocusvaluePendiente($event,participante)"
                                type="number">

                                </v-text-field>
                                <div v-if="vIfBorradorTextPendiente(participante)" class="text-caption grey--text" align="center">{{BorradorText(participante)}}</div>
                            </v-col>
                        </v-row>
                    </v-list>
                    <br>
                    <v-row class="ma-0 pa-3">
                        <p class="text-h6">Entregadas/Recogidas</p>
                    </v-row>
                    <v-list class="pa-0">
                        <v-row no-gutters
                            v-for="participante in entregadas" 
                            :key="participante.idActividadMateriaBloqueParticipante"
                            align-content="center"
                            >
                            <v-col align="center" cols="2">
                                <v-checkbox :color="$store.state.accentColor2" class="ml-5"
                                    @change="onselectEntregada($event,participante)" 
                                    v-model="participante.selected"></v-checkbox>
                            </v-col>
                            <v-col cols="7" align-self="center">
                                <v-list-item class="pa-0" two-line @click="goEntrega(participante)">
                                    <v-avatar class="mr-2" :color="$store.state.accentColor2" size="30">
                                        <v-icon color="white">person</v-icon>
                                    </v-avatar>
                                    <v-list-item-content ripple>
                                        <v-list-item-title>
                                            {{participanteData(participante)}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="px-2" align-self="center">
                                <v-text-field
                                single-line
                                :rules="punteoRules" 
                                dense 
                                :suffix="`/${getMaxPunteo}`" 
                                :color="$store.state.accentColor2"
                                maxlength="4"
                                v-model.number="participante.actividadesMateriasBloquesEntregas.borradorCalificacion"
                                autocomplete="off"
                                @change="onchangevalueEntregada($event,participante)"
                                @input="oninputchangeEntregada($event,participante)"
                                :loading="participante.isLoadingBorradorEntregada"
                                :disabled="disableinputPunteo(participante.isLoadingBorradorEntregada)"
                                @focus="onfocusvalueEntregada($event,participante)"
                                type="number">

                                </v-text-field>
                                <div v-if="vIfBorradorTextPendiente(participante)" class="text-caption grey--text" align="center">{{BorradorText(participante)}}</div>
                            </v-col>
                        </v-row>
                    </v-list>
                    <v-row class="ma-0 pa-3">
                        <p class="text-h6">Anuladas</p>
                    </v-row>
                    <v-list class="pa-0">
                        <v-form ref="anuladasPunteos">
                            <v-row no-gutters
                                v-for="participante in anuladas" 
                                :key="participante.idActividadMateriaBloqueParticipante"
                                align-content="center"
                                >
                                <v-col align="center" cols="2">
                                    <v-checkbox :color="$store.state.accentColor2" class="ml-5"
                                        @change="onselectAnulada($event,participante)" 
                                        v-model="participante.selected"></v-checkbox>
                                </v-col>
                                <v-col cols="7" align-self="center">
                                    <v-list-item class="pa-0" two-line @click="goEntrega(participante)">
                                        <v-avatar class="mr-2" :color="$store.state.accentColor2" size="30">
                                            <v-icon color="white">person</v-icon>
                                        </v-avatar>
                                        <v-list-item-content ripple>
                                            <v-list-item-title>
                                                {{participanteData(participante)}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col class="px-2" align-self="center">
                                    <v-text-field
                                    disabled
                                    single-line
                                    dense 
                                    :suffix="`/${getMaxPunteo}`" 
                                    :color="$store.state.accentColor2"
                                    maxlength="4"
                                    v-model.number="participante.actividadesMateriasBloquesEntregas.borradorCalificacion"
                                    autocomplete="off"
                                    type="number">

                                    </v-text-field>
                                    <div v-if="vIfBorradorTextCalificada(participante)" class="text-caption grey--text" align="center">{{BorradorText(participante)}}</div>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-list>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col v-if="secondPanelValidation()" :style="getHeight">
                    <v-row class="ma-0" justify="center"><div class="text-subtitle-1 font-weight-medium">Todas</div></v-row>
                    <v-row class="ma-0">
                        <v-col v-for="participante in tareaparticipantesData" 
                        :key="participante.idActividadMateriaBloqueParticipante"
                        cols="12" sm="12" md="4" lg="4" xl="2"
                        >
                            <v-card class="rounded-lg">
                                <v-list-item @click="()=>{goEntrega(participante)}" link>
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="wrap-text">
                                            {{participanteData(participante)}}
                                        </v-list-item-subtitle>
                                        <v-divider class="my-2"></v-divider>
                                        <v-list-item-subtitle class="wrap-text text-caption font-weight-bold">
                                            {{estatusEntrega(participante)}}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="wrap-text text-caption">
                                            {{getCalificacion(participante)}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <router-view @onclickvideo="(video)=>{playvideo(video)}" :selected="selectedEntrega" :refreshCall="refreshFlag"></router-view>
            </v-row>
        </v-card>
    </div>
</template>
<script>
import Loader from '../../../../globalComponents/Loader.vue';
import VideoPlayer from '../VideoPlayer.vue';
//import Loader from '../../../../globalComponents/Loader.vue';
export default {
    components:{VideoPlayer,Loader},
    props:[
        "tab",
        "from"
    ],
    watch:{
        tab:function(newval,oldval){
            oldval;
            if(newval==1){
                this.loadAll();
            }else{
                if(this.$router.currentRoute.name=="tarea_docente_entrega")
                    this.$router.go(-1);
            }
        }
    },
    computed:{
        isDarkOptions(){
            if(this.selectedPendientes.length>0||this.selectedCalificadas.length>0||this.selectedAnuladas.length>0
            ||this.selectedEntregadas.length>0
            ){
                return true;
            }
            return false;
        },
        disableCancelar(){
            if(this.selectedPendientes.length==0&&
            this.selectedCalificadas.length==0&&this.selectedAnuladas==0
            &&this.selectedEntregadas.length==0||this.isSavingborrador==true
            ){
                return true;
            }
            return false;
        },
        disableOptions(){
            if(this.selectedPendientes.length==0&&
            this.selectedCalificadas.length==0&&this.selectedAnuladas.length==0&&this.selectedEntregadas.length==0
            ||this.isSavingborrador==true){
                return true;
            }
            return false;
        },
        getMaxPunteo(){
            if(this.tareaData){
                return this.tareaData.actividad.valorNeto;
            }
            return 0;
        },
        getHeight(){
            return `overflow-y:auto;height:${this.$vuetify.breakpoint.height-200}px`;
        }
    },
    data(){
        return ({
            selectedEntrega:"",
            videoplayerDialog:false,
            selectedVideo:"",
            tareaparticipantesData:[],
            calificadas:[],
            pendientes:[],
            entregadas:[],
            rechazadas:[],
            anuladas:[],
            selectedPendientes:[],
            selectedCalificadas:[],
            selectedAnuladas:[],
            selectedEntregadas:[],
            selectedGroup:"",
            tareaData:"",
            isLoading:false,
            punteoRules:[
                v=>/^((\d)+((\.|,)(\d)+)*)+$/.test(v) ||"Requerido",
                v=>v<=this.getMaxPunteo||`${this.getMaxPunteo} max`
            ],
            acciones:[
                "Calificar",
                "Rechazar",
                "Anular",
                "Restaurar",
                "Pendiente",
                "Recoger"
            ],
            filteredAcciones:[],
            currentPunteoPendiente:"",
            currentPunteoCalificada:"",
            currentPunteoEntregada:"",
            isSavingborrador:false,
            refreshFlag:false
        })
    },
    methods:{
        onRefresh(){
            this.loadAll();
            if(["examen_final_docente_entrega","examen_final_extra_docente_entrega"].some(item=>item==this.$router.currentRoute.name))
                this.refreshFlag=!this.refreshFlag;
        },
        disableinputPunteo(isLoading){
            if(isLoading||this.isSavingborrador)
                return true;
            return false;
        },
        playvideo(video){
            this.selectedVideo=video;
            this.videoplayerDialog=true;
        },
        onfocusvalueEntregada(val,participante){
            val;

            if(participante.actividadesMateriasBloquesEntregas){
                this.currentPunteoPendiente=participante.actividadesMateriasBloquesEntregas.borradorCalificacion;
            }
        },
        oninputchangeEntregada(val,participante){
            if(!participante.selected){
                if(/\d+/.test(val)){
                    this.selectedCalificadas=[];
                    this.deselectCalificadas();
                    this.selectedAnuladas=[];
                    this.deselectAnuladas();
                    this.selectedPendientes=[];
                    this.deselectPendientes();

                    participante.selected=true;
                    this.selectedEntregadas.push(participante);

                    this.selectedGroup="Entregadas";
                }
            }else{
                if(!val||/^\s+$/.test(val)){
                    participante.selected=false;
                    this.selectedEntregadas.splice(this.selectedEntregadas.indexOf(participante),1);
                }
            }
        },
        onchangevalueEntregada(val,participante){
            if(!this.isSavingborrador){
                this.isSavingborrador=true;
                if(/^((-)*(\d)+((\.|,)(\d)+)*)+$/.test(val)){
                    if(val>=0&&val<=this.getMaxPunteo){
                        this.sendBorradorPunteoEntregadas(participante);
                    }
                    else{
                        participante.actividadesMateriasBloquesEntregas.calificacion=this.currentPunteoEntregada;
                        this.$store.commit("showMessage",{text:`Punteo de calificación debe ser mayor a 0 y menor o igual a ${this.getMaxPunteo}.`,color:"",time:4000});
                        this.isSavingborrador=false;
                    }
                }else{
                    this.sendBorradorPunteoEntregadas(participante);
                }
            }
        },
        onselectEntregada(val,entregada){
            this.filteredAcciones=this.acciones.filter(item=>item=="Calificar"||item=="Anular"||item=="Pendiente");
            this.selectedGroup="Entregadas";

            if(val){
                this.selectedCalificadas=[];
                this.deselectCalificadas();
                this.selectedAnuladas=[];
                this.deselectAnuladas();
                this.selectedPendientes=[];
                this.deselectPendientes();

                this.selectedEntregadas.push(entregada);
            }else{
                this.selectedEntregadas.splice(this.selectedEntregadas.indexOf(entregada),1);
            }
        },
        onselectAnulada(val,anulada){
            this.filteredAcciones=this.acciones.filter(item=>item=="Restaurar");
            this.selectedGroup="Anuladas";

            if(val){
                this.selectedPendientes=[];
                this.deselectPendientes();
                this.selectedCalificadas=[];
                this.deselectCalificadas();
                this.selectedEntregadas=[];
                this.deselectEntregadas();

                this.selectedAnuladas.push(anulada);
            }else{
                this.selectedAnuladas.splice(this.selectedAnuladas.indexOf(anulada),1);
            }
        },
        oninputchangeCalificada(val,participante){
            if(!participante.selected){
                if(/\d+/.test(val)){
                    this.selectedPendientes=[];
                    this.deselectPendientes();
                    this.selectedAnuladas=[];
                    this.deselectAnuladas();
                    this.selectedEntregadas=[];
                    this.deselectEntregadas();

                    participante.selected=true;
                    this.selectedCalificadas.push(participante);
                    this.selectedGroup="Calificadas";
                }
            }else{
                if(!val||/^\s+$/.test(val)){
                    participante.selected=false;
                    this.selectedCalificadas.splice(this.selectedCalificadas.indexOf(participante),1);
                }
            }
        },
        onchangevalueCalificada(val,participante){
            if(!this.isSavingborrador){
                this.isSavingborrador=true;
                if(/^((-)*(\d)+((\.|,)(\d)+)*)+$/.test(val)){
                    if(val>=0&&val<=this.getMaxPunteo){
                        this.sendBorradorPunteoCalificadas(participante);
                    }
                    else{
                        participante.actividadesMateriasBloquesEntregas.borradorCalificacion=this.currentPunteoCalificada;
                        this.$store.commit("showMessage",{text:`Punteo de calificación debe mayor a 0 y ser menor o igual a ${this.getMaxPunteo}.`,color:"",time:4000});
                        this.isSavingborrador=false;
                    }
                }else{
                    this.sendBorradorPunteoCalificadas(participante);
                }
            }
        },
        onselectCalificada(val,calificada){
            this.filteredAcciones=this.acciones.filter(item=>item=="Calificar"||item=="Anular"||item=="Pendiente");
            this.selectedGroup="Calificadas";

            if(val){
                this.selectedPendientes=[];
                this.deselectPendientes();
                this.selectedAnuladas=[];
                this.deselectAnuladas();
                this.selectedEntregadas=[];
                this.deselectEntregadas();  

                this.selectedCalificadas.push(calificada);
            }else{
                this.selectedCalificadas.splice(this.selectedCalificadas.indexOf(calificada),1);
            }
        },
        onfocusvalueCalificada(val,participante){
            this.currentPunteoCalificada=participante.actividadesMateriasBloquesEntregas.borradorCalificacion;
        },
        vIfBorradorTextCalificada(participante){
            if(participante.actividadesMateriasBloquesEntregas.borradorCalificacion!=participante.actividadesMateriasBloquesEntregas.calificacion){
                return true;
            }
            return false;
        },
        vIfBorradorTextPendiente(participante){
            if(participante.actividadesMateriasBloquesEntregas){
                if(participante.actividadesMateriasBloquesEntregas.borradorCalificacion){
                    return true;
                }
            }
            return false;
        },
        BorradorText(participante){
            if(participante.isguardandoBorrador){
                return "Guardando.."
            }else{
                return "Borrador";
            }
        },
        validateSelected(selected){
            selected.forEach(element=>{
                element.valid=true;
            });

            selected.forEach(element => {
                if(element.actividadesMateriasBloquesEntregas.borradorCalificacion==null){
                    element.valid=false;
                }
            });

            var verification=selected.some(item=>item.valid==false);

            if(verification==true){
                this.$store.commit("showMessage",{text:"Los seleccionados a calificar deben tener valor en punteo.",color:"orange",time:4000});
            }else
                return true;
        },
        onclickAction(accion){
            switch(accion){
                case "Recoger":
                    switch(this.selectedGroup){
                        case "Pendientes":
                            this.recogerSeleccionadas(this.selectedPendientes);
                            break;
                    }
                    break;
                case "Calificar":
                    switch(this.selectedGroup){
                        case "Pendientes":
                            if(this.validateSelected(this.selectedPendientes))
                                this.calificarSeleccionadas(this.selectedPendientes);
                            break;
                        case "Calificadas":
                            if(this.validateSelected(this.selectedCalificadas))
                                this.calificarSeleccionadas(this.selectedCalificadas);
                            break;
                        case "Entregadas":
                            if(this.validateSelected(this.selectedEntregadas))
                                this.calificarSeleccionadas(this.selectedEntregadas);
                            break;
                    }
                    break;
                case "Rechazar":
                    break;
                case "Anular":
                    switch(this.selectedGroup){
                        case "Pendientes":
                            this.anularSeleccionadas(this.selectedPendientes);
                            break;
                        case "Calificadas":
                            this.anularSeleccionadas(this.selectedCalificadas);
                            break;
                        case "Entregadas":
                            this.anularSeleccionadas(this.selectedEntregadas);
                            break;
                    }
                    break;
                case "Restaurar":
                    switch(this.selectedGroup){
                        case "Anuladas":
                            this.restaurarSeleccionadas(this.selectedAnuladas);
                            break;
                    }
                    break;
                case "Pendiente":
                    switch(this.selectedGroup){
                        case "Entregadas":
                            this.ponerpendientesSeleccionadas(this.selectedEntregadas);
                            break;
                        case "Calificadas":
                            this.ponerpendientesSeleccionadas(this.selectedCalificadas);
                            break;
                    }
            }
        },
        onfocusvaluePendiente(val,participante){
            val;

            if(participante.actividadesMateriasBloquesEntregas){
                this.currentPunteoPendiente=participante.actividadesMateriasBloquesEntregas.borradorCalificacion;
            }
        },
        oninputchangePendiente(val,participante){
            if(!participante.selected){
                if(/\d+/.test(val)){
                    this.selectedCalificadas=[];
                    this.deselectCalificadas();
                    this.selectedAnuladas=[];
                    this.deselectAnuladas();
                    this.selectedEntregadas=[];
                    this.deselectEntregadas();

                    participante.selected=true;
                    this.selectedPendientes.push(participante);
                    this.selectedGroup="Pendientes";
                }
            }else{
                if(!val||/^\s+$/.test(val)){
                    participante.selected=false;
                    this.selectedPendientes.splice(this.selectedPendientes.indexOf(participante),1);
                }
            }
        },
        onchangevaluePendiente(val,participante){
            if(!this.isSavingborrador){
                this.isSavingborrador=true;
                if(/^((-)*(\d)+((\.|,)(\d)+)*)+$/.test(val)){
                    if(val>=0&&val<=this.getMaxPunteo){
                        this.sendBorradorPunteoPendientes(participante);
                    }
                    else{
                        participante.actividadesMateriasBloquesEntregas.borradorCalificacion=this.currentPunteoPendiente;
                        this.$store.commit("showMessage",{text:`Punteo de calificación debe ser mayor a 0 y menor o igual a ${this.getMaxPunteo}.`,color:"",time:4000});
                        this.isSavingborrador=false;
                    }
                }else{
                    this.sendBorradorPunteoPendientes(participante);
                }
            }
        },
        deselectAll(){
            this.deselectPendientes();
            this.deselectCalificadas();
            this.deselectAnuladas();
            this.deselectEntregadas();

            this.selectedCalificadas=[];
            this.selectedPendientes=[];
            this.selectedAnuladas=[];
            this.selectedEntregadas=[];
        },
        deselectPendientes(){
            this.pendientes.map(item=>{
                item.selected=false;
            });
        },
        deselectCalificadas(){
            this.calificadas.map(item=>{
                item.selected=false;
            });
        },
        deselectAnuladas(){
            this.anuladas.map(item=>{
                item.selected=false;
            });
        },
        deselectEntregadas(){
            this.entregadas.map(item=>{
                item.selected=false;
            });
        },
        onselectPendiente(val,pendiente){
            this.filteredAcciones=this.acciones.filter(item=>item=="Calificar"||item=="Anular"||item=="Recoger");
            this.selectedGroup="Pendientes";

            if(val){
                this.selectedCalificadas=[];
                this.deselectCalificadas();
                this.selectedAnuladas=[];
                this.deselectAnuladas();
                this.selectedEntregadas=[];
                this.deselectEntregadas();

                this.selectedPendientes.push(pendiente);
            }else{
                this.selectedPendientes.splice(this.selectedPendientes.indexOf(pendiente),1);
            }
        },
        estatusEntrega(participante){
            switch(participante.idEstadoActividad){
                case 1:
                    return "Pendiente entregar";
                case 2:
                    return "Entregada";
                case 3:
                    return "Calificada";
                case 4:
                    return "Anulada";
                case 5:
                    return "Rechazada";
            }
        },
        getCalificacion(participante){
            switch(participante.idEstadoActividad){
                case 1:
                    if(participante.actividadesMateriasBloquesEntregas){
                        if(participante.actividadesMateriasBloquesEntregas.borradorCalificacion){
                            return `${participante.actividadesMateriasBloquesEntregas.borradorCalificacion}/${this.getMaxPunteo} (borrador)`;
                        }
                    }

                    return "Sin calificación";
                case 2:
                    if(participante.actividadesMateriasBloquesEntregas){
                        if(participante.actividadesMateriasBloquesEntregas.borradorCalificacion){
                            return `${participante.actividadesMateriasBloquesEntregas.borradorCalificacion} (borrador)`;
                        }
                    }

                    return "Sin calificación";
                case 3:
                    return `${participante.actividadesMateriasBloquesEntregas.calificacion}/${this.getMaxPunteo}`;
                case 4:
                    if(participante.actividadesMateriasBloquesEntregas){
                        if(participante.actividadesMateriasBloquesEntregas.calificacion){
                            return `${participante.actividadesMateriasBloquesEntregas.calificacion}/${this.getMaxPunteo}`;
                        }else{
                            return `${participante.actividadesMateriasBloquesEntregas.borradorCalificacion}/${this.getMaxPunteo} (borrador)`;
                        }
                    }

                    return `Sin calificación`;
                case 5:
                    return "Rechazada";
            }
        },
        firstPanelValidation(){
            if(this.$vuetify.breakpoint.mdAndUp){
                return true;
            }
            
            if(this.$router.currentRoute.name=="examen_final_docente_detalles"||this.$router.currentRoute.name=="examen_final_extra_docente_detalles"){
                return true;
            }

            return false;
        },
        secondPanelValidation(){
            if(this.$vuetify.breakpoint.mdAndUp){
                if(this.$router.currentRoute.name=="examen_final_docente_detalles"||this.$router.currentRoute.name=="examen_final_extra_docente_detalles"){
                    return true;
                }
            }

            return false;
        },
        goEntrega(participante){
            if(this.$router.currentRoute.name=="examen_final_docente_entrega"){
                this.selectedEntrega=participante;

                console.log(participante.idActividadMateriaBloqueParticipante);

                if(this.from)
                    this.$router.replace({name:"examen_final_extra_docente_entrega",params:{idparticipante:participante.idActividadMateriaBloqueParticipante}});
                else
                    this.$router.replace({name:"examen_final_docente_entrega",params:{idparticipante:participante.idActividadMateriaBloqueParticipante}});
            }
            else{
                this.selectedEntrega=participante;
                
                if(this.from)
                    this.$router.push({name:"examen_final_extra_docente_entrega",params:{idparticipante:participante.idActividadMateriaBloqueParticipante}});
                else
                    this.$router.push({name:"examen_final_docente_entrega",params:{idparticipante:participante.idActividadMateriaBloqueParticipante}});
            }
        },
        participanteData(participante){
            if(participante){
                var estudiante=participante.idEstudianteGradoNavigation.idEstudianteNavigation;
                var nombrecompleto=`${estudiante.nombre1} ${estudiante.nombre2} ${estudiante.apellido1} ${estudiante.apellido2}`;
                return nombrecompleto;
            }
            return "";
        },
        async recogerSeleccionadas(selected){
            this.isLoading=this.$store.state.accentColor2;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.put(`api/docentes/actividades/examen-final/${this.tareaData.actividad.idActividadMateriaBloque}/entregas/recoger`,
            selected
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$store.commit("showMessage",{text:"Examen final recogido correctamente.",color:"green",time:4000});
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });

            this.selectedPendientes=[];
            this.selectedCalificadas=[];
            this.selectedAnuladas=[];
            this.selectedEntregadas=[];
            await this.loadTareasCallPendientes();
            this.selectedGroup="";

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        },
        async restaurarSeleccionadas(selected){
            this.isLoading=this.$store.state.accentColor2;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.put(`api/docentes/actividades/examen-final/${this.tareaData.actividad.idActividadMateriaBloque}/entregas/restaurar`,
            selected
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$store.commit("showMessage",{text:"Examenes finales restaurados correctamente.",color:"green",time:4000});
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });

            this.selectedPendientes=[];
            this.selectedCalificadas=[];
            this.selectedAnuladas=[];
            this.selectedEntregadas=[];
            await this.loadTareasCallPendientes();
            this.selectedGroup="";
            
            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        },
        async ponerpendientesSeleccionadas(selected){
            this.isLoading=this.$store.state.accentColor2;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.put(`api/docentes/actividades/examen-final/${this.tareaData.actividad.idActividadMateriaBloque}/entregas/pendiente`,
            selected
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$store.commit("showMessage",{text:"Examenes finales puestos en \"Pendientes\" correctamente.",color:"green",time:4000});
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });

            this.selectedPendientes=[];
            this.selectedCalificadas=[];
            this.selectedAnuladas=[];
            this.selectedEntregadas=[];
            await this.loadTareasCallPendientes();
            this.selectedGroup="";

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        },
        async anularSeleccionadas(selected){
            this.isLoading=this.$store.state.accentColor2;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.put(`api/docentes/actividades/examen-final/${this.tareaData.actividad.idActividadMateriaBloque}/entregas/anular`,
            selected
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$store.commit("showMessage",{text:"Examenes finales anulados correctamente.",color:"green",time:4000});
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });

            this.selectedPendientes=[];
            this.selectedCalificadas=[];
            this.selectedAnuladas=[];
            this.selectedEntregadas=[];
            await this.loadTareasCallPendientes();
            this.selectedGroup="";

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        },
        async calificarSeleccionadas(selected){
            this.isLoading=this.$store.state.accentColor2;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.put(`api/docentes/actividades/examen-final/${this.tareaData.actividad.idActividadMateriaBloque}/entregas/calificar`,
                selected
            ).then(async(response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$store.commit("showMessage",{text:"Examenes finales calificados correctamente.",color:"green",time:4000});
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });
            
            this.selectedPendientes=[];
            this.selectedCalificadas=[];
            this.selectedAnuladas=[];
            this.selectedEntregadas=[];

            await this.loadTareasCallPendientes();

            this.selectedGroup="";
            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        },
        async sendBorradorPunteoCalificadas(participante){
            participante.isLoadingBorradorCalificada=true;
            participante.isguardandoBorrador=1;

            await this.$axios.put(`api/docentes/actividades/examen-final/entregas/${participante.idActividadMateriaBloqueParticipante}/borrador`,
            participante.actividadesMateriasBloquesEntregas).then(async(response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.filteredAcciones=this.acciones.filter(item=>item=="Calificar"||item=="Anular"||item=="Pendiente");
                        await this.loadTareasCallPendientes();
                    }else{
                        participante.actividadesMateriasBloquesEntregas.borradorCalificacion=this.currentPunteoCalificada;
                        this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                    }
                }
            }).catch((error)=>{
                error;
            });
            
            this.isSavingborrador=false;
            participante.isguardandoBorrador=0;
            participante.isLoadingBorradorCalificada=false;
        },
        async sendBorradorPunteoPendientes(participante){
            participante.isLoadingBorradorPendiente=true;            
            participante.isguardandoBorrador=1;

            await this.$axios.put(`api/docentes/actividades/examen-final/entregas/${participante.idActividadMateriaBloqueParticipante}/borrador`,
            participante.actividadesMateriasBloquesEntregas).then(async(response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.filteredAcciones=this.acciones.filter(item=>item=="Calificar"||item=="Anular");
                        await this.loadTareasCallPendientes();
                    }else{
                        participante.actividadesMateriasBloquesEntregas.borradorCalificacion=this.currentPunteoPendiente;
                        this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                    }
                }
            }).catch((error)=>{
                error;
            });
            
            this.isSavingborrador=false;
            participante.isguardandoBorrador=0;
            participante.isLoadingBorradorPendiente=false;
        },
        async sendBorradorPunteoEntregadas(participante){
            participante.isLoadingBorradorEntregada=true;            
            participante.isguardandoBorrador=1;

            await this.$axios.put(`api/docentes/actividades/examen-final/entregas/${participante.idActividadMateriaBloqueParticipante}/borrador`,
            participante.actividadesMateriasBloquesEntregas).then(async(response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.filteredAcciones=this.acciones.filter(item=>item=="Calificar"||item=="Anular"||item=="Pendiente");
                        await this.loadTareasCallPendientes();
                    }else{
                        participante.actividadesMateriasBloquesEntregas.borradorCalificacion=this.currentPunteoEntregada;
                        this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                    }
                }
            }).catch((error)=>{
                error;
            });
            
            this.isSavingborrador=false;
            participante.isguardandoBorrador=0;
            participante.isLoadingBorradorEntregada=false;
        },
        async loadTareasCallPendientes(){
            await this.loadParticipantes().then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.tareaparticipantesData=response.data.data;

                        this.filterCategoriesEntregas();
                    }
                }
            }).catch((error)=>{
                error;
            });
        },
        loadParticipantes(){return this.$axios.get(`api/docentes/actividades/examenes/${this.$route.params.id}/participantes`);},
        loadExamenFinal(){return this.$axios.get(`api/docentes/actividades/examen-final/${this.$route.params.id}`);},
        async loadAll(){
            this.isLoading=this.$store.state.accentColor2;

            await this.$axios.all([this.loadParticipantes(),this.loadExamenFinal()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.tareaparticipantesData=response[0].data.data;
                        this.tareaData=response[1].data.data;

                        this.filterCategoriesEntregas();
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        filterCategoriesEntregas(){
            this.pendientes=this.tareaparticipantesData.filter(item=>item.idEstadoActividad==1);
            this.initializePendientes();

            this.entregadas=this.tareaparticipantesData.filter(item=>item.idEstadoActividad==2);
            this.initializeEntregadas();

            this.calificadas=this.tareaparticipantesData.filter(item=>item.idEstadoActividad==3);
            this.initializeCalificadas();

            this.anuladas=this.tareaparticipantesData.filter(item=>item.idEstadoActividad==4);
            this.initializeAnuladas();

            this.rechazadas= this.tareaparticipantesData.filter(item=>item.idEstadoActividad==5);
        },
        initializeCalificadas(){
            this.calificadas.map(item=>{
                item.isLoadingBorradorCalificada=false;

                var found=this.selectedCalificadas.find(selected=>selected.idActividadMateriaBloqueParticipante==item.idActividadMateriaBloqueParticipante);

                if(found){
                    if(found.selected){
                        var indexof=this.selectedCalificadas.indexOf(found);
                        item.selected=true;

                        this.selectedCalificadas.splice(indexof,1,item);
                        this.selectedGroup="Calificadas";
                    }
                }
            });
        },
        initializePendientes(){
            this.pendientes.map(item=>{
                item.isLoadingBorradorPendiente=false;

                if(!item.actividadesMateriasBloquesEntregas){
                    item.actividadesMateriasBloquesEntregas={
                        borradorCalificacion:null
                    };
                }

                var found=this.selectedPendientes.find(selected=>selected.idActividadMateriaBloqueParticipante==item.idActividadMateriaBloqueParticipante);
                if(found){
                    if(found.selected){
                        var indexof=this.selectedPendientes.indexOf(found);
                        item.selected=true;

                        this.selectedPendientes.splice(indexof,1,item);
                        this.selectedGroup="Pendientes";
                    }
                }


            });
        },
        initializeEntregadas(){
            this.entregadas.map(item=>{
                item.isLoadingBorradorEntregada=false;

                if(!item.actividadesMateriasBloquesEntregas){
                    item.actividadesMateriasBloquesEntregas={
                        borradorCalificacion:null
                    };
                }

                var found=this.selectedEntregadas.find(selected=>selected.idActividadMateriaBloqueParticipante==item.idActividadMateriaBloqueParticipante);
                if(found){
                    if(found.selected){
                        var indexof=this.selectedEntregadas.indexOf(found);
                        item.selected=true;

                        this.selectedEntregadas.splice(indexof,1,item);
                        this.selectedGroup="Entregadas";
                    }
                }
            });
        },
        initializeAnuladas(){
            this.anuladas.map(item=>{
                if(!item.actividadesMateriasBloquesEntregas){
                    item.actividadesMateriasBloquesEntregas={
                        borradorCalificacion:null
                    };
                }
            });
        }
    },
    created(){
        this.loadAll();
    },
}
</script>
<style scoped>
.body{
    height: 85vh;
}
</style>