<template>
    <v-container class="custom-container">
        <delete-dialog :show="deleteactivityDialog" :content="deletecontent" :context="deletecontext" :idEntity="getpostIdentity"
            @ondelete="ondeletePost" @onclose="deleteactivityDialog=false"
            ></delete-dialog>

        <video-player :videoplayerDialog="videoplayerDialog" @onclose="videoplayerDialog=false;"
        :video="selectedVideo"
        ></video-player>
        <v-row no-gutters justify="center">
            <v-progress-circular v-if="isLoading" indeterminate :color="$store.state.accentColor2" size="50" width="5"></v-progress-circular>
        </v-row>
        <v-dialog persistent max-width="400" v-model="startstopDialog">
            <v-card :loading="isLoadingDialog" :disabled="isLoadingDialog">
                <v-card-title>
                    Confirmación
                </v-card-title>
                <v-card-text>
                    {{startstopText}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="startstopDialog=false">Cancelar</v-btn>
                    <v-btn text @click="onstartstopCall" :color="$store.state.accentColor2">{{startstopAction}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row no-gutters v-if="!isLoading">
            <v-col>
                <v-list-item class="pa-0">
                    <v-list-item-avatar :color="$store.state.accentColor2" v-if="$vuetify.breakpoint.smAndUp">
                        <v-icon color="white">
                            {{getIconText}}
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="'text-h5 text-sm-h4 text-md-h4 teal--text text--darken-1 wrap-text'">
                            {{parcial.title}}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-subtitle-2 wrap-text">
                            {{getCatedraticoNombres}} - {{getFechaCreada}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-menu offset-y>
                        <template v-slot:activator="{on:menu}">
                            <v-btn v-on="menu" icon><v-icon :color="$store.state.accentColor2">more_vert</v-icon></v-btn>
                        </template>
                        <v-list class="pa-0" dense>
                            <v-list-item v-for="(option,index) in options" :key="index" @click="onselectOption(option)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{option}}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-list-item>
                <v-list-item class="pa-0">
                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-subtitle class="text-subtitle-2 font-weight-bold mb-5">
                            <br>
                            <v-row class="mb-1" no-gutters>
                                {{getValorNeto}} puntos <v-spacer></v-spacer> Fecha de examen: {{getFechaLimite}}
                            </v-row>
                            <v-row no-gutters>
                                <v-spacer></v-spacer>
                                Duración: {{getDuracion}} min
                            </v-row>
                        </v-list-item-subtitle>
                        <v-divider :color="getDividerColor"></v-divider>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                <br>
                <v-list-item class="pa-0">
                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-avatar>
                    <v-list-item-content class="pa-2">
                        <v-card class="rounded-xl" elevation="5">
                            <v-card-text>
                                <v-row no-gutters justify="center">
                                    <v-btn @click="startExamen" v-if="isToPlay" outlined depressed :color="$store.state.accentColor2" dark>
                                        <v-icon>play_arrow</v-icon>
                                        Iniciar exámen
                                    </v-btn>
                                    <v-btn @click="stopExamen" v-if="!isToPlay" outlined depressed :color="'red'">
                                        <v-icon>stop</v-icon>
                                        Detener exámen
                                    </v-btn>
                                </v-row>
                                <br>
                                <div v-if="!isToPlay">
                                    <v-row v-if="!isToPlay" no-gutters justify="center" class="text-h4 text-sm-h3 font-weight-bold">
                                        {{getTimer}}
                                    </v-row>
                                    <v-row no-gutters justify="center" class="text-body-2">
                                        <v-col cols="12" align="center"><strong>Iniciado:</strong> {{getExamenHoraIniciado}}</v-col>
                                    </v-row>
                                </div>
                                <div v-if="isToPlay">
                                    <v-row no-gutters justify="center" class="text-h5 text-sm-h4 font-weight-bold">
                                        {{playCountdownText}}
                                    </v-row>
                                    <v-row no-gutters justify="center" class="text-body-2">
                                        <v-col cols="12" align="center"><strong>Iniciado:</strong> {{getExamenHoraIniciado}}</v-col>
                                        <v-col cols="12" align="center"><strong>Finalizado:</strong> {{getExamenHoraFinalizado}}</v-col>
                                        <v-col cols="12" align="center"><strong>Tiempo transcurrido:</strong> {{getExamenTiempo}}</v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                <v-list-item class="pa-0">
                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                        
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-divider :color="getDividerColor"></v-divider>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                <v-list-item class="pa-0">
                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                        
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-body-2" v-html="linkfy(parcial.instructions,{
                        target: {
                            url: '_blank'
                            }
                        }
                    )">
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                <v-list-item class="pa-0" v-if="parcial.examen">
                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                        
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-subtitle-2 font-weight-bold">
                            Examen
                        </v-list-item-title>
                        <br>
                        <v-row no-gutters class="pb-5">
                            <v-col cols="12" md="6">
                                <v-card outlined>
                                    <v-row no-gutters align="center">
                                        <v-card-title class="text-subtitle-1 font-weight-medium">
                                            Examen auto-calificado
                                        </v-card-title>
                                        <v-spacer></v-spacer>
                                        <div class="pa-3">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{on}">
                                                    <v-btn v-on="on" icon :color="$store.state.accentColor2"><v-icon>visibility</v-icon></v-btn>
                                                </template>
                                                <span>
                                                    Ver exámen
                                                </span>
                                            </v-tooltip>
                                        </div>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                <v-list-item class="pa-0" v-if="parcial.videos.length>0">
                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                        
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-subtitle-2 font-weight-bold">
                            Videos
                        </v-list-item-title>
                        <br>
                        <v-row no-gutters class="pb-5">
                            <v-col v-for="(video,index) in parcial.videos" :key="index" cols="12">
                                <video-card :videoInfo="video" :action="'get'" @onclickvideo="playvideo(video)"></video-card>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                
                <v-list-item class="pa-0" v-if="parcial.archivos.length>0">
                    <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-subtitle-2 font-weight-bold">
                            Archivos
                        </v-list-item-title>
                        <br>
                        <v-row class="py-0 card-text">
                            <v-col cols="12" xs="12" v-for="(file,index) in parcial.archivos" :key="index">
                                <file-card :file="file" :publico="true"></file-card>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                <v-list-item class="pa-0" v-if="parcial.hasData">
                    <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-divider></v-divider>
                        <comments-publicos @onerror="onerror" 
                            @onupdateComments="(comments)=>{onupdateComments(comments)}" 
                            :comentarios="parcial.comentarios" 
                            from="parcial" 
                            :actividad="parcial"></comments-publicos>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import GetReadableDate from '../../../../../utils/timeUtils';
import DeleteDialog from '../../../../globalComponents/DeleteDialog';
import VideoCard from '../VideoCard.vue';
import VideoPlayer from '../VideoPlayer.vue';
import linkfy from 'linkifyjs/lib/linkify-string';
import CommentsPublicos from '../CommentsPublic.vue';
import FileCard from '../FileCard.vue';

export default {
    components:{DeleteDialog,VideoCard,VideoPlayer,CommentsPublicos,FileCard},
    props:[
        "tab",
        "isExtra"
    ],
    watch:{
        tab:function(newval,oldval){
            oldval;
            if(newval==0)
                this.loadAll();
            else
                clearInterval(this.countdown);
        }
    },
    data:function(){
        return({
            options:[
                "Eliminar",
                "Editar"
            ],
            deleteactivityDialog:false,
            deletecontent:"",
            deletecontext:"",
            startstopDialog:false,
            startstopText:"",
            startstopAction:"Iniciar",
            videoplayerDialog:false,
            selectedVideo:"",
            parcial:"",
            isLoading:true,
            isLoadingDialog:false,
            timer:"",
            countdown:""
        })
    },
    computed:{
        getDividerColor(){return this.$vuetify.theme.dark?'white':'black';},
        isToPlay(){
            if(this.parcial.actividad.examenEnCurso){
                var horaIniciado=new Date(this.parcial.actividad.examenFechaHoraIniciado);
                var tiempoLimite=new Date(horaIniciado.getTime()+(this.parcial.actividad.examenTiempoMaximo*60000)-1000);

                if(new Date().getTime()>=tiempoLimite.getTime())
                    return true;
                else
                    return false;
            }else{
                return true;
            }
        },
        playCountdownText(){
            if(this.isToPlay){
                if(this.parcial.actividad.examenEnCurso==undefined){
                    return "Parcial no iniciado";
                }else{
                    return "Parcial finalizado";
                }
            }
            return "";
        },
        getExamenTiempo(){
            if(this.parcial){
                if(this.parcial.actividad.examenFechaHoraIniciado){
                    var horaIniciado=new Date(this.parcial.actividad.examenFechaHoraIniciado);
                    var horaFinalizado=new Date(this.parcial.actividad.examenFechaHoraFinalizado);
                    var segundosTotales=(horaFinalizado.getTime()-horaIniciado.getTime())/1000;
    
                    if((segundosTotales/60)>=1){
                        var enteroMinuto=Math.floor(segundosTotales/60);
                        var restantSeconds=Math.floor(segundosTotales-(enteroMinuto*60));
    
                        if(restantSeconds<10)
                            restantSeconds=`0${restantSeconds}`;
    
                        return `${enteroMinuto}:${restantSeconds} minutos`;
                    }else{
                        return `${segundosTotales} segundos`;
                    }
                }
            }
            return "";
        },
        getExamenHoraIniciado(){
            if(this.parcial)
                if(this.parcial.actividad.examenFechaHoraIniciado)
                    return this.getHumanDate(this.parcial.actividad.examenFechaHoraIniciado);
            return "";
        },
        getExamenHoraFinalizado(){
            if(this.parcial)
                if(this.parcial.actividad.examenFechaHoraFinalizado)
                    return this.getHumanDate(this.parcial.actividad.examenFechaHoraFinalizado);
            return "";
        },
        getTimer(){

            return this.timer;
        },
        getDuracion(){
            if(this.parcial)
                return this.parcial.actividad.examenTiempoMaximo;
            return "";
        },
        getIconText(){
            if(this.parcial)
                return this.parcial.actividad.tipoActividadNavigation.icono;
            return "";
        },
        getTextClass(){
            return this.$vuetify.theme.dark?'text-h4':`text-h4 teal-darken-1--text`;
        },
        getFechaCreada(){
            if(this.parcial){
                return this.getHumanDate(this.parcial.actividad.fechaCreada);
            }

            return "";
        },
        getFechaLimite(){
            if(this.parcial){
                var nohour=this.getHumanDate(this.parcial.actividad.fechaLimite);
                nohour=nohour.substring(0,nohour.length-6);
                return nohour;
            }

            return "";
        },
        getCatedraticoNombres(){
            if(this.parcial){
                if(this.parcial.catedratico)
                    return this.parcial.catedratico.nombres;
            }
            return "";
        },
        getValorNeto(){
            if(this.parcial){
                return this.parcial.actividad.valorNeto;
            }
            return "";
        },
        getpostIdentity(){
            if(this.parcial){
                return this.parcial.actividad.idActividadMateriaBloque;
            }
            return "";
        }
    },
    methods:{
        onerror(){
            this.loadAll();
        },
        onupdateComments(comments){
            this.tarea.comentarios=comments;
        },
        linkfy,
        getHumanDate: GetReadableDate,
        async onstartstopCall(){
            clearInterval(this.countdown);

            switch(this.startstopAction){
                case "Iniciar":
                    await this.startCall();
                    break;
                case "Detener":
                    await this.stopCall();
                    break;
            }

            this.startstopDialog=false;
        },
        startExamen(){
            this.startstopAction="Iniciar";
            this.startstopText="¿Desea iniciar este exámen?"
            this.startstopDialog=true;
        },
        stopExamen(){
            this.startstopAction="Detener";
            this.startstopText="¿Desea detener este exámen?"
            this.startstopDialog=true;
        },
        onselectOption(option){
            if(option=="Eliminar"){
                if(this.isExtra){
                    this.deletecontext="parcial extra";
                    var tituloextra=this.parcial.title;
                    if(this.parcial.title.length>20){
                        tituloextra=`${tituloextra.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el parcial extra con título "${tituloextra}"?`;
                    this.deleteactivityDialog=true;
                }else{
                    this.deletecontext="parcial";
                    var titulo=this.parcial.title;
                    if(this.parcial.title.length>20){
                        titulo=`${titulo.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el parcial con título "${titulo}"?`;
                    this.deleteactivityDialog=true;
                }
            }else{
                if(this.isExtra)
                    this.$emit("onupdateparcialextra",this.parcial);
                else
                    this.$emit("onupdateparcial",this.parcial);
            }
        },
        ondeletePost(response){
            this.$emit('ondeleteparcial',response);
        },
        getFileName(name){
            if(name.length>30){
                var extensionSplitted=name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${name.substring(0,30)}....${extension}`;
            }
            return name;
        },
        playvideo(video){
            this.selectedVideo=video;
            this.videoplayerDialog=true;
        },
        async startCall(){
            this.isLoadingDialog=this.$store.state.accentColor2;

            await this.$axios.get(`api/docentes/actividades/parcial/${this.$route.params.id}/iniciar`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        response;
                    }
                }
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoadingDialog=false;
        },
        async stopCall(){
            this.isLoadingDialog=this.$store.state.accentColor2;

            await this.$axios.get(`api/docentes/actividades/parcial/${this.$route.params.id}/detener`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        response;
                    }
                }
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoadingDialog=false;
        },
        loadParcial(){return this.$axios.get(`api/docentes/actividades/parcial/${this.$route.params.id}`);},
        async loadAll(){
            this.isLoading=true;

            await this.$axios.all([this.loadParcial()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.parcial=response[0].data.data;
                        this.timerFunction();
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        timerFunction(){
            var horaLimite=new Date((this.parcial.actividad.examenFechaHoraFinalizado));

            if(this.parcial.actividad.examenEnCurso){
                if(this.parcial.actividad.examenEnCurso==true){
                    var millisecondsLimite=horaLimite.getTime()-1000;
                    if(new Date().getTime()>=millisecondsLimite){
                        return;
                    }
                }else{
                    return;
                }
            }else if(!this.parcial.actividad.examenEnCurso){
                return;
            }

            var inicial=(horaLimite-new Date());
            inicial=(inicial/1000)/60;

            var seconds=inicial*60;
            inicial=Math.floor(inicial);

            seconds=Math.round(seconds);
            var restant=Math.floor(seconds/60);
            var currentSecond=seconds-(restant*60);

            if(currentSecond==0)
                inicial=restant;

            if(currentSecond<10)
                currentSecond=`0${currentSecond}`;

            this.timer=`${inicial}:${currentSecond}`;
            this.countdown=setInterval(()=>{
                seconds-=1;

                if(seconds==0){
                    clearInterval(this.countdown);
                    this.loadAll();
                }
                console.log("countdown pass");

                var restant=Math.floor(seconds/60);
                if(restant>=1){
                    var currentSecond=seconds-(restant*60);

                    if(currentSecond<10)
                        currentSecond=`0${currentSecond}`;

                    this.timer=`${restant}:${currentSecond}`;
                }else{
                    if(seconds<10)
                        seconds=`0${seconds}`;
                    this.timer=`${restant}:${seconds}`;
                }
            },1000);
        },
    },
    created(){
        this.loadAll();
    },
    beforeDestroy(){
        clearInterval(this.countdown);
    }
}
</script>
<style scoped>

</style>