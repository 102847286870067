<template>
    <v-dialog persistent width="650" v-model="calificacionesDialog" scrollable>
        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title>Calificaciones Estudiante</v-card-title>
            <v-card-text v-if="loaded">
                <v-row no-gutters class="text-caption font-weight-bold" justify="center">{{getEstudianteNombre}}</v-row>
                <br>
                <calificaciones-content :colorTheme="1" :cuadrosnotasData="calificacionesData"></calificaciones-content>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :color="$store.state.accentColor" @click="$emit('onclose')">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import CalificacionesContent from '../../estudiantil/calificaciones/CalificacionesContent.vue'
export default {
    components:{CalificacionesContent},
    props:[
        "calificacionesDialog",
        "estudianteGrado",
        "estudiante"
    ],
    computed:{
        getEstudianteNombre(){
            if(this.estudianteData)
                return `${this.estudianteData.nombre1} ${this.estudianteData.nombre2} ${this.estudianteData.nombre3} ${this.estudianteData.apellido1} ${this.estudianteData.apellido2}`;
            return "Cargando...";
        }
    },
    watch:{
        calificacionesDialog(newval,oldval){
            oldval;
            if(newval){
                this.loaded=false;
                this.loadAll();
            }
        }
    },
    data:function(){
        return ({
            loaded:false,
            isLoading:false,
            estudianteData:"",
            calificacionesData:""
        });
    },
    methods:{
        loadEstudiante(){return this.$axios.get(`api/estudiantes/${this.estudiante}`);},
        loadCalificaciones(){return this.$axios.get(`api/estudiantes/inscripcion/${this.estudianteGrado}/calificaciones`)},
        async loadAll(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.all([this.loadEstudiante(),this.loadCalificaciones()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.estudianteData=response[0].data.data;
                        this.calificacionesData=response[1].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.loaded=true;
            this.isLoading=false;
        },
    },
}
</script>
<style scoped>

</style>