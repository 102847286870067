<template>
    <v-dialog max-width="700" persistent v-model="asignarClasesDialog" scrollable>
        <delete-dialog :show="deleteDialog" :content="deletecontent" :context="deletecontext" :idEntity="selectedAsignacion.idGradoSeccionMateriaColaborador" 
        @onclose="deleteDialog=false" @ondelete="ondeleteAsignacionResponse">
        </delete-dialog>
        <v-card :loading="allLoaded" :disabled="allLoaded" outlined>
            <v-card-title>Materias {{$store.state.cicloEscolar.cicloEscolar1}} - {{colaborador.nombres}}</v-card-title>
            <v-card-text>
                <v-form ref="asignacionForm">
                    <v-row class="pa-0 ma-0" justify="center">
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                            <v-select
                                :rules="asignacionClase.nivelRules"
                                :loading="isLoading"
                                :disabled="isLoading"
                                label="Nivel estudiantil" 
                                :items="nivelesData"
                                item-text="nombre"
                                item-value="codigoNivelEstudiantil"
                                outlined
                                rounded
                                :color="$store.state.accentColor"
                                v-model="asignacionClase.nivel"
                                item-color=""
                                @change="onnivelChange"
                            >

                            </v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" v-if="validateCarreras">
                            <v-select
                                :rules="asignacionClase.carreraRules"
                                :loading="isLoading"
                                :disabled="isLoading"
                                label="Carrera" 
                                :items="carrerasData"
                                :item-text="item=>`${item.idCarreraNavigation.nombre}`"
                                item-value="idCarrera"
                                outlined
                                rounded
                                @change="oncarreraChange"
                                :color="$store.state.accentColor"
                                return-object
                                v-model="asignacionClase.carrera"
                                item-color=""
                            >

                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" v-if="validateMaterias">
                            <v-select
                                :rules="asignacionClase.materiaRules"
                                :loading="isLoading"
                                :disabled="isLoading"
                                label="Materias" 
                                :items="materiasData"
                                item-text="nombre"
                                item-value="idMateria"
                                outlined
                                rounded
                                @change="onmateriaChange"
                                :color="$store.state.accentColor"
                                return-object
                                v-model="asignacionClase.materia"
                                item-color=""
                            >

                            </v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" v-if="validateGrados">
                            <v-select
                            :rules="asignacionClase.gradosRules"
                            :color="$store.state.accentColor"
                            :loading="isLoading"
                            :disabled="isLoading"
                            label="Grados" 
                            :items="gradosData"
                            :item-text="item =>`${item.idGradoNavigation.nombre} ${item.idSeccionNavigation.seccion}`"
                            item-value="idGradoSeccion"
                            outlined
                            rounded
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            v-model="asignacionClase.grados"
                            item-color=""
                            return-object
                                >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="center">
                        <v-btn rounded outlined @click="addMateria" :disabled="asignacionClase.grados.length==0">Agregar</v-btn>
                    </v-row>
                </v-form>
                <br>
                <v-row no-gutters justify="center" class="text-subtitle-1">
                    Clases asignadas
                </v-row>
                <v-row class="pa-0 ma-0">
                    <v-col v-for="materia in materiasAssigned" :key="materia.idMateria" cols="12" xs="12" sm="6">
                        <materia-asignada-card @ondelete="(item)=>{ondeleteAsignacion(item,materia)}" :materia="materia" :colaborador="colaborador" @ondeleteMateria="deleteMateria(materia)" @ontransfer="ontransfer"></materia-asignada-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="green" @click="closeDialog">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import MateriaAsignadaCard from './MateriaAsignadaCard.vue';
import DeleteDialog from '../../globalComponents/DeleteDialog.vue';
export default {
  components: { MateriaAsignadaCard,DeleteDialog },
    props:[
        "asignarClasesDialog",
        "colaborador"
    ],
    watch:{
        asignarClasesDialog:async function(newval,oldval){
            oldval;
            if(newval){                
                await this.loadAll();
            }
        },

    },
    computed:{
        validateCarreras:function(){
            if(this.asignacionClase.nivel=='03-12-0029-46'){
                return true;
            }
            return false;
        },
        validateMaterias:function(){
            if(this.asignacionClase.nivel){
                if(this.asignacionClase.nivel!="03-12-0029-46"){
                    return true;
                }else{
                    if(this.asignacionClase.carrera){
                        return true;
                    }
                }
            }

            return false;
        },
        validateGrados:function(){
            if(this.asignacionClase.materia){
                return true;
            }

            return false;
        },
        
    },
    data:()=>({
        nivelesData:[],
        carrerasData:[],
        materiasData:[],
        gradosData:[],
        materiasAssigned:[],
        allLoaded:false,
        isLoading:false,
        deleteDialog:false,
        deletecontext:"",
        deletecontent:"",
        selectedAsignacion:"",
        asignacionClase:{
            nivel:null,
            nivelRules:[
                v=>!!v||"Nivel estudiantil es requerido."
            ],
            carrera:"",
            carreraRules:[
                v=>!!v||"Carrera es requerido."
            ],
            materia:"",
            materiaRules:[
                v=>!!v||"Materia es requerido."
            ],
            grados:[],
            gradosRules:[
                v=>v.length>0||"Grados es requerido."
            ]
        }
    }),
    methods:{
        ondeleteAsignacion(asignacion,materia){
            this.selectedAsignacion=asignacion;
            this.deletecontext="asignacion";

            var carrera="";
            var nivelestudiatil="";
            var seccion="";
            var grado="";

            if(this.selectedAsignacion.idGradoNavigation.idCarrera)
                carrera=this.selectedAsignacion.idGradoNavigation.idCarreraNavigation.nombre;
            if(this.selectedAsignacion.idGradoNavigation.idNivelEstudiantil)
                nivelestudiatil=this.selectedAsignacion.idGradoNavigation.idNivelEstudiantilNavigation.nombre;
            if(this.selectedAsignacion.idSeccion)
                seccion=this.selectedAsignacion.idSeccionNavigation.seccion;
            if(this.selectedAsignacion.idGradoNavigation)
                grado=this.selectedAsignacion.idGradoNavigation.nombre;

            this.deletecontent=`¿Desea eliminar el grado "${nivelestudiatil} - ${carrera} - ${grado} - sección ${seccion}" de la clase "${materia.nombre}"?`;
            this.deleteDialog=true;
        },
        ondeleteAsignacionResponse(response){
            switch(response[0]){
                case 1:
                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
                default:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
            }
            this.loadAll();
        },
        ontransfer(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.loadAll();
        },
        async onnivelChange(val){
            this.asignacionClase.carrera="";
            this.asignacionClase.materia="";

            if(val=="03-12-0029-46"){
                await this.loadCarreras(val);
            }else{
                await this.loadMaterias(val);
            }
        },
        async oncarreraChange(val){
            this.asignacionClase.materia="",
            await this.loadMaterias(this.asignacionClase.nivel,val);
        },
        closeDialog(){            
            this.$refs.asignacionForm.reset();
            this.materiasAssigned=[];
            this.$emit('onclose');
        },
        deleteMateria(item){
            this.materiasAssigned.splice(this.materiasAssigned.indexOf(item),1);
        },
        addMateria(){
            if(this.$refs.asignacionForm.validate()){
                    var arrayToAssign=[];

                    this.asignacionClase.grados.forEach((item)=>{
                       arrayToAssign.push(
                            {
                                idColaborador:this.colaborador.idColaborador,
                                idMateria:this.asignacionClase.materia.idMateria,
                                idGradoSeccion:item.idGradoSeccion,
                            }
                        ); 
                    });

                    this.addGradosCall(arrayToAssign);
                    this.$refs.asignacionForm.reset();
            }
        },
        async addGradosCall(grados){
            this.allLoaded=this.$store.state.accentColor;

            await this.$axios.put(`api/colaboradores/materias/grados/${this.colaborador.idColaborador}?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`,grados).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("onassignMaterias",[1,response.data.message]);
                    }else{
                        this.$emit("onassignMaterias",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("onassignMaterias",[2,error.response.data.message]);
                    }else{
                        this.$emit("onassignMaterias",[2]);
                    }
                }
            });

            await this.loadAll();
            this.allLoaded=false;
        },
        async onmateriaChange(val){
            this.asignacionClase.grados=[];
            await this.loadGrados(val.idMateria);
        },
        async loadCarreras(nivel){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.get(`api/carreras/nivel/${nivel}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.carrerasData=response.data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        async loadMaterias(nivel,carrera){
            var url="";

            if(carrera){
                url=`api/materias/nivel/${nivel}?carrera=${carrera.idCarrera}`;
            }else{
                url=`api/materias/nivel/${nivel}`;
            }

            this.isLoading=this.$store.state.accentColor;
            await this.$axios.get(url).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.materiasData=response.data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        async loadGrados(idMateria){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.get(`api/grados/materia/${idMateria}?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}&type=all`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.gradosData=response.data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        loadNiveles(){
            return this.$axios.get(`api/nivelesestudiantiles`);
        },
        loadAssignments(){
            return this.$axios.get(`api/colaboradores/materias/${this.colaborador.idColaborador}?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`);
        },
        async loadAll(){
            this.allLoaded=this.$store.state.accentColor;
            await this.$axios.all([this.loadNiveles(),this.loadAssignments()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.nivelesData=response[0].data.data;
                        this.materiasAssigned=response[1].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.allLoaded=false;
        }
    }
}
</script>
<style>
.edited-new-tag{
    position:absolute;right:5px;bottom:0px;align-content: right;
}
</style>