<template>
    <v-card outlined>
        <v-card-title>
            <v-btn class="delete_button" icon @click="$emit('ondelete')"><v-icon>clear</v-icon></v-btn>
            {{colaborador.nombres}}
        </v-card-title>
        <v-card-subtitle>
            <div v-for="cargo in colaborador.colaboradoresCargos" :key="cargo.idColaboradorCargo">
                {{cargo.idCargoNavigation.nombre}}
            </div>
        </v-card-subtitle>
        <v-card-actions>
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="$emit('onedit')" v-if="$currentYearValidation()"><v-icon>edit</v-icon></v-btn>
                </template>
                <span>Editar colaborador</span>
            </v-tooltip>
            <div class="pr-5"></div>
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="()=>{$emit('onaddclases')}" v-if="colaborador.colaboradoresCargos.some(item=>item.idCargo=='d3687102-7207-4c7b-a69e-c45d210d0ec1')&&$currentYearValidation()"><v-icon>format_list_numbered</v-icon></v-btn>
                </template>
                <span>Materias asignadas</span>
            </v-tooltip>
            <div class="pr-5"></div>
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="()=>{$emit('onaddgrades')}" v-if="colaborador.colaboradoresCargos.some(item=>item.idCargo=='d3687102-7207-4c7b-a69e-c45d210d0ec1')&&$currentYearValidation()"><v-icon>view_module</v-icon></v-btn>
                </template>
                <span>Grados a cargo</span>
            </v-tooltip>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props:[
        "colaborador"
    ],
    created(){
        
    }
}
</script>
<style scoped>
.delete_button{
    position:absolute;right:0;top:0;
}
</style>