<template>
    <div id="InicioColaboradores">
        <AppBar :allLoaded="true" :name="title"/>
        <v-row class="pa-0 ma-0" justify="center">
            <div class="text-h4 font-weight-bold">{{getCicloEscolar}}</div>
        </v-row>
        <br>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" md="4" lg="4" xl="2">
                    <v-card rounded="xl" hover :disabled="$store.state.isLoading">
                        <v-row no-gutters class="pt-4 px-3" justify="center">
                            <div class="text-h5 font-weight-black" align="center">
                                Alumnos insolventes {{getCurrentCiclo}}
                            </div>
                        </v-row>
                        <v-card-text>
                            <v-row no-gutters justify="center">
                                <v-progress-circular 
                                :value="getTotalEquivalent" 
                                size="120"
                                width="15">
                                    {{getTextStat}}
                                </v-progress-circular>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text rounded outlined @click="onPendientesPagos()">Ver resumen</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" xl="2">
                    <v-card rounded="xl" hover :disabled="$store.state.isLoading">
                        <v-row no-gutters class="pt-4 px-3" justify="center">
                            <div class="text-h5 font-weight-black" align="center">
                                Alumnos nuevos {{getCurrentCiclo}}
                            </div>
                        </v-row>
                        <v-card-text>
                            <v-row no-gutters justify="center">
                                <v-progress-circular 
                                :value="getTotalNewsEquivalent" 
                                size="120"
                                width="15">
                                    {{getNuevosTextStat}}
                                </v-progress-circular>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text rounded outlined @click="onNuevosEstudiantes()">Ver resumen</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <router-view :dialog="pagosResumeDialog" :dialogNuevos="nuevosResumeDialog" />
    </div>  
</template>
<script>
import AppBar from '../../globalComponents/Appbar'

export default {
    props:{
        title:String
    },
    computed:{
        getCicloEscolar(){
            if(this.$store.state.cicloEscolar)
                return `Ciclo escolar ${this.$store.state.cicloEscolar.cicloEscolar1}`;
            return "";
        },
        getTotalEquivalent(){
            if(this.pagosResume){
                return (100/this.pagosResume.totalAlumnosCiclo).toFixed(2)*Number.parseFloat(this.pagosResume.totalAlumnosInsolventes).toFixed(2);
            }

            return 0;
        },
        getTotalNewsEquivalent(){
            if(this.nuevosResume){
                return (100/this.nuevosResume.totalAlumnosCiclo).toFixed(2)*Number.parseFloat(this.nuevosResume.totalAlumnosNuevos).toFixed(2);
            }

            return 0;
        },
        getTextStat(){
            if(this.pagosResume){
                return  `${this.pagosResume.totalAlumnosInsolventes} / ${this.pagosResume.totalAlumnosCiclo}`;
            }
            return "";
        },
        getNuevosTextStat(){
            if(this.nuevosResume){
                return  `${this.nuevosResume.totalAlumnosNuevos} / ${this.nuevosResume.totalAlumnosCiclo}`;
            }
            return "";
        },
        getCurrentCiclo(){
            if(this.$store.state.cicloEscolar)
                return this.$store.state.cicloEscolar.cicloEscolar1;
            return "";
        }
    },
    components:{
        AppBar
    },
    data:function(){
        return ({
            pagosResume:"",
            nuevosResume:"",
            pagosResumeDialog:false,
            nuevosResumeDialog:false,
            unsubscribe:""
        });
    },
    methods:{
        onNuevosEstudiantes(){
            this.$router.push({ name:'Estudiantes nuevos', params: { ciclo: this.$store.state.cicloEscolar.cicloEscolar1 } });
            this.nuevosResumeDialog=true;
        },
        onPendientesPagos(){
            this.$router.push({ name:'Pendientes pagos', params: { ciclo: this.$store.state.cicloEscolar.cicloEscolar1 } });
            this.pagosResumeDialog=true;
        },
        getPagosResume(){return this.$axios.get(`api/estudiantes/${this.$store.state.cicloEscolar.idCicloEscolar}/pagos-resume?by=uptodate`)},
        getNuevosAmount(){return this.$axios.get(`api/estudiantes/${this.$store.state.cicloEscolar.cicloEscolar1}/nuevos`)},
        async loadAll(){
            this.$store.commit("setLoading",true);
            await this.$axios.all([this.getPagosResume(),this.getNuevosAmount()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200)
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.pagosResume=response[0].data.data;
                        this.nuevosResume=response[1].data.data;
                    }
            }).catch((error)=>{
                console.log(error);
            });
            this.$store.commit("setLoading",false);
        }
    },
    created(){
        this.loadAll();

        this.unsubscribe=this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'changeCiclo') {
                state;
                this.loadAll();
            }
        });
    },
    beforeDestroy(){
        this.unsubscribe();
    }
}
</script>
<style scoped>

</style>