const Days=[
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo"
];

const Months=[
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
];

function GetRedeableDate (date){
    var datedate=new Date(date);

    var time="";

    var splittedTime=date.split("T")[1];
    if(splittedTime){
        var hourminute=splittedTime.split(".")[0];
        time=hourminute.substring(0,(hourminute.length-1)-2);
    }

    var month=Months[datedate.getMonth()].substring(0,3);

    return `${datedate.getDate()} ${month} ${datedate.getFullYear()} ${time}`;
}

export {Days,Months};
export default GetRedeableDate;