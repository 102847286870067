<template>
    <v-dialog v-model="editavisoDialog" max-width="600" persistent scrollable>
        <new-info-post v-if="editavisoDialog" @oncancel="$emit('onclose')" :action="action" :tipoactividad="getTipoActividad" 
        :actividad="actividad"
        @onupdate="onupdateCall"
        @onsave="(response)=>{$emit('onsave',response)}"
        ></new-info-post>
    </v-dialog>
</template>
<script>
import NewInfoPost from './NewInfoPost.vue'
export default {
    components:{NewInfoPost},
    props:[
        "editavisoDialog",
        "actividad",
        "action",
        "tipoactividad"
    ],
    computed:{
        getTipoActividad:function(){
            if(this.action=="edit"){
                if(this.actividad){
                    return this.actividad.actividad.tipoActividadNavigation
                }
            }else{
                return this.tipoactividad;
            }

            return "";
        }
    },
    watch:{
        editavisoDialog:function(newval,oldval){
            newval;
            oldval;
        }
    },
    methods:{
        onupdateCall(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    this.$emit('onupdate');
                    this.$emit('onclose');
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
    }
}
</script>
<style scope
NewInfoPostd>

</style>