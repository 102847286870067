<template>
    <v-card outlined class="rounded-xl card-text" ripple @click="onclickTarea">
            <v-list-item>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                    <v-icon size="30" :color="$store.state.accentColor2">{{entregaTarea.tarea.actividad.tipoActividadNavigation.icono}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2 wrap-text">
                        {{getTareaTitle}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption">
                        {{getReadableDate(entregaTarea.tarea.actividad.fechaCreada)}}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-row no-gutters>
                        <v-col align="center">
                            <div :class="statusEntrega(1)">{{statusEntrega()}}</div>
                            <div :class="punteoEntrega(1)">{{punteoEntrega()}}</div>
                        </v-col>
                    </v-row>
                </v-list-item-action>
            </v-list-item>
    </v-card>
</template>
<script>
import {Months} from '../../../../../../utils/timeUtils';
export default {
    props:[
        "entregaTarea",
    ],
    computed:{
        getTareaTitle(){
            if(this.entregaTarea.tarea.title.length>30){
                return `Tarea extra: ${this.entregaTarea.tarea.title.substring(0,30)}...`;
            }
            return `Tarea extra: ${this.entregaTarea.tarea.title}`;
        }
    },
    data:function(){
        return({
        })
    },
    methods:{
        statusEntrega(isClass){
            if(this.entregaTarea.participante){
                if(isClass){
                    var baseClass="text-subtitle-2";
                    switch(this.entregaTarea.participante.idEstadoActividad){
                        case 1: 
                        if(this.currentDate>this.lastDate)
                                return baseClass.concat(" red--text");
                        return baseClass;
                        case 2: return baseClass;
                        case 3: return baseClass;
                        case 4: return baseClass+=" red--text";
                        case 5: return baseClass+=" red--text";
                    }

                    return baseClass;
                }else{
                    switch(this.entregaTarea.participante.idEstadoActividad){
                        case 1: 
                            if(this.currentDate>this.lastDate)
                                return "No entregada"
                        return "Pendiente entregar";
                        case 2: return "Entregada";
                        case 3: return "Calificada";
                        case 4: return "Anulada";
                        case 5: return "Rechazada";
                    }
                    return ""
                }
            }
            return "";
        },
        punteoEntrega(isClass){
            if(this.entregaTarea.participante){
                if(isClass){
                    switch(this.entregaTarea.participante.idEstadoActividad){
                        case 3: 
                        var punteo=this.entregaTarea.participante.actividadesMateriasBloquesEntregas.calificacion;
                        var valor=this.entregaTarea.tarea.actividad.valorNeto;
                        if(punteo>(valor/2))
                            return "text-subtitle-2 green--text";
                        else if(punteo<(valor/2))
                            return "text-subtitle-2 red--text";

                        return "text-subtitle-2";
                    }
                    return ""
                }else{
                    switch(this.entregaTarea.participante.idEstadoActividad){
                        case 3: return `${this.entregaTarea.participante.actividadesMateriasBloquesEntregas.calificacion}/${this.entregaTarea.tarea.actividad.valorNeto}`;
                    }
                    return ""
                }
            }
            return "";
        },
        onclickTarea(){
            this.$emit("ondetails");
        },
        getReadableDate(date){
            var datedate=new Date(date);

            var splittedTime=date.split("T")[1];
            var hourminute=splittedTime.split(".")[0];
            var time=hourminute.substring(0,(hourminute.length-1)-2);
            var month=Months[datedate.getMonth()].substring(0,3);

            return `${datedate.getDate()} ${month} ${datedate.getFullYear()} ${time}`;
        }
    }
}
</script>
<style scoped>

</style>