<template>
    <v-app id="diversificado_materias">
        <!--<materias :title="title"></materias>-->
        <app-bar :name="title" :allLoaded="allLoaded"></app-bar>
        <carrera-dialog :carreraDialog="carreraDialog" @onclose="carreraDialog=false;" :action="action" @onsave="onsaveCarrera" :carreraToEdit="selectedCarrera"
        @onupdate="onupdateCarrera"></carrera-dialog>

        <delete-dialog :show="deleteCarreraDialog" :context="'carrera'" :content="carreraNombre"
        :idEntity="selectedCarrera.idCarrera" @onclose="deleteCarreraDialog=false;" @ondelete="ondeleteCarrera"></delete-dialog>
        <materia-dialog :materiaDialog="materiaDialog" @onclose="materiaDialog=false;" :action="action" :carrera="selectedCarrera" @onsave="onsaveMateria" 
        :materiaToEdit="selectedMateria" @onupdate="onupdateMateria"></materia-dialog>
        <delete-dialog :show="deleteMateriaDialog" :context="'materia'" :content="materiaNombre"
        :idEntity="selectedMateria.idMateria" @onclose="deleteMateriaDialog=false;" @ondelete="ondeleteMateria"></delete-dialog>

        <div v-if="allLoaded">
            <v-row justify="center"><h1 class="text-h6 font-weight-light">Carreras</h1></v-row>
            <v-row class="pa-0 ma-0">
                <v-col v-for="carrera in carrerasData" :key="carrera.idCarrera" cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <carrera-card :carrera="carrera" @ondelete="()=>{selectedCarrera=carrera;deleteCarreraDialog=true;}" 
                        @onupdate="()=>{
                            action='edit';
                            selectedCarrera=carrera;
                            carreraDialog=true;
                        }"
                        @onaddmateria="()=>{
                            action='add';
                            selectedCarrera=carrera;
                            materiaDialog=true;
                        }"
                        @ondeleteMateria="(materia)=>{
                            selectedCarrera=carrera;
                            selectedMateria=materia.idMateriaNavigation;
                            deleteMateriaDialog=true;
                        }"
                        @onupdateMateria="(materia)=>{
                            action='edit';
                            selectedMateria=materia.idMateriaNavigation;
                            selectedCarrera=carrera;
                            materiaDialog=true;
                        }"
                        ></carrera-card>
                </v-col>
            </v-row>
            <add-button tooltipText="Agregar carrera" @onadd="()=>{action='add';carreraDialog=true;}"></add-button>
        </div>
    </v-app>    
</template>
<script>
import AddButton from '../../../../globalComponents/AddButton.vue';
//import Materias from '../Materias.vue'
import AppBar from '../../../../globalComponents/Appbar.vue'
import DeleteDialog from '../../../../globalComponents/DeleteDialog.vue';
import MateriaDialog from '../MateriaDialog.vue';
import CarreraCard from './CarreraCard.vue';
import CarreraDialog from './CarreraDialog.vue';
export default {
    components:{/*Materias,*/AppBar, AddButton, CarreraDialog, CarreraCard,DeleteDialog,MateriaDialog },
    props:[
        "title"
    ],
    watch:{

    },
    computed:{
        carreraNombre:function(){
            if(this.selectedCarrera.idCarreraNavigation){
                return `¿Desea eliminar la carrera '${this.selectedCarrera.idCarreraNavigation.nombre}'?`;
            }else{
                return `¿Desea eliminar la carrera?`;
            }
        },
        materiaNombre:function(){
            if(this.selectedMateria.idMateria){
                return `¿Desea eliminar la materia '${this.selectedMateria.nombre}'?`;
            }else{
                return `¿Desea eliminar la materia?`;
            }
        }
    },
    data:function(){
        return ({
            materiaDialog:false,
            allLoaded:true,
            carrerasData:[],
            carreraDialog:false,
            deleteCarreraDialog:false,
            deleteMateriaDialog:false,
            selectedCarrera:Object,
            selectedMateria:Object,
            action:"",
            unsubscribe:"",
            tab:null
        });
    },
    methods:{
        onsaveMateria(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.selectedCarrera.nivelesEstudiantilesCarrerasMaterias.push(response[1]);
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        onupdateMateria(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        ondeleteMateria(response){
            switch(response[0]){
                case 0:
                        this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    var index=this.selectedCarrera.nivelesEstudiantilesCarrerasMaterias.findIndex(item=>item.idMateriaNavigation.idMateria==this.selectedMateria.idMateria);
                    this.selectedCarrera.nivelesEstudiantilesCarrerasMaterias.splice(index,1);

                    this.$store.commit("showMessage",{text:"Materia eliminada correctamente.",color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        onsaveCarrera(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.carrerasData.push(response[1]);
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        ondeleteCarrera(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.carrerasData.splice(this.carrerasData.indexOf(this.selectedCarrera),1);

                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        onupdateCarrera(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        loadCarreras(){return this.$axios.get(`api/carreras/nivel/${this.$store.state.currentNivelEstudiantil}`);},
        async loadAll(){
            this.allLoaded=false;

            await this.$axios.all([this.loadCarreras()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.carrerasData=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.allLoaded=true;
        }
    },
    created(){
      this.loadAll();
      
        this.unsubscribe=this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'setCurrentNivel') {
                state;

                this.loadAll();
            }
        });
    },
    beforeDestroy(){
        this.unsubscribe();
    }
}
</script>
<style scoped>

</style>