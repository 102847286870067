<template>
    <v-card outlined>
        <v-card-title>
            <v-btn icon class="delete_button" @click="$emit('ondelete');"><v-icon>clear</v-icon></v-btn>
            {{grado.nombre}}
        </v-card-title>
        <v-card-subtitle>
            {{getNivelCarrera}}
        </v-card-subtitle>
        <v-card-text>
        <v-row class="ma-0">
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="$emit('onedit')" v-if="$store.state.cicloEscolar.cicloEscolar1>=new Date().getFullYear()"><v-icon>edit</v-icon></v-btn>
                </template>
                <span>Editar</span>
            </v-tooltip>
            <div class="pr-5"/>
            <v-menu :close-on-content-click="false" offset-y v-model="isOpen">
                <template v-slot:activator="{on: menu}">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on: tooltip}">
                            <v-btn icon v-on="{...menu,...tooltip}" v-if="$store.state.cicloEscolar.cicloEscolar1>=new Date().getFullYear()"><v-icon>person_add</v-icon></v-btn>
                        </template>
                        <span>Inscribir alumnos</span>
                    </v-tooltip>
                </template>
                <v-list class="pa-0" :disabled="isLoading">
                    <v-progress-linear indeterminate :color="$store.state.accentColor" v-if="isLoading"></v-progress-linear>
                    <v-list-item>
                        <v-list-item-content>
                            <v-btn :color="$store.state.accentColor" elevation="0" small rounded @click="$emit('onnewstudent')">Agregar nuevo alumno</v-btn>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="IsToCicloAnterior">
                        <v-btn :color="$store.state.accentColor" elevation="0" small  block rounded @click="$emit('onaddcicloanterior')">Inscribir por grupos</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-autocomplete :color="$store.state.accentColor" filled rounded label="Buscar alumnos" :items="studentsData" v-model="searchResult" :filter="searchStudent" 
                                :search-input="searchInput" item-text="nombres" @update:search-input="clearInput" @change="onStudentSelected" return-object>
                                    <template slot="item" slot-scope="{item}">
                                        {{item.nombre1}} {{item.nombre2}} {{item.nombre3}} {{item.apellido1}} {{item.apellido2}} *** {{item.codigo}}
                                    </template>
                                </v-autocomplete>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <div class="pr-5"/>
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="$emit('ondetails')"><v-icon>assignment</v-icon></v-btn>
                </template>
                <span>Detalles de grado</span>
            </v-tooltip>
        </v-row>
        </v-card-text>
    </v-card>    
</template>
<script>
export default {
    props:{
        grado:Object
    },
    watch:{
        isOpen: function(val){
            if(val){
                this.loadStudents();
            }
        }
    },
    computed:{
        getNivelCarrera:function(){
            //if(this.grado)
            var nivelCarreraString=`${this.grado.idNivelEstudiantilNavigation.nombre}`;

            if(this.grado.idCarreraNavigation){
                nivelCarreraString+=` - ${this.grado.idCarreraNavigation.nombre}`;
            }

            return nivelCarreraString;
        },
        IsToCicloAnterior(){
            var isToCicloAnterior=false;
            var ciclosAnteriores=[
                "5197428f-74a6-4ff2-9425-11d93cb47e63",
                "56f48a8c-70d8-42bc-b1b8-632ac33a52f8",
                "7d912dd2-b152-4faf-82bc-e2ab275189bd",
                "d9a6c690-9e2a-4aea-a522-523de771695c",
                "40518aa8-862e-4ca4-9f7c-d2e458aae7cd",
                "bc19e42e-154a-4574-9d68-8a5a80714ab8",
                "4b86a20f-0412-4d90-aeff-535007ca6fed",
                "c4da1ad7-2f9d-414a-9b62-a841d6bbb389",
                "bd3ddb81-cd41-4929-9489-39db41192d99",
                "e3929c51-d66e-4357-a460-2897cfb39c45",
                "d43b2fb5-a8ce-4132-996f-2ccb7f4eaba7",
                "7efafea4-979a-4a7c-b345-34bbefdd9b1d",
                "935431e0-ee50-4d73-ae05-5064bc8ccdf4"
            ];


            if(ciclosAnteriores.some(item=>item==this.grado.idGrado))
                isToCicloAnterior=true;
            return isToCicloAnterior;
        }
    },
    data:()=>({
        studentsData:[],
        isOpen:false,
        isLoading:false,
        searchInput:"",
        searchResult:""
    }),
    methods:{
        clearInput(value){
            value;
            this.searchInput="";
            this.searchResult=null;
        },
        onStudentSelected(value){
            this.$emit("onstudentselected",value);
        },
        async loadStudents(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.get("api/estudiantes?type=partial"
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.studentsData=response.data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        searchStudent(item,queryText,itemText){

            let nombre1="";
            if(item.nombre1)
                nombre1=item.nombre1.toLowerCase();

            let nombre2="";
            if(item.nombre2)
                nombre2=item.nombre2.toLowerCase();

            let nombre3="";
            if(item.nombre3)
                nombre3=item.nombre3.toLowerCase();

            let apellido1="";
            if(item.apellido1)
                apellido1=item.apellido1.toLowerCase();
            

            let apellido2="";
            if(item.apellido2)
                apellido2=item.apellido2.toLowerCase();

            
            let codigo="";
            if(item.codigo)
                codigo=item.codigo;

            let textToSearch=queryText.toLowerCase();

            itemText;

            return nombre1.indexOf(textToSearch)>-1||nombre2.indexOf(textToSearch)>-1||nombre3.indexOf(textToSearch)>-1
            ||apellido1.indexOf(textToSearch)>-1||apellido2.indexOf(textToSearch)>-1||codigo.indexOf(textToSearch)>-1;
        }
    },
    created(){
        
    }
}
</script>
<style scoped>
.delete_button{
    position:absolute;right:0;top:0;
}
</style>