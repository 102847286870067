<template>
    <v-card outlined class="rounded-xl">
        <v-list-item class="card-text">
            <v-list-item-icon>
                <v-icon size="30" :color="color?color:$store.state.accentColor2">person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="text-subtitle-2">
                    {{getCatedraticoNombre}}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption">
                    {{getReadableDate(actividad.actividad.fechaCreada)}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!from">
                <v-menu offset-y>
                    <template v-slot:activator="{on:menu}">
                        <v-btn v-on="menu" icon><v-icon>more_vert</v-icon></v-btn>
                    </template>
                    <v-list class="pa-0" dense>
                        <v-list-item v-for="(option,index) in options" :key="index" @click="onselectOption(option)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{option}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item-action>
        </v-list-item>
        <v-card-text :class="$vuetify.theme.dark?'py-0 white--text':'py-0 black--text'" v-html="linkfy(actividad.contenido,{
             target: {
                url: '_blank'
             }
        })">
        </v-card-text>
        <v-card-text class="card-text">
            <v-row class="pa-0 ma-0">
                <v-col class="pb-0" cols="12" xs="12" sm="6"  v-for="(video,index) in actividad.videos" :key="index">
                    <video-card :videoInfo="video" :action="'get'" @onclickvideo="()=>{$emit('onclickvideo',video)}"></video-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <v-row class="py-0 card-text">
                <v-col cols="12" xs="12" sm="6" md="6" v-for="(file,index) in actividad.archivos" :key="index">
                    <file-card :file="file" :publico="true"></file-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>    
</template>
<script>
import VideoCard from '../../common/VideoCard.vue';
import {Months} from '../../../../../utils/timeUtils';
import linkfy from 'linkifyjs/lib/linkify-string';
import FileCard from '../FileCard.vue';
export default {
  components: { VideoCard,FileCard},
    props:[
        "actividad",
        "from",
        "color"
    ],
    computed:{
        getCatedraticoNombre(){
            if(this.actividad)
                if(this.actividad.catedratico)
                    return this.actividad.catedratico.nombres;
            return "Catedrático";
        }
    },
    data:function(){
        return ({
            options:[
                "Eliminar",
                "Editar"
            ]
        })
    },
    methods:{
        linkfy,
        getFileName(name){
            if(name.length>30){
                var extensionSplitted=name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${name.substring(0,30)}....${extension}`;
            }
            return name;
        },
        onselectOption(option){
            if(option.includes("Eliminar")){
                this.$emit("ondelete");
            }else{
                this.$emit("onupdate");
            }
        },
        getReadableDate(date){
            var datedate=new Date(date);

            var splittedTime=date.split("T")[1];
            var hourminute=splittedTime.split(".")[0];
            var time=hourminute.substring(0,(hourminute.length-1)-2);
            var month=Months[datedate.getMonth()].substring(0,3);

            return `${datedate.getDate()} ${month} ${datedate.getFullYear()} ${time}`;
        }
    }
}
</script>
<style scoped>

</style>