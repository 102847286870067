<template>
    <v-app id="preprimaria_materias">
        <materias :title="title" />
    </v-app>    
</template>
<script>
import Materias from '../Materias.vue';
export default {
  components: { Materias },
    props:[
        "title"
    ],
    watch:{

    },
    data:function(){
        return ({

        });
    },
    methods:{
        
    },
    created(){
        
    },
    beforeDestroy(){
        
    }
}
</script>
<style scoped>

</style>