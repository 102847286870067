<template>
    <div>
        <v-row class="ma-0 text-subtitle-2">NNG = Nota no generada</v-row>
        <v-row>
            <v-col cols="12" v-for="(cuadro,index) in cuadrosnotasData" :key="index">
                <v-card class="rounded-xl" flat outlined>
                    <v-img :class="colorGrade(cuadro)+' pa-2'" height="50" dark>
                        <v-row no-gutters class="text-h6" justify="center">
                            {{cuadro.materia.nombre}}
                        </v-row>
                    </v-img>
                    <v-divider></v-divider>
                    <v-card-text class="pa-0" v-if="verifyBloques(cuadro)">
                        <v-row no-gutters>
                            <v-col cols="12" sm="6" md="3" v-for="(bloque,index) in cuadro.bloques" :key="index">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-row no-gutters :class="colorGradeBloque(cuadro,bloque)+' white--text text-subtitle-1 font-weight-medium'" justify="center">
                                            Bloque {{bloque.bloque}}
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row no-gutters :class="darklightText+' font-weight-medium'">
                                            <v-col align="center">Zona</v-col>
                                            <v-divider vertical></v-divider>
                                            <v-col  align="center">Examen</v-col>
                                            <v-divider vertical></v-divider>
                                            <v-col  align="center">Total</v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row no-gutters>
                                            <v-col align="center">{{getZona(cuadro,bloque)}}</v-col>
                                            <v-divider vertical></v-divider>
                                            <v-col  align="center">{{getExamen(cuadro,bloque)}}</v-col>
                                            <v-divider vertical></v-divider>
                                            <v-col  align="center">{{getTotal(cuadro,bloque)}}</v-col>
                                        </v-row>
                                    </v-col>
                                    <v-divider v-if="index<(cuadro.bloques.length-1)" vertical></v-divider>
                                </v-row>
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row v-if="hasPromedio(cuadro)" no-gutters justify="center" class="black--text font-weight-bold"><div>Tu promedio es: {{getPromedioAmount(cuadro)}}</div></v-row>
                    </v-card-text>
                    <v-card-text v-if="!verifyBloques(cuadro)">
                        <v-row no-gutters justify="center" class="text-subtitle-1 font-weight-bold">
                            La clase no ha sido asignada a un profesor o el profesor no la ha iniciado
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>    
        </v-row>
    </div>    
</template>
<script>
export default {
    props:[
        "cuadrosnotasData",
        "colorTheme"
    ],
    computed:{
        darklightText(){
            if(this.$vuetify.theme.dark)
                return "white--text";
            else
                return "black--text";
        },
    },
    methods:{
        colorGrade(cuadro){
            if(this.colorTheme)
                if(this.colorTheme==1)
                    return this.$store.state.accentColor;
                else{
                    return this.$store.state.accentColor2;
                }
            
            if(cuadro.bloques){
                var noterminadoValidation=cuadro.bloques.some(item=>!item.fechaTerminado);
    
                if(!noterminadoValidation){
                    var totalPromedio=this.getPromedioAmount(cuadro);
    
                    if(/[\d]+/.test(totalPromedio))
                        if(totalPromedio>=0&&totalPromedio<60)
                            return "red lighten-1";
                }
            }
            return this.$store.state.accentColor2;
        },
        colorGradeBloque(cuadro,bloque){
            if(this.colorTheme)
                if(this.colorTheme==1)
                    return this.$store.state.accentColor;
                else{
                    return this.$store.state.accentColor2;
                }
            
            if(bloque.fechaTerminado){
                var totalBloque=this.getTotal(cuadro,bloque.bloque-1);

                if(/[\d]+/.test(totalBloque)){
                    if(totalBloque>=0&&totalBloque<60)
                        return "red lighten-1";
                }
            }
            return this.$store.state.accentColor2;
        },
        verifyBloques(cuadro){
            if(cuadro){
                if(cuadro.bloques){
                    if(cuadro.bloques.length>0){
                        return true;
                    }
                }
            }
            return false;
        },
        hasPromedio(cuadro){
            if(cuadro.notas){
                if(cuadro.notas){
                    var keys=Object.keys(cuadro.notas);
                    keys=keys.filter(item=>item.includes("zona")||item.includes("examen"));

                    var verificador=false;
                    keys.forEach(item=>{
                        if(cuadro.notas[item]>0){
                            verificador=true;
                            return;
                        }
                    });

                    if(verificador)
                        return true;
                }
            }
            return false;
        },
        getPromedioAmount(cuadro){
            if(cuadro.notas){
                var keys=Object.keys(cuadro.notas);
                keys=keys.filter(item=>item.includes("zona")||item.includes("examen"));

                var zonaExamenPair=[];

                keys.forEach(item=>{
                    item;
                    var newPair=keys.splice(0,2);
                    zonaExamenPair.push(newPair);
                });
                
                var countbloquesPunteos=cuadro.bloques.filter(item=>item.fechaIniciado).length;

                var bloquesPunteos=0;
                zonaExamenPair.forEach(element => {
                    if(cuadro.notas[element[0]]){
                        if(cuadro.notas[element[1]]){
                            bloquesPunteos+=cuadro.notas[element[0]];
                            bloquesPunteos+=cuadro.notas[element[1]];
                        }else
                            bloquesPunteos+=cuadro.notas[element[0]];
                    }else if(cuadro.notas[element[1]])
                        bloquesPunteos+=cuadro.notas[element[1]];
                });


                var promedio=(bloquesPunteos/countbloquesPunteos);
                var decimalPromedio=promedio-Math.floor(promedio);

                if(decimalPromedio>0)
                    if(decimalPromedio>=0.5)
                        promedio=Math.ceil(promedio);
                    else
                        promedio=Math.floor(promedio);

                
                if(isNaN(promedio)){
                    cuadro.notas.promedio=false;
                    promedio=0;
                }
                else
                    cuadro.notas.promedio=true;

                return promedio;
            }
        },
        getTotal(cuadro,bloque){
            if(cuadro.notas){
                switch(bloque.bloque){
                    case 1:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona1!=null&&cuadro.notas.examen1!=null)
                                return cuadro.notas.zona1+cuadro.notas.examen1;
                            else if(cuadro.notas.zona1!=null)
                                return cuadro.notas.zona1;
                            else if(cuadro.notas.examen1!=null)
                                return cuadro.notas.examen1;
                            else
                                return 0;
                        return "NNG";
                    case 2:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona2!=null&&cuadro.notas.examen2!=null)
                                return cuadro.notas.zona2+cuadro.notas.examen2;
                            else if(cuadro.notas.zona2!=null)
                                return cuadro.notas.zona2;
                            else if(cuadro.notas.examen2!=null)
                                return cuadro.notas.examen2;
                            else
                                return 0;
                        return "NNG";
                    case 3:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona3!=null&&cuadro.notas.examen3!=null)
                                return cuadro.notas.zona3+cuadro.notas.examen3;
                            else if(cuadro.notas.zona3!=null)
                                return cuadro.notas.zona3;
                            else if(cuadro.notas.examen3!=null)
                                return cuadro.notas.examen3;
                            else
                                return 0;
                        return "NNG";
                    case 4:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona4!=null&&cuadro.notas.examen4!=null)
                                return cuadro.notas.zona4+cuadro.notas.examen4;
                            else if(cuadro.notas.zona4!=null)
                                return cuadro.notas.zona4;
                            else if(cuadro.notas.examen4!=null)
                                return cuadro.notas.examen4;
                            else
                                return 0;
                        return "NNG";                        
                    case 5:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona5!=null&&cuadro.notas.examen5!=null)
                                return cuadro.notas.zona5+cuadro.notas.examen5;
                            else if(cuadro.notas.zona5!=null)
                                return cuadro.notas.zona5;
                            else if(cuadro.notas.examen5!=null)
                                return cuadro.notas.examen5;
                            else
                                return 0;
                        return "NNG";  
                    case 6:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona6!=null&&cuadro.notas.examen6!=null)
                                return cuadro.notas.zona6+cuadro.notas.examen6;
                            else if(cuadro.notas.zona6!=null)
                                return cuadro.notas.zona6;
                            else if(cuadro.notas.examen6!=null)
                                return cuadro.notas.examen6;
                            else
                                return 0;
                        return "NNG";  
                }
            }else
                return "";
        },
        getZona(cuadro,bloque){
            if(cuadro.notas){
                switch(bloque.bloque){
                    case 1:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona1!=null)
                                return cuadro.notas.zona1;
                            else
                                return 0;
                        return "NNG"
                    case 2:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona2!=null)
                                return cuadro.notas.zona2
                            else
                                return 0;
                        return "NNG"
                    case 3:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona3!=null)
                                return cuadro.notas.zona3
                            else
                                return 0;
                        return "NNG"
                    case 4:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona4!=null)
                                return cuadro.notas.zona4
                            else
                                return 0;
                        return "NNG"
                    case 5:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona5!=null)
                                return cuadro.notas.zona5
                            else
                                return 0;
                        return "NNG"
                    case 6:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.zona6!=null)
                                return cuadro.notas.zona6
                            else
                                return 0;
                        return "NNG"
                }
            }else
                return "NNG";
        },
        getExamen(cuadro,bloque){
            if(cuadro.notas){
                switch(bloque.bloque){
                    case 1:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.examen1!=null)
                                return cuadro.notas.examen1
                            else
                                return 0;
                        return "NNG"
                    case 2:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.examen2!=null)
                                return cuadro.notas.examen2
                            else
                                return 0;
                        return "NNG"
                    case 3:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.examen3!=null)
                                return cuadro.notas.examen3
                            else
                                return 0;
                        return "NNG"
                    case 4:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.examen4!=null)
                                return cuadro.notas.examen4
                            else
                                return 0;
                        return "NNG"
                    case 5:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.examen5!=null)
                                return cuadro.notas.examen5
                            else
                                return 0;
                        return "NNG"
                    case 6:
                        if(bloque.fechaIniciado)
                            if(cuadro.notas.examen6!=null)
                                return cuadro.notas.examen6
                            else
                                return 0;
                        return "NNG"
                }
            }else
                return "NNG";
        },
    }
}
</script>
<style scoped>

</style>