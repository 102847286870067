var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-overlay',{attrs:{"value":!_vm.allLoaded,"opacity":"0"}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":_vm.$store.state.accentColor,"indeterminate":""}})],1),_c('tipos-pago-dialog',{attrs:{"tipospagoDialog":_vm.tipospagoDialog,"action":_vm.action,"tipoPagoToEdit":_vm.selectedTipoPago},on:{"onclose":function($event){_vm.tipospagoDialog=false;},"onsave":_vm.onsaveTipoPago,"onupdate":_vm.onupdateTipoPago}}),_c('delete-dialog',{attrs:{"show":_vm.deleteTipoPagoDialog,"context":'tipo de pago',"content":`¿Quieres eliminar el tipo de pago '${_vm.selectedTipoPago.descripcion}'?`,"idEntity":_vm.selectedTipoPago.idPago},on:{"ondelete":_vm.ondeleteTipoPago,"onclose":function($event){_vm.deleteTipoPagoDialog=false;}}}),(_vm.allLoaded)?_c('div',[_c('v-row',{staticClass:"pa-0 ma-0"},_vm._l((_vm.tiposPagosData),function(tipoPago){return _c('v-col',{key:tipoPago.idPago,attrs:{"cols":"12","xs":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('tipo-pago-card',{attrs:{"tipoPago":tipoPago},on:{"ondelete":()=>{
                    _vm.deleteTipoPagoDialog=true;
                    _vm.selectedTipoPago=tipoPago;
                    },"onupdate":()=>{
                        _vm.tipospagoDialog=true;
                        _vm.action='edit';
                        _vm.selectedTipoPago=tipoPago;
                    }}})],1)}),1),_c('add-button',{attrs:{"tooltipText":'Agregar tipo de pago'},on:{"onadd":()=>{
            _vm.tipospagoDialog=true;
            _vm.action='add';
            }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }