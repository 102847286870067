import { render, staticRenderFns } from "./DiversificadoMaterias.vue?vue&type=template&id=0bf82a22&scoped=true"
import script from "./DiversificadoMaterias.vue?vue&type=script&lang=js"
export * from "./DiversificadoMaterias.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf82a22",
  null
  
)

export default component.exports