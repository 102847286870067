<template>
    <v-app id="estudiantes_colaborador">
        <app-bar :allLoaded="allLoaded" :name="title"></app-bar>
        <estudiante-dialog :estudianteDialog="studentDialog" @onclose="studentDialog=false;" @onsave="onsaveEstudiante" :action="action" :estudianteToEdit="selectedStudent.idEstudiante"
            @onupdate="onupdateEstudiante"
            ></estudiante-dialog>

        <delete-dialog :context="'estudiante'" :show="deleteStudentDialog" 
        :content="`¿Quieres eliminar el estudiante '${selectedStudent.nombre1} ${selectedStudent.nombre2} ${selectedStudent.nombre3} ${selectedStudent.apellido1} ${selectedStudent.apellido2}'?`" 
        :idEntity="selectedStudent.idEstudiante" @onclose="deleteStudentDialog=false;" @ondelete="ondeleteEstudiante"
        ></delete-dialog>

        <estudiante-detalle :estudiantedetalleDialog="estudiantedetalleDialog" :estudiante="selectedStudent.idEstudiante"
        @onclose="()=>{estudiantedetalleDialog=false;}"></estudiante-detalle>

        <div v-if="allLoaded">
            <v-row class="pa-0 ma-0" justify="center">
                <v-card outlined>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
                            <v-text-field clearable filled rounded :color="$store.state.accentColor" label="Buscar estudiante..." v-model="studentsTableProps.search" autocomplete="off"></v-text-field>

                        </v-col>
                    </v-row>
                    <v-data-table :items="studentsData"
                    height="600"
                    :search="studentsTableProps.search"
                    :headers="headers" 
                    hide-default-footer
                    @page-count="studentsTableProps.pageCount=$event" 
                    :page.sync="studentsTableProps.page"
                    :items-per-page="studentsTableProps.itemsPage"
                    >
                        <template v-slot:item.fechaIngreso="{item}">
                            {{item.fechaIngreso.split("T")[0]}}
                        </template>
                        <template v-slot:item.becado="{item}">
                            {{item.becado?"Sí":"No"}}
                        </template>
                        <template v-slot:item.actions="{item}">
                            <v-menu>
                                <template v-slot:activator="{on:menu}">
                                    <v-btn icon v-on="{...menu}">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense class="pa-0">
                                    <v-list-item v-for="option in options" :key="option" @click="onselectOption(item,option)">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{option}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                    <v-pagination total-visible="7" circle v-model="studentsTableProps.page" :length="studentsTableProps.pageCount" :color="$store.state.accentColor"></v-pagination>
                </v-card>
            </v-row>
            <add-button tooltipText="Agregar alumno" @onadd="()=>{
            action='add';
            selectedStudent=Object;
            studentDialog=true;}"></add-button>
        </div>
    </v-app>
</template>
<script>
import AppBar from '../../globalComponents/Appbar'
import AddButton from '../../globalComponents/AddButton'
import EstudianteDialog from './EstudianteDialog.vue';
import DeleteDialog from '../../globalComponents/DeleteDialog.vue';
import EstudianteDetalle from './EstudianteDetalle.vue';

export default {
    props:{
        title:String
    },
    components:{
        AppBar,
        AddButton,
        EstudianteDialog,
        DeleteDialog,
        EstudianteDetalle
    },
    data:()=>({
        allLoaded:false,
        studentsTableProps:{
            pageCount:0,
            page:1,
            itemsPage:10,
            search:""
        },
        studentsData:[],
        studentDialog:false,
        deleteStudentDialog:false,
        estudiantedetalleDialog:false,
        action:"",
        selectedStudent:Object,
        headers:[
            {text:"Código",value:"codigo"},
            {text:"Usuario",value:"usuario"},
            {text:"Nombre 1",value:"nombre1"},
            {text:"Nombre 2",value:"nombre2"},
            {text:"Apellido 1",value:"apellido1"},
            {text:"Apellido 2",value:"apellido2"},
            {text:"CUI",value:"cui"},
            {text:"Becado",value:"becado"},
            {text:"Acción",value:"actions"},
        ],
        options:[
            "Editar",
            "Ver",
            "Eliminar"
        ],
        unsubscribe:""
    }),
    methods:{
        onselectOption(item,option){
            switch(option){
                case "Editar":
                    this.action='edit';
                    this.selectedStudent=item;
                    this.studentDialog=true;
                    break;
                case "Ver":
                    this.selectedStudent=item;
                    this.estudiantedetalleDialog=true;
                    break;
                case "Eliminar":
                    this.selectedStudent=item;
                    this.deleteStudentDialog=true;
                    break;
            }
        },
        loadStudents(){
            return this.$axios.get("api/estudiantes?type=partial");
        },
        async loadAll(){
            this.allLoaded=false;

            await this.$axios.all([this.loadStudents()]).then((responses)=>{
                if(responses[0].status==200){
                    if(responses[0].data.code==1){
                        this.studentsData=responses[0].data.data;
                    }
                }
            }).catch((errors)=>{
                errors;
            });

            this.allLoaded=true;
        },
        onupdateEstudiante(response){
            switch(response[0]){
            case 0:
                this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                break;
            case 1:
                this.$updateChanges(this.selectedStudent,response[1]);
                this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                break;
            case 2:
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                break;
          }
        },
        ondeleteEstudiante(response){
            switch(response[0]){
              case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                break;
            case 1:
                this.studentsData.splice(this.studentsData.indexOf(this.selectedStudent),1);

                this.$store.commit("showMessage",{text:"Estudiante eliminado correctamente.",color:"green",time:4000});
                break;
            case 2:
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                break;
          }
        },
        onsaveEstudiante(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.studentsData.push(response[1]);
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        }
    },
    created(){
        this.loadAll();
    },
    beforeDestroy(){
    }
}
</script>
<style scoped>

</style>