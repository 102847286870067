<template>
    <v-card width="300" class="pa-6" elevation="3">
        <video-attach-dialog :from="'estudiantes'" :videoDialog="videoDialog" @onclose="videoDialog=false;" @onaddVideo="onaddVideo"></video-attach-dialog>
        <file-attach-dialog :fileDialog="fileDialog" @onclose="fileDialog=false;" @onaddFile="onaddFile"></file-attach-dialog>
        <texto-dialogo :actiontext="actiontext" :texttoEdit="mitrabajo.contenido" :contenidoDialog="contenidoDialog" @onclose="contenidoDialog=false;" @onagregar="onagregarcontenido"></texto-dialogo>

        <v-dialog persistent width="350" v-model="completarDialog">
            <v-card  :loading="isLoading" :disabled="isLoading">
                <v-card-title>
                    Completar tarea
                </v-card-title>
                <v-card-text>
                    ¿Desea marcar la tarea como completada sin entregar nada?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="completarDialog=false">Cancelar</v-btn>
                    <v-btn text @click="oncompleteCall" :color="$store.state.accentColor2">Completar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent width="350" v-model="entregarDialog">
            <v-card :loading="isLoading" :disabled="isLoading">
                <v-card-title>
                    Entregar tarea
                </v-card-title>
                <v-card-text>
                    ¿Desea entregar su trabajo y terminar la tarea?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="entregarDialog=false">Cancelar</v-btn>
                    <v-btn text @click="onentregarTareaCall" :color="$store.state.accentColor2">Entregar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent max-width="400" v-model="anularentregaDialog">
            <v-card :loading="isLoading" :disabled="isLoading">
                <v-card-title>
                    Confirmación
                </v-card-title>
                <v-card-text>
                    ¿Deseas anular la entrega de tu tarea?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="anularentregaDialog=false">Cancelar</v-btn>
                    <v-btn text @click="onanularEntregaCall" :color="$store.state.accentColor2">Anular</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row no-gutters align="center" class="mb-1">
            <v-card-title class="pa-0 text-h6 font-weight-regular">
                Tu trabajo
            </v-card-title>
            <v-spacer></v-spacer>
            <div :class="statusEntregaClass">{{getStatusEntrega}}</div>
        </v-row>
        <div v-if="isEntregable">
            <v-row no-gutters class="mb-5">
                <v-menu offset-y>
                    <template v-slot:activator="{on}">
                        <v-btn v-on="on" class="normal-text" text outlined block :color="$store.state.accentColor2">
                            <v-icon>add</v-icon> Agregar
                        </v-btn>
                    </template>
                    <v-list class="pa-0" dense>
                        <v-list-item v-for="(item,index) in attachments" :key="index" @click="onselectOption(index)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-row>
            <v-row no-gutters v-if="!hasEntrega">
                <v-btn @click="oncompleteTarea" class="normal-text" dark block :color="$store.state.accentColor2">Marcar como completada</v-btn>
            </v-row>
            <v-row no-gutters v-if="hasEntrega" class="mb-5">
                <v-btn @click="onentregarTarea" class="normal-text" dark block :color="$store.state.accentColor2">Entregar</v-btn>
            </v-row>
            <v-row no-gutters v-if="hasEntrega">
                <v-btn @click="onborrarMitrabajo" small class="normal-text" dark block :color="$store.state.accentColor2" text>Borrar mi trabajo</v-btn>
            </v-row>
        </div>
        <div v-if="isAnulable">
            <v-row no-gutters class="mb-5">
                <v-btn @click="onanularEntrega" class="normal-text" outlined dark block :color="$store.state.accentColor2">Anular entrega</v-btn>
            </v-row>
        </div>
        <v-container v-if="isDescriptible" fill-height style="height:100px;" class="pa-0 ma-0">
            <v-row no-gutters align-content="center">
                <v-col align="center">
                    <div :class="descriptiveStatusClass">{{getDescriptiveStatus}}</div>
                </v-col>
            </v-row>
        </v-container>
        <v-divider v-if="isDescriptible"></v-divider>
        <div  v-if="mitrabajo.contenido">
            <br>
            <p class="text-body-2 text-justify ma-0" 
            v-html="linkfy(mitrabajo.contenido,{
                    target: {
                        url: '_blank'
                        }
                    }
                )">{{mitrabajo.contenido}}</p>
            <div v-if="isEntregable">
                <v-btn x-small rounded dark outlined :color="$store.state.accentColor2" @click="oneditText" class="mr-2">Editar</v-btn>
                <v-btn x-small rounded dark outlined :color="$store.state.accentColor2" @click="ondeleteText">Borrar</v-btn>
            </div>
        </div>
        <div v-if="mitrabajo.videos.length>0||mitrabajo.archivos.length>0||archivos.length>0">
            <br>
            <v-row no-gutters class="pb-5">
                <v-col v-for="(video,index) in mitrabajo.videos" :key="index" cols="12">
                    <video-card :videoInfo="video" :action="getactionVideo" @ondelete="ondeleteVideo" @onclickvideo="$emit('onclickvideo',video)"></video-card>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="isEntregable">
                <v-col v-for="(file,index) in archivos" :key="index" cols="12">
                    <file-card :file="file"  @ondelete="ondeleteFile"></file-card>
                </v-col>
                <v-col v-for="(file,index) in mitrabajo.archivos" :key="index" cols="12">
                    <file-card :file="file" @ondelete="ondeletesavedFile"></file-card>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="!isEntregable">
                <v-col cols="12" v-for="(file,index) in mitrabajo.archivos" :key="index">
                    <file-card :file="file" :publico="true"></file-card>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>
<script>
import TextoDialogo from './TextoDialogo.vue';
import linkfy from 'linkifyjs/lib/linkify-string';
import VideoAttachDialog from '../../../../colaborador/escritorio/common/VideoAttachDialog.vue';
import VideoCard from '../../../../colaborador/escritorio/common/VideoCard.vue';
import FileAttachDialog from '../../../../colaborador/escritorio/common/FileAttachDialog.vue';
import FileCard from '../../../../colaborador/escritorio/common/FileCard.vue';

export default {
  components: { TextoDialogo,VideoAttachDialog,VideoCard,FileAttachDialog,FileCard },
    data:function(){
        return ({
            currentdate:"",
            lastdate:"",
            attachments:[
                "Archivos",
                "Video YouTube",
                "Texto"
            ],
            completarDialog:false,
            entregarDialog:false,
            contenidoDialog:false,
            anularentregaDialog:false,
            videoDialog:false,
            fileDialog:false,
            isLoading:false,
            mitrabajo:{
                videos:[],
                archivos:[],
                contenido:"",
            },
            archivos:[],
            actiontext:"add"
        })
    },
    watch:{
        mientrega:function(newval,oldval){
            oldval;
            newval;

            /*if(newval){
                this.mitrabajo.contenido=newval.contenido;
            }*/
        }
    },
    props:[
        "entregaTarea",
        "tarea",
        "mientrega"
    ],
    computed:{
        getactionVideo(){
            if(this.entregaTarea.participante)
                if(this.entregaTarea.participante.idEstadoActividad==1)
                    return "add";
            return "get";
        },
        hasEntrega(){
            if(this.mitrabajo.contenido||this.mitrabajo.videos.length>0||this.mitrabajo.archivos.length>0||this.archivos.length>0)
                return true;
            return false;
        },
        isAnulable(){
            if(this.entregaTarea.participante.idEstadoActividad==2)
                if(this.lastdate)
                    if(this.currentdate<=this.lastdate)
                        return true;
            return false;
        },
        isDescriptible(){
            if(this.entregaTarea.participante){
                if(this.entregaTarea.participante.idEstadoActividad!=1)
                    return true;
                else if(this.currentdate>this.lastdate)
                    return true;
            }
            return false;
        },
        isEntregable(){
            if(this.entregaTarea.participante){
                if(this.entregaTarea.participante.idEstadoActividad==1){
                    if(this.currentdate<=this.lastdate)
                        return true;
                }
            }

            return false;
        },
        statusEntregaClass(){
            var baseClass=`text-subtitle-2 {color} font-weight-medium`

            if(this.entregaTarea.participante){
                switch(this.entregaTarea.participante.idEstadoActividad){
                    case 1:return baseClass.replace("{color}","red--text");
                    case 2:return baseClass.replace("{color}","");
                    case 3:return baseClass.replace("{color}","green--text");
                    case 4:return baseClass.replace("{color}","red--text");
                    case 5:return baseClass.replace("{color}","red--text");
                }
            }

            return baseClass.replace("{color}","red--text");
        },
        getStatusEntrega(){
            if(this.entregaTarea.participante){
                switch(this.entregaTarea.participante.idEstadoActividad){
                    case 1: 
                    return "Sin entregar";
                    case 2: return "Entregado";
                    case 3: return "Calificado";
                    case 4: return "Anulado";
                    case 5: return "Rechazado";
                }
            }

            return "Sin estado";
        },
        getDescriptiveStatus(){
            if(this.entregaTarea.participante){
                switch(this.entregaTarea.participante.idEstadoActividad){
                    case 1:return "Tarea no entregada";
                    case 2:return "Calificarán pronto tu trabajo";
                    case 3:return `${this.entregaTarea.participante.actividadesMateriasBloquesEntregas.calificacion}/${this.tarea.actividad.valorNeto}`;
                    case 4:return "Tarea anulada";
                    case 5:return "Tarea rechazada";
                }
            }

            return "";
        },
        descriptiveStatusClass(){
            var baseClass=`text-{size} font-weight-bold`

            if(this.entregaTarea.participante){
                switch(this.entregaTarea.participante.idEstadoActividad){
                    case 1:return baseClass.replace("{size}","h6").concat(" red--text");
                    case 2:return baseClass.replace("{size}","h6");
                    case 3:
                        var punteo=this.entregaTarea.participante.actividadesMateriasBloquesEntregas.calificacion;
                        if(punteo>(this.tarea.actividad.valorNeto/2))
                            baseClass+=" green--text";
                        else if(punteo<(this.tarea.actividad.valorNeto/2))
                            baseClass+=" red--text";
                        return baseClass.replace("{size}","h4")
                    case 4:return baseClass.replace("{size}","h6").concat(" red--text");
                    case 5:return baseClass.replace("{size}","h6").concat(" red--text");
                }
            }

            return "";
        },
        isDisableEntrega(){
            if(this.entregaTarea.participante){
                if(this.entregaTarea.participante.idEstadoActividad!=1){
                    return true;
                }
            }
            return false;
        },
    },
    methods:{
        linkfy,
        onaddFile(files){
            var currentAmountFiles=this.mitrabajo.archivos.length+this.archivos.length;

            if(currentAmountFiles<=5){
                if((currentAmountFiles+files.length)<=5){
                    this.archivos.push(...files);
                    this.fileDialog=false;

                    return;
                }
            }
            this.fileDialog=false;
            alert("No se pueden agregar más de 5 archivos a la publicación.");
        },
        ondeletesavedFile(fileItem){
            var index=this.mitrabajo.archivos.indexOf(fileItem);
            this.mitrabajo.archivos.splice(index,1);
        },
        ondeleteFile(fileItem){
            var index=this.archivos.indexOf(fileItem);
            this.archivos.splice(index,1);
        },
        onaddVideo(videoInfo){
           if(!this.mitrabajo.videos.some(item=>item.id==videoInfo.id)){
                this.mitrabajo.videos.push(videoInfo);
                this.videoDialog=false;
                this.emittrabajoUpdate();
            }
        },
        ondeleteVideo(videoItem){
            var index=this.mitrabajo.videos.indexOf(videoItem);
            this.mitrabajo.videos.splice(index,1);
            this.emittrabajoUpdate();
        },
        ondeleteText(){
            this.mitrabajo.contenido="";
            this.emittrabajoUpdate();
        },
        oneditText(){
            this.actiontext="edit";
            this.contenidoDialog=true;
        },
        onborrarMitrabajo(){
            this.mitrabajo={
                videos:[],
                archivos:[],
                contenido:"",
            };

            this.videos=[];
            this.archivos=[];

            this.emittrabajoUpdate();
        },
        onagregarcontenido(contenido){
            this.mitrabajo.contenido=contenido;
            this.emittrabajoUpdate();
        },
        onentregarTarea(){
            this.entregarDialog=true;
        },
        async onentregarTareaCall(){
            var data=new FormData();

            this.archivos.forEach((item)=>{
                data.append("archivos",item);
            });

            data.append("entrega",JSON.stringify(this.mitrabajo));

            this.isLoading=this.$store.state.accentColor2;
            await this.$axios.put(`api/estudiantil/actividades/tareas/${this.$route.params.idtarea}/entregar`,
            data
            ).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 1:
                            this.$store.commit("showMessage",{text:"¡¡Felicidades!! Tu tarea ha sido entregada.",color:"green",time:4000});
                            break;
                        default:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            break;
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });

            this.$emit("onsuccessentrega");
            this.isLoading=false;
        },
        oncompleteTarea(){
            this.completarDialog=true;
        },
        onanularEntrega(){
            this.anularentregaDialog=true;
        },
        async onanularEntregaCall(){
            this.isLoading=this.$store.state.accentColor2;

            await this.$axios.put(`api/estudiantil/actividades/tareas/${this.entregaTarea.participante.idActividadMateriaBloqueParticipante}/anular`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 1:
                            this.$store.commit("showMessage",{text:"La entrega de tu tarea ha sido anulada.",color:"green",time:4000});
                            break;
                        default:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            break;
                    }
                }

            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });

            this.$emit("onsuccessentrega");
            this.anularentregaDialog=false;
            this.isLoading=false;
        },
        async oncompleteCall(){
            this.isLoading=this.$store.state.accentColor2;
            await this.$axios.put(`api/estudiantil/actividades/tareas/${this.$route.params.idtarea}/completar`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 1:
                            this.$store.commit("showMessage",{text:"¡¡Felicidades!! Tu tarea ha sido entregada.",color:"green",time:4000});
                            break;
                        default:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            break;
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
            });
            
            this.$emit("onsuccessentrega");
            this.isLoading=false;
        },
        onselectOption(option){
            switch(option){
                case 0:
                    this.fileDialog=true;
                    break;
                case 1:
                    this.videoDialog=true;
                    break;
                case 2:
                this.contenidoDialog=true;
                    break;
            }
        },
        emittrabajoUpdate(){
            this.$emit("onnewTrabajo",{
                contenido:this.mitrabajo.contenido,
                videos:this.mitrabajo.videos,
                archivos:this.mitrabajo.archivos,
                newArchivos:this.archivos
            });
        },
        async loadCurrentDate(){
            await this.$axios.get(`api/estudiantil/currentdate`).then((response)=>{
                if(response.status==200){
                    this.currentdate=new Date(response.data);
                    this.lastdate=new Date(this.tarea.actividad.fechaLimite);
                }
            }).catch((error)=>{
                error;
            });
        },
    },
    created(){
        this.loadCurrentDate();

        if(this.mientrega){
            this.mitrabajo.contenido=this.mientrega.contenido;
            this.mitrabajo.videos=this.mientrega.videos;
            this.mitrabajo.archivos=this.mientrega.archivos;
            this.archivos=this.mientrega.newArchivos;
        }
    }
}
</script>
<style scoped>

</style>