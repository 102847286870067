<template>
    <v-dialog v-model="pagonivelDialog" max-width="400" persistent>
        <v-card outlined :disabled="isLoading" :loading="isLoading">
            <v-card-title>
                {{title}}
            </v-card-title>
            <v-card-text>
                <v-form ref="pagoForm">
                    <v-row class="pa-0 ma-0 px-3">
                        <v-select
                        item-color=""
                        dense
                        filled
                        rounded
                        label="Tipos de pago"
                        :items="tiposPagoData"
                        item-value="idPago"
                        item-text="descripcion"
                        :color="$store.state.accentColor"
                        v-model="pago.idPago"
                        :rules="pago.idPagoRules"
                        >

                        </v-select>
                    </v-row>
                    <v-row class="pa-0 ma-0 px-3">
                        <v-text-field
                        v-model="pago.cantidad"
                        :rules="pago.cantidadRules"
                        filled
                        label="Cantidad (dinero)"
                        rounded
                        :color="$store.state.accentColor"
                        counter
                        maxlength="15"
                        autocomplete="off"
                        >

                        </v-text-field>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose')">Cancelar</v-btn>
                <v-btn text color="green" @click="save">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    props:[
        "pagonivelDialog",
        "nivelestudiantil",
        "action",
        "pagoToEdit"
    ],
    watch:{
        pagonivelDialog:async function(newval,oldval){
            oldval;

            if(newval){
                this.loadAll();

                delete this.pago.idPagoNivelEstudiantil;
                delete this.pago.fechaCreado;
                delete this.pago.codigoNivelEstudiantil;

                if(this.action=="edit"){
                    this.title=`Edición de pago de ${this.nivelestudiantil.nombre}`;

                    await this.getPago(this.pagoToEdit.idPagoNivelEstudiantil);
                }else{
                    this.title=`Nuevo pago de ${this.nivelestudiantil.nombre}`;
                }
            }else{
                this.$refs.pagoForm.reset();
            }
        }
    },
    data:function(){
        return({
            isLoading:false,
            title:"",
            tiposPagoData:[],
            pago:{
                cantidad:"",
                cantidadRules:[
                    v => !!v || 'Cantidad es requerido.',
                    v => ( !!v) || `Cantidad debe ser de tamaño ${v?v.length:null} o menos.`,
                    v=>!/^\s+$/.test(v)||'Cantidad no debe ser un espacio en blanco.',
                    v=>/^(\d)+(\.(\d){1,2})?$/.test(v)||'Cantidad debe ser numérico (xx.xx).'
                ],
                idPago:"",
                idPagoRules:[
                    v=>!!v||'Tipo de pago es obligatorio.'
                ],
                activo:true
            },
        });
    },
    methods:{
        async save(){
            if(this.action=="edit"){
                await this.saveCall();
            }else{
                await this.saveCall();
            }
        },
        async saveCall(){
            if(this.$refs.pagoForm.validate()){
                this.pago.codigoNivelEstudiantil=this.nivelestudiantil.codigoNivelEstudiantil;

                this.isLoading=this.$store.state.accentColor;

                var responseArray=[];
                await this.$axios.put(`api/nivelesestudiantiles/pagos/${this.nivelestudiantil.codigoNivelEstudiantil}`,
                this.pago
                ).then(async (response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            responseArray=[1,response.data.data,response.data.message];
                            await this.getOne();
                        }else{
                            responseArray=[0,response.data.message];
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){

                            responseArray=[2,error.response.data.message];
                        }else{
                            responseArray=[2];
                        }
                    }

                    this.isLoading=false;
                });

                this.onNotification(responseArray);
                this.$emit("onclose");
            }
        },
        async getOne(){
            this.isLoading=this.$store.state.accentColor;
            await this.$axios.get(`api/nivelesestudiantiles/${this.nivelestudiantil.codigoNivelEstudiantil}?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`).
            then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$updateChanges(this.nivelestudiantil,response.data.data);
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        async getPago(id){
            this.isLoading=this.$store.state.accentColor;
            await this.$axios.get(`api/nivelesestudiantiles/pagos/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.pago.idPagoNivelEstudiantil=response.data.data.idPagoNivelEstudiantil;
                        this.pago.idPago=response.data.data.idPago;
                        this.pago.cantidad=response.data.data.cantidad;
                        this.pago.fechaCreado=response.data.data.fechaCreado;
                        this.pago.codigoNivelEstudiantil=response.data.data.codigoNivelEstudiantil;
                        this.pago.activo=response.data.data.activo;
                    }
                }
            }).catch((error)=>{
                error;
            });
            this.isLoading=false;
        },
        onNotification(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        },
        loadPagos(){
            return this.$axios.get("api/tipospagos");
        },
        async loadAll(){
            this.isLoading=this.$store.state.accentColor;
            await this.$axios.all([this.loadPagos()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.tiposPagoData=response[0].data.data;
                    }
                }
            }).catch((errors)=>{
                errors;
            });

            this.isLoading=false;
        }
    }
}
</script>
<style scoped>

</style>