<template>
    <v-dialog fullscreen v-model="show" @click:outside="onclose" transition="scale-transition" hide-overlay>

        <estudiante-detalle :estudiante="studentID" :estudiantedetalleDialog="estudiantedetalleDialog"
        @onclose="()=>estudiantedetalleDialog=false"></estudiante-detalle>

        <estudiante-dialog :estudianteDialog="studentDialog" @onclose="studentDialog=false;" :action="action" :estudianteToEdit="selectedEnrollment.idEstudiante"
            @onupdate="onupdateEstudiante"
            ></estudiante-dialog>

        <pagos-estudiante :pagosEstudiante="pagosestudianteDialog" @onclose="pagosestudianteDialog=false;" 
        :estudianteGrado="selectedEnrollment.idEstudianteGrado" :estudiante="selectedEnrollment.idEstudiante"
        ></pagos-estudiante>

        <calificaciones-estudiante :calificacionesDialog="calificacionesDialog" @onclose="calificacionesDialog=false;"
        :estudianteGrado="selectedEnrollment.idEstudianteGrado" :estudiante="selectedEnrollment.idEstudiante"
        ></calificaciones-estudiante>
        <v-card>
            <v-app id="grado_detalles">
                <app-bar-2 :allLoaded="allLoaded" name="Grado"></app-bar-2>
                <div class="body" v-if="allLoaded==1">
                    <v-sheet class="py-6 py-sm-7 py-md-8 py-lg-9 py-xl-10" color="transparent">
                        <v-row class="pa-0 ma-0" justify="center">
                            <h1 class="text-h5 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h3 font-weight-medium">{{gradeData.nombre}} - {{$store.state.cicloEscolar.cicloEscolar1}}</h1>
                        </v-row>
                        <v-row class="pa-0 ma-0" justify="center">
                            <h1 class="text-subtitle-2 gray--text font-weight-light">{{gradeData.idNivelEstudiantilNavigation.nombre}}</h1>
                        </v-row>
                    </v-sheet>
                    <v-dialog v-model="unsubscribeDialog" persistent max-width="400">
                        <v-card :loading="isLoading" :disabled="isLoading">
                            <v-card-title>
                                Desasignar estudiante
                            </v-card-title>
                            <v-card-text>
                                {{unsubscribeText}}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="unsubscribeDialog=false;">Cancelar</v-btn>
                                <v-btn text :color="$store.state.accentColor" @click="unsubscribeanularCall(selectedEnrollment.idEstudianteGrado,'desasignar')">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="unsubscribeRangeDialog" persistent max-width="400">
                        <v-card :loading="isLoading" :disabled="isLoading">
                            <v-card-title>
                                Desasignar estudiantes
                            </v-card-title>
                            <v-card-text>
                                ¿Quieres des-asignar a los estudiantes seleccionados?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="unsubscribeRangeDialog=false;">Cancelar</v-btn>
                                <v-btn text :color="$store.state.accentColor" @click="unsubscribeRangeCall">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <br>
                    <div>
                        <v-row class="ma-0 px-3"><h1 class="text-h6 font-weight-medium">Materias</h1></v-row>
                        <br>
                        <v-row class="ma-0 px-3">
                            <h1 class="text-subtitle-1 font-weight-normal">
                                <v-avatar color="red" size="15"></v-avatar> = materia descontinuada del pénsum
                            </h1>
                        </v-row>
                        <div class="scrolling-wrapper">
                            <div class="card" v-for="(materia,index) in gradeData.gradosMaterias" :key="materia.idGradoMateria" :style="index==(gradeData.gradosMaterias.length-1)?'padding-right:15px;':''">
                                <v-card outlined width="200" class="flex-row">
                                    <v-card-title>
                                        <v-avatar v-if="!materia.idMateriaNavigation.activo" color="red" size="15" style="position:absolute;right:5px;top:5px;"></v-avatar>
                                        <h1 class="text-subtitle-1">{{materia.idMateriaNavigation.nombre}}</h1>
                                    </v-card-title>
                                </v-card>
                            </div>
                        </div>
                        <br>
                        <v-row class="ma-0 px-3"><h1 class="text-caption">Deslizar a los lados para ver todas las materias.</h1></v-row>
                    </div>
                    <br>
                    <br>
                    <div>
                        <v-row class="ma-0 px-3"><h1 class="text-h6 font-weight-medium">Secciones</h1></v-row>
                        <br>
                        <div v-for="seccion in studentsData" :key="seccion.idGradoSeccion" class="mx-3" align="center">
                            <v-card class="rounded-lg" elevation="5" max-width="1350">
                                <v-row no-gutters class="pa-3" justify="end">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-btn
                                            :disabled="DisabledUnsubscribe(seccion.toUnsubscribe)"
                                            v-on="on"
                                            fab
                                            small
                                            elevation="0"
                                            :color="$store.state.accentColor"
                                            @click="unsubscribeRange(seccion.toUnsubscribe)"
                                            >
                                                <v-icon>clear</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Des-asignar seleccionados</span>
                                    </v-tooltip>
                                </v-row>
                                <v-row no-gutters justify="center">
                                    <div class="text-h6">Alumnos sección {{seccion.seccion}}</div>
                                </v-row>
                                <v-row class="ma-0 py-3 px-3" justify="center">
                                    <v-data-table :items="seccion.estudiantesGrados" 
                                    :items-per-page="100"
                                    :headers="headers" 
                                    dense
                                    show-select
                                    item-key="idEstudianteGrado"
                                    v-model="seccion.toUnsubscribe"
                                    >
                                        <template v-slot:item.idEstudianteNavigation.fechaNacimiento="{item}">
                                            {{item.idEstudianteNavigation.fechaNacimiento.split("T")[0]}}
                                        </template>
                                        <template v-slot:item.idEstudianteNavigation.fechaIngreso="{item}">
                                            {{item.idEstudianteNavigation.fechaIngreso.split("T")[0]}}
                                        </template>
                                        <template v-slot:item.fechaAsignado="{item}">
                                            {{item.fechaAsignado.split("T")[0]}}
                                        </template>
                                        <template v-slot:item.becado="{item}">
                                            {{item.becado?"Si":"No"}}
                                        </template>
                                        <template v-slot:item.pagos="{item}">
                                            <v-row no-gutters justify="center">
                                                <v-btn icon :color="'primary'" @click="onclicpagos(item)">
                                                    <v-icon>fact_check</v-icon>
                                                </v-btn>
                                            </v-row>
                                        </template>
                                        <template v-slot:item.calificaciones="{item}">
                                            <v-row no-gutters justify="center">
                                                <v-btn icon :color="'amber'" @click="oncliccalificaciones(item)">
                                                    <v-icon>grade</v-icon>
                                                </v-btn>
                                            </v-row>
                                        </template>
                                        <template v-slot:item.actions="{item}">
                                            <v-menu>
                                                <template v-slot:activator="{on:menu}">
                                                    <v-btn icon v-on="{...menu}">
                                                        <v-icon>more_vert</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list dense class="pa-0">
                                                    <v-list-item v-for="option in options" :key="option" @click="onselectOption(item,option,seccion)">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{option}}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-data-table>
                                </v-row>
                            </v-card>
                            <br>
                        </div>
                    </div>
                </div>
            </v-app>
        </v-card>
    </v-dialog>
</template>
<script>

import AppBar2 from '../../../globalComponents/AppBar2';
import EstudianteDetalle from '../../estudiantes/EstudianteDetalle.vue';
import EstudianteDialog from '../../estudiantes/EstudianteDialog.vue';
import PagosEstudiante from '../../estudiantes/PagosEstudiante.vue';
import CalificacionesEstudiante from '../../estudiantes/CalificacionesEstudiante';

export default {
    components:{
        AppBar2,
        EstudianteDetalle,
        EstudianteDialog,
        PagosEstudiante,
        CalificacionesEstudiante
    },
    computed:{
        unsubscribeText:function(){
            if(this.selectedEnrollment){
                var estudiante=this.selectedEnrollment.idEstudianteNavigation;
                var nombre=`'${estudiante.nombre1} ${estudiante.nombre2} ${estudiante.nombre3} ${estudiante.apellido1} ${estudiante.apellido2}'`;

                return `¿Desea desasignar al estudiante ${nombre}?`;
            }else{
                return "";
            }
        },
        anularText:function(){
            if(this.selectedEnrollment){
                var estudiante=this.selectedEnrollment.idEstudianteNavigation;
                var nombre=`'${estudiante.nombre1} ${estudiante.nombre2} ${estudiante.nombre3} ${estudiante.apellido1} ${estudiante.apellido2}'`;

                return `¿Desea anular la asignación del estudiante "${nombre}"?`;
            }else{
                return "";
            }
        },
        studentID(){
            if(this.selectedEnrollment.idEstudianteNavigation){
                return this.selectedEnrollment.idEstudianteNavigation.idEstudiante;
            }

            return "";
        }
    },
    data:()=>({
        show:false,
        allLoaded:0,
        isLoading:false,
        gradeData:Object,
        studentsData:[],
        unsubscribeDialog:false,
        studentDialog:false,
        estudiantedetalleDialog:false,
        pagosestudianteDialog:false,
        calificacionesDialog:false,
        unsubscribeRangeDialog:false,
        selectedEnrollment:"",
        selectedSection:Object,
        selectedToUnsubscribe:"",
        draggable1:{
            isDown: false,
            startX:0,
            scrollLeft:0,
        },
        headers:[
            {text:"Código",value:"idEstudianteNavigation.codigo"},
            {text:"Usuario",value:"usuario"},
            {text:"Nombre 1",value:"idEstudianteNavigation.nombre1"},
            {text:"Nombre 2",value:"idEstudianteNavigation.nombre2"},
            {text:"Apellido 1",value:"idEstudianteNavigation.apellido1"},
            {text:"Apellido 2",value:"idEstudianteNavigation.apellido2"},
            {text:"CUI",value:"idEstudianteNavigation.cui"},
            {text:"Becado",value:"becado"},
            {text:"Pagos",value:"pagos"},
            {text:"Calificaciones",value:"calificaciones"},
            {text:"Acción",value:"actions"},
        ],
        studentstableMeta:{
            pageCount:0,
            page:1,
            itemsPage:10,
            search:""
        },
        options:[
            "Editar",
            "Ver",
            "Des-asignar"
        ],
        unsubscribe:"",
        action:""
    }),
    methods:{
        DisabledUnsubscribe(toUnsubscribe){
            if(toUnsubscribe)
                if(toUnsubscribe.length>0)
                    return false;
            return true;
        },
        onclicpagos(item){
            this.selectedEnrollment=item;
            this.pagosestudianteDialog=true;
        },
        oncliccalificaciones(item){
            this.selectedEnrollment=item;
            this.calificacionesDialog=true;
        },
        onselectOption(item,option,seccion){
            switch(option){
                case "Editar":
                    this.action='edit';
                    this.selectedEnrollment=item;
                    this.studentDialog=true;
                    break;
                case "Ver":
                    this.selectedEnrollment=item;
                    this.estudiantedetalleDialog=true;
                    break;
                case "Des-asignar":
                    this.selectedEnrollment=item;
                    this.selectedSection=seccion;
                    this.unsubscribeDialog=true;
                    break;
            }
        },
        onclose(){
            this.show=false;
            this.$emit("onclose");
            this.$router.go(-1);
        },
        onupdateEstudiante(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }

            this.loadAll();
        },
        async unsubscribeanularCall(idAsignacion,action){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.put(`api/grados/estudiantes/${action}/${idAsignacion}`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 0:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            break;
                        case 1:
                            this.$store.commit("showMessage",{text:"Estudiante desasignado correctamente.",color:"green",time:4000});
                            break;
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte..",color:"red",time:4000});
            });

            await this.loadAll();
            this.unsubscribeDialog=false;
            this.isLoading=false;
        },
        unsubscribeRange(toUnsubscribe){
            this.selectedToUnsubscribe=toUnsubscribe.map(item=>item.idEstudianteGrado);
            this.unsubscribeRangeDialog=true;
        },
        async unsubscribeRangeCall(){
            this.isLoading=this.$store.state.accentColor;
            
            await this.$axios.put(`api/grados/estudiantes/desasignar`,this.selectedToUnsubscribe).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 0:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            break;
                        case 1:
                            this.$store.commit("showMessage",{text:"Estudiante desasignado correctamente.",color:"green",time:4000});
                            break;
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte..",color:"red",time:4000});
            });

            await this.loadAll();
            this.unsubscribeRangeDialog=false;
            this.unsubscribeDialog=false;
            this.isLoading=false;
        },
        loadGrado(){
            return this.$axios.get(`api/grados/${this.$route.params.id}?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`);
        },
        loadStudents(){
            return this.$axios.get(`api/estudiantes/grado/${this.$route.params.id}/ciclo/${this.$store.state.cicloEscolar.idCicloEscolar}`);
        },
        async loadAll(){
            this.allLoaded=0;

            await this.$axios.all([this.loadGrado(),this.loadStudents()]).then((responses)=>{
                if(responses[0].status==200&&responses[1].status==200){
                    if(responses[0].data.code==1&&responses[1].data.code==1){
                        this.gradeData=responses[0].data.data;
                        this.studentsData=responses[1].data.data;
                        this.allLoaded=1;
                    }   
                }
            }).catch((errors)=>{
                errors;
                this.allLoaded=2;
            });

        }
    },
    mounted(){
        this.show=true;
        this.loadAll();
    },
    created(){
        this.unsubscribe=this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'changeCiclo') {
                state;
                this.loadAll();
            }
        });
    },
    beforeDestroy(){
        this.unsubscribe();
    }
}
</script>
<style scoped>
.body{
    padding-top: 60px;
}
.scrolling-wrapper {
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.card {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    display: inline-block;
  }
</style>