<template>
    <div>
        <div>
            <div v-if="!isLoading">
                <v-app-bar app flat :color="$vuetify.theme.dark?'transparent':'white'" extension-height="72">
                    <v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn>
                    <v-toolbar-title>{{asignacionData.idMateriaNavigation.nombre}} - {{getGradoSeccion}}</v-toolbar-title>
                    <template v-slot:extension>
                        <v-tabs class="tab-bar"
                            :color="$store.state.accentColor2"
                            v-model="tab"
                            centered
                            @change="onchangeTab"
                            slider-size="5"
                            :icons-and-text="$vuetify.breakpoint.smAndUp"
                            :fixed-tabs="$vuetify.breakpoint.xsOnly"
                                >
                            <v-tabs-slider></v-tabs-slider>
                            <v-tab
                                v-for="item in tabItems"
                                :key="item.text"
                                style="text-transform:none !important"
                            >
                                {{ item.text }}
                                <v-icon v-if="$vuetify.breakpoint.smAndUp">{{item.icon}}</v-icon>
                            </v-tab>
                        </v-tabs>
                    </template>
                    <v-progress-linear v-if="isLoading2" :color="$store.state.accentColor2" indeterminate bottom background-color="transparent" absolute/>
                </v-app-bar>
                <v-tabs-items v-model="tab" class="tabs_items" touchless>
                    <v-tab-item key="#Pizarra">
                        <pizarra v-if="tab==0" :tab="selectedTab" @isLoading2="onLoading2"></pizarra>
                    </v-tab-item>
                    <v-tab-item key="#Actividades">
                        <actividades :tab="selectedTab" v-if="tab==1"></actividades>
                    </v-tab-item>
                    <v-tab-item key="#Alumnos">
                        <listado-alumnos v-if="tab==2" :tab="selectedTab"></listado-alumnos>
                    </v-tab-item>
                    <v-tab-item key="#Calificaciones">
                        <calificaciones v-if="tab==3" :tab="selectedTab"></calificaciones>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
    </div>    
</template>
<script>
import Pizarra from '../pizarra/Pizarra.vue';
import Actividades from '../actividades/Actividades.vue'
import ListadoAlumnos from '../listadoAlumnos/ListadoAlumnos.vue'
import Calificaciones from '../calificaciones/Calificaciones.vue';
export default {
  components: { Pizarra,ListadoAlumnos, Actividades, Calificaciones },
  computed:{
      getGradoSeccion(){
          if(this.asignacionData){
              return `${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.nombre} ${this.asignacionData.idGradoSeccionNavigation.idSeccionNavigation.seccion}`;
          }
          return "Cargando...";
      }
  },
    data:function(){
        return({
            tabItems:[
                {text:"Pizarra",icon:"dashboard"},
                {text:"Actividades",icon:"assignment"},
                {text:"Alumnos",icon:"face"},
                {text:"Calificaciones",icon:"grade"}
            ],
            tab:null,
            asignacionData:Object,
            //bloquesData:[],
            isLoading:false,
            isLoading2:true,
            selectedTab:0
        });
    },
    methods:{
        onchangeTab(val){
            this.selectedTab=val;
        },
        onLoading2(val){
            this.isLoading2=val;
        },
        loadAsignacion(){return this.$axios.get(`api/docentes/asignaciones/asignacion/${this.$route.params.idassignment}`)},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadAsignacion()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.asignacionData=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style>
.tabs_items{
    background-color: transparent !important;
}
.v-tabs-bar{
    background-color: transparent !important;
}

</style>