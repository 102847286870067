<template>
        <div>
            <delete-dialog :show="deletePagoDialog" :context="'pago de nivel'" 
            :content="`¿Quieres eliminar el pago '${nombrePago}' del nivel '${selectedNivelEstudiantil.nombre}'?`"
            :idEntity="selectedPago.idPagoNivelEstudiantil"
            @ondelete="ondeletePago" @onclose="deletePagoDialog=false;"></delete-dialog>

            <delete-dialog :show="deletepagocarreraDialog" :context="'pago de carrera'"
            :content="getTextDeleteDialogPagoCarrera()"
            :idEntity="selectedPago.idPagoNivelEstudiantilCarrera"
            @ondelete="ondeletePagoCarrera" @onclose="deletepagocarreraDialog=false;"
            ></delete-dialog>

            <pago-nivel-dialog :pagonivelDialog="pagonivelDialog" :nivelestudiantil="selectedNivelEstudiantil" @onclose="pagonivelDialog=false;" 
            :pagoToEdit="selectedPago" :action="action"></pago-nivel-dialog>

            <pago-carrera-dialog :pagocarreraDialog="pagocarreraDialog" :carrera="selectedCarrera" @onclose="pagocarreraDialog=false;"
            :pagoToEdit="selectedPago" :action="action"
            ></pago-carrera-dialog>

            <div v-if="allLoaded">
                <v-row class="pa-0 ma-0" justify="center">
                    <div class="text-h4 font-weight-bold">{{getCicloEscolar}}</div>
                </v-row>
                <br>
                <v-row class="pa-0 ma-0">
                    <v-col v-for="nivel in nivelesData" :key="nivel.codigoNivelEstudiantil" cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                        <pagos-niveles-card :nivel="nivel" @onaddpagos="()=>{
                        selectedNivelEstudiantil=nivel;
                        action='add';
                        pagonivelDialog=true;
                        }" @ondeletePago="(item)=>{
                            selectedPago=item;
                            nombrePago=item.idPagoNavigation.descripcion;
                            selectedNivelEstudiantil=nivel;
                            deletePagoDialog=true;
                        }" @onupdatePago="(item)=>{
                            selectedNivelEstudiantil=nivel;
                            selectedPago=item;
                            action='edit';
                            pagonivelDialog=true;
                            }"
                        ></pagos-niveles-card>
                    </v-col>
                    <v-col v-for="carrera in carrerasData" :key="carrera.idCarrera" cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                        <pagos-carreras-card :carrera="carrera" @onaddpagos="()=>{
                            selectedCarrera=carrera;
                            action='add'
                            pagocarreraDialog=true;
                            }" @ondeletePago="(item)=>{
                                selectedPago=item;
                                nombrePago=item.idPagoNavigation.descripcion;
                                selectedCarrera=carrera;
                                deletepagocarreraDialog=true;
                            }" @onupdatePago="(item)=>{
                                selectedCarrera=carrera;
                                selectedPago=item;
                                action=`edit`;
                                pagocarreraDialog=true;
                                }"
                            >

                        </pagos-carreras-card>
                    </v-col>
                </v-row>
            </div>
        </div>
</template>
<script>
import DeleteDialog from '../../../globalComponents/DeleteDialog.vue';
import PagoCarreraDialog from './PagoCarreraDialog.vue';
import PagoNivelDialog from './PagoNivelDialog.vue';
import PagosCarrerasCard from './PagosCarrerasCard.vue';
import PagosNivelesCard from './PagosNivelesCard.vue';
export default {
    props:{selectedTab:Number},
    components:{ PagosNivelesCard, DeleteDialog, PagosCarrerasCard, PagoNivelDialog, PagoCarreraDialog},
    watch:{
        selectedTab:function(newval,oldval){
            oldval;
            if(newval==0){
                this.loadAll();
            }
        }
    },
    computed:{
        getCicloEscolar(){
            if(this.$store.state.cicloEscolar)
                return `Ciclo escolar ${this.$store.state.cicloEscolar.cicloEscolar1}`;
            return "";
        },
    },
    data:()=>({
        unsubscribe:"",
        nivelesData:[],
        carrerasData:[],
        allLoaded:false,
        pagosNivelesDialog:false,
        pagonivelDialog:false,
        pagocarreraDialog:false,
        deletePagoDialog:false,
        deletepagocarreraDialog:false,
        selectedNivelEstudiantil:Object,
        selectedCarrera:Object,
        selectedPago:Object,
        action:"",
        nombrePago:""
    }),
    methods:{
        getTextDeleteDialogPagoCarrera(){
            var text=``;

            if(this.selectedCarrera.idCarreraNavigation){
                text=`¿Quieres eliminar el pago '${this.nombrePago}' de la carrera '${this.selectedCarrera.idCarreraNavigation.nombre}'?`;
            }else{
                `¿Quieres eliminar el pago '${this.nombrePago}' de la carrera ''?`;
            }

            return text;
        },
        onChangeCiclo(val){
            this.$store.commit("changeCiclo",val);
        },
        returnName(item){
            return item.idPagoNavigation.descripcion;
        },
        loadNiveles(){
            return this.$axios.get(`api/nivelesestudiantiles?type=pagos&ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`);
        },
        loadCarreras(){
            return this.$axios.get(`api/carreras/nivel/03-12-0029-46?type=nomaterias&ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`);
        },
        async loadAll(){
            this.allLoaded=false;
            this.$store.commit("setLoading",!this.allLoaded);

            await this.$axios.all([this.loadNiveles(),this.loadCarreras()]).then((responses)=>{
                if(responses[0].status==200){
                    if(responses[0].data.code==1){
                        this.nivelesData=responses[0].data.data;
                        var index=this.nivelesData.indexOf(item=>item.codigoNivelEstudiantil=="03-12-0029-46")-1;
                        this.nivelesData.splice(index,1);
                        
                        this.carrerasData=responses[1].data.data;
                    }
                }
            }).catch((errors)=>{
                errors;
            });

            this.allLoaded=true;
            this.$store.commit("setLoading",!this.allLoaded);
        },
        ondeletePago(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                break;
                case 1:
                    this.selectedNivelEstudiantil.pagosNivelesEstudiantiles.splice(this.selectedNivelEstudiantil.pagosNivelesEstudiantiles.indexOf(this.selectedPago),1);

                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
                }  
        },
        ondeletePagoCarrera(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                break;
                case 1:
                    this.selectedCarrera.pagosNivelesEstudiantilesCarreras.splice(this.selectedCarrera.pagosNivelesEstudiantilesCarreras.indexOf(this.selectedPago),1);

                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
                }  
        },
        onupdatePagos(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
        }
    },
    created(){
        this.loadAll();

        this.unsubscribe=this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'changeCiclo') {
                state;
                this.loadAll();
            }
        });
    },
    beforeDestroy(){
        this.unsubscribe();
    }
}
</script>
<style scoped>

</style>