import { render, staticRenderFns } from "./GradoDetalle.vue?vue&type=template&id=65d2ee4b&scoped=true"
import script from "./GradoDetalle.vue?vue&type=script&lang=js"
export * from "./GradoDetalle.vue?vue&type=script&lang=js"
import style0 from "./GradoDetalle.vue?vue&type=style&index=0&id=65d2ee4b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d2ee4b",
  null
  
)

export default component.exports