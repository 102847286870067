<template>
    <v-card outlined>
        <v-card-title>
            <v-btn class="delete_button" icon @click="$emit('ondelete')">
                <v-icon>clear</v-icon>
            </v-btn>
            {{materia.nombre}}
        </v-card-title>
        <v-card-actions>
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <v-btn icon @click="$emit('onedit')" v-on="on"><v-icon>edit</v-icon></v-btn>
                </template>
                <span>Editar materia</span>
            </v-tooltip>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props:[
        "materia"
    ]
}
</script>
<style scoped>
.delete_button{
    position:absolute;right:0;top:0;
}
</style>