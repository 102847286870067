<template>
    <v-dialog v-model="iniciarFinalizarDialog" persistent max-width="400">
        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title>{{title}}</v-card-title>
            <v-card-text>
                {{content}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose')">Cancelar</v-btn>
                <v-btn text color="green" @click="save">{{buttonOkText}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:[
        "iniciarFinalizarDialog",
        "action",
        "bloque"
    ],
    watch:{
        iniciarFinalizarDialog:function(newval,oldval){
            oldval;

            if(newval){
                if(this.action=="iniciar"){
                    this.title=`Iniciar bloque ${this.bloque.bloque}`;
                    this.buttonOkText="Iniciar";

                    this.content=`¿Desea iniciar el bloque ${this.bloque.bloque}?`
                }else{
                    this.title=`Finalizar bloque ${this.bloque.bloque}`;
                    this.buttonOkText="Finalizar";

                    this.content=`¿Desea finalizar el bloque ${this.bloque.bloque}?`
                }
            }
        }
    },
    data:function(){
        return ({
            title:"",
            content:"",
            buttonOkText:"",
            isLoading:false
        });
    },
    methods:{
        async save(){
            if(this.action=="iniciar"){
                await this.startCall();
            }else{
                await this.endCall();
            }
        },
        async startCall(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.put(`api/docentes/bloques/iniciar/${this.bloque.idMateriaBloque}`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 1:
                            this.$store.commit("showMessage",{text:"Bloque iniciado correctamente.",color:"green",time:4000});
                            //this.$updateChanges(this.bloque,response.data.data);

                            return [1,response.data.data];
                        default:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            return [0];
                                
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"green",time:4000});

                return [2];
            });

            this.$emit("onstart");
            this.$emit("onclose");

            this.isLoading=false;
        },
        async endCall(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.put(`api/docentes/bloques/finalizar/${this.bloque.idMateriaBloque}`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 1:
                            this.$store.commit("showMessage",{text:"Bloque finalizado correctamente.",color:"green",time:4000});
                            //this.$updateChanges(this.bloque,response.data.data);

                            return [1,response.data.data];
                        default:
                            this.$store.commit("showMessage",{text:response.data.message,color:"",time:4000});
                            return [0];
                                
                    }
                }
            }).catch((error)=>{
                error;
                this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});

                return [2];
            });

            this.$emit("onend");
            this.$emit("onclose");

            this.isLoading=false;
        }
    }
}
</script>
<style scoped>

</style>