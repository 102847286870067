<template>
    <div>
        <div>
            <div v-if="!isLoading">
                <v-app-bar app flat :color="$vuetify.theme.dark?'transparent':'white'" extension-height="72">
                    <v-row>
                        <v-col class="pa-0" cols="4"><v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn></v-col>
                        <v-col class="pa-0" cols="4" align="center"><notifications-actividades/></v-col>
                        <v-col class="pa-0" cols="4"></v-col>
                    </v-row>
                    <template v-slot:extension>
                        <v-tabs class="tab-bar"
                            :color="$store.state.accentColor2"
                            v-model="tab"
                            centered
                            @change="onchangeTab"
                            slider-size="5"
                            :icons-and-text="$vuetify.breakpoint.smAndUp"
                            :fixed-tabs="$vuetify.breakpoint.xsOnly"
                                >
                            <v-tabs-slider></v-tabs-slider>
                            <v-tab
                                v-for="item in tabItems"
                                :key="item.text"
                                style="text-transform:none !important"
                            >
                                {{ item.text }}
                                <v-icon v-if="$vuetify.breakpoint.smAndUp">{{item.icon}}</v-icon>
                            </v-tab>
                        </v-tabs>
                    </template>
                    <v-progress-linear v-if="isLoading2" :color="$store.state.accentColor2" indeterminate bottom background-color="transparent" absolute/>
                </v-app-bar>
                <v-tabs-items v-model="tab" class="tabs_items">
                    <v-tab-item key="Pizarra">
                        <pizarra v-if="tab==0" :tab="selectedTab" @isLoading2="onLoading2"></pizarra>
                    </v-tab-item>
                    <v-tab-item key="Actividades">
                        <actividades :tab="selectedTab" v-if="tab==1"></actividades>
                    </v-tab-item>
                    <v-tab-item key="Participantes">
                        <participantes :tab="selectedTab" v-if="tab==2"></participantes>
                    </v-tab-item>
                    <v-tab-item key="Calificaciones">
                        <!--<calificaciones v-if="tab==3" :gradoData="gradoData" :tab="selectedTab"></calificaciones>-->
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
    </div>    
</template>
<script>
import Participantes from './participantes/Participantes.vue';
import Pizarra from './pizarra/Pizarra.vue';
import Actividades from './actividades/Actividades.vue'
import NotificationsActividades from '../home/NotificationsActividades.vue';
export default {
    components: { Pizarra, Participantes,NotificationsActividades/*,ListadoAlumnos,*/ ,Actividades/*, Calificaciones */},
    computed:{
        
    },
    data:function(){
        return({
            tabItems:[
                {text:"Pizarra",icon:"dashboard"},
                {text:"Actividades",icon:"assignment"},
                {text:"Participantes",icon:"people"},
            ],
            tab:null,
            //bloquesData:[],
            isLoading:false,
            isLoading2:true,
            selectedTab:0
        });
    },
    methods:{
        onLoading2(val){
            this.isLoading2=val;
        },
        onchangeTab(val){
            this.selectedTab=val;
        },
    },
    created(){
    }
}
</script>
<style>
.tabs_items{
    background-color: transparent !important;
}
.v-tabs-bar{
    background-color: transparent !important;
}

</style>