<template>
    <v-card elevation="2" class="rounded-xl card-text" :loading="isLoading" :disabled="isLoading">
        <template slot="progress">
            <v-progress-linear indeterminate :color="$store.state.accentColor2" :background-color="'transparent'"></v-progress-linear>
        </template>
        <video-attach-dialog :videoDialog="videoDialog" @onclose="videoDialog=false" @onaddVideo="onaddVideo"></video-attach-dialog>
        <file-attach-dialog :fileDialog="fileDialog" @onclose="fileDialog=false" @onaddFile="onaddFile"></file-attach-dialog>
        <v-card-title v-if="action">
            {{getTitulo}}
        </v-card-title>
        <v-card-text>
            <v-row no-gutters>
                <v-col align="start">
                    <h1 class="text-subtitle-2">Para</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-0" cols="12" sm="6">
                    <v-select
                    filled
                    :color="$store.state.accentColor2"
                    :item-color="$store.state.accentColor2"
                    :items="studentsData"
                    item-value="idEstudiante"
                    :item-text="(item)=>`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2} ${item.idEstudianteNavigation.apellido1} ${item.idEstudianteNavigation.apellido2}`"
                    label="Estudiantes"
                    multiple
                    v-model="newAvisoPost.alumnos"
                    :rules="newAvisoPost.alumnosRules"
                    return-object
                    rounded
                    >
                        <template v-slot:selection="{item,index}">
                            <div v-if="newAvisoPost.alumnos.length!==(studentsData.length)">
                                <v-chip v-if="index===0" small>
                                    <span>{{`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2}`}}</span>
                                </v-chip>
                                <span
                                v-if="index === 1"
                                class="grey--text caption"
                                >
                                (+{{ newAvisoPost.alumnos.length - 1 }})
                                </span>
                            </div>
                            <v-chip v-if="index===(studentsData.length-1)" small>
                                <span>Todos los alumnos</span>
                            </v-chip>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list-item
                            ripple
                            @click="toggle"
                            >
                            <v-list-item-action>
                                <v-icon :color="'teal darken-3'">
                                {{icon}}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                Seleccionar todos
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-select>
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                    <v-select
                    readonly
                    rounded
                    filled
                    :items="bloques"
                    item-value="idMateriaBloque"
                    :item-text="(item)=>`Bloque ${item.bloque}`"
                    v-model="newAvisoPost.bloque"
                    :rules="newAvisoPost.bloqueRules"
                    label="Bloque"
                    >

                    </v-select>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col align="start">
                    <h1 class="text-subtitle-2">Contenido</h1>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-textarea
                rounded
                    v-model="newAvisoPost.contenido"
                    :color="$store.state.accentColor2"
                    no-resize
                    filled
                    label="Publica algo a la clase"
                    rows="3"
                    >
                    
                </v-textarea>
            </v-row>
            <v-row no-gutters>
                <v-col align="start">
                    <h1 class="text-subtitle-2">Videos</h1>
                </v-col>
            </v-row>
            <v-row no-gutters class="pb-5">
                <v-col v-for="(video,index) in newAvisoPost.videos" :key="index" cols="12">
                    <video-card :videoInfo="video" :action="'add'" @ondelete="ondeleteVideo"></video-card>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col align="start">
                    <h1 class="text-subtitle-2">Archivos</h1>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col v-for="(file,index) in archivos" :key="index" cols="12">
                    <file-card :file="file" @ondelete="ondeleteFile"></file-card>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col v-for="(file,index) in newAvisoPost.archivos" :key="index" cols="12">
                    <file-card :file="file" @ondelete="ondeletesavedFile"></file-card>
                </v-col>
            </v-row>
            <br>
            <v-row no-gutters>
                <v-menu>
                    <template v-slot:activator="{on:menu}">
                        <v-btn text small v-on="{...menu}" outlined :color="$store.state.accentColor2">
                            <v-icon small>attach_file</v-icon>
                            Agregar
                        </v-btn>
                    </template>
                    <v-list class="pa-0" dense>
                        <v-list-item v-for="(opcion,index) in attachOptions" :key="index" @click="()=>{onattachOption(opcion)}">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{opcion.option}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text @click="$emit('oncancel')">Cancelar</v-btn>
            <v-btn small color="green" text @click="save" :disabled="publicarVerifier()">{{postButtonText}}</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import VideoCard from '../../common/VideoCard.vue';
import VideoAttachDialog from '../../common/VideoAttachDialog.vue'
import FileAttachDialog from '../../common/FileAttachDialog.vue'
import FileCard from '../../common/FileCard.vue';

export default {
  components: { VideoCard,VideoAttachDialog,FileAttachDialog, FileCard },
    props: [
        "tipoactividad",
        "action",
        "actividad"
    ],
    computed:{
        postButtonText(){
            switch(this.action){
                case "edit":
                return "Guardar";
                case "add":
                return "Publicar";
                default:
                return "Publicar";
            }
        },
        getTitulo(){
            switch(this.action){
                case "edit":
                return "Edición de aviso";
                case "add":
                return "Nuevo aviso";
                default:
                return "";
            }
        },
        allStudentsSelected(){
            return this.studentsData.length===this.newAvisoPost.alumnos.length;
        },
        someStudentSelected() {
            return this.newAvisoPost.alumnos.length > 0 && !this.allStudentsSelected
        },
        icon () {
            if (this.allStudentsSelected) return 'mdi-close-box'
            if (this.someStudentSelected) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    data:function(){
        return ({
            avisoData:"",
            isLoading:false,
            studentsData:[],
            bloques:[],
            currentBloque:"",
            videoDialog:false,
            fileDialog:false,
            attachOptions:[
                {option:"Video YouTube",icon:"smart_display"},
                {option:"Archivos",icon:"attach_file"}
            ],
            newAvisoPost:{
                bloque:"",
                tipoActividad:this.tipoactividad,
                alumnos:[],
                alumnosRules:[
                    v=>!!v&&v.length>0||"Alumnos es requerido."
                ],
                bloqueRules:[
                    v=>!!v||"Bloque es requerido."
                ],
                contenido:"",
                videos:[],
                archivos:[]
            },
            archivos:[]
        });
    },
    methods:{
        ondeletesavedFile(fileItem){
            var index=this.newAvisoPost.archivos.indexOf(fileItem);
            this.newAvisoPost.archivos.splice(index,1);
        },
        ondeleteFile(fileItem){
            var index=this.archivos.indexOf(fileItem);
            this.archivos.splice(index,1);
        },
        ondeleteVideo(videoItem){
            var index=this.newAvisoPost.videos.indexOf(videoItem);
            this.newAvisoPost.videos.splice(index,1);
        },
        onaddVideo(videoInfo){
            if(!this.newAvisoPost.videos.some(item=>item.id==videoInfo.id)){
                this.newAvisoPost.videos.push(videoInfo);
                this.videoDialog=false;
            }
        },
        onaddFile(files){
            var currentAmountFiles=this.newAvisoPost.archivos.length+this.archivos.length;
            currentAmountFiles;
            if(currentAmountFiles<=5){
                if((currentAmountFiles+files.length)<=5){
                    Array.prototype.push.apply(this.archivos,files);
                    this.fileDialog=false;

                    return;
                }
            }
            this.fileDialog=false;
            alert("No se pueden agregar más de 5 archivos a la publicación.");
        },
        onattachOption(option){
            switch(option.option){
                case "Video YouTube":
                    this.videoDialog=true;
                    break;
                case "Archivos":
                    this.fileDialog=true;
                    break;
            }
        },
        publicarVerifier(){
            return this.newAvisoPost.contenido.trim().length==0||this.newAvisoPost.alumnos.length==0||!this.newAvisoPost.bloque
        },
        toggle(){
            this.$nextTick(() => {
                if (this.allStudentsSelected) {
                    this.newAvisoPost.alumnos=[]
                } else {
                    this.newAvisoPost.alumnos=this.studentsData;
                }
            })
        },
        async save(){
            this.isLoading=this.$store.state.accentColor2;
            if(this.action=="edit"){
                await this.updateCall();
            }else{
                await this.saveCall();
            }
            this.isLoading=false;
        },
        async saveCall(){
            var formData=new FormData();

            for( var counter = 0; counter < this.archivos.length; counter++ ){
                let file = this.archivos[counter];

                formData.append('archivos', file);
            }
            
            formData.append("avisoString",JSON.stringify(this.newAvisoPost));
            
            await this.$axios.put(`api/docentes/actividades/avisos/create/?bloque=${this.newAvisoPost.bloque.idMateriaBloque}`,
            formData
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit('onsave',[1,response.data.data,response.data.message]);
                    }else{
                        this.$emit('onsave',[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("onsave",[2,error.response.data.message]);
                    }else{
                        this.$emit("onsave",[2]);
                    }
                }
            });
        },
        async updateCall(){
            var formData=new FormData();

            this.newAvisoPost.alumnos.forEach((item)=>{
                var participante=this.avisoData.actividad.actividadesMateriasBloquesParticipantes.find(item2=>item2.idEstudianteGrado==item.idEstudianteGrado);
                if(participante){
                    item.idActividadMateriaBloqueParticipante=participante.idActividadMateriaBloqueParticipante;
                }
            });

            console.log(this.newAvisoPost.alumnos);

            for( var counter = 0; counter < this.archivos.length; counter++ ){
                let file = this.archivos[counter];

                formData.append('archivos', file);
            }
            
            formData.append("avisoString",JSON.stringify(this.newAvisoPost));

            await this.$axios.put(`api/docentes/actividades/avisos/update/${this.avisoData.actividad.idActividadMateriaBloque}`,
                formData
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit('onupdate',[1,response.data.data,response.data.message]);
                    }else{
                        this.$emit('onupdate',[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("onupdate",[2,error.response.data.message]);
                    }else{
                        this.$emit("onupdate",[2]);
                    }
                }
            });
        },
        loadStudents(){return this.$axios.get(`api/docentes/asignaciones/asignacion/${this.$route.params.idassignment}/estudiantes`);},
        loadCurrentBloque(){return this.$axios.get(`api/docentes/bloques/actual/${this.$route.params.idassignment}`)},
        loadAviso(){return this.$axios.get(`api/docentes/actividades/avisos/${this.actividad.actividad.idActividadMateriaBloque}`)},
        async loadAll(action){
            this.isLoading=this.$store.state.accentColor2;
            var httpCalls=[this.loadStudents(),this.loadCurrentBloque()];
            
            if(action=="edit")
                httpCalls=[this.loadStudents(),this.loadCurrentBloque(),this.loadAviso()];

            await this.$axios.all(httpCalls).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.studentsData=response[0].data.data;
                        this.bloques=response[1].data.data;
                        
                        this.newAvisoPost.alumnos=this.studentsData;
                        if(action=="edit")
                            this.initializeAvisoEdit(response[2].data.data);
                        
                        if(this.bloques.length>0)
                            this.newAvisoPost.bloque=this.bloques[0]
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        initializeAvisoEdit(actividad){
            this.avisoData=actividad;

            this.newAvisoPost.bloque=actividad.actividad.idMateriaBloqueNavigation;

            var studentsInActivity=this.newAvisoPost.alumnos.filter(item=>actividad.actividad.actividadesMateriasBloquesParticipantes.some(item2=>item2.idEstudianteGrado==item.idEstudianteGrado));
            this.newAvisoPost.alumnos=studentsInActivity;

            this.newAvisoPost.videos=actividad.videos.slice();
            this.newAvisoPost.archivos=actividad.archivos.slice();
            this.newAvisoPost.contenido=actividad.contenido;
        }
    },
    async created(){
        await this.loadAll(this.action);
    }
}
</script>
<style scoped>

</style>