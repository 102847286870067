<template>
    <v-dialog fullscreen transition="scale-transition" v-model="show" @click:outside="onclose">
        <video-attach-dialog :videoDialog="videoDialog" @onclose="videoDialog=false;" @onaddVideo="onaddVideo"></video-attach-dialog>
        <file-attach-dialog :fileDialog="fileDialog" @onclose="fileDialog=false;" @onaddFile="onaddFile"></file-attach-dialog>
        <v-card :disabled="isLoading==0">
            <v-app id="material_docente">
                <v-app-bar flat fixed :color="$vuetify.theme.dark?'':'white'">
                    <v-btn icon @click="$router.go(-1)"><v-icon>clear</v-icon></v-btn>
                    <v-toolbar-title>
                        Material de aprendizaje
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="enableAsign" depressed :color="$store.state.accentColor2" :dark="isDark" @click="save">
                        Asignar
                    </v-btn>
                    <v-progress-linear indeterminate bottom absolute v-if="isLoading==0" :color="$store.state.accentColor2"></v-progress-linear>
                </v-app-bar>
                <div :class="$vuetify.breakpoint.xsOnly?'body2':'body'">
                    <v-form ref="newmaterialForm">
                        <v-row class="ma-0 pa-0" v-if="$vuetify.breakpoint.xsOnly">
                            <v-divider></v-divider>
                            <v-col cols="12">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-text-field
                                        rounded
                                        v-model="newmaterial.title"
                                        :rules="newmaterial.titleRules"
                                        label="Título"
                                        filled
                                        :color="$store.state.accentColor2"
                                        autocomplete="off"
                                        >

                                        </v-text-field>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-textarea
                                        rounded
                                        v-model="newmaterial.contenido"
                                        label="Contenido (opcional)"
                                        filled
                                        :color="$store.state.accentColor2"
                                        rows="4"
                                        no-resize
                                        autocomplete="off"
                                        >

                                        </v-textarea>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <div>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{on:menu}">
                                                    <v-btn text outlined :color="$store.state.accentColor2" v-on="menu">
                                                        <v-icon>attach_file</v-icon> Agregar
                                                    </v-btn>
                                                </template>
                                                <v-list class="pa-0" dense>
                                                    <v-list-item v-for="(opcion,index) in attachOptions" :key="index" @click="()=>{onattachOption(index)}">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{opcion.option}}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="newmaterial.videos.length>0">
                                    <v-list-item-content>
                                        <v-row no-gutters class="pb-5">
                                            <v-col v-for="(video,index) in newmaterial.videos" :key="index" cols="12">
                                                <video-card :videoInfo="video" :action="'add'" @ondelete="ondeleteVideo"></video-card>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item  v-if="newmaterial.archivos.length>0||archivos.length>0">
                                    <v-list-item-content>
                                        <v-row no-gutters>
                                            <v-col v-for="(file,index) in archivos" :key="index" cols="12">
                                                <file-card :file="file" @ondelete="ondeleteFile"></file-card>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col v-for="(file,index) in newmaterial.archivos" :key="index" cols="12">
                                                <file-card :file="file" @ondelete="ondeletesavedFile"></file-card>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col>
                                <v-row class="pa-0 ma-0">
                                    <p class="text-subtitle-2">Para</p>
                                </v-row>
                                <v-row gutters>
                                    <v-col cols="12" xl="6" class="py-0">
                                        <v-select
                                        filled
                                        rounded
                                        :color="$store.state.accentColor2"
                                        :item-color="$store.state.accentColor2"
                                        :items="studentsData"
                                        item-value="idEstudiante"
                                        :item-text="(item)=>`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2} ${item.idEstudianteNavigation.apellido1} ${item.idEstudianteNavigation.apellido2}`"
                                        label="Estudiantes"
                                        multiple
                                        v-model="newmaterial.alumnos"
                                        :rules="newmaterial.alumnosRules"
                                        return-object
                                        >
                                            <template v-slot:selection="{item,index}">
                                                <div v-if="newmaterial.alumnos.length!==(studentsData.length)">
                                                    <v-chip v-if="index===0" small>
                                                        <span>{{`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2}`}}</span>
                                                    </v-chip>
                                                    <span
                                                    v-if="index === 1"
                                                    class="grey--text caption"
                                                    >
                                                    (+{{ newmaterial.alumnos.length - 1 }})
                                                    </span>
                                                </div>
                                                <v-chip v-if="index===(studentsData.length-1)" small>
                                                    <span>Todos</span>
                                                </v-chip>
                                            </template>
                                            <template v-slot:prepend-item>
                                                <v-list-item
                                                ripple
                                                @click="toggle"
                                                >
                                                <v-list-item-action>
                                                    <v-icon :color="'teal darken-3'">
                                                    {{icon}}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                    Seleccionar todos
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" xl="6" class="py-0">
                                        <v-select
                                        filled
                                        rounded
                                        :items="bloques"
                                        item-value="idMateriaBloque"
                                        :item-text="(item)=>`Bloque ${item.bloque}`"
                                        disabled
                                        v-model="newmaterial.bloque"
                                        :rules="newmaterial.bloqueRules">
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="fill-height ma-0 pa-0" v-if="$vuetify.breakpoint.smAndUp">
                            <v-col cols="12" sm="8" md="9" lg="9" xl="10">
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon>assignment</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-text-field
                                        rounded
                                        v-model="newmaterial.title"
                                        :rules="newmaterial.titleRules"
                                        label="Título"
                                        filled
                                        :color="$store.state.accentColor2"
                                        autocomplete="off"
                                        counter="100"
                                        maxlength="100"
                                        >

                                        </v-text-field>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon>subject</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-textarea
                                        rounded
                                        v-model="newmaterial.contenido"
                                        label="Contenido (opcional)"
                                        filled
                                        :color="$store.state.accentColor2"
                                        rows="4"
                                        no-resize
                                        autocomplete="off"
                                        >

                                        </v-textarea>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>

                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <div>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{on:menu}">
                                                    <v-btn text outlined :color="$store.state.accentColor2" v-on="menu">
                                                        <v-icon>attach_file</v-icon> Agregar
                                                    </v-btn>
                                                </template>
                                                <v-list class="pa-0" dense>
                                                    <v-list-item v-for="(opcion,index) in attachOptions" :key="index" @click="()=>{onattachOption(index)}">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{opcion.option}}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>

                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-row no-gutters class="pb-5">
                                            <v-col v-for="(video,index) in newmaterial.videos" :key="index" cols="12">
                                                <video-card :videoInfo="video" :action="'add'" @ondelete="ondeleteVideo(video)"></video-card>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>

                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-row no-gutters>
                                            <v-col v-for="(file,index) in archivos" :key="index" cols="12">
                                                <file-card :file="file" @ondelete="ondeleteFile"></file-card>
                                            </v-col>
                                            <v-col v-for="(file,index) in newmaterial.archivos" :key="index" cols="12">
                                                <file-card :file="file" @ondelete="ondeletesavedFile"></file-card>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col>
                                <v-row gutters>
                                    <p class="text-subtitle-2">Para</p>
                                </v-row>
                                <v-row gutters>
                                    <v-col cols="12" xl="6">
                                        <v-select
                                        filled
                                        rounded
                                        :color="$store.state.accentColor2"
                                        :item-color="$store.state.accentColor2"
                                        :items="studentsData"
                                        item-value="idEstudiante"
                                        :item-text="(item)=>`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2} ${item.idEstudianteNavigation.apellido1} ${item.idEstudianteNavigation.apellido2}`"
                                        label="Estudiantes"
                                        multiple
                                        v-model="newmaterial.alumnos"
                                        :rules="newmaterial.alumnosRules"
                                        return-object
                                        >
                                            <template v-slot:selection="{item,index}">
                                                <div v-if="newmaterial.alumnos.length!==(studentsData.length)">
                                                    <v-chip v-if="index===0" small>
                                                        <span>{{`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2}`}}</span>
                                                    </v-chip>
                                                    <span
                                                    v-if="index === 1"
                                                    class="grey--text caption"
                                                    >
                                                    (+{{ newmaterial.alumnos.length - 1 }})
                                                    </span>
                                                </div>
                                                <v-chip v-if="index===(studentsData.length-1)" small>
                                                    <span>Todos</span>
                                                </v-chip>
                                            </template>
                                            <template v-slot:prepend-item>
                                                <v-list-item
                                                ripple
                                                @click="toggle"
                                                >
                                                <v-list-item-action>
                                                    <v-icon :color="'teal darken-3'">
                                                    {{icon}}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                    Seleccionar todos
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" xl="6">
                                        <v-select
                                        filled
                                        rounded
                                        :items="bloques"
                                        item-value="idMateriaBloque"
                                        :item-text="(item)=>`Bloque ${item.bloque}`"
                                        disabled
                                        v-model="newmaterial.bloque"
                                        :rules="newmaterial.bloqueRules">
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </v-app>
        </v-card>
    </v-dialog>
</template>
<script>
import FileAttachDialog from '../FileAttachDialog.vue';
import VideoAttachDialog from '../VideoAttachDialog.vue';
import VideoCard from '../VideoCard.vue';
import FileCard from '../FileCard.vue';
export default {
  components: { VideoAttachDialog, FileAttachDialog, VideoCard,FileCard },
    props:[
        "tipoactividad",
        "action"
    ],
    watch:{
        action:async function(newval,oldval){
            oldval;
            newval;
        },
    },
    computed:{
        isDark(){
            if(this.enableAsign)
                return false;
            return true;
        },
        enableAsign(){
            if(this.newmaterial.title&&this.$refs.newmaterialForm.validate()){
                return false;
            }

            return true;
        },
        allStudentsSelected(){
            return this.studentsData.length===this.newmaterial.alumnos.length;
        },
        someStudentSelected() {
            return this.newmaterial.alumnos.length > 0 && !this.allStudentsSelected
        },
        icon () {
            if (this.allStudentsSelected) return 'mdi-close-box'
            if (this.someStudentSelected) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    data:function(){
        return({
            materialData:"",
            isLoading:false,
            show:false,
            studentsData:[],
            bloques:[],
            currentBloque:"",
            videoDialog:false,
            fileDialog:false,
            attachOptions:[
                {option:"Video YouTube",icon:"smart_display"},
                {option:"Archivos",icon:"attach_file"}
            ],
            newmaterial:{
                title:"",
                titleRules:[
                    v=>!!v||"Título es obligatorio",
                    v=>!/^\s+$/.test(v)||"Título no debe ser solo espacios",
                    v=>v.length<=100||"Título debe ser menor o igual a 50 caracteres"
                ],
                contenido:"",
                alumnos:[],
                alumnosRules:[
                    v=>!!v&&v.length>0||"Alumnos es requerido."
                ],
                bloque:"",
                bloqueRules:[
                    v=>!!v||"Bloque es requerido."
                ],
                archivos:[],
                videos:[],
                tipoActividad:this.tipoactividad,
            },
            archivos:[],
            maxZonaRestante:0,
            maxZonaRestanteshow:0
        });
    },
    methods:{
        toggle(){
            this.$nextTick(() => {
                if (this.allStudentsSelected) {
                    this.newmaterial.alumnos=[]
                } else {
                    this.newmaterial.alumnos=this.studentsData;
                }
            })
        },
        onclose(){
            this.show=false;
            this.$router.go(-1);
            //this.show=false;
        },
        ondeletesavedFile(fileItem){
            var index=this.materialData.archivos.indexOf(fileItem);
            this.materialData.archivos.splice(index,1);
        },
        ondeleteFile(fileItem){
            var index=this.archivos.indexOf(fileItem);
            this.archivos.splice(index,1);
        },
        ondeleteVideo(videoItem){
            var index=this.newmaterial.videos.indexOf(videoItem);
            this.newmaterial.videos.splice(index,1);
        },
        onattachOption(index){
            if(index==0){
                this.videoDialog=true;
            }else{
                this.fileDialog=true;
            }
        },
        onaddFile(files){
            var currentAmountFiles=this.newmaterial.archivos.length+this.archivos.length;
            currentAmountFiles;
            if(currentAmountFiles<=5){
                if((currentAmountFiles+files.length)<=5){
                    Array.prototype.push.apply(this.archivos,files);
                    this.fileDialog=false;

                    return;
                }
            }
            this.fileDialog=false;
            alert("No se pueden agregar más de 5 archivos a la publicación.");
        },
        onaddVideo(videoInfo){
           if(!this.newmaterial.videos.some(item=>item.id==videoInfo.id)){
                this.newmaterial.videos.push(videoInfo);
                this.videoDialog=false;
            }
        },
        save(){
            console.log(this.action);

            if(this.action=="add"){
                this.saveCall();
            }else{
                this.updateCall();
            }
        },
        async saveCall(){
            if(this.$refs.newmaterialForm.validate()){
                this.isLoading=0;

                var data=new FormData();
                this.archivos.forEach((item)=>{
                    data.append("archivos",item);
                });

                data.append("materialString",JSON.stringify(this.newmaterial));

                await this.$axios.put(
                    `api/docentes/actividades/material/create/?bloque=${this.newmaterial.bloque.idMateriaBloque}`,
                    data
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$emit('onsavematerial',[1,response.data.data,response.data.message]);
                            this.onclose();
                        }else{
                            this.$emit('onsavematerial',[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onsavematerial",[2,error.response.data.message]);
                        }else{
                            this.$emit("onsavematerial",[2]);
                        }
                    }
                });

                this.isLoading=1;       
            }
        },
        async updateCall(){
            if(this.$refs.newmaterialForm.validate()){
                this.isLoading=0;

                var data=new FormData();
                this.archivos.forEach((item)=>{
                    data.append("archivos",item);
                });

                data.append("materialString",JSON.stringify(this.newmaterial));

                await this.$axios.put(
                    `api/docentes/actividades/material/update/${this.materialData.actividad.idActividadMateriaBloque}`,
                    data
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$emit('onupdatematerial',[1,response.data.data,response.data.message]);
                            this.onclose();
                        }else{
                            this.$emit('onupdatematerial',[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onupdatematerial",[2,error.response.data.message]);
                        }else{
                            this.$emit("onupdatematerial",[2]);
                        }
                    }
                });

                this.isLoading=1;       
            }
        },
        loadStudents(){return this.$axios.get(`api/docentes/asignaciones/asignacion/${this.$route.params.idassignment}/estudiantes`);},
        loadCurrentBloque(){return this.$axios.get(`api/docentes/bloques/actual/${this.$route.params.idassignment}`)},
        loadMaterial(){return this.$axios.get(`api/docentes/actividades/material/${this.$route.params.idmaterial}`);},
        async loadAll(action){
            this.isLoading=0;
            var httpCalls=[this.loadStudents(),this.loadCurrentBloque()];

            if(action=="edit")
                httpCalls=[this.loadStudents(),this.loadCurrentBloque(),this.loadMaterial()];

            await this.$axios.all(httpCalls).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.studentsData=response[0].data.data;
                        this.bloques=response[1].data.data;

                        this.newmaterial.alumnos=this.studentsData;

                        if(action=="edit")
                            this.initializeMaterial(response[2].data.data);

                        if(this.bloques.length>0){
                            this.newmaterial.bloque=this.bloques[0];
                        }
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=1;
        },
        initializeMaterial(response){
            this.materialData=response;

            this.newmaterial.title=this.materialData.title;
            this.newmaterial.contenido=this.materialData.contenido;
            this.newmaterial.bloque=this.materialData.actividad.idMateriaBloqueNavigation;
            this.newmaterial.videos=this.materialData.videos;
            this.newmaterial.archivos=this.materialData.archivos;

            var studentsInActivity=this.newmaterial.alumnos.filter(item=>this.materialData.actividad.actividadesMateriasBloquesParticipantes.some(item2=>item2.idEstudianteGrado==item.idEstudianteGrado));
            this.newmaterial.alumnos=studentsInActivity;
        }
    },
    mounted(){
        this.show=true;
    },
    async created(){
        await this.loadAll(this.action);
    }
}
</script>
<style scoped>
.body{
    padding-top: 60px;
}
.body2{
    padding-top: 60px;
}
</style>