<template>
    <div>
        <div v-if="$router.currentRoute.name=='grados_docente'">
            <div v-if="!isLoading">
                <v-app-bar app flat color="transparent">
                    <v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn>
                </v-app-bar>
                <generic-header :text="'Mis grados'"></generic-header>
                <v-container fill-height :style="$vuetify.breakpoint.mdAndUp?'height:65vh':'height:65vh'">
                    <v-row justify="center">
                        <v-col v-for="grado in gradosData" :key="grado.idMaestroGuiaGradoSeccion" align="center" cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                            <v-card flat class="rounded-xl card-text" dark :color="'blue'" @click="()=>{}">
                                <v-container class="pa-3">
                                    <v-row justify="center" no-gutters>
                                        <h1 class="text-h5 font-weight-medium wrap-text">{{getNombreGrado(grado)}}</h1>
                                    </v-row>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle class="wrap-text text-subtitle-2">
                                                {{getNombreNivelCarrera(grado)}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <transition name="scroll-y-transition" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>
<script>
import GenericHeader from '../../../globalComponents/GenericHeader.vue';
export default {
  components: { GenericHeader },
  data:function(){
      return ({
          isLoading:false,
          materia:"",
          gradosData:[],
          unsubscribe:""
      });
  },
    methods:{
        getNombreNivelCarrera(grado){
            if(grado.idGradoSeccionNavigation)
                if(grado.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation)
                    return `${grado.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation.nombre}`
                else
                    return `${grado.idGradoSeccionNavigation.idGradoNavigation.idNivelEstudiantilNavigation.nombre}`
            return "";
        },
        getNombreGrado(grado){
            if(grado.idGradoSeccionNavigation)
                return `${grado.idGradoSeccionNavigation.idGradoNavigation.nombre} ${grado.idGradoSeccionNavigation.idSeccionNavigation.seccion}`;
            return "";
        },
        gotoClassGrade(asignacion){
            asignacion;
            //this.$router.push({name:"escritorio_docente",params:{idassignment:asignacion.idGradoSeccionMateriaColaborador}});
        },
        loadGradosACargo(){return this.$axios.get(`api/docentes/docentes/grados-encargados`);},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadGradosACargo()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.gradosData=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.loadAll();
    },
}
</script>
<style scoped>

</style>;