<template>
    <v-col class="pa-0">
        <v-col cols="12" class="pa-0">
            <v-progress-linear v-if="isLoading" indeterminate :color="$store.state.accentColor2"></v-progress-linear>
            <v-row no-gutters>
                <v-btn icon @click="$router.go(-1)" large><v-icon>clear</v-icon></v-btn>
            </v-row>
            <v-row class="ma-0" no-gutters>
                <v-col cols="12" sm="8" md="6" align="start">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6">
                                {{getNombreEstudiante}}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{getStatusEntrega}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col align="end" cols="12" sm="4" md="6">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-subtitle-1 font-weight-medium">
                                {{getCalificacion}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <div v-if="isVisibleEntregables">
                <br>
                <v-divider></v-divider>
                <br>
                <v-row class="text-subtitle-1 ma-0 font-weight-bold">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-col cols="12" class="pa-0">
                                    Entregables:
                                </v-col>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-row>
                <v-row class="ma-0"  v-if="entregaData.contenido">
                    <v-list-item style="overflow-x:auto;width:500px">
                        <v-list-item-content>
                            <v-list-item-title class="wrap-text text-body-1 text-justify ma-0 pa-0" 
                                    v-html="linkfy(entregaData.contenido,{
                                            target: {
                                                url: '_blank'
                                                }
                                            }
                                    )">
                                {{entregaData.contenido}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-row>
                <v-row no-gutters v-if="entregaData.videos.length>0">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-col class="pa-0" cols="12" v-for="(video,index) in entregaData.videos" :key="index">
                                    <video-card :videoInfo="video" :action="'get'" @onclickvideo="$emit('onclickvideo',video)"></video-card>
                                </v-col>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-row>
                <v-row no-gutters  v-if="entregaData.archivos.length>0">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-col class="pa-0" cols="12" v-for="(file,index) in entregaData.archivos" :key="index">
                                    <file-card :file="file" :publico="true"></file-card>
                                </v-col>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-row>
            </div>
            <v-divider></v-divider>
            <v-row no-gutters>
                <v-col>
                    <comments-privados :isMaestro="true" :loading="isLoading" @onupdateComments="onupdateComments" :from="'examen-final'" :actividad="entregaData" :comentarios="entregaData.comentarios"></comments-privados>
                </v-col>
            </v-row>
        </v-col>
    </v-col>
</template>
<script>
import linkfy from 'linkifyjs/lib/linkify-string';
import VideoCard from '../../common/VideoCard.vue';
import CommentsPrivados from '../CommentsPrivados.vue';
import FileCard from '../../../../colaborador/escritorio/common/FileCard.vue';

export default {
    props:[
        "selected",
        "refreshCall"
    ],
    watch:{
        selected:function(newval,oldval){
            oldval;
            if(this.entregaData.participante.idActividadMateriaBloqueParticipante!=newval.idActividadMateriaBloqueParticipante)
                this.loadAll();
        },
        refreshCall(newval,oldval){
            newval;
            oldval;
            this.loadAll();
        }
    },
    components:{VideoCard,CommentsPrivados,FileCard},
    computed:{
        isVisibleEntregables(){
            if(this.entregaData){
                if(this.entregaData.participante.idEstadoActividad==2)
                    return true;
                else if(this.entregaData.participante.actividadesMateriasBloquesEntregas)
                    if(this.entregaData.participante.actividadesMateriasBloquesEntregas.estudianteEntrega)
                        return true
            }
            return false;
        },
        getMaxPunteo(){
            if(this.tareaData){
                return this.tareaData.actividad.valorNeto;
            }
            return 0;
        },
        getNombreEstudiante(){
            if(this.entregaData.estudiante){
                return `${this.entregaData.estudiante.nombre1} ${this.entregaData.estudiante.nombre2} ${this.entregaData.estudiante.apellido1} ${this.entregaData.estudiante.apellido2}`;
            }

            return "Cargando datos...";
        },
        getStatusEntrega(){
            if(this.entregaData){
                switch(this.entregaData.participante.idEstadoActividad){
                    case 1:
                        return "Pendiente entregar";
                    case 2:
                        return "Entregada";
                    case 3:
                        return "Calificada";
                    case 4:
                        return "Anulada";
                    case 5:
                        return "Rechazada";
                }
            }
            return "Cargando...";
        },
        getCalificacion(){
            if(this.entregaData){
                switch(this.entregaData.participante.idEstadoActividad){
                    case 1:
                        if(this.entregaData.participante.actividadesMateriasBloquesEntregas){
                            if(this.entregaData.participante.actividadesMateriasBloquesEntregas.borradorCalificacion){
                                return `${this.entregaData.participante.actividadesMateriasBloquesEntregas.borradorCalificacion}/${this.getMaxPunteo} (borrador)`;
                            }
                        }

                        return "Sin calificación";
                    case 2:
                        if(this.entregaData.participante.actividadesMateriasBloquesEntregas){
                            if(this.entregaData.participante.actividadesMateriasBloquesEntregas.borradorCalificacion){
                                return `${this.entregaData.participante.actividadesMateriasBloquesEntregas.borradorCalificacion} (borrador)`;
                            }
                        }

                        return "Sin calificación";
                    case 3:
                        return `${this.entregaData.participante.actividadesMateriasBloquesEntregas.calificacion}/${this.getMaxPunteo}`;
                    case 4:
                        if(this.entregaData.participante.actividadesMateriasBloquesEntregas){
                            if(this.entregaData.participante.actividadesMateriasBloquesEntregas.calificacion){
                                return `${this.entregaData.participante.actividadesMateriasBloquesEntregas.calificacion}/${this.getMaxPunteo}`;
                            }else{
                                return `${this.entregaData.participante.actividadesMateriasBloquesEntregas.borradorCalificacion}/${this.getMaxPunteo} (borrador)`;
                            }
                        }

                        return `Sin calificación`;
                        
                    case 5:
                        return "Rechazada";
                }
            }
            return "Cargando...";
        },
    },
    data:function(){
        return ({
            isLoading:true,
            entregaData:"",
            estudianteData:"",
            tareaData:""
        });
    },
    methods:{
        onerror(){
            this.loadAll();
        },
        onupdateComments(comments){
            this.entregaData.comentarios=comments;
        },
        linkfy,
        async loadEntrega(){return this.$axios.get(`api/docentes/actividades/examen-final/entregas/${this.$route.params.idparticipante}`);},
        async loadTarea(){return this.$axios.get(`api/docentes/actividades/examen-final/${this.$route.params.id}`);},
        async loadAll(){
            this.isLoading=true;

            await this.$axios.all([this.loadEntrega(),this.loadTarea()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.entregaData=response[0].data.data;
                        this.tareaData=response[1].data.data;

                        this.initializeEntregaData();
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        initializeEntregaData(){
            if(!this.entregaData.contenido)
                this.entregaData.contenido="";
            if(!this.entregaData.videos)
                this.entregaData.videos=[];
            if(!this.entregaData.archivos)
                this.entregaData.archivos=[];
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>

</style>