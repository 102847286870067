<template>
    <v-card outlined rounded="xl">
        <v-list-item class="card-text">
            <v-list-item-icon>
                <v-icon size="30" :color="color?color:$store.state.accentColor2">person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="text-subtitle-2">
                    {{getEstudianteNombre}}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption">
                    {{getReadableDate(avisoEstudiante.actividad.fechaCreada)}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="HasOptions">
                <v-menu offset-y>
                    <template v-slot:activator="{on:menu}">
                        <v-btn v-on="menu" icon><v-icon>more_vert</v-icon></v-btn>
                    </template>
                    <v-list class="pa-0" dense>
                        <v-list-item v-for="(option,index) in options" :key="index" @click="onselectOption(option)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{option}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item-action>
        </v-list-item>
        <div class="pa-4" v-html="onGetLinks(avisoEstudiante.contenido)">

        </div>
        <v-card-text v-if="avisoEstudiante.videos.length>0" class="card-text">
            <v-row class="pa-0 ma-0">
                <v-col class="pb-0" cols="12" xs="12" sm="6"  v-for="(video,index) in avisoEstudiante.videos" :key="index">
                    <video-card :videoInfo="video" :action="'get'" @onclickvideo="()=>{$emit('onclickvideo',video)}"></video-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text v-if="avisoEstudiante.archivos.length>0">
            <v-row class="py-0 card-text">
                <v-col cols="12" xs="12" sm="6" md="6" v-for="(file,index) in avisoEstudiante.archivos" :key="index">
                    <file-card :file="file" :publico="true"></file-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>    
</template>
<script>
import {Months} from '../../../../../utils/timeUtils';
import linkfy from 'linkifyjs/lib/linkify-string';
import * as linkify from 'linkifyjs'
import VideoCard from '../../../../colaborador/escritorio/common/VideoCard.vue'
import FileCard from '../../../../colaborador/escritorio/common/FileCard.vue';
export default {
    components:{VideoCard,FileCard},
    props:[
        "avisoEstudiante",
        "from"
    ],
    computed:{
        HasOptions(){
            if(this.from=="estudiante")
                if(this.$store.state.personIdentity.idEstudiante==this.avisoEstudiante.estudiante.IdEstudiante) return true;
                else return false;
            return true;
        },
        getEstudianteNombre(){
            if(this.avisoEstudiante)
                if(this.avisoEstudiante.estudiante)
                    return `${this.avisoEstudiante.estudiante.Nombre1} ${this.avisoEstudiante.estudiante.Nombre2} ${this.avisoEstudiante.estudiante.Apellido1} ${this.avisoEstudiante.estudiante.Apellido2}`;

            return "Nombre Apellido";
        }
    },
    data:function () {
        return ({
            options:[
                "Editar",
                "Eliminar"
            ]
        })
    },
    methods:{
        onGetLinks(contenido){
            var htmldecoder=document.createElement("div");
            htmldecoder.innerHTML=contenido;
            var clearHtml=htmldecoder.innerHTML.replace(/(&nbsp;|<([^>]+)>)/ig, "#");

            var splitterString=clearHtml.split("#");
            splitterString=splitterString.filter(item=>["http:","HTTP:","https:","HTTPS:"].some(item2=>item.includes(item2)));

            var links=[];
            splitterString.forEach(item=>{
                var splitterLINK=item.split(" ");

                var filteredLink=splitterLINK.filter(item=>linkify.test(item))
                if(filteredLink)
                    links.push(...filteredLink);
            });

            links.forEach(item=>{
                var generatedRef=linkfy(item,{
                    target: {
                        url: '_blank'
                    },
                });
                
                htmldecoder.innerHTML=htmldecoder.innerHTML.replace(item,generatedRef);
            });

            return htmldecoder.innerHTML;
        },
        linkfy,
        onselectOption(option){
            switch(option.toLowerCase()){
                case "eliminar":
                    this.$emit("ondeletePost");
                    break;
                case "editar":
                    this.$emit("oneditPost");
                    break;
            }
        },
        getFileName(name){
            if(name.length>30){
                var extensionSplitted=name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${name.substring(0,30)}....${extension}`;
            }
            return name;
        },
        getReadableDate(date){
            var datedate=new Date(date);

            var splittedTime=date.split("T")[1];
            var hourminute=splittedTime.split(".")[0];
            var time=hourminute.substring(0,(hourminute.length-1)-2);
            var month=Months[datedate.getMonth()].substring(0,3);

            return `${datedate.getDate()} ${month} ${datedate.getFullYear()} ${time}`;
        },
    },
    created(){
        if(this.from=="docente")
            this.options=this.options.filter(item=>item.toLowerCase()=="eliminar");
    }
}
</script>

<style scoped>
</style>