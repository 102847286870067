<template>
    <v-dialog fullscreen transition="scale-transition" v-model="show" persistent no-click-animation>
        <v-card tile>
            <v-app-bar fixed flat :color="$vuetify.theme.dark?'':'white'">
                <v-btn icon @click="closeDialog()"><v-icon>arrow_back</v-icon></v-btn>
                <v-toolbar-title>Detalles de parcial</v-toolbar-title>
                <template v-slot:extension>
                    <v-tabs class="tab-bar"
                        :color="$store.state.accentColor2"
                        v-model="tab"
                        centered
                        slider-size="5"
                        :icons-and-text="$vuetify.breakpoint.smAndUp"
                        :fixed-tabs="$vuetify.breakpoint.xsOnly"
                        @change="onchangeTab"
                            >
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab
                            v-for="item in tabItems"
                            :key="item.text"
                            style="text-transform:none !important"
                        >
                            {{ item.text }}
                            <v-icon v-if="$vuetify.breakpoint.smAndUp">{{item.icon}}</v-icon>
                        </v-tab>
                    </v-tabs>
                </template>
            </v-app-bar>
            <v-main>
                <v-tabs-items v-model="tab" class="tabs_items">
                    <v-tab-item key="Instrucciones">
                        <instrucciones :tab="selectedTab" @ondeleteparcial="ondeleteParcial" @onupdateparcial="onupdateParcial"></instrucciones>
                    </v-tab-item>
                    <v-tab-item key="Entregas">
                        <entregas :tab="selectedTab"></entregas>
                    </v-tab-item>
                </v-tabs-items>
            </v-main>
        </v-card>
    </v-dialog>    
</template>
<script>
import Instrucciones from './Instrucciones.vue';
import Entregas from './Entregas.vue';

//import AppBar2 from '../../../../globalComponents/AppBar2.vue';
export default {
  components: { Instrucciones,Entregas },
  //components: { AppBar2 },
    props:[
        //"tarea"
    ],
    computed:{
        getparcialTitle(){
            if(this.parcialData){
                if(this.parcialData.title.length>30)
                    return `${this.parcialData.title.substring(0,30)}...`;
                return this.parcialData.title;
            }
            return "Cargando..."
        }
    },
    data:function(){
        return ({
            show:false,
            parcialData:"",
            tabItems:[
                {text:"Instrucciones"},
                {text:"Entregas"},
            ],
            tab:null,
            selectedTab:0,
            initialRoute:0
        })
    },
    methods:{
        onchangeTab(val){
            this.selectedTab=val;
        },
        closeDialog(){
            if((window.history.length-this.initialRoute)>0){
                this.$router.go((this.initialRoute-window.history.length)-1);
            }
            else
                this.$router.go(-1);
        },
        onclose(){
            this.show=false;
            this.$router.go(-1);
        },
        ondeleteParcial(response){
            if(response[0]==1){
                this.$emit("ondeleteparcial",response);
                this.$router.go(-1);
                return;
            }
            this.$emit("ondeleteparcial",response);
        },
        onupdateParcial(parcial){
            this.$emit("onupdateparcialCall",parcial);
        },

    },
    created(){
        this.show=true;
        this.initialRoute=window.history.length;
    }
}
</script>
<style scoped>

</style>