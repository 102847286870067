<template>
    <v-dialog fullscreen transition="scale-transition" v-model="show" @click:outside="onclose">
        <video-attach-dialog :videoDialog="videoDialog" @onclose="videoDialog=false;" @onaddVideo="onaddVideo"></video-attach-dialog>
        <file-attach-dialog :fileDialog="fileDialog" @onclose="fileDialog=false;" @onaddFile="onaddFile"></file-attach-dialog>
        <v-card :disabled="isLoading==0">
            <v-app id="examen_final_docente">
                <v-app-bar flat fixed :color="$vuetify.theme.dark?'':'white'">
                    <v-btn icon @click="$router.go(-1)"><v-icon>clear</v-icon></v-btn>
                    <v-toolbar-title>
                        Examen final extra
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="enableAsign" depressed :color="$store.state.accentColor2" :dark="isDark" @click="save">
                        Asignar
                    </v-btn>
                    <v-progress-linear indeterminate bottom absolute v-if="isLoading==0" :color="$store.state.accentColor2"></v-progress-linear>
                </v-app-bar>
                <div :class="$vuetify.breakpoint.xsOnly?'body2':'body'">
                    <v-form ref="newexamenfinalForm">
                        <v-row class="ma-0 pa-0" v-if="$vuetify.breakpoint.xsOnly">
                            <v-col cols="12">
                                <v-list-item class="pa-0">
                                    <v-list-item-content>
                                        <v-text-field
                                        rounded
                                        v-model="newexamenfinal.title"
                                        :rules="newexamenfinal.titleRules"
                                        label="Título"
                                        filled
                                        :color="$store.state.accentColor2"
                                        autocomplete="off"
                                        >

                                        </v-text-field>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="pa-0">
                                    <v-list-item-content>
                                        <v-textarea
                                        rounded
                                        v-model="newexamenfinal.instructions"
                                        label="Instrucciones (opcional)"
                                        filled
                                        :color="$store.state.accentColor2"
                                        rows="4"
                                        no-resize
                                        autocomplete="off"
                                        >

                                        </v-textarea>
                                    </v-list-item-content>
                                </v-list-item>
                                <!--<v-row no-gutters>
                                    <v-checkbox label="Generar examen auto-calificable"
                                    :color="$store.state.accentColor2"
                                    ></v-checkbox>
                                </v-row>-->
                                <v-row no-gutters align="center">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{on:menu}">
                                                <v-btn text outlined :color="$store.state.accentColor2" v-on="menu">
                                                    <v-icon>attach_file</v-icon> Agregar
                                                </v-btn>
                                            </template>
                                            <v-list class="pa-0" dense>
                                                <v-list-item v-for="(opcion,index) in attachOptions" :key="index" @click="()=>{onattachOption(index)}">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{opcion.option}}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                </v-row>
                                <v-list-item class="pa-0" v-if="newexamenfinal.videos.length>0">
                                    <v-list-item-content>
                                        <v-row no-gutters class="pb-5">
                                            <v-col v-for="(video,index) in newexamenfinal.videos" :key="index" cols="12">
                                                <video-card :videoInfo="video" :action="'add'" @ondelete="ondeleteVideo"></video-card>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="pa-0" v-if="newexamenfinal.archivos.length>0||archivos.length>0">
                                    <v-list-item-content>
                                        <v-row no-gutters>
                                            <v-col v-for="(file,index) in archivos" :key="index" cols="12">
                                                <file-card :file="file" @ondelete="ondeleteFile"></file-card>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col v-for="(file,index) in newexamenfinal.archivos" :key="index" cols="12">
                                                <file-card :file="file" @ondelete="ondeletesavedFile"></file-card>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col>
                                <v-row class="pa-0 ma-0">
                                    <p class="text-subtitle-2">Para</p>
                                </v-row>
                                <v-row gutters>
                                    <v-col cols="12" xl="6" class="py-0">
                                        <v-select
                                        filled
                                        rounded
                                        :color="$store.state.accentColor2"
                                        :item-color="$store.state.accentColor2"
                                        :items="studentsData"
                                        item-value="idEstudiante"
                                        :item-text="(item)=>`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2} ${item.idEstudianteNavigation.apellido1} ${item.idEstudianteNavigation.apellido2}`"
                                        label="Estudiantes"
                                        multiple
                                        v-model="newexamenfinal.alumnos"
                                        :rules="newexamenfinal.alumnosRules"
                                        return-object
                                        >
                                            <template v-slot:selection="{item,index}">
                                                <div v-if="newexamenfinal.alumnos.length!==(studentsData.length)">
                                                    <v-chip v-if="index===0" small>
                                                        <span>{{`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2}`}}</span>
                                                    </v-chip>
                                                    <span
                                                    v-if="index === 1"
                                                    class="grey--text caption"
                                                    >
                                                    (+{{ newexamenfinal.alumnos.length - 1 }})
                                                    </span>
                                                </div>
                                                <v-chip v-if="index===(studentsData.length-1)" small>
                                                    <span>Todos</span>
                                                </v-chip>
                                            </template>
                                            <template v-slot:prepend-item>
                                                <v-list-item
                                                ripple
                                                @click="toggle"
                                                >
                                                <v-list-item-action>
                                                    <v-icon :color="'teal darken-3'">
                                                    {{icon}}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                    Seleccionar todos
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" xl="6" class="py-0">
                                        <v-select
                                        filled
                                        rounded
                                        :items="bloques"
                                        item-value="idMateriaBloque"
                                        :item-text="(item)=>`Bloque ${item.bloque}`"
                                        disabled
                                        v-model="newexamenfinal.bloque"
                                        :rules="newexamenfinal.bloqueRules">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row class="pa-0 ma-0">
                                    <p class="text-subtitle-2">Puntos</p>
                                </v-row>
                                <v-row no-gutters>
                                    <v-text-field
                                    :color="$store.state.accentColor2"
                                    type="number"
                                    filled
                                    rounded
                                    label="Puntos"
                                    autocomplete="off"
                                    v-model="actividad.valorNeto"
                                    :rules="actividad.valorNetoRules"
                                    max
                                    readonly
                                    >

                                    </v-text-field>
                                </v-row>
                                <v-row class="pa-0 ma-0">
                                    <p class="text-subtitle-2">Fecha de examen</p>
                                </v-row>
                                <v-row class="pa-0 ma-0">
                                    <v-menu
                                        ref="fechaLimite"
                                        v-model="actividad.fechaPopup"
                                        :close-on-content-click="false"
                                        :return-value.sync="actividad.fechaLimite"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :rules="actividad.fechaLimiteRules"
                                                v-model="actividad.fechaLimite"
                                                label="Fecha"
                                                prepend-inner-icon="event"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                filled
                                                rounded
                                                :color="$store.state.accentColor2"
                                                autocomplete="off"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        :color="$store.state.accentColor2"
                                        v-model="actividad.fechaLimite"
                                        no-title
                                        scrollable
                                        :min="getCurrentDate"
                                        @input="actividad.fechaPopup;$refs.fechaLimite.save(actividad.fechaLimite);"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="fill-height ma-0 pa-0" v-if="$vuetify.breakpoint.smAndUp">
                            <v-col cols="12" sm="8" md="9" lg="9" xl="10">
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon>assignment</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-text-field
                                        rounded
                                        v-model="newexamenfinal.title"
                                        :rules="newexamenfinal.titleRules"
                                        label="Título"
                                        filled
                                        :color="$store.state.accentColor2"
                                        autocomplete="off"
                                        counter="100"
                                        maxlength="100"
                                        >

                                        </v-text-field>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon>subject</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-textarea
                                        rounded
                                        v-model="newexamenfinal.instructions"
                                        label="Instrucciones (opcional)"
                                        filled
                                        :color="$store.state.accentColor2"
                                        rows="4"
                                        no-resize
                                        autocomplete="off"
                                        />
                                    </v-list-item-content>
                                </v-list-item>
                                <!--<v-list-item v-if="!examenfinalData.examen">
                                    <v-list-item-icon>

                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-checkbox
                                            v-model="actividad.examenGenerado"
                                            label="Generar examen auto-calificado"
                                            :color="$store.state.accentColor2"
                                            ></v-checkbox>
                                    </v-list-item-content>
                                </v-list-item>-->
                                <v-list-item v-if="examenfinalData.examen">
                                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                        
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-subtitle-2 font-weight-bold">
                                            Examen
                                        </v-list-item-title>
                                        <br>
                                        <v-row no-gutters class="pb-5">
                                            <v-col cols="12" md="6">
                                                <v-card outlined>
                                                    <v-row no-gutters align="center">
                                                        <v-card-title class="text-subtitle-1 font-weight-medium">
                                                            Examen auto-calificado
                                                        </v-card-title>
                                                        <v-spacer></v-spacer>
                                                        <div class="pa-3">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{on}">
                                                                    <v-btn v-on="on" icon :color="$store.state.accentColor2"><v-icon>edit</v-icon></v-btn>
                                                                </template>
                                                                <span>
                                                                    Editar exámen
                                                                </span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{on}">
                                                                    <v-btn v-on="on" icon :color="'red'"><v-icon>clear</v-icon></v-btn>
                                                                </template>
                                                                <span>
                                                                    Eliminar exámen
                                                                </span>
                                                            </v-tooltip>
                                                        </div>
                                                    </v-row>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>

                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <div>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{on:menu}">
                                                    <v-btn  text outlined :color="$store.state.accentColor2" v-on="menu">
                                                        <v-icon >attach_file</v-icon> Agregar
                                                    </v-btn>
                                                </template>
                                                <v-list class="pa-0" dense>
                                                    <v-list-item v-for="(opcion,index) in attachOptions" :key="index" @click="()=>{onattachOption(index)}">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{opcion.option}}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>

                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-row no-gutters class="pb-5">
                                            <v-col v-for="(video,index) in newexamenfinal.videos" :key="index" cols="12">
                                                <video-card :videoInfo="video" :action="'add'" @ondelete="ondeleteVideo(video)"></video-card>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>

                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-row no-gutters>
                                            <v-col v-for="(file,index) in archivos" :key="index" cols="12">
                                                <file-card :file="file" @ondelete="ondeleteFile"></file-card>
                                            </v-col>
                                            <v-col v-for="(file,index) in newexamenfinal.archivos" :key="index" cols="12">
                                                <file-card :file="file" @ondelete="ondeletesavedFile"></file-card>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col>
                                <v-row gutters>
                                    <p class="text-subtitle-2">Para</p>
                                </v-row>
                                <v-row gutters>
                                    <v-col cols="12" xl="6">
                                        <v-select
                                        filled
                                        rounded
                                        :color="$store.state.accentColor2"
                                        :item-color="$store.state.accentColor2"
                                        :items="studentsData"
                                        item-value="idEstudiante"
                                        :item-text="(item)=>`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2} ${item.idEstudianteNavigation.apellido1} ${item.idEstudianteNavigation.apellido2}`"
                                        label="Estudiantes"
                                        multiple
                                        v-model="newexamenfinal.alumnos"
                                        :rules="newexamenfinal.alumnosRules"
                                        return-object
                                        >
                                            <template v-slot:selection="{item,index}">
                                                <div v-if="newexamenfinal.alumnos.length!==(studentsData.length)">
                                                    <v-chip v-if="index===0" small>
                                                        <span>{{`${item.idEstudianteNavigation.nombre1} ${item.idEstudianteNavigation.nombre2}`}}</span>
                                                    </v-chip>
                                                    <span
                                                    v-if="index === 1"
                                                    class="grey--text caption"
                                                    >
                                                    (+{{ newexamenfinal.alumnos.length - 1 }})
                                                    </span>
                                                </div>
                                                <v-chip v-if="index===(studentsData.length-1)" small>
                                                    <span>Todos</span>
                                                </v-chip>
                                            </template>
                                            <template v-slot:prepend-item>
                                                <v-list-item
                                                ripple
                                                @click="toggle"
                                                >
                                                <v-list-item-action>
                                                    <v-icon :color="'teal darken-3'">
                                                    {{icon}}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                    Seleccionar todos
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" xl="6">
                                        <v-select
                                        filled
                                        rounded
                                        :items="bloques"
                                        item-value="idMateriaBloque"
                                        :item-text="(item)=>`Bloque ${item.bloque}`"
                                        disabled
                                        v-model="newexamenfinal.bloque"
                                        :rules="newexamenfinal.bloqueRules">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <p class="text-subtitle-2">Puntos</p>
                                </v-row>
                                <v-row no-gutters>
                                    <v-text-field
                                    :color="$store.state.accentColor2"
                                    type="number"
                                    filled
                                    rounded
                                    label="Puntos"
                                    autocomplete="off"
                                    v-model="actividad.valorNeto"
                                    :rules="actividad.valorNetoRules"
                                    max
                                    readonly
                                    >

                                    </v-text-field>
                                </v-row>
                                <v-row no-gutters>
                                    <p class="text-subtitle-2">Duración</p>
                                </v-row>
                                <v-row no-gutters>
                                    <v-text-field
                                    :color="$store.state.accentColor2"
                                    type="number"
                                    filled
                                    rounded
                                    label="Minutos"
                                    autocomplete="off"
                                    v-model="actividad.examenTiempoMaximo"
                                    :rules="actividad.examenTiempoMaximoRules"
                                    >

                                    </v-text-field>
                                </v-row>
                                <v-row class="pa-0 ma-0">
                                    <p class="text-subtitle-2">Fecha de examen</p>
                                </v-row>
                                <v-row class="pa-0 ma-0">
                                    <v-menu
                                        ref="fechaLimite"
                                        v-model="actividad.fechaPopup"
                                        :close-on-content-click="false"
                                        :return-value.sync="actividad.fechaLimite"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :rules="actividad.fechaLimiteRules"
                                                v-model="actividad.fechaLimite"
                                                label="Fecha"
                                                prepend-inner-icon="event"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                filled
                                                rounded
                                                :color="$store.state.accentColor2"
                                                autocomplete="off"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        :color="$store.state.accentColor2"
                                        v-model="actividad.fechaLimite"
                                        no-title
                                        scrollable
                                        :min="getCurrentDate"
                                        @input="actividad.fechaPopup;$refs.fechaLimite.save(actividad.fechaLimite);"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </v-app>
        </v-card>
    </v-dialog>
</template>
<script>
import FileAttachDialog from '../../FileAttachDialog.vue';
import VideoAttachDialog from '../../VideoAttachDialog.vue';
import VideoCard from '../../VideoCard.vue';
import FileCard from '../../FileCard.vue';
export default {
  components: { VideoAttachDialog, FileAttachDialog, VideoCard,FileCard },
    props:[
        "tipoactividad",
        "action"
    ],
    watch:{
        action:async function(newval,oldval){
            oldval;
            newval;
        },
        'actividad.valorNeto':function(newval,oldval){
            oldval;

            if((this.maxZonaRestante-newval)>this.maxZonaRestante)
                this.maxZonaRestanteshow=this.maxZonaRestante;
            else if((this.maxZonaRestante-newval)<0)
                    this.maxZonaRestanteshow=0;
                else
                    this.maxZonaRestanteshow=this.maxZonaRestante-newval;
        }
    },
    computed:{
        isDark(){
            if(this.enableAsign)
                return false;
            return true;
        },
        getCurrentDate(){
            var currentDate=new Date();
            var year=currentDate.getFullYear();
            var month=currentDate.getMonth()+1;
            var day=currentDate.getDate();

            if(month<10)
                month=`0${month}`;
            if(day<10)
                day=`0${day}`;
            return `${year}-${month}-${day}`;
        },
        enableAsign(){
            if(this.newexamenfinal.title&&this.$refs.newexamenfinalForm.validate()){
                return false;
            }

            return true;
        },
        allStudentsSelected(){
            return this.studentsData.length===this.newexamenfinal.alumnos.length;
        },
        someStudentSelected() {
            return this.newexamenfinal.alumnos.length > 0 && !this.allStudentsSelected
        },
        icon () {
            if (this.allStudentsSelected) return 'mdi-close-box'
            if (this.someStudentSelected) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    data:function(){
        return({
            examenfinalData:"",
            isLoading:false,
            show:false,
            studentsData:[],
            bloques:[],
            currentBloque:"",
            videoDialog:false,
            fileDialog:false,
            attachOptions:[
                {option:"Video YouTube",icon:"smart_display"},
                {option:"Archivos",icon:"attach_file"}
            ],
            newexamenfinal:{
                title:"",
                titleRules:[
                    v=>!!v||"Título es obligatorio",
                    v=>!/^\s+$/.test(v)||"Título no debe ser solo espacios",
                    v=>v.length<=100||"Título debe ser menor o igual a 50 caracteres"
                ],
                instructions:"",
                alumnos:[],
                alumnosRules:[
                    v=>!!v&&v.length>0||"Alumnos es requerido."
                ],
                bloque:"",
                bloqueRules:[
                    v=>!!v||"Bloque es requerido."
                ],
                archivos:[],
                videos:[],
                examenExtra:true
            },
            actividad:{
                fechaPopup:false,
                fechaLimite:"",
                fechaLimiteRules:[
                    v=>!!v||"Fecha de entrega es requerida."
                ],
                valorNeto:"30",
                valorNetoRules:[
                    v=>/\d+/.test(v)||"Puntos es requerido y debe ser numérico.",
                    v=>v>0&&v<=30||`Puntos debe ser mayor a 0 y menor o igual a 30.`
                ],
                examenTiempoMaximo:"",
                examenTiempoMaximoRules:[
                    v=>/^\d+$/.test(v)||"Minutos es requerido y debe ser numérico.",
                    v=>v>0&&v<=240||`Minutos debe ser mayor a 0 y menor o igual a 240.`
                ],
                examenGenerado:false
            },
            archivos:[],
            maxZonaRestante:0,
            maxZonaRestanteshow:0
        });
    },
    methods:{
        toggle(){
            this.$nextTick(() => {
                if (this.allStudentsSelected) {
                    this.newexamenfinal.alumnos=[]
                } else {
                    this.newexamenfinal.alumnos=this.studentsData;
                }
            })
        },
        onclose(){
            this.show=false;
            this.$router.go(-1);
            //this.show=false;
        },
        ondeletesavedFile(fileItem){
            var index=this.examenfinalData.archivos.indexOf(fileItem);
            this.examenfinalData.archivos.splice(index,1);
        },
        ondeleteFile(fileItem){
            var index=this.archivos.indexOf(fileItem);
            this.archivos.splice(index,1);
        },
        ondeleteVideo(videoItem){
            var index=this.newexamenfinal.videos.indexOf(videoItem);
            this.newexamenfinal.videos.splice(index,1);
        },
        onattachOption(index){
            if(index==0){
                this.videoDialog=true;
            }else{
                this.fileDialog=true;
            }
        },
        onaddFile(files){
            var currentAmountFiles=this.newexamenfinal.archivos.length+this.archivos.length;
            currentAmountFiles;
            if(currentAmountFiles<=5){
                if((currentAmountFiles+files.length)<=5){
                    Array.prototype.push.apply(this.archivos,files);
                    this.fileDialog=false;

                    return;
                }
            }
            this.fileDialog=false;
            alert("No se pueden agregar más de 5 archivos a la publicación.");
        },
        onaddVideo(videoInfo){
           if(!this.newexamenfinal.videos.some(item=>item.id==videoInfo.id)){
                this.newexamenfinal.videos.push(videoInfo);
                this.videoDialog=false;
            }
        },
        save(){
            if(this.action=="add"){
                this.saveCall();
            }else{
                this.updateCall();
            }
        },
        async saveCall(){
            if(this.$refs.newexamenfinalForm.validate()){
                this.isLoading=0;

                this.newexamenfinal.actividad=this.actividad;
                delete this.newexamenfinal.bloque;
                delete this.newexamenfinal.bloqueRules;
                
                var data=new FormData();

                this.archivos.forEach((item)=>{
                    data.append("archivos",item);
                });

                data.append("examenfinalString",JSON.stringify(this.newexamenfinal));

                var url_request="api/docentes/actividades/extras/examen-final/create";

                await this.$axios.put(
                    `${url_request}/?bloque=${this.currentBloque.idMateriaBloque}`,
                    data
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$emit('onsaveexamenfinal',[1,response.data.data,response.data.message]);
                            this.onclose();
                        }else{
                            this.$emit('onsaveexamenfinal',[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onsaveexamenfinal",[2,error.response.data.message]);
                        }else{
                            this.$emit("onsaveexamenfinal",[2]);
                        }
                    }
                });

                this.isLoading=1;       
            }
        },
        async updateCall(){
            if(this.$refs.newexamenfinalForm.validate()){
                this.isLoading=0;
                
                this.newexamenfinal.actividad=this.actividad;
                var data=new FormData();

                this.archivos.forEach((item)=>{
                    data.append("archivos",item);
                });

                data.append("examenfinalString",JSON.stringify(this.newexamenfinal));

                await this.$axios.put(
                    `api/docentes/actividades/extras/examen-final/${this.examenfinalData.actividad.idActividadMateriaBloque}/update`,
                    data
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$emit('onupdateexamenfinal',[1,response.data.data,response.data.message]);
                            this.onclose();
                        }else{
                            this.$emit('onupdateexamenfinal',[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onupdateexamenfinal",[2,error.response.data.message]);
                        }else{
                            this.$emit("onupdateexamenfinal",[2]);
                        }
                    }
                });

                this.isLoading=1;       
            }
        },
        loadStudents(){return this.$axios.get(`api/docentes/asignaciones/asignacion/${this.$route.params.idassignment}/estudiantes`);},
        loadBloque(){return this.$axios.get(`api/docentes/bloques/bloque/${this.$route.params.bloque}`)},
        loadExamenFinal(){return this.$axios.get(`api/docentes/actividades/examen-final/${this.$route.params.id}`);},
        async loadAll(action){
            this.isLoading=0;
            var httpCalls=[this.loadStudents(),this.loadBloque()];

            if(action=="edit")
                httpCalls=[this.loadStudents(),this.loadBloque(),this.loadExamenFinal()];

            await this.$axios.all(httpCalls).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.studentsData=response[0].data.data;
                        this.bloques=[response[1].data.data];

                        this.newexamenfinal.alumnos=this.studentsData;

                        if(action=="edit")
                            this.initializeExamenFinal(response[2].data.data);

                        if(this.bloques.length>0){
                            this.newexamenfinal.bloque=this.bloques[0];
                            this.currentBloque=this.bloques[0];
                            //this.initializeMaxPunteo();
                        }
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=1;
        },
        initializeMaxPunteo(){
            var maxZona=this.newexamenfinal.bloque.maxZona;

            var currentZona=0;

            this.newexamenfinal.bloque.actividadesMateriasBloques.forEach(item=>{

                if(item.valorNeto){
                    if(this.examenfinalData){
                        if(this.examenfinalData.actividad.idActividadMateriaBloque!=item.idActividadMateriaBloque){
                            currentZona+=item.valorNeto;
                        }
                    }
                    else{
                        currentZona+=item.valorNeto;
                    }
                }
            });

            this.maxZonaRestante=maxZona-currentZona;
            this.maxZonaRestanteshow=this.maxZonaRestante;
            

            delete this.newexamenfinal.bloque.actividadesMateriasBloques;
        },
        initializeExamenFinal(response){
            this.examenfinalData=response;

            this.newexamenfinal.title=this.examenfinalData.title;
            this.newexamenfinal.instructions=this.examenfinalData.instructions;
            this.newexamenfinal.bloque=this.examenfinalData.actividad.idMateriaBloqueNavigation;
            this.newexamenfinal.videos=this.examenfinalData.videos;
            this.newexamenfinal.archivos=this.examenfinalData.archivos;

            var studentsInActivity=this.newexamenfinal.alumnos.filter(item=>this.examenfinalData.actividad.actividadesMateriasBloquesParticipantes.some(item2=>item2.idEstudianteGrado==item.idEstudianteGrado));
            this.newexamenfinal.alumnos=studentsInActivity;

            var fechaHoraSplitted=this.examenfinalData.actividad.fechaLimite.split("T");
            var fecha=fechaHoraSplitted[0];
            
            this.actividad.fechaLimite=fecha;
            this.actividad.examenTiempoMaximo=this.examenfinalData.actividad.examenTiempoMaximo;
            this.actividad.examenGenerado=this.examenfinalData.actividad.examenGenerado;
            this.actividad.valorNeto=this.examenfinalData.actividad.valorNeto;
        }
    },
    mounted(){
        this.show=true;
    },
    async created(){
        await this.loadAll(this.action);
    }
}
</script>
<style scoped>
.body{
    padding-top: 60px;
}
.body2{
    padding-top: 60px;
}
</style>