<template>
    <v-dialog fullscreen persistent v-model="dialog">
        <pagos-estudiante :pagosEstudiante="pagosestudianteDialog" @onclose="pagosestudianteDialog=false;" 
        :estudianteGrado="selectedAlumno.idEstudianteGrado" :estudiante="selectedAlumno.idEstudiante"
        ></pagos-estudiante>

        <v-card>
            <v-app>
                <app-bar-2 name="Pendientes de pagos"></app-bar-2>
                <v-container class="body">
                    <v-row no-gutters justify="center">
                        <v-list>
                            <v-list-item  v-for="alumno in alumnosInsolventes" :key="alumno.idEstudianteGrado">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{getNombreAlumno(alumno)}}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-tooltip left>
                                        <template v-slot:activator="{on}">
                                            <v-btn v-on="on" icon :color="'primary'" @click="()=>{onSelectAlumno(alumno)}">
                                                <v-icon>fact_check</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver pagos</span>
                                    </v-tooltip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-row>
                </v-container>
            </v-app>
        </v-card>
    </v-dialog>    
</template>
<script>
import AppBar2 from '../../globalComponents/AppBar2.vue';
import PagosEstudiante from '../estudiantes/PagosEstudiante.vue';

export default {
    components:{AppBar2,PagosEstudiante},
    props:["dialog"],
    computed:{

    },
    data:function(){
        return ({
            alumnosInsolventes:[],
            pagosestudianteDialog:false,
            selectedAlumno:""
        });
    },
    methods:{
        onSelectAlumno(alumno){
            this.selectedAlumno=alumno;
            this.pagosestudianteDialog=true;
        },
        getNombreAlumno(alumno){
            if(alumno)
                return `${alumno.nombre1} ${alumno.nombre2} ${alumno.apellido1} ${alumno.apellido2}`;
            return "";
        },
        getAlumnosInsolventes(){return this.$axios.get(`api/estudiantes/${this.$store.state.cicloEscolar.idCicloEscolar}/pendientes-pagos`)},
        async loadAll(){
            this.$store.commit("setLoading",true);

            await this.$axios.all([this.getAlumnosInsolventes()]).then((response)=>{
                if(response[0].status==200)
                    if(response[0].data.code==1)
                        this.alumnosInsolventes=response[0].data.data;
            }).catch((error)=>{
                console.log(error);
            });

            this.$store.commit("setLoading",false);
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>
.body{
    padding-top: 60px;
}
</style>