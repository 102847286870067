<template>
    <v-snackbar v-model="show" :timeout="time" :color="color">
        {{text}}
    </v-snackbar>
</template>
<script>
export default {
    data:()=>({
        show:false,
        time:3000,
        color:"",
        text:"",
        unsubscribe:""
    }),
    methods:{
        
    },
    created(){
        this.unsubscribe=this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'showMessage') {
                this.text = state.notifications1.text;
                this.color = state.notifications1.color;
                this.time=state.notifications1.time;
                this.show = true;
            }
        });
    },
    beforeDestroy(){
        this.unsubscribe();
    }
}
</script>
<style scoped>

</style>