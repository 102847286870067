<template>
    <v-tooltip right>
        <template v-slot:activator="{on:tooltip}">
            <v-menu rounded="lg" offset-y transition="scale-transition" auto :close-on-content-click="false" v-model="menuPopup">
                <template v-slot:activator="{on:menu}">
                    <v-btn icon v-on="{...tooltip,...menu}">
                        <v-badge bordered color="red" :content="$store.state.pendientesData.length" :value="$store.state.pendientesData.length">
                            <v-icon :color="$store.state.accentColor2">notifications</v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-list class="pa-0">
                    <v-card :width="tareasPendientesWidth" :disabled="$store.state.isLoadingPendientes" :loading="$store.state.isLoadingPendientes">
                        <v-card-title class="pa-0 ma-0" style="position:sticky;top:0px;background:white;z-index:3">
                            <v-row class="text-subtitle-1 font-weight-medium" no-gutters justify="center">Actividades pendientes</v-row>
                        </v-card-title>
                        <v-divider></v-divider>
                        <!--<div v-if="$store.state.isLoadingPendientes">
                            <br>
                            <v-row no-gutters justify="center" class="mt-1">Cargando...</v-row>
                            <br>
                        </div>-->
                        <div>
                            <div v-if="$store.state.pendientesData.length==0">
                                <br>
                                <v-row no-gutters class="text-subtitle-2 grey--text" justify="center">No tienes ninguna actividad pendiente</v-row>
                                <br>
                            </div>
                            <div v-if="$store.state.pendientesData.length>0">
                                <div v-for="(pendiente,index) in $store.state.pendientesData" :key="index">
                                    <v-list-item @click="()=>{ondetailsActividad(pendiente)}" dense>
                                        <v-list-item-icon>
                                            <v-icon>{{pendiente.actividad.tipoActividadNavigation.icono}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{getMaxText(pendiente.title)}}</v-list-item-title>
                                            <v-list-item-subtitle class="text-subtitle-2">{{pendiente.materia}}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="text-caption">{{getFechaActividad(pendiente.actividad)}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-list>
            </v-menu>
        </template>
        <span>Actividades pendientes</span>
    </v-tooltip>    
</template>
<script>
import GetReadableDate from '../../../utils/timeUtils';
import {CallGetNotifications} from '../../../utils/notificationWorker';

export default {
    props:[
    ],
    watch:{
        menuPopup(newval,oldval){
            oldval;
            if(newval)
                CallGetNotifications();
        }
    },
    computed:{
        tareasPendientesWidth(){
            if(this.$vuetify.breakpoint.xsOnly)
                return 320;
            return 400;
        },
    },
    data:function () {
        return ({
            pendientesActividades:[],
            isLoading:this.$store.state.accentColor2,
            unsubscribePendientes:"",
            menuPopup:false
        });
    },
    methods:{
        ondetailsActividad(actividad){
            switch(actividad.actividad.tipoActividadNavigation.nombre){
                case "Tarea":
                    this.$router.push({name:"tarea_estudiante_pendiente",params:{idtarea:actividad.actividad.idActividadMateriaBloque}});
                    break;
                case "Tarea extra":
                    this.$router.push({name:"tarea_extra_estudiante_pendiente",params:{idtarea:actividad.actividad.idActividadMateriaBloque}});
                    break;
                case "Parcial":
                    this.$router.push({name:"parcial_estudiante_pendiente",params:{idparcial:actividad.actividad.idActividadMateriaBloque}});
                    break;
                case "Parcial extra":
                    this.$router.push({name:"parcial_extra_estudiante_pendiente",params:{idparcial:actividad.actividad.idActividadMateriaBloque}});
                    break;
                case "Examen final":
                    this.$router.push({name:"examen_final_estudiante_pendiente",params:{idexamenfinal:actividad.actividad.idActividadMateriaBloque}});
                    break;
                case "Examen final extra":
                    this.$router.push({name:"examen_final_extra_estudiante_pendiente",params:{idexamenfinal:actividad.actividad.idActividadMateriaBloque}});
                    break;
            }
        },
        getFechaActividad(actividad){
            var humanDate=this.getHumanDate(actividad.fechaLimite);
            if([2,8].some(item=>item==actividad.tipoActividad)){
                humanDate=humanDate.substring(0,humanDate.length-5);
                return `Fecha de exámen: ${humanDate}`;
            }else if([1,9].some(item=>item==actividad.tipoActividad)){
                humanDate=humanDate.substring(0,humanDate.length-5);
                return `Fecha de parcial: ${humanDate}`;
            }else
                return `Fecha de entrega: ${humanDate}`
        },
        getMaxText(text){
            if(text.length>30)
                return `${text.substring(0,30)}...`;
            return text;
        },
        getHumanDate: GetReadableDate,
    },
    created(){
    },
}
</script>
<style scoped>

</style>