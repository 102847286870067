<template>
    <v-dialog v-model="materiaDialog" persistent max-width="400">
        <v-card :loading="isLoading" :disabled="isLoading" outlined>
            <v-card-title>
                {{title}}
            </v-card-title>
            <v-card-text>
                <v-row no-gutters v-if="subtitle" justify="center"><h1 class="text-subtitle-1">{{subtitle}}</h1></v-row>
                <br>
                <v-form ref="materiaForm">
                    <v-text-field 
                    label="Nombre de materia" 
                    outlined rounded
                    counter
                    maxlength="50"
                    v-model="materiaForm.nombre"
                    :rules="materiaForm.nombreRules"
                    :color="$store.state.accentColor"
                    autocomplete="off"
                    >

                    </v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose');$refs.materiaForm.reset();">Cancelar</v-btn>
                <v-btn text color="green" @click="save">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:[
        "materiaDialog",
        "action",
        "materiaToEdit",
        "carrera"
    ],
    watch:{
        materiaDialog:function(newVal,oldVal){
            oldVal;
            if(newVal){
                if(this.carrera){
                    this.subtitle=this.carrera.idCarreraNavigation.nombre;
                }

                if(this.action=="add"){
                    this.title="Nueva materia";
                    
                }else{
                    this.materiaForm.idMateria=this.materiaToEdit.idMateria;
                    this.materiaForm.activo=this.materiaToEdit.activo;
                    this.materiaForm.nombre=this.materiaToEdit.nombre;
                    this.title="Edición de materia";
                }
            }
        }
    },
    data:()=>({
        title:"",
        subtitle:null,
        isLoading:false,
        materiaForm:{
            nombre:"",
            nombreRules:[
                v => !!v || 'Nombre es requerido.',
                v => ( v &&v.length <= 50) || 'Nombre debe ser de tamaño 50 o menos.',
                //v=>/^[(\w)|(\s)]*$/.test(v)||'Nombre debe ser alfanumérico.',
                v=>!/^\s+$/.test(v)||'Nombre no debe ser un espacio en blanco.' 
            ]
        }
    }),
    methods:{
        async save(){
            this.isLoading=this.$store.state.accentColor;
            if(this.action=="add"){
                await this.saveCall();
            }else{
                await this.updateCall();
            }
            this.isLoading=false;            
        },
        async saveCall(){
            if(this.$refs.materiaForm.validate()){
                var url="";
                if(this.carrera){
                    url=`api/materias/nivel/${this.$store.state.currentNivelEstudiantil}?carrera=${this.carrera.idCarrera}`;
                }else{
                    url=`api/materias/nivel/${this.$store.state.currentNivelEstudiantil}`;
                }

                await this.$axios.put(url,this.materiaForm).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$refs.materiaForm.reset();
                            this.$emit("onsave",[1,response.data.data,response.data.message]);
                        }else{
                            this.$emit("onsave",[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onsave",[2,error.response.data.message]);
                        }else{
                            this.$emit("onsave",[2]);
                        }
                    }
                });

                this.$emit("onclose");
            }
        },
        async updateCall(){
            if(this.$refs.materiaForm.validate()){
                var url="";
                if(this.carrera){
                    url=`api/materias/nivel/update/${this.$store.state.currentNivelEstudiantil}?carrera=${this.carrera.idCarrera}`;
                }else{
                    url=`api/materias/nivel/update/${this.$store.state.currentNivelEstudiantil}`;
                }

                await this.$axios.put(url,
                    this.materiaForm
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$refs.materiaForm.reset();
                            this.$emit("onupdate",[1,response.data.data,response.data.message]);

                            if(this.carrera){
                                this.$updateChanges(this.materiaToEdit,response.data.data.idMateriaNavigation);
                            }else{
                                this.$updateChanges(this.materiaToEdit,response.data.data);
                            }
                        }else{
                            this.$emit("onupdate",[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onupdate",[2,error.response.data.message]);
                        }else{
                            this.$emit("onupdate",[2]);
                        }
                    }
                });

                this.$emit("onclose");
            }
        }
    }
}
</script>
<style lang="stylus" scoped>

</style>