<template>
    <div>
        <v-card flat :disabled="isLoading" color="transparent" >
        <div>
            <video-player :videoplayerDialog="videoplayerDialog" @onclose="videoplayerDialog=false"
            :video="selectedVideo"
            ></video-player>
            <delete-dialog :show="deleteDialog" :content="deleteContent" :context="'publicacion'" :idEntity="getIdPublicacion" @onclose="deleteDialog=false"
            @ondelete="ondelete"
            >

            </delete-dialog>
            <v-container class="custom-container">
                <v-sheet color="transparent">
                    <v-row justify="center">
                        <v-col class="ma-0">
                            <v-card :color="$store.state.accentColor2" flat class="rounded-xl" :height="$vuetify.breakpoint.mdAndUp?'275':'250'">
                                <v-card-text class="pa-7 white--text">
                                    <v-row class="ma-0">
                                        <h1 class="text-h5 text-xs-h5 text-sm-h4 text-md-h4 text-xl-h3 font-weight-medium">{{getMateriaNombre}}</h1>
                                    </v-row>
                                    <br>
                                    <v-row class="ma-0">
                                        <v-list-item class="pa-0" dark>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-subtitle-2">
                                                    Profesor
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{getCatedraticoNombre}}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-row>
                                    <br>
                                    <v-row class="ma-0">
                                        <v-btn v-if="asignacionData.meetingLink" outlined dark  x-small rounded class="normal-text"
                                            @click="joinMeeting"
                                        >
                                            Unírse a videoconferencia
                                        </v-btn>
                                    </v-row>
                                    <br>
                                    <v-row class="ma-0 text-caption" v-if="meetPassword"><p>Passcode de zoom: <strong>{{meetPassword}}</strong></p></v-row>
                                    <v-row></v-row>
                                    <v-row no-gutters style="position:absolute;right:20px;bottom:5px;" class="text-caption">{{getCurrentBloque}}</v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-row v-if="asignacionData.inicializado">
                    <v-col cols="0" xs="0" sm="0" md="3" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-card class="rounded-xl" outlined>
                            <v-card-text>
                                <p class="text-subtitle-2 font-weight-medium">Próximas entregas:</p>
                                <div v-if="actividadesProximas.length==0">No tienes actividades próximas a entregar.</div>
                                <v-list dense class="pa-0">
                                    <div class="pa-0" v-for="proxima in actividadesProximas" :key="proxima.actividad.idActividadMateriaBloque">
                                        <v-btn class="px-1" @click="ondetailsTarea(proxima,'proximas')" small text block style="text-transform:none !important;">
                                            <v-row no-gutters justify="left">
                                                {{getFechaTitle(proxima)}}
                                            </v-row>
                                        </v-btn>
                                    </div>
                                </v-list>
                            </v-card-text>
                        </v-card>
                        <br>
                        <v-card class="rounded-xl" outlined>
                            <v-card-text>
                                <p class="text-subtitle-2 font-weight-medium">Próximos exámenes/parciales:</p>
                                <div v-if="examenesProximos.length==0">No tienes exámenes/parciales próximos a realizar.</div>
                                <v-list dense class="pa-0">
                                    <div class="pa-0" v-for="proximo in examenesProximos" :key="proximo.actividad.idActividadMateriaBloque">
                                        <v-btn class="px-1" @click="ondetailsExamen(proximo,'proximas')" small text block style="text-transform:none !important;">
                                            <v-row no-gutters justify="left">
                                                {{getFechaTitle(proximo)}}
                                            </v-row>
                                        </v-btn>
                                    </div>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="9">
                        <v-row>
                            <v-col cols="12">
                                <edit-informacion-dialog :informacionSaved="newinfoPostSaved"
                                :action="selectedAction"
                                :archivosSaved="archivosSaved"
                                :editinformacionDialog="isActiveNewPost"
                                @onchangecontentInfo="onchangecontentInfo"
                                @onchangevideosInfo="onchangevideosInfo"
                                @onchangearchivosexistInfo="onchangearchivosexistInfo"
                                @onchangearchivosInfo="onchangearchivosInfo"
                                @oncancelar="oncancelPost"
                                v-if="(isActiveNewPost&&!IsMdAndUp)||(isActiveNewPost&&IsMdAndUp&&selectedAction=='edit')"
                                :idInformacion="getIdPublicacion"
                                @onrefresh="onrefresh"
                                @oncancelPost="oncancelPost"
                                >

                                </edit-informacion-dialog>
                                <informacion
                                :newinfoPostSaved="newinfoPostSaved"
                                :archivosSaved="archivosSaved"
                                @onchangecontentInfo="onchangecontentInfo"
                                @onchangevideosInfo="onchangevideosInfo"
                                @onchangearchivosexistInfo="onchangearchivosexistInfo"
                                @onchangearchivosInfo="onchangearchivosInfo"
                                @oncancelar="oncancelPost" v-if="isActiveNewPost&&IsMdAndUp&&selectedAction=='add'" :action="'add'"
                                @onrefresh="onrefresh"
                                @oncancelPost="oncancelPost"
                                ></informacion>
                                <v-card hover class="rounded-xl card-text" ripple v-if="!isActiveNewPost||!IsMdAndUp" @click="onaddnewInformacionPost">
                                    <v-container class="fill-height" :style="'height:70px'">
                                        <v-row no-gutters justify="center">
                                            <v-col align="center">
                                                <h1 class="text-h6"><v-icon>add</v-icon> Publica en la clase</h1>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-col>
                            <v-col v-for="(actividad) in actividadesData" :key="getUniqueID(actividad)" cols="12">
                                <aviso-card :color="$store.state.accentColor2" :from="'estudiante'" v-if="onlyAviso(actividad)" :actividad="actividad" @onclickvideo="(video)=>{videoplayerDialog=true;selectedVideo=video;}"
                                    ></aviso-card>
                                <tarea-card v-if="onlyTarea(actividad)" :entregaTarea="actividad"
                                @ondetails="()=>{ondetailsTarea(actividad)}"
                                ></tarea-card>
                                <material-card v-if="onlyMaterial(actividad)" :material="actividad"
                                @ondetails="()=>{ondetailsMaterial(actividad)}"
                                ></material-card>
                                <tarea-extra-card v-if="onlyTareaExtra(actividad)" :entregaTarea="actividad"
                                @ondetails="()=>{ondetailsTareaExtra(actividad)}"></tarea-extra-card>
                                <examen-final-card v-if="onlyExamenfinal(actividad)" :entregaExamenfinal="actividad"
                                @ondetails="()=>{ondetailsExamenfinal(actividad)}"
                                ></examen-final-card>
                                <parcial-card
                                v-if="onlyParcial(actividad)" :entregaParcial="actividad"
                                @ondetails="()=>{ondetailsParcial(actividad)}"
                                >
                                </parcial-card>
                                <parcial-extra-card
                                v-if="onlyParcialExtra(actividad)" :entregaParcial="actividad"
                                @ondetails="()=>{ondetailsParcialExtra(actividad)}"
                                >

                                </parcial-extra-card>
                                <examen-final-extra-card
                                v-if="onlyExamenfinalExtra(actividad)" :entregaExamenfinal="actividad"
                                @ondetails="()=>{ondetailsExamenfinalExtra(actividad)}">

                                </examen-final-extra-card>
                                <informacion-card v-if="onlyAvisoEstudiante(actividad)" :avisoEstudiante="actividad"
                                @ondeletePost="ondeletePublicacion(actividad)"
                                @oneditPost="oneditPublicacion(actividad)"
                                :from="'estudiante'"
                                @onclickvideo="(video)=>{videoplayerDialog=true;selectedVideo=video;}"
                                ></informacion-card>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center" v-if="moreCondition">                        
                            <v-btn text rounded small @click="loadMoreActividades">Cargar más...</v-btn>
                        </v-row>
                    </v-col>
                </v-row>
                <router-view @onloadtareaerror="onloaderror" @onloadmaterialerror="onloaderror"/>
            </v-container>
        </div>
        </v-card>
    </div>    
</template>
<script>
import AvisoCard from '../../../colaborador/escritorio/common/aviso/AvisoCard';
import VideoPlayer from '../../../colaborador/escritorio/common/VideoPlayer';
import TareaCard from '../common/tarea/TareaCard.vue';
import {Days} from '../../../../utils/timeUtils.js';
import MaterialCard from '../common/material/MaterialCard.vue';
import TareaExtraCard from '../common/extra/tarea/TareaExtraCard.vue';
import ExamenFinalCard from '../common/examenFinal/ExamenFinalCard.vue';
import ParcialCard from '../common/parcial/ParcialCard.vue';
import ParcialExtraCard from '../common/extra/parcial/ParcialExtraCard.vue';
import ExamenFinalExtraCard from '../common/extra/examenFinal/ExamenFinalExtraCard.vue';
import Informacion from '../common/informacion/Informacion.vue';
import EditInformacionDialog from '../common/informacion/EditInformacionDialog.vue';
import InformacionCard from '../common/informacion/InformacionCard.vue';
import DeleteDialog from '../../../globalComponents/DeleteDialog.vue';
//import Loader from '../../../globalComponents/Loader.vue';
export default {
  components: { AvisoCard, VideoPlayer,TareaCard, MaterialCard,TareaExtraCard, ExamenFinalCard, ParcialCard, ParcialExtraCard, ExamenFinalExtraCard,Informacion, EditInformacionDialog, InformacionCard,DeleteDialog},
    props:[
        "tab"
    ],
    watch:{
        tab:function(newval,oldval){
            oldval;
            if(newval==0){
                this.currentPage=1;
                this.loadAll();
            }
        },
    },
    computed:{
        getIdPublicacion(){
            if(this.selectedActivity)
                return this.selectedActivity.actividad.idActividadMateriaBloque;
            return "";
        },
        meetPassword(){
            if(!this.asignacionData.meetingLinkString)
                return "";

            var meetpass=this.asignacionData.meetingLinkString.split("$$$$$");

            if(meetpass.length>1)
                return `${meetpass[1]}`;
            return "";
        },
        moreCondition:function(){
            if((this.actividadesData.length)%5==0&&this.actividadesData.length>0){
                return true;
            }

            return false;
        },
        getCurrentBloque(){
            if(this.currentBloque.length>0){
                return `Bloque ${this.currentBloque[0].bloque}`;
            }
            return "";
        },
        getCatedraticoNombre(){
            if(this.asignacionData){
                return this.asignacionData.idColaboradorNavigation.nombres;
            }
            return "Cargando...";
        },
        getMateriaNombre:function(){
            if(this.asignacionData){
                return this.asignacionData.idMateriaNavigation.nombre;
            }

            return "Cargando..."
        },
        getGradoNombre:function(){
            var text=``;

            if(this.asignacionData){
                text=`${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.nombre} 
                ${this.asignacionData.idGradoSeccionNavigation.idSeccionNavigation.seccion}`;

                if(this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation){
                    text+=` - ${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation.nombre}`;
                }else{
                    text+=` - ${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.idNivelEstudiantilNavigation.nombre}`;
                }
            }

            return text;
        },
        IsMdAndUp(){
            if(this.$vuetify.breakpoint.mdAndUp)
                return true;
            return false;
        }
    },
    data:function(){
        return({
            currentBloque:[],
            actividadesData:[],
            actividadesProximas:[],
            examenesProximos:[],
            isLoading:false,
            asignacionData:"",
            videoplayerDialog:false,
            deleteDialog:false,
            deleteContent:"",
            selectedActivity:"",
            selectedAction:"",
            selectedVideo:"",
            currentPage:1,
            isActiveNewPost:false,
            newinfoPostSaved:{
                content:"",
                videos:[],
                archivos:[]
            },
            archivosSaved:[],
        });
    },
    methods:{
        getUniqueID(actividad){
            if(actividad.actividad)
                if(actividad.actividad.actividad)
                    return actividad.actividad.idActividadMateriaBloque;
            if(actividad.examenFinal)
                if(actividad.examenFinal.actividad)
                    return actividad.examenFinal.actividad.idActividadMateriaBloque;
            if(actividad.tarea)
                if(actividad.tarea.actividad)
                    return actividad.tarea.actividad.idActividadMateriaBloque;
        },
        onrefresh(){
            this.loadActividadesSingle();
        },
        ondeletePublicacion(actividad){
            this.selectedActivity=actividad;
            this.deleteContent=`¿Desea eliminar esta publicación?`;
            this.deleteDialog=true;
        },
        oneditPublicacion(actividad){  
            this.newinfoPostSaved.content=actividad.contenido;
            this.newinfoPostSaved.videos=actividad.videos;
            this.newinfoPostSaved.archivos=actividad.archivos;
            this.selectedAction='edit';
            this.selectedActivity=actividad;

            this.isActiveNewPost=true;
        },
        oncancelPost(){
            this.newinfoPostSaved.content="";
            this.newinfoPostSaved.videos=[];
            this.newinfoPostSaved.archivos=[];
            this.archivosSaved=[];
            this.isActiveNewPost=false;
        },
        onchangecontentInfo(content){
            this.newinfoPostSaved.content=content;
        },
        onchangevideosInfo(videos){
            this.newinfoPostSaved.videos=[...videos];
        },
        onchangearchivosexistInfo(archivosExist){
            this.archivosSaved=[...archivosExist];
        },
        onchangearchivosInfo(archivos){
            this.newinfoPostSaved.archivos=[...archivos];
        },
        onaddnewInformacionPost(){
            this.selectedAction="add";
            this.isActiveNewPost=!this.isActiveNewPost;
        },
        onloaderror(){
            this.loadAll();
        },
        onlyAvisoEstudiante(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Aviso estudiante')
                    return true;
            return false;
        },
        onlyExamenfinalExtra(actividad){
            if(actividad.examenFinal)
                if(actividad.examenFinal.actividad.tipoActividadNavigation.nombre=='Examen final extra')
                    return true;
            return false;
        },
        onlyParcialExtra(actividad){
            if(actividad.examenFinal)
                if(actividad.examenFinal.actividad.tipoActividadNavigation.nombre=='Parcial extra')
                    return true;
            return false;
        },
        onlyParcial(actividad){
            if(actividad.examenFinal)
                if(actividad.examenFinal.actividad.tipoActividadNavigation.nombre=='Parcial')
                    return true;
            return false;
        },
        onlyExamenfinal(actividad){
            if(actividad.examenFinal)
                if(actividad.examenFinal.actividad.tipoActividadNavigation.nombre=='Examen final')
                    return true;
            return false;
        },
        onlyAviso(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Aviso')
                    return true;
            return false;
        },
        onlyTarea(actividad){
            if(actividad.tarea)
                if(actividad.tarea.actividad.tipoActividadNavigation.nombre=='Tarea')
                    return true;
            return false;
        },
        onlyTareaExtra(actividad){
            if(actividad.tarea)
                if(actividad.tarea.actividad.tipoActividadNavigation.nombre=='Tarea extra')
                    return true;
            return false;
        },
        onlyMaterial(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Material')
                    return true;
            return false;
        },
        joinMeeting(){
            if(!this.asignacionData.meetingLinkString)
                return "";
            var meetlink=this.asignacionData.meetingLinkString.split("$$$$$");

            window.open(meetlink[0]);
        },
        getFechaTitle(actividad){
            var date=new Date(actividad.actividad.fechaLimite);
            var timeUnit=actividad.actividad.fechaLimite.split("T")[1];
            var title=actividad.title;
            var day=date.getDay();

            if(day==0)
                day=7;

            if([1,2].includes(actividad.actividad.tipoActividad)){
                if(title.length>19)
                    title=`${title.substring(0,19)}...`;
                return `${Days[day-1].substring(0,3)} - ${title}`;
            }

            if(title.length>13){
                title=`${title.substring(0,13)}...`;
            }

            return `${Days[day-1].substring(0,3)} ${timeUnit.substring(0,5)} - ${title}`;
        },
        ondetailsExamenfinalExtra(actividad){
            this.$router.push({name:"examen_final_extra_estudiante_detalles",params:{idexamenfinal:actividad.examenFinal.actividad.idActividadMateriaBloque}});
        },
        ondetailsParcialExtra(actividad){
            this.$router.push({name:"parcial_extra_estudiante_detalles",params:{idparcial:actividad.examenFinal.actividad.idActividadMateriaBloque}});
        },
        ondetailsParcial(actividad){
            this.$router.push({name:"parcial_estudiante_detalles",params:{idparcial:actividad.examenFinal.actividad.idActividadMateriaBloque}});
        },
        ondetailsExamenfinal(actividad){
            this.$router.push({name:"examen_final_estudiante_detalles",params:{idexamenfinal:actividad.examenFinal.actividad.idActividadMateriaBloque}});
        },
        ondetailsExamen(actividad,from){
            switch(actividad.actividad.tipoActividad){
                case 1:
                    if(from){
                        this.$router.push({name:"parcial_estudiante_detalles",params:{idparcial:actividad.actividad.idActividadMateriaBloque}});    
                    }else{
                        this.$router.push({name:"parcial_estudiante_detalles",params:{idparcial:actividad.examenFinal.actividad.idActividadMateriaBloque}});
                    }
                    break;
                case 2:
                    if(from){
                        this.$router.push({name:"examen_final_estudiante_detalles",params:{idexamenfinal:actividad.actividad.idActividadMateriaBloque}});    
                    }else{
                        this.$router.push({name:"examen_final_estudiante_detalles",params:{idexamenfinal:actividad.examenFinal.actividad.idActividadMateriaBloque}});
                    }
                    break;
            }
        },
        ondetailsTarea(actividad,from){
            if(from){
                this.$router.push({name:"tarea_estudiante_detalles",params:{idtarea:actividad.actividad.idActividadMateriaBloque}});    
            }else{
                this.$router.push({name:"tarea_estudiante_detalles",params:{idtarea:actividad.tarea.actividad.idActividadMateriaBloque}});
            }
        },
        ondetailsTareaExtra(actividad,from){
            if(from){
                this.$router.push({name:"tarea_extra_estudiante_detalles",params:{idtarea:actividad.actividad.idActividadMateriaBloque}});    
            }else{
                this.$router.push({name:"tarea_extra_estudiante_detalles",params:{idtarea:actividad.tarea.actividad.idActividadMateriaBloque}});
            }
        },
        ondetailsMaterial(actividad){
            this.$router.push({name:"material_estudiante_detalles",params:{idmaterial:actividad.actividad.idActividadMateriaBloque}});    
        },
        ondelete(response){
            switch (response[0]) {
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:"Publicación eliminada.",color:"green",time:4000});
                    this.oncancelPost();
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.loadActividadesSingle();
        },
        loadTareasProximasCall(){
            this.loadTareasProximas().then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.actividadesProximas=response.data.data;
                    }
                }
            }).catch((error)=>{error});
        },
        async loadActividadesSingle(){
            this.$emit('isLoading2',true);
            this.isLoading=true;
            await this.$axios.all([this.loadTareasProximas(),this.loadExamenesProximos(),this.loadActividades()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200&&response[2].status==200)
                    if(response[0].data.code==1&&response[1].data.code==1&&response[2].data.code==1){
                        this.actividadesProximas=response[0].data.data;
                        this.examenesProximos=response[1].data.data;
                        this.actividadesData=response[2].data.data;
                    }
            }).catch((error)=>{
                error;
            });

            this.$emit('isLoading2',false);
            this.isLoading=false;
        },
        loadInscripcion(){return this.$axios.get(`api/estudiantil/asignaciones/asignacion/${this.$route.params.idassignment}`)},
        loadActividades(){this.currentPage=1;return this.$axios.get(`api/estudiantil/actividades?asignacion=${this.$route.params.idassignment}&page=${this.currentPage}`)},
        loadTareasProximas(){return this.$axios.get(`api/estudiantil/actividades/proximas?asignacion=${this.$route.params.idassignment}`);},
        loadExamenesProximos(){return this.$axios.get(`api/estudiantil/actividades/examenes/proximos?asignacion=${this.$route.params.idassignment}`);},
        loadCurrentBloque(){return this.$axios.get(`api/estudiantil/bloques/actual/${this.$route.params.idassignment}`)},
        loadCurrentDate(){return this.$axios.get(`api/estudiantil/currentdate`)},
        async loadMoreActividades(){
            this.isLoading=true;
            this.$emit('isLoading2',true);

            this.currentPage+=1;
            await this.$axios.get(`api/estudiantil/actividades?asignacion=${this.$route.params.idassignment}&page=${this.currentPage}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        if(response.data.data.length==0){
                            this.$store.commit("showMessage",{text:"No hay más publicaciones que cargar.",color:"",time:4000});
                        }else{
                            this.actividadesData.push(...response.data.data);
                        }
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.$emit('isLoading2',false);
            this.isLoading=false;
        },
        async loadAll(){
            this.isLoading=true;
            this.$emit('isLoading2',true);

            await this.$axios.all([this.loadInscripcion(),
            this.loadTareasProximas(),this.loadActividades(),this.loadCurrentBloque(),this.loadCurrentDate(),
            this.loadExamenesProximos()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200&&response[2].status==200
                &&response[3].status==200
                &&response[5].status==200
                ){
                    if(response[0].data.code==1&&response[1].data.code==1&&response[2].data.code==1
                    &&response[3].data.code==1
                    &&response[5].data.code==1){
                        this.$store.commit("setCurrentDate",new Date(response[4].data));
                        this.asignacionData=response[0].data.data;
                        this.actividadesProximas=response[1].data.data;
                        this.actividadesData=response[2].data.data;
                        this.currentBloque=response[3].data.data;
                        this.examenesProximos=response[5].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.$emit('isLoading2',false);
            this.isLoading=false;
        },
    },
    created(){
        this.loadAll();     
        this.$vuetify.theme.dark=false;   
    }
}
</script>
<style scoped>

</style>
