import { render, staticRenderFns } from "./Pagos.vue?vue&type=template&id=3ae54741&scoped=true"
import script from "./Pagos.vue?vue&type=script&lang=js"
export * from "./Pagos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ae54741",
  null
  
)

export default component.exports