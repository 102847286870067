<template>
    <v-dialog persistent max-width="1000" v-model="estudianteDialog" scrollable>
        <v-dialog persistent v-model="userPasswordDialog" max-width="500">
            <v-card class="rounded-xl">
                <v-card-title>
                    Usuario y contraseña
                </v-card-title>
                <v-card-text>
                    <br>
                    <v-row class="ma-0 text-subtitle-2">
                        Este es el usuario y contraseña del estudiante "{{fullNameStudent}}",
                        por favor guarde la contraseña que no la podrá ver de nuevo, solo cambiarla.
                    </v-row>
                    <v-row class="ma-0" justify="center">
                        <v-col cols="12" sm="6" align="center" v-if="userUser">
                            <div class="text-subtitle-1">Usuario</div>
                            <div class="text-h5 font-weight-medium">{{userUser}}</div>
                        </v-col>
                        <v-col cols="12" sm="6" align="center" v-if="userPassword">
                            <div  class="text-subtitle-1">Contraseña</div>
                            <div class="text-h5  font-weight-medium">{{userPassword}}</div>
                        </v-col>
                    </v-row>
                    <v-row justify="center" class="ma-0">
                        <v-checkbox label="Entendido"
                        v-model="agreed"
                        :color="$store.state.accentColor"
                        >
                        </v-checkbox>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="green" :disabled="!agreed" @click="()=>{
                            if(actionUserPassword=='add'){
                                finishSave();
                            }else{
                                finishUpdate();
                            }
                        }">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card :loading="isLoading" :disabled="isLoading" outlined>
            <v-card-title>
                {{titleText}} 
            </v-card-title>
            <v-card-text>
                * indica campo obligatorio
                <v-form ref="estudianteForm">
                    <v-row>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            rounded
                            label="Código"
                            v-model="estudiante.codigo"
                            counter
                            maxlength="25"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field
                            :color="$store.state.accentColor" 
                                autocomplete="off"
                                label="Nombre 1 *" 
                                outlined rounded
                                v-model="estudiante.nombre1"
                                :rules="estudiante.nombre1Rules"
                                counter
                                maxlength="25"
                                >
                                
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field
                            :color="$store.state.accentColor" 
                                autocomplete="off"
                                label="Nombre 2 *" 
                                outlined rounded
                                v-model="estudiante.nombre2"
                                :rules="estudiante.nombre2Rules"
                                counter
                                maxlength="25"
                                >
                                
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field
                            :color="$store.state.accentColor" 
                                autocomplete="off"
                                label="Nombre 3" 
                                outlined rounded
                                v-model="estudiante.nombre3"
                                :rules="estudiante.nombre3Rules"
                                counter
                                maxlength="25"
                                >
                                
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field label="Apellido 1 *" outlined rounded
                            autocomplete="off"
                            v-model="estudiante.apellido1"
                            :rules="estudiante.apellido1Rules"
                            counter
                            maxlength="25"
                            :color="$store.state.accentColor" 
                            >
                                
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field label="Apellido 2 *" outlined rounded
                            autocomplete="off"
                            v-model="estudiante.apellido2"
                            :rules="estudiante.apellido2Rules"
                            counter
                            maxlength="25"
                            :color="$store.state.accentColor" 
                            >
                                
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field 
                            autocomplete="off"
                                label="CUI *" 
                                outlined rounded
                                v-model="estudiante.cui"
                                :rules="estudiante.cuiRules"
                                counter
                                maxlength="25"
                                :color="$store.state.accentColor" 
                                >
                                
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-menu
                                ref="fechaNacimiento"
                                v-model="estudiante.fechaNacimientoPopup"
                                :close-on-content-click="false"
                                :return-value.sync="estudiante.fechaNacimiento"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :rules="estudiante.fechaNacimientoRules"
                                    v-model="estudiante.fechaNacimiento"
                                    label="Fecha nacimiento *"
                                    prepend-inner-icon="event"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    rounded
                                    :color="$store.state.accentColor" 
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="estudiante.fechaNacimiento"
                                no-title
                                scrollable
                                @input="estudiante.fechaNacimientoPopup=false;$refs.fechaNacimiento.save(estudiante.fechaNacimiento);"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            autocomplete="off"
                                outlined 
                                v-model="estudiante.direccion" 
                                label="Dirección" 
                                counter 
                                maxlength="100" 
                                :rules="estudiante.direccionRules"
                                :color="$store.state.accentColor" 
                                >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            v-model="estudiante.nombreMadre"
                            :rules="estudiante.nombreMadreRules"
                            counter
                            maxlength="50"
                            label="Nombre de madre *"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            v-model="estudiante.telefonoMadre"
                            :rules="estudiante.telefonoMadreRules"
                            label="Telefono de madre"
                            counter
                            maxlength="20"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            v-model="estudiante.nombrePadre"
                            :rules="estudiante.nombrePadreRules"
                            label="Nombre de padre "
                            counter
                            maxlength="50"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            v-model="estudiante.telefonoPadre"
                            :rules="estudiante.telefonoPadreRules"
                            label="Telefono de padre"
                            counter
                            maxlength="20"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            v-model="estudiante.email"
                            :rules="estudiante.emailRules"
                            label="Email responsable"
                            counter
                            maxlength="50"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-select :items="[{text:'Masculino',value:0},{text:'Femenino',value:1}]" 
                            label="Género" 
                            :color="$store.state.accentColor" 
                            outlined 
                            rounded
                            item-text="text"
                            item-value="value"
                            v-model="estudiante.sexo"
                            :rules="estudiante.sexoRules"
                            item-color=""
                            >

                            </v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            v-model="estudiante.nombreResponsable"
                            :rules="estudiante.nombreResponsableRules"
                            label="Nombre responsable *"
                            counter
                            maxlength="50"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            v-model="estudiante.dpiresponsable"
                            :rules="estudiante.dpiresponsableRules"
                            label="DPI responsable"
                            counter
                            maxlength="25"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            v-model="estudiante.nombreRecoger"
                            :rules="estudiante.nombreRecogerRules"
                            label="Nombre quién recoge *"
                            counter
                            maxlength="50"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-text-field rounded
                            :color="$store.state.accentColor" 
                            autocomplete="off"
                            outlined
                            v-model="estudiante.dpirecoger"
                            :rules="estudiante.dpirecogerRules"
                            label="DPI quién recoge"
                            counter
                            maxlength="25"
                            >

                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" justify="center">
                        <v-switch
                            :color="$store.state.accentColor"
                            v-model="estudiante.usuarioBit"
                            inset
                            :label="`¿Usuario?`"
                        ></v-switch>
                    </v-row>
                    <v-row no-gutters justify="center" v-if="estudiante.usuarioBit">
                        <div class="text-subtitle-1" v-if="isnewUser">
                            El usuario y contraseña se generarán y se mostrarán cuando se guarde correctamente el usuario.
                        </div>
                        <v-row v-if="!isnewUser" justify="center">
                            <v-col cols="12" align="center">
                                Si desea cambiar el usuario se debe cambiar el primer nombre o el primer apellido o el segundo apellido.
                                <br>
                                Nota: Si solo cambia el usuario, no se mostrará la contraseña.
                            </v-col>
                            <!--<v-col cols="12" align="center">
                                <v-btn rounded dark href="https://accounts.google.com/SignUp?service=mail&continue=https://mail.google.com/mail/?pc=topnav-about-n-en" target="_blank">                                
                                    <v-avatar size="25" class="mr-2">
                                        <v-img src="../../../assets/google.png"></v-img>
                                    </v-avatar>
                                    Crear cuenta
                                </v-btn>
                            </v-col>
                            <br>
                            <br>
                            <br>
                            <v-col cols="12" xs="12" sm="5" align="center">
                                <v-text-field label="Usuario"
                                outlined
                                rounded
                                v-model="estudiante.estudiantesUsuarios.usuario"
                                :rules="estudiante.estudiantesUsuarios.usuarioRules"
                                suffix="@gmail.com"
                                :color="$store.state.accentColor"
                                >

                                </v-text-field>
                            </v-col>-->
                            <v-col cols="12" sm="5" align="center">
                                <v-text-field label="Usuario"
                                outlined
                                rounded
                                v-model="estudiantesUsuarios.usuario"
                                :rules="estudiantesUsuarios.usuarioRules"
                                :color="$store.state.accentColor"
                                readonly
                                >

                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5"  align="center">
                                <v-text-field label="Contraseña"
                                outlined
                                rounded
                                v-model="estudiantesUsuarios.contraseniaPlain"
                                :color="$store.state.accentColor"
                                readonly
                                >

                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5"  align="center">
                                <v-btn rounded @click="passwordGenerator">Generar contraseña</v-btn>
                            </v-col>
                        </v-row>
                    </v-row>
                    <v-row class="pa-0 ma-0" justify="center">
                        <v-switch
                            :color="$store.state.accentColor"
                            v-model="estudiante.observacionesBit"
                            inset
                            :label="`¿Observaciones?`"
                        ></v-switch>
                    </v-row>
                    <v-row class="pa-0 ma-0" v-if="estudiante.observacionesBit">
                        <v-textarea
                        counter
                        maxlength="5000"
                        label="Observaciones"
                        v-model="estudiante.observaciones"
                        :rules="estudiante.observacionesRules"
                        outlined
                        rounded
                        :color="$store.state.accentColor" 
                        >

                        </v-textarea>
                    </v-row>
                </v-form>   
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text @click="()=>{
                    const form=$refs.estudianteForm;
                    form.reset();

                    $emit('onclose');
                }">Cancelar</v-btn>
                <v-btn text color="green" @click="save()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    props:[
        "estudianteDialog",
        "action",
        "estudianteToEdit"
    ],
    computed:{
        fullNameStudent(){
            return `${this.estudiante.nombre1} ${this.estudiante.nombre2} ${this.estudiante.nombre3} ${this.estudiante.apellido1} ${this.estudiante.apellido2}`;
        },
        userUser(){
            if(this.response){
                if(this.response.data.data.estudiantesUsuarios){
                    return `${this.response.data.data.estudiantesUsuarios.usuario}`;    
                }
            }
            return ``;
        },
        userPassword(){
            if(this.response){
                if(this.response.data.data.estudiantesUsuarios){
                    if(this.response.data.data.estudiantesUsuarios.contraseniaPlain){
                        return `${this.response.data.data.estudiantesUsuarios.contraseniaPlain}`;    
                    }
                }
            }
            return ``;
        },
        shouldShowUserPassDialog(){
            if(this.response){
                if(this.response.data.data.estudiantesUsuarios.contraseniaPlain){
                    return true;
                }else{
                    if(this.estudianteToEdit.estudiantesUsuarios){
                        if(this.estudianteToEdit.estudiantesUsuarios.usuario!=this.response.data.data.estudiantesUsuarios.usuario){
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        isnewUser(){
            if(this.estudiantesUsuarios.usuario){
                return false;
            }else{
                return true;
            }
        },
    },
    watch:{
        estudianteDialog:function(newVal,oldVal){
            oldVal;
            if(newVal){
                if(this.action=="edit"){
                    this.titleText="Edición de alumno";
                    this.loadStudent();
                }else{                    
                    this.titleText="Nuevo alumno";
                    delete this.estudiante.idEstudiante;
                    delete this.estudiante.fechaIngreso;
                    delete this.estudiante.estudiantesUsuarios.idEstudiante;
                    delete this.estudiante.estudiantesUsuarios.idRol;
                }
            }
        }
    },
    data:()=>({
        titleText:"Nuevo alumno",
        isLoading:false,
        userPasswordDialog:false,
        actionUserPassword:"",
        agreed:false,
        estudiante:{
            codigo:"",
            nombre1:"",
            nombre1Rules:[
                v => !!v || 'Nombre 1 es requerido.',
                v => ( v && v.length <= 25) || `Nombre 1 debe ser de tamaño ${v?v.length:null} o menos.`,
                v=>(v&&!/^\s+$/.test(v))||'Nombre 1 no debe ser un espacio en blanco.'
            ],
            nombre2:"",
            nombre2Rules:[
                v => !!v || 'Nombre 2 es requerido.',
                v => ( v && v.length <= 25) || `Nombre 2 debe ser de tamaño ${v?v.length:null} o menos.`,
                v=>(v&&!/^\s+$/.test(v))||'Nombre 2 no debe ser un espacio en blanco.'
            ],
            nombre3:"",
            nombre3Rules:[
                v => ( !v || v.length <= 25) || `Nombre 3 debe ser de tamaño ${v?v.length:null} o menos.`,
                v=>(!v||!/^\s+$/.test(v))||'Nombre 3 no debe ser un espacio en blanco.'
            ],
            apellido1:"",
            apellido1Rules:[
                v => !!v || 'Apellido 1 es requerido.',
                v => ( v &&v.length <= 50) || `Apellido 1 debe ser de tamaño ${v?v.length:null} o menos.`,
                v=>!/^\s+$/.test(v)||'Apellido 1 no debe ser un espacio en blanco.'
            ],
            apellido2:"",
            apellido2Rules:[
                v => !!v || 'Apellido 2 es requerido.',
                v => ( v &&v.length <= 50) || `Apellido 2 debe ser de tamaño ${v?v.length:null} o menos.`,
                v=>!/^\s+$/.test(v)||'Apellido 2 no debe ser un espacio en blanco.'
            ],
            cui:"",
            cuiRules:[
                v => !!v || 'CUI es requerido.',
                v => ( v &&v.length <= 25) || `CUI debe ser de tamaño ${v?v.length:null} o menos.`,
                v=>!/^\s+$/.test(v)||'CUI no debe ser un espacio en blanco.',
                v=>/^\d+$/.test(v)||'CUI debe ser numérico.'
            ],
            fechaNacimiento:"",
            fechaNacimientoPopup:false,
            fechaNacimientoRules:[
                v => !!v || 'Fecha nacimiento es requerido.',
            ],
            direccion:"",
            direccionRules:[
                v=>/^[(\w)|(\s)|(\\#)|(\-)|(\\,)|(\\.)]*$/.test(v)||'Dirección solo puede ser alfanumérico.',
                v=>(!v||v.length<=100||`Dirección debe ser de tamaño ${v?v.length:null} o menos.`),
            ],
            nombreMadre:"",
            nombreMadreRules:[
                v=>!!v||"Nombre de madre es requerido.",
                v=>(v&&v.length<=50||`Nombre de madre debe ser de tamaño ${v?v.length:null} o menos.`),
                v=>!/^\s+$/.test(v)||'Nombre de madre no debe ser espacios en blanco.'
            ],
            telefonoMadre:"",
            telefonoMadreRules:[
                v=>(/^\d*$/.test(v)||!v)||"Telefono de madre debe ser numérico.",
                v=>(!v||v.length<=20||`Telefono de madre debe ser de tamaño ${v?v.length:null} o menos.`),
            ],
            nombrePadre:"",
            nombrePadreRules:[
                v=>(!v||v.length<=50||`Nombre de padre debe ser de tamaño ${v?v.length:null} o menos.`),
                v=>(!/^\s+$/.test(v)||!v)||'Nombre de padre no debe ser espacios en blanco.'
            ],
            telefonoPadre:"",
            telefonoPadreRules:[
                v=>(/^\d*$/.test(v)||!v)||"Telefono de madre debe ser numérico.",
                v=>(!v||v.length<=20||`Telefono de madre debe ser de tamaño ${v?v.length:null} o menos.`),
            ],
            email:"",
            emailRules:[
                v=>{
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                    return (pattern.test(v)||!v) || 'Email inválido.'
                }
            ],
            sexo:"",
            sexoRules:[
                
            ],
            nombreResponsable:"",
            nombreResponsableRules:[
                v => !!v || 'Nombre responsable es requerido.',
                v => ( v && v.length <= 50) || `Nombre debe ser de tamaño ${v?v.length:null} o menos.`,
                v=>(v&&!/^\s+$/.test(v))||'Nombre no debe ser un espacio en blanco.'
            ],
            dpiresponsable:"",
            dpiresponsableRules:[
                v=>(/^\d*$/.test(v)||!v)||"DPI de responsable debe ser numérico.",
                v=>(!v||v.length<=25)
            ],
            nombreRecoger:"",
            nombreRecogerRules:[
                v => !!v || 'Nombre quién recoge es requerido.',
                v => ( v && v.length <= 50) || `Nombre debe ser de tamaño ${v?v.length:null} o menos.`,
                v=>(v&&!/^\s+$/.test(v))||'Nombre no debe ser un espacio en blanco.'
            ],
            dpirecoger:"",
            dpirecogerRules:[
                v=>(/^\d*$/.test(v)||!v)||"DPI de quién recoge debe ser numérico.",
                v=>(!v||v.length<=25)
            ],
            usuarioBit:false,
            observacionesBit:false,
            observaciones:"",
            observacionesRules:[
                v=>!!v||"Observaciones es requerido si se activa.",
                v=>(v&&v.length<=5000||`Observaciones debe ser de tamaño ${v?v.length:null} o menos.`),
                v=>(!/^\s+$/.test(v)||!v)||'Observaciones no debe ser espacios en blanco.'
            ],
            becado:false,
            estudiantesUsuarios:{
                usuario:"",
                usuarioRules:[
                    v=>!!v||"Usuario es requerido si se activa."
                ],
            }
        },
        estudiantesUsuarios:{
            usuario:"",
            usuarioRules:[
                v=>!!v||"Usuario es requerido si se activa.",
            ],
            contraseniaPlain:null
        },
        response:""
    }),
    methods:{
        userGenerator(){
            var nombre1=this.estudiante.nombre1.trim();
            var apellido1=this.estudiante.apellido1.trim();
            var apellido2=this.estudiante.apellido2.trim();

            if(nombre1&&apellido1&&apellido2){
                this.estudiante.estudiantesUsuarios.usuario=nombre1[0]+apellido1+apellido2[0];
            }
        },
        passwordGenerator(){
            var length=8;
            var password="";

            for(var counter=0;counter<length;counter++){
                var asciicode=Number.parseInt(Math.floor(Math.random()*25));
                var charCode=String.fromCharCode(asciicode+65);
                password+=charCode;
            }

            password=password.toLowerCase();

            this.estudiantesUsuarios.contraseniaPlain=password;
        },
        async save(){
            this.isLoading=this.$store.state.accentColor;

            if(this.action=="edit"){
                await this.updateCall();
            }else{
                await this.saveCall();
            }

            this.isLoading=false;
        },
        async saveCall(){
            if(this.$refs.estudianteForm.validate()){
                this.$trimAll(this.estudiante);

                await this.$axios.post("api/estudiantes",this.estudiante).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            /*this.response=response;*/
                            this.actionUserPassword="add";
                            this.response=response;

                            if(this.estudiante.usuarioBit){
                                this.userPasswordDialog=true;
                            }else{
                                this.finishSave();
                            }
                            //this.finishSave();
                        }else{
                            this.$emit("onsave",[0,response.data.message]);
                        }
                    }else{
                        this.$emit("onsave",[0,"Ha ocurrido un error y no se obtuvo la respuesta esperada."]);
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onsave",[2,error.response.data.message]);
                        }else{
                            this.$emit("onsave",[2]);
                        }
                    }
                    this.$emit("onclose");
                });
            }
        },
        finishSave(){
            this.$emit("onsave",[1,this.response.data.data,this.response.data.message]);
            this.$emit("onsavefromgrade",[1,this.response.data.data,this.response.data.message]);

            this.$refs.estudianteForm.reset();

            this.userPasswordDialog=false;
            this.agreed=false;
            this.$emit("onclose");
        },
        async updateCall(){
            if(this.$refs.estudianteForm.validate()){
                this.$trimAll(this.estudiante);

                if(this.estudiantesUsuarios.idEstudiante){
                    this.estudiante.estudiantesUsuarios=this.estudiantesUsuarios;
                    //this.estudiante.estudiantesUsuarios.idEstudiante=this.estudiante.idEstudiante;
                }            
                
                await this.$axios.put(`api/estudiantes/update/${this.estudianteToEdit.codigo}`,this.estudiante).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.response=response;
                            
                            if(this.estudiante.usuarioBit){
                                if(this.shouldShowUserPassDialog){
                                    this.userPasswordDialog=true;
                                }else{
                                    this.finishUpdate();
                                }
                            }else{
                                this.finishUpdate();
                            }
                        }else{
                            this.$emit("onupdate",[0,response.data.message]);
                        }
                    }else{
                        this.$emit("onupdate",[0,"Ha ocurrido un error y no se obtuvo la respuesta esperada."]);
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onupdate",[2,error.response.data.message]);
                        }else{
                            this.$emit("onupdate",[2]);
                        }
                    }
                    this.$emit("onclose");
                });
            }
        },
        finishUpdate(){
            this.$emit("onupdate",[1,this.response.data.data,this.response.data.message]);

            this.$refs.estudianteForm.reset();
            this.userPasswordDialog=false;
            this.agreed=false;
            this.$emit("onclose");
        },
        async loadStudent(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.get(`api/estudiantes/${this.estudianteToEdit}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        var estudentResponse=response.data.data;

                        this.estudiante.idEstudiante=estudentResponse.idEstudiante;
                        this.estudiante.codigo=estudentResponse.codigo;
                        this.estudiante.nombre1=estudentResponse.nombre1;
                        this.estudiante.nombre2=estudentResponse.nombre2;
                        this.estudiante.nombre3=estudentResponse.nombre3;
                        this.estudiante.apellido1=estudentResponse.apellido1;
                        this.estudiante.apellido2=estudentResponse.apellido2;
                        this.estudiante.cui=estudentResponse.cui;
                        this.estudiante.fechaNacimiento=estudentResponse.fechaNacimiento.split("T")[0];
                        this.estudiante.fechaIngreso=estudentResponse.fechaIngreso;
                        this.estudiante.direccion=estudentResponse.direccion;
                        this.estudiante.nombreMadre=estudentResponse.nombreMadre;
                        this.estudiante.telefonoMadre=estudentResponse.telefonoMadre;
                        this.estudiante.nombrePadre=estudentResponse.nombrePadre;
                        this.estudiante.telefonoPadre=estudentResponse.telefonoPadre;
                        this.estudiante.email=estudentResponse.email;
                        this.estudiante.sexo=estudentResponse.sexo?1:estudentResponse.sexo==false?0:null;
                        this.estudiante.nombreResponsable=estudentResponse.nombreResponsable;
                        this.estudiante.dpiresponsable=estudentResponse.dpiresponsable;
                        this.estudiante.nombreRecoger=estudentResponse.nombreRecoger;
                        this.estudiante.dpirecoger=estudentResponse.dpirecoger;
                        this.estudiante.observacionesBit=estudentResponse.observacionesBit;
                        this.estudiante.observaciones=estudentResponse.observaciones;
                        this.estudiante.activo=estudentResponse.activo;
                        this.estudiante.observacionesName=estudentResponse.observacionesName;
                        //this.estudiante.becado=this.estudianteToEdit.becado;
                        this.estudiante.usuarioBit=estudentResponse.usuarioBit;

                        if(this.estudiante.usuarioBit){
                            this.estudiantesUsuarios.idEstudiante=estudentResponse.estudiantesUsuarios.idEstudiante;
                            this.estudiantesUsuarios.usuario=estudentResponse.estudiantesUsuarios.usuario;
                            //this.estudiante.estudiantesUsuarios.contrasenia=this.estudianteToEdit.estudiantesUsuarios.contrasenia;
                            this.estudiantesUsuarios.idRol=estudentResponse.estudiantesUsuarios.idRol;
                        }else{
                            if(this.estudentResponse.estudiantesUsuarios){
                                this.estudiantesUsuarios.idEstudiante=estudentResponse.estudiantesUsuarios.idEstudiante;
                                this.estudiantesUsuarios.usuario=estudentResponse.estudiantesUsuarios.usuario;
                                //this.estudiante.estudiantesUsuarios.contrasenia=this.estudianteToEdit.estudiantesUsuarios.contrasenia;
                                this.estudiantesUsuarios.idRol=estudentResponse.estudiantesUsuarios.idRol;
                            }
                        }
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        }
    }
}
</script>
<style scoped>

</style>