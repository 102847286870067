<template>
    <v-container class="custom-container">
        <delete-dialog :show="deleteactivityDialog" :content="deletecontent" :context="deletecontext" :idEntity="getpostIdentity"
            @ondelete="ondeletePost" @onclose="deleteactivityDialog=false"
            ></delete-dialog>

        <video-player :videoplayerDialog="videoplayerDialog" @onclose="videoplayerDialog=false;"
        :video="selectedVideo"
        ></video-player>
        <v-row no-gutters justify="center">
            <v-progress-circular v-if="isLoading" indeterminate :color="$store.state.accentColor2" size="50" width="5"></v-progress-circular>
        </v-row>
        <v-row no-gutters v-if="!isLoading">
            <v-col>
                <v-list-item class="pa-0">
                    <v-list-item-avatar :color="$store.state.accentColor2" v-if="$vuetify.breakpoint.smAndUp">
                        <v-icon color="white">
                            {{getIconText}}
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="'text-h5 text-sm-h4 text-md-h4 teal--text text--darken-1 wrap-text'">
                            {{tarea.title}}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-subtitle-2 wrap-text">
                            {{getCatedraticoNombres}} - {{getFechaCreada}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-menu offset-y>
                        <template v-slot:activator="{on:menu}">
                            <v-btn v-on="menu" icon><v-icon :color="$store.state.accentColor2">more_vert</v-icon></v-btn>
                        </template>
                        <v-list class="pa-0" dense>
                            <v-list-item v-for="(option,index) in options" :key="index" @click="onselectOption(option)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{option}}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-list-item>
                <v-list-item class="pa-0">
                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-subtitle class="text-subtitle-2 font-weight-bold mb-5">
                            <br>
                            <v-row no-gutters>
                                {{getValorNeto}} puntos <v-spacer></v-spacer> Fecha de entrega: {{getFechaLimite}}
                            </v-row>
                        </v-list-item-subtitle>
                        <v-divider :color="getDividerColor"></v-divider>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                <v-list-item class="pa-0">
                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                        
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-body-2" v-html="linkfy(tarea.instructions,{
                        target: {
                            url: '_blank'
                            }
                        }
                    )">
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                <v-list-item class="pa-0" v-if="tarea.videos.length>0">
                    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                        
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-subtitle-2 font-weight-bold">
                            Videos
                        </v-list-item-title>
                        <br>
                        <v-row no-gutters class="pb-5">
                            <v-col v-for="(video,index) in tarea.videos" :key="index" cols="12">
                                <video-card :videoInfo="video" :action="'get'" @onclickvideo="playvideo(video)"></video-card>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                
                <v-list-item class="pa-0" v-if="tarea.archivos.length>0">
                    <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-subtitle-2 font-weight-bold">
                            Archivos
                        </v-list-item-title>
                        <br>
                        <v-row class="py-0 card-text">
                            <v-col cols="12" xs="12" v-for="(file,index) in tarea.archivos" :key="index">
                                <file-card :file="file" :publico="true"></file-card>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
                <v-list-item class="pa-0" v-if="tarea.hasData">
                    <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-divider></v-divider>
                        <comments-publicos @onerror="onerror" 
                            @onupdateComments="(comments)=>{onupdateComments(comments)}" 
                            :comentarios="tarea.comentarios" 
                            from="tarea" 
                            :actividad="tarea"></comments-publicos>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                    </v-list-item-action>
                </v-list-item>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import GetReadableDate from '../../../../../utils/timeUtils';
import DeleteDialog from '../../../../globalComponents/DeleteDialog';
import VideoCard from '../VideoCard.vue';
import VideoPlayer from '../VideoPlayer.vue';
import linkfy from 'linkifyjs/lib/linkify-string';
import CommentsPublicos from '../CommentsPublic.vue';
import FileCard from '../FileCard.vue'

export default {
    components:{DeleteDialog,VideoCard,VideoPlayer,CommentsPublicos,FileCard},
    props:[
        "tab",
        "isExtra"
    ],
    watch:{
        tab:function(newval,oldval){
            oldval;
            if(newval==0)
                this.loadAll();
        }
    },
    data:function(){
        return({
            options:[
                "Eliminar",
                "Editar"
            ],
            deleteactivityDialog:false,
            deletecontent:"",
            deletecontext:"",
            videoplayerDialog:false,
            selectedVideo:"",
            tarea:"",
            isLoading:true
        })
    },
    computed:{
        getDividerColor(){return this.$vuetify.theme.dark?'white':'black';},
        getIconText(){
            if(this.tarea)
                return this.tarea.actividad.tipoActividadNavigation.icono;
            return "";
        },
        getTextClass(){
            return this.$vuetify.theme.dark?'text-h4':`text-h4 teal-darken-1--text`;
        },
        getFechaCreada(){
            if(this.tarea){
                return this.getHumanDate(this.tarea.actividad.fechaCreada);
            }

            return "";
        },
        getFechaLimite(){
            if(this.tarea){
                return this.getHumanDate(this.tarea.actividad.fechaLimite);
            }

            return "";
        },
        getCatedraticoNombres(){
            if(this.tarea){
                if(this.tarea.catedratico)
                    return this.tarea.catedratico.nombres;
            }
            return "Catedrático";
        },
        getValorNeto(){
            if(this.tarea){
                return this.tarea.actividad.valorNeto;
            }
            return "";
        },
        getpostIdentity(){
            if(this.tarea){
                return this.tarea.actividad.idActividadMateriaBloque;
            }
            return "";
        }
    },
    methods:{
        onerror(){
            this.loadAll();
        },
        onupdateComments(comments){
            this.tarea.comentarios=comments;
        },
        linkfy,
        getHumanDate: GetReadableDate,
        onselectOption(option){
            if(option=="Eliminar"){
                if(this.isExtra){
                    this.deletecontext="tarea extra";
                    var tituloextra=this.tarea.title;
                    if(this.tarea.title.length>20){
                        tituloextra=`${tituloextra.substring(0,15)}...`;
                    }

                    this.deletecontent=`¿Desea eliminar la tarea extra con título "${tituloextra}"?`;
                    this.deleteactivityDialog=true;
                }else{
                    this.deletecontext="tarea";
                    var titulo=this.tarea.title;
                    if(this.tarea.title.length>20){
                        titulo=`${titulo.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar la tarea con título "${titulo}"?`;
                    this.deleteactivityDialog=true;
                }
            }else{
                if(this.isExtra)
                    this.$emit("onupdatetareaextra",this.tarea);
                else
                    this.$emit("onupdatetarea",this.tarea);
            }
        },
        ondeletePost(response){
            this.$emit('ondeletetarea',response);
        },
        getFileName(name){
            if(name.length>30){
                var extensionSplitted=name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${name.substring(0,30)}....${extension}`;
            }
            return name;
        },
        playvideo(video){
            this.selectedVideo=video;
            this.videoplayerDialog=true;
        },
        loadTarea(){return this.$axios.get(`api/docentes/actividades/tareas/${this.$route.params.id}`);},
        async loadAll(){
            this.isLoading=true;

            await this.$axios.all([this.loadTarea()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.tarea=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>

</style>