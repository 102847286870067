<template>
    <v-card outlined :ripple="actions2.some(item=>item==action)" @click="$emit('onclickvideo')">
        <v-list-item class="pa-0">
            <v-list-item-icon class="ma-0 mr-2">
                <v-img :src="videoInfo.snippet.thumbnails.default.url" contain :max-width="videoInfo.snippet.thumbnails.default.width" :max-height="videoInfo.snippet.thumbnails.default.height"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="wrap-text text-subtitle-2">
                    {{getShortVideoName(videoInfo.snippet.title)}}
                </v-list-item-title>
                <v-list-item-subtitle class="wrap-text text-caption">
                    {{getVideoDuration(videoInfo.contentDetails.duration)}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon class="delete_button" v-if="actions1.some(item=>item==action)" @click="()=>{$emit('ondelete',videoInfo)}"><v-icon>clear</v-icon></v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-card>    
</template>
<script>
export default {
    props:[
        "videoInfo",
        "action"
    ],
    data:function(){
        return ({
            actions1:[
                "add",
                "update"
            ],
            actions2:[
                "get"
            ]
        })
    },
    methods:{
        getShortVideoName(name){
            if(name.length>30){
                return `${name.substring(0,30)}...`;
            }

            return name;
        },
        getVideoDuration(durationString){
            var numbers=durationString.split("PT")[1];

            if(numbers.includes("H")){
                numbers=`${numbers.split("H")[0]} horas`;
            }else if (numbers.includes("M")){
                numbers=`${numbers.split("M")[0]} minutos`;
            }else if(numbers.includes("S")){
                numbers=`${numbers.split("S")[0]} segundos`;
            }

            return numbers;
        },
    }
}
</script>
<style scoped>

</style>