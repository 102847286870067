<template>
    <v-sheet class="pa-2" :color="'pink'" rounded="xl">
        <v-card flat class="rounded-xl card-text" ripple>
            <v-container style="height:150px" fill-height @click="gotoClassGrade(gradoMateria)">
                <v-row justify="center" no-gutters>
                    <h1 class="text-h5 font-weight-medium" align="center">{{getMateriaNombre}}</h1>
                </v-row>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon :color="'pink'">groups</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            Grado
                        </v-list-item-title>
                        <v-list-item-subtitle class="wrap-text">
                            {{getGradoNombre}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-container>
        </v-card>
    </v-sheet>    
</template>
<script>
export default {
    props:["gradoMateria"],
    computed:{
        getMateriaNombre(){
            if(this.gradoMateria){
                return this.gradoMateria.idMateriaNavigation.nombre;
            }
            return "";
        },
        getGradoNombre(){
            var text=`${this.gradoMateria.idGradoNavigation.nombre} ${this.gradoMateria.idSeccionNavigation.seccion} - ${this.gradoMateria.idGradoNavigation.idNivelEstudiantilNavigation.nombre}`;

            if(this.gradoMateria.idGradoNavigation.idCarreraNavigation){
                text=`${this.gradoMateria.idGradoNavigation.nombre} ${this.gradoMateria.idSeccionNavigation.seccion} - ${this.gradoMateria.idGradoNavigation.idCarreraNavigation.nombre}`
            }

            return text;
        },
    },
    methods:{
        gotoClassGrade(gradoMateria){
            this.$emit("ongotoClassGrade",gradoMateria.idGradoSeccionMateriaColaborador);
        }
    }
}
</script>
<style scoped>

</style>