<template>
    <v-dialog width="500" v-model="fileDialog" persistent scrollable>
        <v-card>
            <v-card-title>
                Archivos
            </v-card-title>
            <v-card-text>
                <br>
                <v-card hover width="500"
                :color="isoverArea?$store.state.accentColor2:''"
                    @drop.prevent="ondropFiles"
                    @dragenter.prevent="isoverArea=true"
                    @dragover.prevent="isoverArea=true"
                    @dragleave.prevent="isoverArea=false"
                >
                <v-file-input
                ref="filePicker"
                style="display:none"
                @change="onchangefilePicker"
                v-model="filesToUpload2"
                multiple>
                </v-file-input>
                <v-container fluid fill-height :class="isoverArea?'white--text':''"
                style="min-height:300px"
                >
                    <v-row justify="center" @click="$refs.filePicker.$refs.input.click();">
                        <v-col cols="12" align="center">
                            <h1 class="text-h6 font-weight-regular">Arrastrar y soltar o clic aquí para agregar</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" v-for="(file,index) in filesToUpload" :key="index">
                            <v-row no-gutters justify="end">
                                <v-btn icon @click="deleteFile(file)"><v-icon>clear</v-icon></v-btn>
                            </v-row>
                            <v-row justify="center"><v-icon>insert_drive_file</v-icon></v-row>
                            <v-row justify="center"><div class="text-caption" align="center">{{file.name}}</div></v-row>
                        </v-col>
                    </v-row>
                </v-container>
                </v-card>
                <br>
                <v-row no-gutters v-if="filesToUpload.length>0">
                    <v-spacer></v-spacer>
                    <v-btn small text @click="filesToUpload=[]">Quitar todos</v-btn>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose')">Cancelar</v-btn>
                <v-btn text color="green" @click="$emit('onaddFile',filesToUpload)" :disabled="filesToUpload.length==0">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    props:[
        "fileDialog"
    ],
    watch:{
        fileDialog:function(newval,oldval){
            oldval;

            if(!newval){
                this.filesToUpload=[];
            }
        }
    },
    data:function(){
        return ({
            isoverArea:false,
            filesToUpload:[],
            filesToUpload2:[]
        })
    },
    methods:{
        onchangefilePicker(val){
            var currentAmountFiles=val.length+this.filesToUpload.length;

            if(currentAmountFiles<=5){
                this.filesToUpload.push(...this.filesToUpload2);
                this.filesToUpload2=[];
                
                return;
            }

            val=[];

            alert("No se puede adjuntar más de 5 archivos.");
        },
        deleteFile(file){
            var index=this.filesToUpload.indexOf(file);

            this.filesToUpload.splice(index,1);
        },
        ondropFiles(event){
            this.isoverArea=false;
            var currentAmountFiles=event.dataTransfer.files.length+this.filesToUpload.length;
            var files=event.dataTransfer.files;

            if(currentAmountFiles<=5){
                this.filesToUpload.push(...files);
                return;
            }

            alert("No se puede adjuntar más de 5 archivos");
        }
    }
}
</script>
<style scoped>

</style>