<template>
    <v-dialog persistent v-model="estudiantedetalleDialog" width="1000" scrollable>
        <pagos-estudiante :estudiante="selectedAsignacion.idEstudiante" :estudianteGrado="selectedAsignacion.idEstudianteGrado"
        :pagosEstudiante="pagosestudianteDialog" @onclose="pagosestudianteDialog=false"></pagos-estudiante>

        <calificaciones-estudiante :estudianteGrado="selectedAsignacion.idEstudianteGrado"
        :calificacionesDialog="calificacionesDialog" @onclose="calificacionesDialog=false"
        :estudiante="selectedAsignacion.idEstudiante"></calificaciones-estudiante>

        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title>Detalles de estudiante</v-card-title>
            <v-card-text>
                <v-row no-gutters class="text-h6 font-weight-medium" justify="center">Datos personales</v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.codigo"
                        label="Código"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.nombre1"
                        label="Nombre 1"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.nombre2"
                        label="Nombre 2"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.nombre3"
                        label="Nombre 3"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.apellido1"
                        label="Apellido 1"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.apellido2"
                        label="Apellido 2"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.cui"
                        label="CUI"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="edadCalculator"
                        label="Edad"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="GetReadableDate(estudianteData.fechaNacimiento)"
                        label="Fecha de nacimiento"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="username"
                        label="Usuario"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                        no-resize
                        rows="3"
                        filled
                        rounded
                        readonly
                        :value="estudianteData.observaciones"
                        label="Observaciones"
                        :color="$store.state.accentColor"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row no-gutters class="text-h6 font-weight-medium" justify="center">Registro estudiantil</v-row>
                <v-row>
                    <v-col cols="12" class="text-subtitle-1 font-weight-medium">
                        Grados cursados
                    </v-col>
                    <v-col v-for="asignacion in estudianteData.estudiantesGrados" :key="asignacion.idEstudianteGrado" cols="12" sm="12" md="6">
                        <v-card>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-subtitle-2">
                                        {{asignacionGradoSeccion(asignacion)}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{asignacionAnio(asignacion)}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-tooltip top>
                                        <template v-slot:activator="{on}">
                                            <v-btn icon v-on="on" color="amber" @click="onclicCalificaciones(asignacion)"><v-icon>grade</v-icon></v-btn>
                                        </template>
                                        <span>Calificaciones</span>
                                    </v-tooltip>
                                </v-list-item-action>
                                <v-list-item-action>
                                    <v-tooltip top>
                                        <template v-slot:activator="{on}">
                                            <v-btn icon v-on="on" color="primary" @click="onclicPagos(asignacion)"><v-icon>fact_check</v-icon></v-btn>
                                        </template>
                                        <span>Pagos</span>
                                    </v-tooltip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
                <br>
                <v-row no-gutters class="text-h6 font-weight-medium" justify="center">Datos de contacto</v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.nombreMadre"
                        label="Nombre de la madre"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.telefonoMadre"
                        label="Teléfono de la madre"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.nombrePadre"
                        label="Nombre del padre"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.telefonoPadre"
                        label="Teléfono del padre"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.email"
                        label="Email del responsable"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="generoEstudiante"
                        label="Género"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.nombreResponsable"
                        label="Nombre del responsable"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.dpiresponsable"
                        label="DPI del responsable"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.nombreRecoger"
                        label="Nombre de quién recoge"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                        filled
                        rounded
                        readonly
                        :value="estudianteData.dpirecoger"
                        label="DPI de quién recoge"
                        :color="$store.state.accentColor"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose')">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
import GetRedeableDate from '../../../utils/timeUtils'
import CalificacionesEstudiante from './CalificacionesEstudiante.vue';
import PagosEstudiante from './PagosEstudiante.vue';
export default {
  components: { PagosEstudiante, CalificacionesEstudiante },
    props:[
        "estudiantedetalleDialog",
        "estudiante"
    ],
    watch:{
        estudiantedetalleDialog:function(newval,oldval){
            oldval;

            if(newval){
                this.loadAll();
            }
        }
    },
    computed:{
        edadCalculator(){
            var current=new Date(this.$store.state.currentRealDate);
            var fechaNacimiento=new Date(this.estudianteData.fechaNacimiento);
            return current.getFullYear()-fechaNacimiento.getFullYear();
        },
        username(){
            if(this.estudianteData.estudiantesUsuarios){
                return this.estudianteData.estudiantesUsuarios.usuario;
            }
            return "";
        },
        generoEstudiante(){
            if(this.estudianteData.sexo){
                return "Femenino";
            }else if(this.estudianteData.sexo==false){
                return "Masculino";
            }

            return "No definido";
        }
    },
    data:function(){
        return ({
            estudianteData:"",
            isLoading:false,
            pagosestudianteDialog:false,
            calificacionesDialog:false,
            selectedAsignacion:""
        });
    },
    methods:{
        onclicCalificaciones(asignacion){
            this.selectedAsignacion=asignacion;
            this.calificacionesDialog=true;
        },
        onclicPagos(asignacion){
            this.selectedAsignacion=asignacion;
            this.pagosestudianteDialog=true;
        },
        asignacionAnio(asignacion){
            if(asignacion){
                return asignacion.idCicloEscolarNavigation.cicloEscolar1;
            }

            return "";
        },
        asignacionGradoSeccion(asignacion){
            if(asignacion){
                var grado=asignacion.idGradoSeccionNavigation.idGradoNavigation.nombre;
                var seccion=asignacion.idGradoSeccionNavigation.idSeccionNavigation.seccion;
                var nivel=asignacion.idGradoSeccionNavigation.idGradoNavigation.idNivelEstudiantilNavigation.nombre;
                var carrera=asignacion.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation;

                if(carrera){
                    return `${grado} ${seccion} - ${carrera.nombre}`;
                }

                return `${grado} ${seccion} - ${nivel}`;
            }
            return "";
        },
        GetReadableDate:GetRedeableDate,
        loadStudentData(){return this.$axios.get(`api/estudiantes/${this.estudiante}`);},
        async loadAll(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.all([this.loadStudentData()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.estudianteData=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        }
    }
}
</script>
<style scoped>

</style>