<template>
    <v-card outlined class="rounded-xl card-text" ripple @click="onclickExamenFinal">
            <v-list-item>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                    <v-icon size="30" :color="$store.state.accentColor2">{{examenfinal.actividad.tipoActividadNavigation.icono}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2 wrap-text">
                        {{getExamenFinalTitle}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption">
                        {{getReadableDate(examenfinal.actividad.fechaCreada)}}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="!from">
                    <v-menu offset-y>
                        <template v-slot:activator="{on:menu}">
                            <v-btn v-on="menu" icon><v-icon>more_vert</v-icon></v-btn>
                        </template>
                        <v-list class="pa-0" dense>
                            <v-list-item v-for="(option,index) in options" :key="index" @click="onselectOption(option)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{option}}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-list-item-action>
            </v-list-item>
    </v-card>
</template>
<script>
import {Months} from '../../../../../../utils/timeUtils';
export default {
    props:[
        "examenfinal",
        "from"
    ],
    computed:{
        getExamenFinalTitle(){
            var catedraticoNombres="";

            if(this.examenfinal.catedratico)
                catedraticoNombres=this.examenfinal.catedratico.nombres;
            else
                catedraticoNombres="Catedrático";

            if(this.examenfinal.title.length>30){
                return `${catedraticoNombres} publicó un exámen final extra: ${this.examenfinal.title.substring(0,30)}...`;
            }
            return `${catedraticoNombres} publicó un exámen final extra: ${this.examenfinal.title}`;
        }
    },
    data:function(){
        return({
            options:[
                "Eliminar",
                "Editar"
            ]
        })
    },
    methods:{
        onclickExamenFinal(){
            this.$emit("ondetails");
        },
        onselectOption(option){
            if(option=="Eliminar"){
                this.$emit("ondelete");
            }else{
                this.$emit("onupdate");
            }
        },
        getReadableDate(date){
            var datedate=new Date(date);

            var splittedTime=date.split("T")[1];
            var hourminute=splittedTime.split(".")[0];
            var time=hourminute.substring(0,(hourminute.length-1)-2);
            var month=Months[datedate.getMonth()].substring(0,3);

            return `${datedate.getDate()} ${month} ${datedate.getFullYear()} ${time}`;
        }
    }
}
</script>
<style scoped>

</style>