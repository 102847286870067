<template>
    <v-app id="home_colaborador_admin">
        <loader></loader>
        <v-navigation-drawer app :color="$store.state.accentColor" :dark="!$vuetify.theme.dark" v-model="$store.state.drawerState">
            <br>
            <v-row justify="center" class="pa-0 ma-0">
                <v-avatar size="80">
                    <v-img :src="$store.state.profileInfo.fL"></v-img>
                </v-avatar>
            </v-row>
            <v-list-item two-line>
                <v-list-item-content align="center">
                    <v-list-item-title class="wrap-text">
                        {{$store.state.personIdentity.nombres}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="pb-2 wrap-text">
                        {{$store.state.profileInfo.uu}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-for="cargo in $store.state.profileInfo.cargos" :key="cargo.idCargo">
                        {{cargo.cargo}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-row no-gutters class="pa-2" v-if="$store.state.profileInfo.cargos.some(cargo=>cargo.roles.some(rol=>rol.rol.toLowerCase().includes('operador')))" @click="()=>{}">
                <v-btn small block class="normal-text" to="/colaborador/docente">Ir a área docente</v-btn>
            </v-row>
            <br>
            <v-row class="pa-0 ma-0" justify="center">
                <v-select
                item-color=""
                item-value="idCicloEscolar"
                :items="$store.state.years"
                :value="$store.state.cicloEscolar"
                filled
                rounded
                color="white"
                dense
                class="ma-2"
                label="Ciclo escolar"
                @change="(val)=>{$store.commit('changeCiclo',val)}"
                return-object
                >
                    <template slot="item" slot-scope="{item}">
                        {{item.cicloEscolar1}}
                    </template>
                    <template slot="selection" slot-scope="{item}">
                        {{item.cicloEscolar1}}
                    </template>
                </v-select>
            </v-row>
            <v-subheader>Menu</v-subheader>
            <v-list shaped>
                <div v-for="(item,index) in menuItems" :key="index">
                    <div v-if="!item.childrens">
                        <v-list-item :to="item.to" @click="()=>{onclicRoute(item)}">
                            <v-list-item-icon>
                                <v-icon>{{item.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.text}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <div v-if="item.childrens">
                        <v-list-group :prepend-icon="item.icon" color="white">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.text}}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list dense rounded>
                                <v-list-item v-for="child in item.childrens" :key="child.codigoNivelEstudiantil" :to="child.to" @click="()=>{
                                    selectedScreen=`${item.text} ${child.nombre}`;
                                    $store.commit('setCurrentNivel',child.codigoNivelEstudiantil);
                                    //selectedNivelEstudiantil=child.codigoNivelEstudiantil;
                                    }">
                                    <v-list-item-icon>
                                        
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{child.nombre}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-list-group>
                    </div>
                </div>
            </v-list>
            <v-subheader>Cuenta</v-subheader>
            <v-list shaped>
                <v-list-item link @click="()=>{
                    logoutDialog=true;
                    }">
                    <v-list-item-icon>
                        <v-icon>exit_to_app</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Cerrar sesión
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <logout-dialog :logoutDialog="logoutDialog" @onclose="logoutDialog=false;"></logout-dialog>
        <v-main>
            <transition name="scroll-y-transition" mode="out-in">
                <v-container fluid>
                    <router-view :title="selectedScreen" :nivelId="selectedNivelEstudiantil"/>
                </v-container>
            </transition>
        </v-main>
        <Snackbar />
    </v-app>
</template>
<script>
import LogoutDialog from '../../../globalComponents/LogoutDialog.vue';
import Snackbar from '../../../globalComponents/Snackbar'
import Loader from '../../../globalComponents/Loader.vue';
export default {
    components:{
        Snackbar,
        LogoutDialog,
        Loader
    },
    data:()=>({
        menuItems:[
            {text:"Inicio",to:"/colaborador/administrador/inicio",icon:"home"},
            {text:"Grados",to:"/colaborador/administrador/grados",icon:"view_module"},
            {text:"Alumnos",to:"/colaborador/administrador/alumnos",icon:"child_care"},
            {text:"Materias",/*to:"/colaborador/administrador/materias"*/childrens:[

            ],icon:"list"},
            {text:"Colaboradores",to:"/colaborador/administrador/colaboradores",icon:"face"},
            {text:"Cuotas y niveles",to:"/colaborador/administrador/niveles/cuotas",icon:"payment"}
        ],
        isLoading:false,
        selectedScreen:"",
        selectedNivelEstudiantil:"",
        years:[],
        logoutDialog:false,
    }),
    methods:{
        onclicRoute(item){
            this.selectedScreen=item.text;
        },
        loadCurrentDate(){return this.$axios.get(`api/currentdate`)},
        loadYears(){return this.$axios.get(`api/ciclos`);},
        loadNivelesEstudiantiles(){return this.$axios.get(`api/nivelesestudiantiles`);},
        loadColaboradorInfo(){return this.$axios.get(`api/colaboradores/colaborador`)},
        async loadAll(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.all([this.loadYears(),this.loadNivelesEstudiantiles(),this.loadCurrentDate(),
            this.loadColaboradorInfo()
            ]).then((response)=>{
                if(response[0].status==200&&response[1].status==200&&response[2].status==200&&response[3].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1&&response[3].data.code==1){
                        this.$store.commit("setCurrentDate",new Date(response[2].data));
                        this.$store.commit("setPersonIdentity",response[3].data.data);

                        this.initializeYears(response[0].data.data);
                        var materiasItem=this.menuItems.find(item=>item.text=="Materias");

                        materiasItem.childrens=response[1].data.data;

                        materiasItem.childrens.forEach(element=>{
                            var path="/colaborador/administrador";

                            if(element.nombre.includes("Básico")){
                                path=`${path}/basicos/materias`;
                            }else{
                                path=`${path}/${element.nombre.toLowerCase()}/materias`;
                            }
                            element.to=path;
                        });
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
        async initializeYears(data){
            this.years=data;
            this.years.sort(function(a,b){
                return a.cicloEscolar1-b.cicloEscolar1;
            });

            if(new Date().getMonth()>=7){
                this.initializeCiclo();
                this.years=this.years.filter(item=>item.cicloEscolar1<=new Date().getFullYear()+1);
            }else{
                this.years=this.years.filter(item=>item.cicloEscolar1<=new Date().getFullYear());
            }
            
            this.$store.commit("addCiclos",this.years);
            this.$store.commit("changeCiclo",this.years.find(element=>element.cicloEscolar1==new Date().getFullYear()));
        },
        async initializeCiclo(){
            this.$axios.get(`api/ciclos/inicializar`).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });
        },
        initializeDrawer(){
            if(this.$vuetify.breakpoint.lgAndUp){
                this.$store.commit("openDrawer",true);
            }else{
                this.$store.commit("openDrawer",false);
            }
        }
    },
    created(){
        this.selectedScreen=this.$route.matched[2].name;

        this.loadAll().then((response)=>{
            response;

            if(this.selectedScreen.includes("Materias")){
                var nivel=this.selectedScreen.split(" ")[1];
                var niveles=this.menuItems.find(item=>item.text=="Materias").childrens;

                var codigo=niveles.find(item=>item.nombre==nivel).codigoNivelEstudiantil;
                this.$store.commit("setCurrentNivel",codigo);
            }
        });

        this.initializeDrawer();
    }
}
</script>
<style>
.delete_button{
    position:absolute;right:0;top:0;
}
.wrap-text {
  white-space: unset !important;
}
</style>