<template>
    <v-dialog v-model="showDialog" fullscreen persistent>
        <v-card class="overlay-dialog pa-0" tile dark>
            <v-row no-gutters>
                <v-btn icon x-large dark @click="$emit('onclose')"><v-icon>arrow_back</v-icon></v-btn>
                <v-col align-self="center">
                    <h1 class="text-subtitle-1">{{getFileName}}</h1>
                </v-col>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn v-on="on" icon x-large dark :href="getDownloadUrl" target="_blank" download rel="noopener noreferrer"><v-icon>download</v-icon></v-btn>
                    </template>
                    <span>Descargar archivo</span>
                </v-tooltip>
            </v-row>
            <v-card-text class="pa-0">
                <v-container class="fill-height pa-0" style="height:90vh" fluid>
                    <v-row no-gutters justify="center" class="fill-height">
                        <v-col align="center">
                            <iframe v-if="showDialog&&!isNotImage" :src="getUrl" frameborder="0" style="width:100%; height:100%;"  ></iframe>
                            <v-img v-if="isNotImage" :src="getImageUrl" height="90vh" contain>
                                
                            </v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    props:[
        "showDialog",
        "file"
    ],
    computed:{
        getFileName:function(){
            if(this.file.name.length>30){
                var extensionSplitted=this.file.name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${this.file.name.substring(0,30)}....${extension}`;
            }
            return this.file.name;
        },
        isNotImage(){
            if(this.file){
                var extensionSplitted=this.file.name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                if(this.imageCommonExtensions.some(item=>item==extension.toLowerCase()))
                    return true;
            }

            return false;
        },
        getImageUrl(){
            if(this.file)
                if(this.file.url)
                    return `${this.file.url}`;
            return "";
        },
        getUrl(){
            if(this.file)
                if(this.file.url)
                    return `https://docs.google.com/viewer?url=${this.file.url}&embedded=true`;
            return "";
        },
        getDownloadUrl(){
            if(this.file)
                if(this.file.url)
                    return `${this.file.url}`;
            return "";
        }
    },
    data:function(){
        return({
            imageCommonExtensions:["jpg","jpeg","png","gif","tiff"]
        });
    },
    methods:{
    }
}
</script>
<style scoped>
.overlay-dialog{
    background-color: rgba(0, 0, 0, 0.8)
}
</style>