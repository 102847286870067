<template>
    <div v-if="$router.currentRoute.name=='calificaciones_generales'">
        <v-app-bar app flat :color="!$vuetify.theme.dark?'white':''">
            <v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn>
            <v-spacer></v-spacer>
        </v-app-bar>
        <generic-header :text="getTitle"></generic-header>
        <v-tabs class="tab-bar"
            :color="$store.state.accentColor2"
            centered
            slider-size="5"
            :icons-and-text="$vuetify.breakpoint.smAndUp"
            v-model="tab"
            :fixed-tabs="$vuetify.breakpoint.xsOnly">
            <v-tabs-slider></v-tabs-slider>
            <v-tab
                v-for="item in secciones"
                :key="item.idGradoSeccion"
                style="text-transform:none !important"
            >
                {{ "Sección "+item.seccion }}
                <v-icon v-if="$vuetify.breakpoint.smAndUp">{{item.icon}}</v-icon>
            </v-tab>
        </v-tabs>
        <v-container>
            <v-tabs-items v-model="tab" touchless>
                <v-tab-item v-for="seccion in secciones" :key="`#${seccion.idGradoSeccion}`">
                    <calificaciones-seccion :idgradoseccion="seccion.idGradoSeccion" :doctitle="getTitle"></calificaciones-seccion>
                </v-tab-item>
            </v-tabs-items>
        </v-container>
    </div>    
</template>
<script>
import GenericHeader from '../../globalComponents/GenericHeader.vue';
import CalificacionesSeccion from './CalificacionesSeccion.vue';
export default {
    components:{GenericHeader,CalificacionesSeccion},
    computed:{
        getTitle(){
            if(this.grado)
                return `Calificaciones ${this.grado.nombre} - ${this.$route.params.ciclo}`;
            return "";
        }
    },
    data:function(){
        return({
            grado:"",
            secciones:[],
            tab:0,
        });
    },
    methods:{
        getGradeName(){return this.$axios.get(`api/docentes/grados/${this.$route.params.idgrado}`)},
        getSections(){return this.$axios.get(`api/docentes/grados/${this.$route.params.idgrado}/secciones`)},
        async loadAll(){
            this.$store.commit("setLoading",true);
            await this.$axios.all([this.getGradeName(),this.getSections()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200)
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.grado=response[0].data.data;
                        this.secciones=response[1].data.data;

                        this.$store.commit("setSection",this.getTitle);
                    }
            }).catch((error)=>{
                console.log(error);
            });

            //this.$store.commit("setLoading",false);
        },
        
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>

</style>
