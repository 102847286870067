<template>
    <v-dialog persistent v-model="contenidoDialog" width="400">
        <v-card>
            <v-card-title>
                Entrega de texto
            </v-card-title>
            <v-card-text>
                <v-form ref="entregaTexto">
                    <v-textarea
                    :color="$store.state.accentColor2"
                    rounded
                    filled
                    rows="5"
                    label="Entrega"
                    v-model="texto"
                    :rules="textoRules"
                    >

                    </v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose')">Cancelar</v-btn>
                <v-btn text :color="$store.state.accentColor2" @click="onagregarTexto"
                :disabled="validateAgregar">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    props:[
        "contenidoDialog",
        "actiontext",
        "texttoEdit"
    ],
    watch:{
        contenidoDialog:function(newval,oldval){
            oldval;
            if(!newval)
                this.$refs.entregaTexto.reset();
            else
                if(this.actiontext=="edit")
                    this.texto=this.texttoEdit;
        }
    },
    computed:{
        validateAgregar(){
            this.texto;

            if(this.$refs.entregaTexto){
                if(this.$refs.entregaTexto.validate())
                    return false;
                else
                    return true;
            }
            return true;
        }
    },
    data:function(){
        return ({
            texto:"",
            textoRules:[
                v=>!!v||"Entrega es requerido.",
                v=>!/^\s+$/.test(v)||"Entrega no debe ser solo espacios."
            ]
        })
    },
    methods:{
        onagregarTexto(){
            this.$emit('onagregar',this.texto)
            this.$emit("onclose");
        }
    }
}
</script>
<style scoped>

</style>