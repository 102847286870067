<template>
    <v-col>
        <v-list-item>
            <v-list-item-avatar>
                <v-avatar :color="getColorBloque()" size="20">
                </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="font-weight-medium text-h6 text-xs-h6 text-sm-h5 text-md-h5 text-lg-h4 text-xl-h4">
                    Bloque {{bloque.bloque.bloque}}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption">
                    {{getInicioFinText(bloque.bloque)}}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider :color="$vuetify.theme.dark?'white':'black'"></v-divider>
        <br>
        <v-list>
            <v-list-item v-for="(actividad,index) in bloque.actividades" :key="index">
                <v-row>
                    <v-col cols="12">
                        <tarea-card v-if="onlyTarea(actividad)" :entregaTarea="actividad"
                        @ondetails="detailsTarea(actividad)"></tarea-card>
                        <material-card v-if="onlyMaterial(actividad)" :material="actividad"
                        @ondetails="detailsMaterial(actividad)"></material-card>
                        <tarea-extra-card v-if="onlyTareaExtra(actividad)" :entregaTarea="actividad"
                        @ondetails="detailsTareaExtra(actividad)"></tarea-extra-card>
                        <examen-final-card v-if="onlyExamenFinal(actividad)" :entregaExamenfinal="actividad"
                        @ondetails="detailsExamenFinal(actividad)"></examen-final-card>
                        <parcial-card
                        v-if="onlyParcial(actividad)" :entregaParcial="actividad"
                        @ondetails="detailsParcial(actividad)"
                        ></parcial-card>
                        <parcial-extra-card
                        v-if="onlyParcialExtra(actividad)" :entregaParcial="actividad"
                        @ondetails="detailsParcialExtra(actividad)"></parcial-extra-card>
                        <examen-final-extra-card
                        v-if="onlyExamenFinalExtra(actividad)" :entregaExamenfinal="actividad"
                        @ondetails="detailsExamenFinalExtra(actividad)"
                        ></examen-final-extra-card>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-list>
    </v-col>
</template>
<script>
import TareaCard from '../common/tarea/TareaCard.vue';
import MaterialCard from '../common/material/MaterialCard.vue';
import TareaExtraCard from './extra/tarea/TareaExtraCard.vue';
import ExamenFinalCard from './examenFinal/ExamenFinalCard.vue';
import ParcialCard from './parcial/ParcialCard.vue';
import ExamenFinalExtraCard from './extra/examenFinal/ExamenFinalExtraCard.vue';
import ParcialExtraCard from './extra/parcial/ParcialExtraCard.vue';
export default {
  components: { TareaCard,MaterialCard, TareaExtraCard, ExamenFinalCard,ParcialCard, ExamenFinalExtraCard, ParcialExtraCard },
    props:[
        "bloque"
    ],
    computed:{

    },
    data:function(){
        return ({

        });
    },
    methods:{
        onlyExamenFinalExtra(actividad){
            if(actividad.examenFinal)
                if(actividad.examenFinal.actividad.tipoActividadNavigation.nombre=='Examen final extra')
                    return true;
            return false;
        },
        onlyParcialExtra(actividad){
            if(actividad.examenFinal)
                if(actividad.examenFinal.actividad.tipoActividadNavigation.nombre=='Parcial extra')
                    return true;
            return false;
        },
        onlyParcial(actividad){
            if(actividad.examenFinal)
                if(actividad.examenFinal.actividad.tipoActividadNavigation.nombre=='Parcial')
                    return true;
            return false;
        },
        onlyExamenFinal(actividad){
            if(actividad.examenFinal)
                if(actividad.examenFinal.actividad.tipoActividadNavigation.nombre=='Examen final')
                    return true;
            return false;
        },
        onlyTareaExtra(actividad){
            if(actividad.tarea)
                if(actividad.tarea.actividad.tipoActividadNavigation.nombre=='Tarea extra')
                    return true;
            return false;
        },
        onlyTarea(actividad){
            if(actividad.tarea)
                if(actividad.tarea.actividad.tipoActividadNavigation.nombre=='Tarea')
                    return true;
            return false;
        },
        onlyMaterial(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Material')
                    return true;
            return false;
        },
        detailsExamenFinalExtra(actividad){
            this.$emit("ondetailsexamenfinalextra",actividad);
        },
        detailsParcialExtra(actividad){
            this.$emit("ondetailsparcialextra",actividad);
        },
        detailsParcial(actividad){
            this.$emit("ondetailsparcial",actividad);
        },
        detailsExamenFinal(actividad){
            this.$emit("ondetailsexamenfinal",actividad);
        },
        detailsTareaExtra(actividad){
            this.$emit("ondetailstareaextra",actividad);
        },
        detailsTarea(actividad){
            this.$emit("ondetailstarea",actividad);
        },
        detailsMaterial(actividad){
            this.$emit("ondetailsmaterial",actividad);
        },
        getColorBloque(){
            var color=this.$vuetify.theme.dark?`white`:`black`;

            if(this.bloque.bloque.fechaIniciado){
                color="green";
            }
            if(this.bloque.bloque.fechaIniciado&&this.bloque.bloque.fechaTerminado){
                color="primary";
            }

            return color;
        },
        getInicioFinText(bloque){
            var text=`No iniciado`;

            if(bloque.fechaIniciado){
                text=`Iniciado en ${bloque.fechaIniciado.split("T")[0]}`;
            }

            if(bloque.fechaTerminado){
                text+=` - Finalizado en ${bloque.fechaTerminado.split("T")[0]}`;
            }

            return text;
        },
    },
    created(){

    }
}
</script>
<style scoped>

</style>