<template>
    <v-app id="pagos_niveles">
        <v-app-bar app flat :color="$vuetify.theme.dark?'':'white'" outlined>
            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click="openDrawer"></v-app-bar-nav-icon>
            <v-toolbar-title>
                {{title}}
            </v-toolbar-title>
            <v-spacer/>
            <v-tooltip bottom>
                <template v-slot:activator="{on,attr}">
                    <v-btn icon v-on="on" v-bind="attr" @click="changeTheme">
                        <v-icon v-if="!$vuetify.theme.dark">brightness_3</v-icon>
                        <v-icon v-if="$vuetify.theme.dark">brightness_7</v-icon>
                    </v-btn>
                </template>
                <span v-if="!$vuetify.theme.dark">Tema oscuro</span>
                <span v-if="$vuetify.theme.dark">Tema claro</span>
            </v-tooltip>
            <div class="pr-2">
            </div>

            <v-tooltip bottom>
                <template v-slot:activator="{on,attr}">
                    <v-btn icon v-on="on" v-bind="attr"><v-icon>more_vert</v-icon></v-btn>
                </template>
                <span>Opciones</span>
            </v-tooltip>
            <template v-slot:extension>
                <v-tabs
                :color="$vuetify.theme.dark?'white':'black'"
                v-model="tab"
                centered
                @change="ontabchange"
                >
                <v-tabs-slider></v-tabs-slider>

                <v-tab
                    v-for="item in tabItems"
                    :key="item"
                >
                    {{ item }}
                </v-tab>
                </v-tabs>
            </template>
        </v-app-bar> 
        <div v-if="allLoaded">
            <v-tabs-items v-model="tab" class="tabs_items">
                <v-tab-item key="Niveles">
                    <niveles :selectedTab="selectedTab"/>
                </v-tab-item>
                <v-tab-item key="Tipos de pago">
                    <tipos-pago :selectedTab="selectedTab"/>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-app>
</template>
<script>
import Niveles from './PagosNiveles/Niveles.vue';
import TiposPago from './TiposPago/TiposPago.vue';
export default {
    props:{title:String},
    components:{Niveles,TiposPago},
    data:()=>({
        allLoaded:true,
        tab:null,
        tabItems:["Niveles","Tipos de pago"],
        selectedTab:0
    }),
    methods:{
        ontabchange(val){
            this.selectedTab=val;
        },
        changeTheme(){
            if(this.$vuetify.theme.dark){
                this.$vuetify.theme.dark=false;
                this.$store.commit("changeTheme","light");
            }else{
                this.$vuetify.theme.dark=true;
                this.$store.commit("changeTheme","dark");
            }
        },
        openDrawer(){
            this.$store.commit('openDrawer',!this.$store.state.drawerState);
        }
    }
}
</script>
<style scoped>
.tabs_items{
    background-color: transparent !important;
}
</style>