<template>
    <div>
        <v-row no-gutters align="center">
            <v-menu offset-x :close-on-content-click="false" v-model="meetingLinkPopup">
                <template v-slot:activator="{on:menu}">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on:tooltip}">
                            <v-btn icon v-on="{...tooltip,...menu}" dark><v-icon>settings</v-icon></v-btn>
                        </template>
                        <span>
                            Configurar videoconferencia
                        </span>
                    </v-tooltip>
                </template>
                <v-list class="pa-0" :disabled="isLoadingMeetingPopup">
                    <v-progress-linear v-if="isLoadingMeetingPopup" :color="$store.state.accentColor2" indeterminate></v-progress-linear>
                    <v-form ref="linkmeetingForm" class="pa-2">
                        <v-row class="ma-0">
                            <div class="text-subtitle-2 font-weight-bold">
                                Configuración de videoconferencia
                            </div>
                        </v-row>
                        <br>
                        <v-row class="ma-0">
                            <v-text-field
                            label="Link"
                            filled
                            rounded
                            :color="$store.state.accentColor2"
                            v-model="meetingLinkData.meetingLinkString"
                            :rules="meetingLinkData.meetingLinkStringRules"
                            autocomplete="off"
                            >
    
                            </v-text-field>
                        </v-row>
                        <v-row class="ma-0">
                            <v-text-field
                            label="Passcode de zoom"
                            filled
                            rounded
                            :color="$store.state.accentColor2"
                            v-model="meetingLinkData.contraseniaMeet"
                            autocomplete="off"
                            >
    
                            </v-text-field>
                        </v-row>
                        <v-row class="ma-0" justify="center">
                            <v-btn text x-small rounded outlined @click="crearGoogleMeeting">Crear Google Meeting</v-btn>
                        </v-row>
                        <br>
                        <v-row class="ma-0">
                            <v-spacer></v-spacer>
                            <v-btn text @click="meetingLinkPopup=false;" small>Cancelar</v-btn>
                            <v-btn text color="green" small @click="saveMeetinglink">Guardar</v-btn>
                        </v-row>
                    </v-form>
                </v-list>
            </v-menu>
            <div class="mr-2"></div>
            <v-btn v-if="asignacionData.meetingLink" outlined dark  x-small rounded class="normal-text"
                @click="joinMeeting"
            >
                Unírse a videoconferencia
            </v-btn>
        </v-row>
    </div>
</template>
<script>
export default {
    props:["asignacionData"],
    watch:{
        meetingLinkPopup:function(newval,oldval){
            oldval;
            if(newval){
                this.getLinkMeeting();
            }else{
                this.$refs.linkmeetingForm.resetValidation();
            }
        }
    },
    computed:{

    },
    data:function(){
        return({
            meetingLinkData:{
                meetingLinkString:"",
                meetingLinkStringRules:[
                    v=>!!v||"El link es obligatorio."
                ],
                contraseniaMeet:""
            },
            meetingLinkPopup:false,
            isLoadingMeetingPopup:false,
        });
    },
    methods:{
        joinMeeting(){
            var splittedUrl=this.asignacionData.meetingLinkString.split(new RegExp("[$]+"));
            window.open(splittedUrl[0]);
        },
        saveMeetinglink(){
            if(this.$refs.linkmeetingForm.validate()){
                this.createMeetinglinkCall();
            }
        },
        crearGoogleMeeting(){
            window.open("http://meet.google.com/new");
        },
        async createMeetinglinkCall(){
            this.isLoadingMeetingPopup=true;

            await this.$axios.put(`api/zoommeet/meet/create?asignacion=${this.asignacionData.idGradoSeccionMateriaColaborador}`,
            this.meetingLinkData).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("onsuccess");
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.meetingLinkPopup=false;
            this.isLoadingMeetingPopup=false;
        },
        getLinkMeeting(){
            if(this.asignacionData.meetingLink){
                var contraseñaSplit=this.asignacionData.meetingLinkString.split("$$$$$");
                this.meetingLinkData.meetingLinkString=contraseñaSplit[0];
                
                if(contraseñaSplit.length>1)
                    this.meetingLinkData.contraseniaMeet=contraseñaSplit[1];
            }
        },
    }
}
</script>
<style scoped>

</style>