import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
      accentColor:"primary",
      accentColor2:"teal darken-1",
      notifications1:{
        text: "",
        color:"",
        time:""
      },
      drawerState:false,
      cicloEscolar:"",
      years:[],
      profileInfo:"",
      isLoggued:false,
      currentNivelEstudiantil:"",
      currentRealDate:"",
      personIdentity:"",
      isLoading:false,
      pendientesData:[],
      isLoadingPendientes:false,
      selectedSection:""
    },
    mutations: {
      changeTheme (state,theme) {
          switch (theme) {
            case "dark":
                state.accentColor="orange darken-1";
                window.localStorage.setItem("darkTheme","true");
                break;
            case "light":
                state.accentColor="primary";
                window.localStorage.setItem("darkTheme","false");
            break;
          }
      },
      showMessage(state,payload){
        state.notifications1.text=payload.text;
        state.notifications1.color=payload.color;
        state.notifications1.time=payload.time;
      },
      openDrawer(state,drawerState){
        state.drawerState=drawerState;
      },
      changeCiclo(state,ciclo){
        state.cicloEscolar=ciclo;
      },
      addCiclos(state,ciclos){
        state.years=ciclos;
      },
      setProfileInfo(state,profileInfo){
        state.profileInfo=profileInfo;
      },
      setIsLoggued(state,logued){
        state.isLoggued=logued;
      },
      setCurrentNivel(state,codigoNivel){
        state.currentNivelEstudiantil=codigoNivel;
      },
      setCurrentDate(state,date){
        state.currentRealDate=date;
      },
      setPersonIdentity(state,identity){
        state.personIdentity=identity;
      },
      setAccentColor2(state,color){
        state.accentColor2=color;
      },
      setLoading(state,loading){
        state.isLoading=loading;
      },
      setNotificationsPendientes(state,pendientes){
        state.pendientesData=pendientes;
      },
      setIsLoadingPendientes(state,isLoading){
        state.isLoadingPendientes=isLoading;
      },
      setSection(state,section){
        state.selectedSection=section;
      }
    },
    getters:{
      profileInfo:state=>{
        return state.profileInfo;
      },
      notificationsPendientes:(state)=>{
        return state.pendientesData;
      }
    }
  });

  export default store;