<template>
    <router-view />
</template>
<script>
export default {
    created(){
        console.log(this.$router.currentRoute.path);
        this.$store.commit("setIsLoggued",true);

        if(window.location.href.includes("colaborador")||window.location.href.includes("Colaborador")){
            var splitURLColaboradores=this.$router.currentRoute.path.split("/");

            var count1=0;
            splitURLColaboradores.forEach(element => {
                if(!element)
                    splitURLColaboradores.splice(count1,1);
                count1++;
            });

            if(splitURLColaboradores[0].toLowerCase()=="colaborador"){
                this.$router.replace({path:this.$router.currentRoute.path});
                return;
            }
            this.$router.replace({name:"home_colaborador_admin"});
        }else if(window.location.href.includes("estudiante")||window.location.href.includes("Estudiante")){
            var splitURLEstudiantes=this.$router.currentRoute.path.split("/");
            
            console.log(splitURLEstudiantes);

            var count2=0;
            splitURLEstudiantes.forEach(element => {
                if(!element)
                    splitURLEstudiantes.splice(count2,1);
                count2++;
            });

            if(splitURLEstudiantes[0].toLowerCase()=="estudiante"){
                this.$router.replace({path:this.$router.currentRoute.path});
                return;
            }
            this.$router.replace({name:"home_estudiante"});
        }
    }
}
</script>
<style>
.rootpage-title1{
    font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
    font-size: 50px;
}
.rootpage-subtitle1{
    font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
    font-size: 20px;
}

.card-style{
    padding: 20px;
    font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
    font-size: 15px;
}

@media screen and (min-width: 560px) {
    .rootpage-title1{
        font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
        font-size: 60px;
    }
    .rootpage-subtitle1{
        font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
        font-size: 30px;
    }       

    .card-style{
        padding: 20px;
        font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
        font-size: 17px;
    }
}

@media screen and (min-width: 960px) {
    .rootpage-title1{
        font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
        font-size: 85px;
    }
    .rootpage-subtitle1{
        font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
        font-size: 30px;
    }       
    .card-style{
        padding: 20px;
        font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
        font-size: 20px;
    }
}

@media screen and (min-width: 1260px) {
    .rootpage-title1{
        font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
        font-size: 120px;
    }
    .rootpage-subtitle1{
        font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
        font-size: 40px;
    }
    .card-style{
        padding: 20px;
        font-family: 'grande_october_fourregular',Arial, Helvetica, sans-serif;
        font-size: 23px;
    }
}


.card-text{
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-container{
    max-width: 1024px;
}

@media screen and (min-width: 1024px) {
  .custom-container {
    max-width: 1024px !important;
  }
}

.normal-text{
    text-transform: none !important;
}
.ql-align-center{
    text-align: center;
}

.ql-align-right{
    text-align: right;
}

.ql-align-justify{
  text-align: justify;  
}

.overlay-dialog{
    background-color: rgba(0, 0, 0, 0.8)
}
</style>