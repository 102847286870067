<template>
    <v-dialog fullscreen transition="scale-transition" v-model="show" persistent no-click-animation>
        <video-player :videoplayerDialog="videoplayerDialog" @onclose="videoplayerDialog=false;"
        :video="selectedVideo"
        ></video-player>
        <v-card tile>
            <v-app-bar fixed flat :color="$vuetify.theme.dark?'':'white'">
                <v-btn icon @click="$router.go(-1)"><v-icon>arrow_back</v-icon></v-btn>
                <v-toolbar-title>Detalles de material</v-toolbar-title>
                <v-progress-linear background-color="transparent" indeterminate bottom absolute v-if="isLoading" :color="$store.state.accentColor2"></v-progress-linear>
            </v-app-bar>
            <v-container fluid class="body">
                <v-row no-gutters v-if="!isLoading" justify="center">
                    <div style="max-width:1500px;width:1000px">
                        <v-list-item class="pa-0">
                            <v-list-item-avatar :color="$store.state.accentColor2" v-if="$vuetify.breakpoint.smAndUp">
                                <v-icon color="white">
                                    {{getIconText}}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title :class="getTitleStyle">
                                    {{material.title}}
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-subtitle-2 wrap-text">
                                    {{getCatedraticoNombres}} - {{getFechaCreada}}
                                </v-list-item-subtitle>
                                <br>
                                <br>
                                <v-divider :color="getDividerColor"></v-divider>
                            </v-list-item-content>
                            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="pa-0">
                            <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-body-2 wrap-text" v-html="linkfy(material.contenido,{
                                target: {
                                    url: '_blank'
                                    }
                                }
                            )">
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="pa-0" v-if="material.videos.length>0">
                            <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                                
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-subtitle-2">
                                    Videos
                                </v-list-item-title>
                                <br>
                                <v-row no-gutters class="pb-5">
                                    <v-col v-for="(video,index) in material.videos" :key="index" cols="12">
                                        <video-card :videoInfo="video" :action="'get'" @onclickvideo="playvideo(video)"></video-card>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-action>
                        </v-list-item>
                        
                        <v-list-item class="pa-0" v-if="material.archivos.length>0">
                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-subtitle-2">
                                    Archivos
                                </v-list-item-title>
                                <br>
                                <v-row class="py-0 card-text">
                                    <v-col cols="12" xs="12" v-for="(file,index) in material.archivos" :key="index">
                                        <file-card :file="file" :publico="true"></file-card>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="pa-0"
                        v-if="material.hasData">
                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-divider></v-divider>
                                <comments-publicos @onerror="onerror" @ondeleteComment="ondeleteComment" 
                                    @onupdateComments="(comments)=>{onupdateComments(comments)}" 
                                    :comentarios="material.comentarios" 
                                    from="material" 
                                    :actividad="material"></comments-publicos>
                            </v-list-item-content>
                            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">

                            </v-list-item-action>
                        </v-list-item>
                    </div>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>    
</template>
<script>
import GetReadableDate from '../../../../../utils/timeUtils';
import VideoCard from '../../../../colaborador/escritorio/common/VideoCard.vue';
import VideoPlayer from '../../../../colaborador/escritorio/common/VideoPlayer.vue';
import linkfy from 'linkifyjs/lib/linkify-string';
import CommentsPublicos from '../CommentsPublic.vue';
import FileCard from '../../../../colaborador/escritorio/common/FileCard.vue';
//import AppBar2 from '../../../../globalComponents/AppBar2.vue';
export default {
  components: { VideoCard,VideoPlayer,CommentsPublicos,FileCard },
  //components: { AppBar2 },
    props:[
        
    ],
    computed:{
        getDividerColor(){return this.$vuetify.theme.dark?'white':'black';},
        getTitleStyle(){
            return `text-h5 text-sm-h4 text-md-h4 ${this.$store.state.accentColor2}--text wrap-text`;
        },
        getIconText(){
            if(this.material)
                return this.material.actividad.tipoActividadNavigation.icono;
            return "";
        },
        getTextClass(){
            return this.$vuetify.theme.dark?'text-h4':`text-h4 teal-darken-1--text`;
        },
        getFechaCreada(){
            if(this.material){
                return this.getHumanDate(this.material.actividad.fechaCreada);
            }

            return "";
        },
        getCatedraticoNombres(){
            if(this.material){
                if(this.material.catedratico)
                    return this.material.catedratico.nombres;
            }
            return "Catedrático";
        },
        getpostIdentity(){
            if(this.material){
                return this.material.actividad.idActividadMateriaBloque;
            }
            return "";
        }
    },
    data:function(){
        return ({
            videoplayerDialog:false,
            selectedVideo:"",
            isLoading:true,
            material:"",
            show:false
        })
    },
    methods:{
        onerror(){
            this.loadAll();
        },
        onupdateComments(comments){
            this.material.comentarios=comments;
        },
        onclose(){
            this.show=false;
            this.$router.go(-1);
        },
        linkfy,
        getHumanDate: GetReadableDate,
        getFileName(name){
            if(name.length>30){
                var extensionSplitted=name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${name.substring(0,30)}....${extension}`;
            }
            return name;
        },
        playvideo(video){
            this.selectedVideo=video;
            this.videoplayerDialog=true;
        },
        loadMaterial(){return this.$axios.get(`api/estudiantil/actividades/material/${this.$route.params.idmaterial}`);},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadMaterial()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.material=response[0].data.data;
                    }else{
                        this.$store.commit("showMessage",{text:response[0].data.message,color:"",time:5000})
                        this.$emit("onloadmaterialerror");
                        this.$router.go(-1);
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.show=true;
        this.loadAll();
    }
}
</script>
<style scoped>

</style>