<template>
    <v-app-bar app flat :color="$vuetify.theme.dark?'':'white'" outlined>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click="openDrawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
            {{name}}
        </v-toolbar-title>
        <v-spacer/>
        <v-tooltip bottom>
            <template v-slot:activator="{on,attr}">
                <v-btn icon v-on="on" v-bind="attr" @click="changeTheme">
                    <v-icon v-if="!$vuetify.theme.dark">brightness_3</v-icon>
                    <v-icon v-if="$vuetify.theme.dark">brightness_7</v-icon>
                </v-btn>
            </template>
            <span v-if="!$vuetify.theme.dark">Tema oscuro</span>
            <span v-if="$vuetify.theme.dark">Tema claro</span>
        </v-tooltip>
        <div class="pr-2">
        </div>

        <v-tooltip bottom>
            <template v-slot:activator="{on,attr}">
                <v-btn icon v-on="on" v-bind="attr"><v-icon>more_vert</v-icon></v-btn>
            </template>
            <span>Opciones</span>
        </v-tooltip>
        <v-progress-linear indeterminate bottom absolute v-if="!allLoaded" :color="$store.state.accentColor"></v-progress-linear>
    </v-app-bar>    
</template>
<script>
export default {
    props:{
        optionsMenu:[],
        allLoaded:Boolean,
        name:String
    },
    data:()=>({
        drawer:false
    }),
    methods:{
        changeTheme(){
            if(this.$vuetify.theme.dark){
                this.$vuetify.theme.dark=false;
                this.$store.commit("changeTheme","light");
            }else{
                this.$vuetify.theme.dark=true;
                this.$store.commit("changeTheme","dark");
            }
        },
        openDrawer(){
            this.$store.commit('openDrawer',!this.$store.state.drawerState);
        }
    },
    created(){
    }
}
</script>
<style scoped>

</style>