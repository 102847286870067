<template>
    <div>
        <br>
        <br>
        <v-card flat class="pa-0 ma-0" :loading="isLoading" :disabled="isLoading">
            <div v-for="alumno in calificaciones" :key="alumno.estudiante.idEstudiante">
                    <v-simple-table ref="calificacionesTable" dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-h5 font-weight-bold bordered-cell" style="text-align:center;" colspan="20">{{getNombreAlumno(alumno.estudiante)}}</th>
                                </tr>
                                <tr>
                                    <th class="text-h6 font-weight-bold bordered-cell">Materias</th>
                                    <th class="bordered-cell text-h6 font-weight-bold" v-for="bloque in bloques" :key="bloque.count" style="text-align:center !important" colspan="3">
                                        {{bloque.text}}
                                    </th>
                                    <th class="bordered-cell text-h6 font-weight-bold">
                                    </th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="bordered-cell"></td>
                                    <td class="bordered-cell font-weight-bold" style="text-align:center;" v-for="(text,index) in bloquesChunks" :key="index">
                                        {{text}}
                                    </td>
                                </tr>
                                <tr v-for="(materia,index) in alumno.nota" :key="index">
                                    <td class="bordered-cell ">{{materia.materia.nombre}}</td>
                                    <td class="bordered-cell " style="text-align:center;" v-for="(nota,index) in getNotas(materia)" :key="index">{{nota}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <br>
                    <br>
                    <br>
            </div>
            <v-tooltip left>
                <template v-slot:activator="{on}">
                    <v-btn fab fixed bottom right dark v-on="on" @click="onDownload()">
                        <v-icon>download</v-icon>
                    </v-btn>
                </template>
                <span>Descargar calificaciones</span>
            </v-tooltip>
        </v-card>
    </div>
</template>
<script>
export default {
    props:[
        "idgradoseccion"
    ],
    data:function(){
        return ({
            calificaciones:[],
            bloques:[],
            bloquesChunks:[],
            once:false,
            isLoading:false
        })
    },
    methods:{
        onDownload(){
            var delimitador=["a","b"].toLocaleString();
            if(delimitador.includes(","))
                delimitador=",";
            else
                delimitador=";";

            var tablesSelect=this.$refs.calificacionesTable;

            var dataReporteCSV=[];
            tablesSelect.forEach(item=>{
                var tableSelect=item.$el.querySelector("table");

                for( var index=0;index<tableSelect.rows.length;index++){
                    var dataRow=[];
                    for(var indexColumns=0;indexColumns<tableSelect.rows[index].cells.length;indexColumns++){
                        var dataCell=tableSelect.rows[index].cells[indexColumns].innerText;
                        dataCell=dataCell.replaceAll("more_vert","");
                        dataCell=dataCell.replaceAll("\n"," ");

                        if(this.bloques.some(item=>item.text==dataCell)){
                            for(var relleno=0;relleno<2;relleno++){
                                dataRow.push(dataCell);    
                            }
                        }

                        dataRow.push(dataCell);
                    }
                    dataReporteCSV.push(dataRow);
                }
                dataReporteCSV.push([]);dataReporteCSV.push([]);
            });

            var csvContent = "data:text/csv;charset=utf-8,\uFEFF";
            var count=0;
            dataReporteCSV.forEach(row=>{
                if(count>0){
                    for(var countCell=0;countCell<row.length;countCell++){
                        if(row[countCell].includes(" / ")){
                            var splitPunteo=row[countCell].split(" / ");
                            row[countCell]=splitPunteo[0];
                        }
                    }
                }
                
                var rowCSV=row.join(delimitador);
                csvContent+=rowCSV+"\r\n";
                count++;
            });

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            var nombreClase=this.$store.state.selectedSection;

            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${nombreClase}.csv`);
            document.body.appendChild(link);
            
            link.click();
        },
        getNotas(clase){
            var notas=clase.notas;
            if(notas){
                var keys=Object.keys(notas);
                

                var zonakeys=keys.filter(item=>item.toLowerCase().includes("zona"));
                var examenkeys=keys.filter(item=>item.toLowerCase().includes("examen"));

                var zonaExamenPair=[];
                var totalAcumulado=0;
                for(var counter=0;counter<(this.bloques.length);counter++){
                    var zona=notas[zonakeys[counter]];
                    var examen=notas[examenkeys[counter]];
                    var total=zona+examen;
                    zonaExamenPair.push(zona);
                    zonaExamenPair.push(examen);                    

                    if(total){
                        zonaExamenPair.push(total);
                        totalAcumulado+=total;
                    }
                    else
                        zonaExamenPair.push(" ");

                    if(counter==(this.bloques.length-1)){
                        var countBloquesIniciados=clase.bloques.filter(item=>item.fechaIniciado).length;
                        zonaExamenPair.push(totalAcumulado/countBloquesIniciados);
                    }

                }
                return zonaExamenPair;
            }

            return Array(this.bloquesChunks.length).fill(" ");
        },
        getNombreAlumno(estudiante){
            if(estudiante)
                return `${estudiante.nombre1} ${estudiante.nombre2} ${estudiante.apellido1} ${estudiante.apellido2}`;
            return "";
        },
        getCalificacionesGrade(){return this.$axios.get(`api/docentes/grados/${this.idgradoseccion}/calificaciones?ciclo=${this.$route.params.ciclo}`)},
        async loadAll(){
            this.$store.commit("setLoading",true);
            this.isLoading=this.$store.state.accentColor2;

            await this.$axios.all([this.getCalificacionesGrade()]).then((response)=>{
                if(response[0].status==200)
                    if(response[0].data.code==1){
                        this.calificaciones=response[0].data.data;

                        
                        this.generateBloquesProcess();
                        this.generateBloqueHeader();
                    }
            }).catch((error)=>{
                console.log(error);
            });

            this.once=true;
            this.$store.commit("setLoading",false);
            this.isLoading=false;
        },
        generateBloquesProcess(){
            if(this.calificaciones.some(item=>item.nota.some(item=>item.bloques))){
                var founded=this.calificaciones.filter(item=>item.nota.some(item=>item.bloques));

                if(founded){
                    var hasMore6=false;

                    for(let element of founded){
                        var filtrados=element.nota.filter(item=>item.bloques);

                        if(filtrados.some(item=>item.bloques.length>4))
                        {
                            hasMore6=true;
                            break;
                        }
                    }
                    

                    if(hasMore6){
                        this.generateBloques(6);
                        return;
                    }
                }
            }
            this.generateBloques(4);
        },
        generateBloques(count){
            Array.from(Array(count).keys()).forEach(element => {
                element+=1;
                this.bloques.push({
                    text:`Bloque ${element}`,
                    count:element
                })
            });
        },
        generateBloqueHeader(){
            var array=Array.from(Array(this.bloques.length*3+1).keys());
            array.shift();
            var order=[
                "Zona",
                "Exam final",
                "Total"
            ];

            array.forEach(element=>{
                if((element%3)==0){
                    this.bloquesChunks.push(order[0]);
                    this.bloquesChunks.push(order[1]);
                    this.bloquesChunks.push(order[2]);
                }
            });
            this.bloquesChunks.push("Promedio")
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>
 .bordered-cell{
     border-style: solid;border-width:1px
 }
</style>