<template>
    <v-card outlined>
        <v-card-title>{{nivel.nombre}}</v-card-title>
        <v-card-text>
            <v-data-table hide-default-footer :items="nivel.pagosNivelesEstudiantiles"
            caption="Pagos"
            :headers="pagosHeader" dense>
                <template v-slot:item.actions="{item}" v-if="$currentYearValidation()">
                    <v-tooltip right>
                        <template v-slot:activator="{on}">
                            <v-btn icon @click="$emit('onupdatePago',item)" v-on="on" small>
                                <v-icon small>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar pago</span>
                    </v-tooltip>
                    <v-tooltip right>
                        <template v-slot:activator="{on}">
                            <v-btn icon @click="$emit('ondeletePago',item)" v-on="on" small>
                                <v-icon small>clear</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar pago</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small rounded @click="$emit('onaddpagos')" v-if="$currentYearValidation()"><v-icon>add</v-icon> agregar pago</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props:{
        nivel:Object
    },
    data:()=>({
        pagosHeader:[
            {text:"Descripción",value:"idPagoNavigation.descripcion"},
            {text:"Cantidad (Q)",value:"cantidad"},
            {text:"Acción",value:"actions"}
        ]
    })
}
</script>
<style scoped>

</style>