<template>
    <div>
        <delete-dialog :show="deleteactivityDialog" :content="deletecontent" :context="deletecontext" :idEntity="getpostIdentity"
            @ondelete="ondeletePost" @onclose="deleteactivityDialog=false"
            ></delete-dialog>

        <div v-if="!isLoading">
            <v-container class="custom-container">
                <br>
                <v-row no-gutters justify="center">
                    <v-col cols="12" sm="6" md="4" lg="3" align="center">
                        <v-select
                        filled
                        rounded
                        label="Bloques"
                        :items="bloquesData"
                        item-value="idMateriaBloque"
                        :item-text="(item)=>`Bloque ${item.bloque}`"
                        v-model="currentBloque"
                        :color="$store.state.accentColor2"
                        :item-color="$store.state.accentColor2"
                        @change="onchangeBloque"
                        >

                        </v-select>
                    </v-col>
                </v-row>
                <br>
                <v-card flat outlined>
                    <v-row no-gutters class="pa-2" justify="start">
                        <v-tooltip right>
                            <template v-slot:activator="{on}">
                                <v-btn icon large v-on="on" @click="onRefresh"><v-icon>refresh</v-icon></v-btn>
                            </template>
                            <span>Refrescar</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-tooltip left>
                            <template v-slot:activator="{on}">
                                <v-btn icon large v-on="on" @click="onDownload"><v-icon>file_download</v-icon></v-btn>
                            </template>
                            <span>Descargar calificaciones</span>
                        </v-tooltip>
                    </v-row>
                    <v-divider></v-divider>
                    <v-simple-table ref="calificacionesTable">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th :style="getStyleTransform">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Alumnos
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </th>
                                    <th align="center" style="max-width:200px" v-for="(actividad,index) in actividadesData" :key="index">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{on}">
                                                            <div v-on="on">{{actividad.title}}</div>
                                                        </template>
                                                        <span>{{actividad.title}}</span>
                                                    </v-tooltip>
                                                </v-list-item-title>
                                                <v-divider class="my-2"></v-divider>
                                                <v-list-item-subtitle>
                                                    de {{getActividadValor(actividad)}} pts
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action v-if="!(actividad.zonafinal||actividad.examenfinal)">
                                                <v-menu>
                                                    <template v-slot:activator="{on}">
                                                        <v-btn icon v-on="on">
                                                            <v-icon>more_vert</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list class="pa-0" dense>
                                                        <v-list-item v-for="(opcion,index) in opcionesTarea" :key="index" @click="selectedOpcion(opcion,actividad)">
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{opcion}}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </th>
                                    <th class="pa-0" color="red">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Zona total
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </th>
                                    <th  class="pa-0">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Examen final
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </th>
                                    <th  class="pa-0">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Nota final
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(estudianteActividad,index) in estudiantesActividadesData" :key="index">
                                    <td :style="getStyleTransform" class="text-subtitle-2">{{getNombreAlumno(estudianteActividad)}}</td>
                                    <td v-for="(actividad,index) in estudianteActividad.actividadesMateriasBloquesParticipantes" :key="index">
                                        <div align="center" v-if="actividad" :class="getEstadoText(actividad,true)">
                                            <div>
                                                {{getEstadoText(actividad)}}
                                            </div>
                                        </div>
                                        <div align="center" v-if="actividad==null" class="text-subtitle-2 font-weight-bold grey--text">Sin participación</div>
                                    </td>
                                    <td class="pa-0 font-weight-bold">
                                        <div align="center">
                                            <div>
                                                {{getSumaZona(estudianteActividad)}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="pa-0 font-weight-bold">
                                        <div align="center">
                                            <div>
                                                {{getExamenFinal(estudianteActividad)}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="pa-0 font-weight-bold">
                                        <div align="center">
                                            <div>
                                                {{getTotal(estudianteActividad)}}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
                <router-view :action="actionActivity"
                @onupdatetarea="onupdateActividad"
                @ondeletetarea="ondeletePost"
                @onupdatetareaCall="updateTarea"

                @onupdateexamenfinal="onupdateActividad"
                @ondeleteexamenfinal="ondeletePost"
                @onupdateexamenfinalCall="updateExamenFinal"

                @onupdateparcial="onupdateActividad"
                @ondeleteparcial="ondeletePost"
                @onupdateparcialCall="updateParcial"

                @onupdatetareaextraCall="updateTareaExtra"
                @onupdateparcialextraCall="updateParcialExtra"
                @onupdateexamenfinalextraCall="updateExamenFinalExtra"
                />
            </v-container>
        </div>
    </div>
</template>
<script>
import DeleteDialog from '../../../globalComponents/DeleteDialog.vue';
//import ContruccionBanner from "../../../globalComponents/ContruccionBanner.vue";

export default {
  components: { /*ContruccionBanner*/ 
    DeleteDialog},
    props:[
        "tab"
    ],
    computed:{
        getStyleTransform(){
            var baseClass=`min-width:300px;transform: translate(${this.transformLeft}px,0px); background:white;`;
            if(this.transformLeft>0)
                baseClass+="box-shadow: 0 5px 8px 0 rgb(0 0 0 / 14%), 0 1px 14px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%)";
            return baseClass;
        },
        getVisible(){
            if(this.focusItem)
                if(this.focusItem.showInput)
                    return true;

            console.log(this.focusItem);
            return false;
        },
        getpostIdentity(){
            if(this.selectedActivity){
                return this.selectedActivity.actividad.idActividadMateriaBloque;
            }
            return "";
        }
    },
    watch:{
        tab:function(newval,oldval){

            oldval;
            if(newval==3){
                this.loadAll();
            }
        }
    },
    data:function(){
        return ({
            deleteactivityDialog:false,
            selectedActivity:"",
            deletecontent:"",
            deletecontext:"",

            actionActivity:"",
            focusItem:"",
            isLoading:false,
            bloquesData:[],
            currentBloque:"",
            actividadesData:[],
            estudiantesActividadesData:[],
            transformLeft:0,
            opcionesTarea:[
                "Ver",
                "Editar",
                "Eliminar"
            ]
        })
    },
    methods:{
        onDownload(){
            var delimitador=["a","b"].toLocaleString();
            if(delimitador.includes(","))
                delimitador=",";
            else
                delimitador=";";

            var tableSelect=this.$refs.calificacionesTable.$el.querySelector("table");
            var dataReporteCSV=[];

            for( var index=0;index<tableSelect.rows.length;index++){
                var dataRow=[];
                for(var indexColumns=0;indexColumns<tableSelect.rows[index].cells.length;indexColumns++){
                    var dataCell=tableSelect.rows[index].cells[indexColumns].innerText;
                    dataCell=dataCell.replaceAll("more_vert","");
                    dataCell=dataCell.replaceAll("\n"," ");
                    dataRow.push(dataCell);
                }
                dataReporteCSV.push(dataRow);
            }

            var csvContent = "data:text/csv;charset=utf-8,\uFEFF";
            var count=0;
            dataReporteCSV.forEach(row=>{
                if(count>0){
                    for(var countCell=0;countCell<row.length;countCell++){
                        if(row[countCell].includes(" / ")){
                            var splitPunteo=row[countCell].split(" / ");
                            row[countCell]=splitPunteo[0];
                        }
                    }
                }
                
                var rowCSV=row.join(delimitador);
                csvContent+=rowCSV+"\r\n";
                count++;
            });

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            var bloque=this.bloquesData.find(bloque=>bloque.idMateriaBloque==this.currentBloque);
            var nombreClase=document.getElementsByClassName("v-toolbar__title");
            if(nombreClase.length>0){
                nombreClase=nombreClase[0];
                nombreClase=nombreClase.innerText.split(" - ")[0];
            }

            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${nombreClase} bloque ${bloque.bloque}.csv`);
            document.body.appendChild(link);
            
            link.click();
        },
        async onRefresh(){
            await this.loadAll2(this.currentBloque);
            this.initializeActividadesEstudiante();
            this.initializeScrollTable();
        },
        getSumaZona(estudianteActividades){
            var filteredActivities=estudianteActividades.actividadesMateriasBloquesParticipantes.filter(item=>item);
            var actividadesZona=filteredActivities.filter(item=>item.actividadesMateriasBloquesEntregas!=null
            &&(item.idEstadoActividad==3)
            &&[1,3,6,9].some(activity=>activity==item.idActividadMateriaBloqueNavigation.tipoActividad));

            var zonatotal=actividadesZona.reduce((acumulador,currentValue)=>{
                return acumulador+=currentValue.actividadesMateriasBloquesEntregas.calificacion;
            },0);

            return zonatotal;
        },
        getExamenFinal(estudianteActividades){
            var filteredActivities=estudianteActividades.actividadesMateriasBloquesParticipantes.filter(item=>item);
            var examenfinal=filteredActivities.filter(item=>item.actividadesMateriasBloquesEntregas!=null
            &&(item.idEstadoActividad==3)
            &&[2,8].some(activity=>activity==item.idActividadMateriaBloqueNavigation.tipoActividad));
            
            var examenfinaltotal=examenfinal.reduce((acumulador,currentValue)=>{
                return acumulador+=currentValue.actividadesMateriasBloquesEntregas.calificacion;
            },0);

            return examenfinaltotal;
        },
        getTotal(estudianteActividades){
            var zonatotal=this.getSumaZona(estudianteActividades);
            var examenfinaltotal=this.getExamenFinal(estudianteActividades);

            return  zonatotal+examenfinaltotal;
        },
        getActividadValor(actividad){
            if(actividad){
                if(actividad.actividad)
                    return actividad.actividad.valorNeto;
            }
            return "";
        },
        updateExamenFinalExtra(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"examen_final_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        updateParcialExtra(actividad){
            this.actionActivity="edit";

            this.$router.push({name:"parcial_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        updateParcial(actividad){
            this.actionActivity="edit";

            this.$router.push({name:"parcial_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateExamenFinal(actividad){
            this.actionActivity="edit";

            this.$router.push({name:"examen_final_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateTarea(actividad){
            this.actionActivity="edit";

            this.$router.push({name:"tarea_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateTareaExtra(actividad){
            this.actionActivity="edit";

            this.$router.push({name:"tarea_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        selectedOpcion(opcion,actividad){
            actividad;
            switch(opcion){
                case "Ver":
                    switch(actividad.actividad.tipoActividad){
                        case 1:
                            this.$router.push({name:"parcial_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
                            break;
                        case 2:
                            this.$router.push({name:"examen_final_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
                            break;
                        case 3:
                            this.$router.push({name:"tarea_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
                            break;
                        case 6:
                            this.$router.push({name:"tarea_extra_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
                            break;
                        case 8:
                            this.$router.push({name:"examen_final_extra_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
                            break;
                        case 9:
                            this.$router.push({name:"parcial_extra_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
                            break;
                    }
                    break;
                case "Editar":
                    switch(actividad.actividad.tipoActividad){
                        case 1:
                            this.actionActivity="edit";
                            this.$router.push({name:"parcial_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
                            break;
                        case 2:
                            this.actionActivity="edit";
                            this.$router.push({name:"examen_final_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
                            break;
                        case 3:
                            this.actionActivity="edit";
                            this.$router.push({name:"tarea_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
                            break;
                        case 6:
                            this.actionActivity="edit";
                            this.$router.push({name:"tarea_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
                            break;
                        case 8:
                            this.actionActivity="edit";
                            this.$router.push({name:"examen_final_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
                            break;
                        case 9:
                            this.actionActivity="edit";
                            this.$router.push({name:"parcial_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
                            break;
                    }
                    break;
                case "Eliminar":
                    switch(actividad.actividad.tipoActividad){
                        case 1:
                            this.deletecontext="parcial";
                            var tituloparcial=actividad.title;
                            if(actividad.title.length>20){
                                tituloparcial=`${tituloparcial.substring(0,15)}...`;
                            }
                            this.deletecontent=`¿Desea eliminar el parcial con título "${tituloparcial}"?`;
                            this.selectedActivity=actividad;
                            this.deleteactivityDialog=true;
                            break;
                        case 2:
                            this.deletecontext="examen final";
                            var tituloexamenfinal=actividad.title;
                            if(actividad.title.length>20){
                                tituloexamenfinal=`${tituloexamenfinal.substring(0,15)}...`;
                            }
                            this.deletecontent=`¿Desea eliminar el exámen final con título "${tituloexamenfinal}"?`;
                            this.selectedActivity=actividad;
                            this.deleteactivityDialog=true;
                            break;
                        case 3:
                            this.deletecontext="tarea";
                            var titulo=actividad.title;
                            if(actividad.title.length>20){
                                titulo=`${titulo.substring(0,15)}...`;
                            }
                            this.deletecontent=`¿Desea eliminar la tarea con título "${titulo}"?`;
                            this.selectedActivity=actividad;
                            this.deleteactivityDialog=true;
                            break;
                        case 6:
                            this.deletecontext="tarea extra";
                            var tituloExtra=actividad.title;
                            if(actividad.title.length>20){
                                tituloExtra=`${tituloExtra.substring(0,15)}...`;
                            }
                            this.deletecontent=`¿Desea eliminar la tarea extra con título "${tituloExtra}"?`;
                            this.selectedActivity=actividad;
                            this.deleteactivityDialog=true;
                            break;
                        case 8:
                            this.deletecontext="examen final extra";
                            var tituloExtraExamenFinal=actividad.title;
                            if(actividad.title.length>20){
                                tituloExtraExamenFinal=`${tituloExtraExamenFinal.substring(0,15)}...`;
                            }
                            this.deletecontent=`¿Desea eliminar el exámen final extra con título "${tituloExtraExamenFinal}"?`;
                            this.selectedActivity=actividad;
                            this.deleteactivityDialog=true;
                            break;
                        case 9:
                            this.deletecontext="parcial extra";
                            var tituloExtraParcial=actividad.title;
                            if(actividad.title.length>20){
                                tituloExtraParcial=`${tituloExtraParcial.substring(0,15)}...`;
                            }
                            this.deletecontent=`¿Desea eliminar el parcial extra con título "${tituloExtraParcial}"?`;
                            this.selectedActivity=actividad;
                            this.deleteactivityDialog=true;
                            break;
                    }
                    break;
            }
        },
        onscrollTable(value){
            this.transformLeft=value.target.scrollLeft;
        },
        getEstadoText(actividad,style){
            if(!style){
                if(actividad){
                    switch(actividad.idEstadoActividad){
                        case 1:
                            return "Pendiente entregar";
                        case 2:
                            return "Entregada";
                        case 3:
                            if(actividad.actividadesMateriasBloquesEntregas)
                                return `${actividad.actividadesMateriasBloquesEntregas.calificacion} / ${actividad.idActividadMateriaBloqueNavigation.valorNeto}`;
                            return "";
                        case 4:
                            return "Anulada";
                    }
                }
                return "";
            }else{
                if(actividad){
                    switch(actividad.idEstadoActividad){
                        case 1:
                            return "text-subtitle-2 font-weight-bold red--text";
                        case 2:
                            return "text-subtitle-2 font-weight-bold";
                        case 3:
                            if(actividad.actividadesMateriasBloquesEntregas.calificacion>(actividad.idActividadMateriaBloqueNavigation.valorNeto/2))    
                                return "text-subtitle-2 font-weight-bold green--text";
                            else if(actividad.actividadesMateriasBloquesEntregas.calificacion==(actividad.idActividadMateriaBloqueNavigation.valorNeto/2))
                                return "text-subtitle-2 font-weight-bold";
                            else if(actividad.actividadesMateriasBloquesEntregas.calificacion<(actividad.idActividadMateriaBloqueNavigation.valorNeto))
                                return "text-subtitle-2 font-weight-bold red--text";
                            return "text-subtitle-2 font-weight-bold";
                        case 4:
                            return "text-subtitle-2 font-weight-bold red--text";
                    }
                }
                return "";
            }
        },
        getNombreAlumno(estudianteActividad){
            if(estudianteActividad)
                return `${estudianteActividad.idEstudianteNavigation.nombre1} 
                ${estudianteActividad.idEstudianteNavigation.nombre2} 
                ${estudianteActividad.idEstudianteNavigation.apellido1} 
                ${estudianteActividad.idEstudianteNavigation.apellido2}`;
            return "";
        },
        getActividadTitulo(actividad){
            if(actividad)
                return actividad.title;
            return "";
        },
        async onchangeBloque(val){
            await this.loadAll2(val);
            this.initializeActividadesEstudiante();
            this.initializeScrollTable();
        },
        async ondeletePost(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:

                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            await this.loadAll2(this.currentBloque);
            this.initializeActividadesEstudiante();
            this.initializeScrollTable();
        },
        async onsaveActividad(response){
            switch (response[0]) {
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    this.selectedOption="";
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            
                default:
                    break;
            }
            await this.loadAll2(this.currentBloque);
            this.initializeActividadesEstudiante();
            this.initializeScrollTable();
        },
        async onupdateActividad(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            await this.loadAll2(this.currentBloque);
            this.initializeActividadesEstudiante();
            this.initializeScrollTable();
        },
        loadActividadesBloque(bloque){return this.$axios.get(`api/docentes/bloques/${bloque}/actividades`)},
        async loadAll2(bloque){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);
            
            var httpCalls=[this.loadActividadesBloque(bloque),this.loadEstudiantesActividades(bloque)];

            await this.$axios.all(httpCalls).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.actividadesData=response[0].data.data;
                        this.estudiantesActividadesData=response[1].data.data;

                    }
                }
            }).catch((error)=>{
                error;
            });
            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        },
        loadBloques(){return this.$axios.get(`api/docentes/bloques/${this.$route.params.idassignment}`)},
        loadEstudiantesActividades(bloque){return this.$axios.get(`api/docentes/asignaciones/asignacion/${this.$route.params.idassignment}/estudiantes/?actividades=true&bloque=${bloque}`)},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadBloques()]).then(async (response)=>{
                if(response[0].status==200)
                    if(response[0].data.code==1){
                        this.bloquesData=response[0].data.data;

                        this.currentBloque=this.bloquesData.find(item=>item.fechaIniciado&&!item.fechaTerminado);

                        if(this.currentBloque){
                            this.currentBloque=this.currentBloque.idMateriaBloque;

                            await this.loadAll2(this.currentBloque);
                            this.initializeActividadesEstudiante();
                        }

                        this.initializeScrollTable();
                    }
            }).catch((error)=>{
                error;
            });
            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        },
        initializeActividadesEstudiante(){

            this.estudiantesActividadesData.forEach(estudiante=>{

                var indexActividades=0;
                var orderedActividades=[];

                if(estudiante.actividadesMateriasBloquesParticipantes.length>0){
                    this.actividadesData.forEach(actividad=>{
                        var encontrada=estudiante.actividadesMateriasBloquesParticipantes.find(item=>item.idActividadMateriaBloque==actividad.actividad.idActividadMateriaBloque);

                        if(encontrada){
                            orderedActividades.push(encontrada);
                        }
                        else
                            orderedActividades.push(null);

                        indexActividades++;
                        indexActividades;
                    });

                    estudiante.actividadesMateriasBloquesParticipantes=orderedActividades;
                }else{
                    estudiante.actividadesMateriasBloquesParticipantes=Array(this.actividadesData.length).fill(null);
                }
            });
        },
        initializeScrollTable(){
            this.transformLeft=0;
            var dt=this.$refs.calificacionesTable.$el.querySelector(".v-data-table__wrapper")
            dt.addEventListener("scroll",this.onscrollTable);
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>

table th{
    position: relative;
    z-index: 1 !important;
}

table thead th:first-child{
    z-index: 2 !important;
}

</style>