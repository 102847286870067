<template>
    <v-card max-width="500" ripple hover class="card-style rounded-xl" :color="color" :dark="dark" :light="!dark" :to="pathTo" @click="$emit('onclick')">
        <v-row no-gutters justify="center"><v-icon size="75" dark>{{icon}}</v-icon></v-row>
        <h1 align="center" unselectable="on" class="card-text font-weight-bold text-h5 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h4">
            {{text}}
        </h1>
    </v-card>
</template>
<script>
export default {
    props:[
        "text",
        "pathTo",
        "color",
        "dark",
        "icon",
        "loading"
    ]
}
</script>
<style lang="stylus" scoped>

</style>