<template>
    <v-dialog persistent v-model="pagosEstudiante" width="650" scrollable>
        <v-dialog persistent width="400" v-model="addpagoDescription">
            <v-card :loading="isLoading" :disabled="isLoading">
                <v-card-title>Pago</v-card-title>
                <v-card-text>
                    Ingrese el nombre del pago que desea agregar
                    <br>
                    <br>
                    <v-form ref="pagodescriptivo">
                        <v-text-field
                        :color="$store.state.accentColor"
                        label="Nombre de pago"
                        filled
                        autocomplete="off"
                        rounded
                        v-model="newpago.pago"
                        :rules="newpago.pagoRules"
                        >
                        </v-text-field>
                        <v-text-field
                        :color="$store.state.accentColor"
                        type="number"
                        filled
                        rounded
                        label="Monto de pago"
                        autocomplete="off"
                        v-model="newpago.monto"
                        :rules="newpago.montoRules"
                        max
                        >
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="addpagoDescription=false;">Cancelar</v-btn>
                    <v-btn text :color="$store.state.accentColor" @click="addcustompagoCall">Agregar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent width="400" v-model="addpagoConfirmation">
            <v-card :loading="isLoading" :disabled="isLoading">
                <v-card-title>Confirmacion</v-card-title>
                <v-card-text>
                    ¿Desea agregar al alumno el pago "{{getpagoDescription}}"?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="addpagoConfirmation=false;">Cancelar</v-btn>
                    <v-btn text :color="$store.state.accentColor" @click="addpagoCall">Agregar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent width="400" v-model="deletepagoConfirmation">
            <v-card :loading="isLoading" :disabled="isLoading">
                <v-card-title>Confirmacion</v-card-title>
                <v-card-text>
                    ¿Desea eliminar al alumno el pago "{{getpagoDescription}}"?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deletepagoConfirmation=false;">Cancelar</v-btn>
                    <v-btn text :color="$store.state.accentColor" @click="deletepagoCall">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent width="400" v-model="generarpagosConfirmation">
            <v-card :loading="isLoading" :disabled="isLoading">
                <v-card-title>Confirmacion</v-card-title>
                <v-card-text>
                    ¿Desea generar los pagos del alumno "{{getEstudianteNombre}}"?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="generarpagosConfirmation=false;">Cancelar</v-btn>
                    <v-btn text :color="$store.state.accentColor" @click="generarpagosCall">Generar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title>Pagos Estudiante</v-card-title>
            <v-card-text v-if="loaded">
                <v-row no-gutters class="text-caption font-weight-bold" justify="center">{{getEstudianteNombre}}</v-row>
                <br>
                <div v-if="mostrarPagosMensualidades">
                    <v-card outlined class="rounded-lg">
                        <v-row class="ma-0 pa-2" justify="end">
                            <v-menu offset-y v-model="pagosPopup">
                                <template v-slot:activator="{on}">
                                    <v-btn small outlined rounded v-on="on">Agregar pago</v-btn>
                                </template>
                                <v-list class="pa-0 ma-0" dense :disabled="isLoadingPopup">
                                    <v-progress-linear v-if="isLoadingPopup" :color="$store.state.accentColor" indeterminate></v-progress-linear>
                                    <v-list-item v-for="(pago,index) in pagosgradoData" :key="index" @click="()=>{onselectPago(pago)}">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{pago.idPagoNavigation.descripcion}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-row>
                        <v-row justify="center" no-gutters class="text-h6 font-weight-bold">Pagos</v-row>
                        <v-divider></v-divider>
                        <v-data-table
                        hide-default-footer
                        :headers="headersPagos" :items="pagosData"
                        >
                            <template v-slot:item.pago.idPagoNavigation.descripcion="{item}">
                                <div v-if="item.pago">{{item.pago.idPagoNavigation.descripcion}}</div>
                                <div v-if="!item.pago">{{item.descripcionPersonalizado}}</div>
                            </template>
                            <template v-slot:item.pago.cantidad="{item}">
                                <div v-if="item.pago">{{item.pago.cantidad}}</div>
                                <div v-if="!item.pago">{{item.montoPersonalizado}}</div>
                            </template>
                            <template v-slot:item.fechaPagado="{item}">
                                <div v-if="item.pagado">{{item.fechaPagado.split("T")[0]}}</div>
                            </template>
                            <template v-slot:item.pagado="{item}">
                                <v-row no-gutters justify="center">
                                    <v-chip :color="getColorPago(item)" dark @click="clicestadopagoAccion(item)">
                                        {{getPagadoText(item)}}
                                    </v-chip>
                                </v-row>
                            </template>
                            <template v-slot:item.eliminar="{item}">
                                <v-btn icon color="red" @click="deletePago(item)"><v-icon>clear</v-icon></v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                    <br>
                    <v-card outlined class="rounded-lg">
                        <br>
                        <v-row justify="center" no-gutters class="text-h6 font-weight-bold">Registro de mensualidades</v-row>
                        <br>
                        <v-row class="ma-0 px-2">
                            <div class="text-subtitle-2">{{getColegiaturaTexto}} <v-btn x-small rounded outlined>Actualizar</v-btn></div>
                        </v-row>
                        <br>
                        <v-divider></v-divider>
                        <v-data-table
                        hide-default-footer
                        :headers="headersMensualidades" :items="mensualidadesData"
                        >
                            <template v-slot:item.mes="{item}">
                                    {{getMesMensualidadText(item)}}
                                </template>
                            <template v-slot:item.fechaPagado="{item}">
                                <div v-if="item.pagada">{{item.fechaPagado.split("T")[0]}}</div>
                            </template>
                            <template v-slot:item.cantidad="{item}">
                                <div v-if="item.pagada">{{item.cantidad}}</div>
                            </template>
                            <template v-slot:item.pagado="{item}">
                                <v-row no-gutters justify="center">
                                    <v-chip :color="getColorPago(item)" dark @click="()=>{clicestadoAccion(item)}">
                                        {{getPagadoText(item)}}
                                    </v-chip>
                                </v-row>
                            </template>
                            <template v-slot:item.fechaMaximaPago="{item}">
                                    {{getHumanDate(item.fechaMaximaPago)}}
                            </template>
                        </v-data-table>
                    </v-card>
                </div>
                <div v-if="!mostrarPagosMensualidades&&loaded">
                    <v-row no-gutters class="text-h6 font-weight-bold" justify="center">El estudiante no ha generado su cuenta de pagos aún.</v-row>
                    <br>
                    <v-row no-gutters justify="center" ><v-btn @click="generarPagos()" rounded outlined small>Generar pagos</v-btn></v-row>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :color="$store.state.accentColor" @click="$emit('onclose')">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import GetReadableDate from '../../../utils/timeUtils';

export default {
    props:[
        "pagosEstudiante",
        "estudianteGrado",
        "estudiante"
    ],
    watch:{
        pagosEstudiante(newval,oldval){
            oldval;

            if(newval){
                this.loaded=false;
                this.loadAll();
            }else{
                this.pagosData=[];
                this.mensualidadesData=[];
            }
        },
        pagosPopup(newval,oldval){
            oldval;
            if(newval){
                this.pagosgradoData=[];
                this.getpagosGrado();
            }
        },
        addpagoDescription(newval,oldval){
            oldval;
            if(!newval){
                this.$refs.pagodescriptivo.reset();
            }
        }
    },
    computed:{
        getColegiaturaTexto(){
            if(this.colegiaturaEspecificacion)
                return `Colegiatura actual: Q${this.colegiaturaEspecificacion.pago.cantidad} durante ${this.colegiaturaEspecificacion.numeroMeses} meses.`;
            return "";
        },
        getpagoDescription(){
            if(this.selectedPago)
                if(this.selectedPago.pago)
                    return this.selectedPago.pago.idPagoNavigation.descripcion;
                else
                    return this.selectedPago.descripcionPersonalizado;
            return "";
        },
        mostrarPagosMensualidades(){
            if(this.mensualidadesData.length>0)
                return true;
            return false;
        },
        getEstudianteNombre(){
            if(this.estudianteData)
                return `${this.estudianteData.nombre1} ${this.estudianteData.nombre2} ${this.estudianteData.nombre3} ${this.estudianteData.apellido1} ${this.estudianteData.apellido2}`;
            return "Cargando...";
        }
    },
    data:function(){
        return({
            loaded:false,
            isLoading:true,
            isLoadingPopup:false,
            pagosPopup:false,
            addpagoConfirmation:false,
            addpagoDescription:false,
            deletepagoConfirmation:false,
            generarpagosConfirmation:false,
            estudianteData:"",
            pagosData:[],
            mensualidadesData:[],
            pagosgradoData:[],
            selectedPago:"",
            colegiaturaEspecificacion:"",
            newpago:{
                pago:"",
                pagoRules:[
                    v=>!!v||"Nombre de pago es obligatorio",
                    v=>(v&&v.length<=50)||"Nombre de pago debe ser menor o igual a 50"
                ],
                monto:"",
                montoRules:[
                    v=>/\d+/.test(v)||"Monto de pago es requerido y debe ser numérico.",
                    v=>v>0&&v<=10000||`Monto de pago debe ser mayor a 0 y menor o igual a ${v}.`
                ]
            },
            headersPagos:[
                {text:"Nombre",value:"pago.idPagoNavigation.descripcion"},
                {text:"Meses de pago",value:"numeroMeses"},
                {text:"Monto (Q)",value:"pago.cantidad"},
                {text:"Fecha pagado",value:"fechaPagado"},
                {text:"Estado",value:"pagado"},
                {text:"Eliminar",value:"eliminar"}
            ],
            headersMensualidades:[
                {text:"Mes",value:"mes"},
                {text:"Fecha límite de pago",value:"fechaMaximaPago"},
                {text:"Monto (Q)",value:"cantidad"},
                {text:"Fecha pagada",value:"fechaPagado"},
                {text:"Estado",value:"pagado"},
            ],
        });
    },
    methods:{
        getMesMensualidadText(item){
            var mes="";
            var maximoMonth=new Date(item.fechaMaximaPago);

            mes=`${maximoMonth.toLocaleString('default', { month: 'long' })}`;

            return mes;
        },
        async generarpagosCall(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.get(`api/estudiantes/inscripcion/${this.estudianteGrado}/pagos/generar`).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.generarpagosConfirmation=false;
        },
        clicestadopagoAccion(item){
            if(!item.pagado)
                this.pagarpagoCall(item);
            else
                this.anularpagoCall(item);
        },
        async deletepagoCall(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.delete(`api/estudiantes/inscripcion/pagos/${this.selectedPago.idEstudianteGradoPago}/eliminar`).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoading=false;
            this.deletepagoConfirmation=false;
        },
        deletePago(item){
            this.selectedPago=item;
            this.deletepagoConfirmation=true;
        },
        async addcustompagoCall(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.put(`api/estudiantes/inscripcion/${this.estudianteGrado}/pagos/agregar`,
            this.newpago).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoading=false;
            this.addpagoConfirmation=false;
            this.addpagoDescription=false;
        },
        async addpagoCall(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.put(`api/estudiantes/inscripcion/${this.estudianteGrado}/pagos/agregar`,
            this.selectedPago).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoading=false;
            this.addpagoConfirmation=false;
        },
        onselectPago(pago){
            if(pago.isPersonalizado){
                this.addpagoDescription=true;
                return;
            }

            this.selectedPago=pago;
            this.addpagoConfirmation=true;
        },
        async getpagosGrado(){
            this.isLoadingPopup=true;

            await this.$axios.get(`api/estudiantes/inscripcion/${this.estudianteGrado}/grado/pagos`).then((response)=>{
                if(response.status==200){
                    this.pagosgradoData=response.data.data;
                    this.pagosgradoData=this.pagosgradoData.filter(item=>!item.idPagoNavigation.colegiatura);

                    this.pagosgradoData.push({
                        idPagoNavigation:{
                            descripcion:"Pago personalizado"
                        },
                        isPersonalizado:true
                    });
                }
            }).catch((error)=>{
                error;
            });

            this.isLoadingPopup=false;
        },
        generarPagos(){
            this.generarpagosConfirmation=true;
        },
        clicestadoAccion(mensualidad){
            if(!mensualidad.pagada)
                this.pagarmensualidadCall(mensualidad);
            else
                this.anularmensualidadCall(mensualidad);
        },
        getHumanDate(date){
            var datenotime=GetReadableDate(date);
            return datenotime.substring(0,datenotime.length-6);
        },
        getPagadoText(item){
            if(item.pagada!=undefined){
                var fechaMaximaPago=new Date(item.fechaMaximaPago);
                fechaMaximaPago.setDate(fechaMaximaPago.getDate()+1);

                if(this.$store.state.currentRealDate>fechaMaximaPago){
                    if(item.pagada){
                        return "Pagado";
                    }else{
                        return  "Pendiente";
                    }
                }else{
                    if(!item.pagada){
                        return "Pendiente";
                    }else{
                        return "Pagado";
                    }
                }
            }else if(item.pagado!=undefined){
                if(item.pagado){
                    return "Pagado";
                }else{
                    return  "Pendiente";
                }
            }
        },
        getColorPago(item){
            if(item.pagada!=undefined){
                var fechaMaximaPago=new Date(item.fechaMaximaPago);
                fechaMaximaPago.setDate(fechaMaximaPago.getDate()+1);
    
                if(this.$store.state.currentRealDate>fechaMaximaPago){
                    if(item.pagada){
                        return "green";
                    }else{
                        return  "orange";
                    }
                }else{
                    if(!item.pagada){
                        return "";
                    }else{
                        return "green";
                    }
                }
            }else if(item.pagado!=undefined){
                if(!item.pagado){
                    return "";
                }else{
                    return "green";
                }
            }
        },
        async pagarpagoCall(pago){
            this.isLoading=this.$store.state.accentColor;
            await this.$axios.put(`api/estudiantes/pago/${pago.idEstudianteGradoPago}/pagar`).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoading=false;
        },
        async anularpagoCall(pago){
            this.isLoading=this.$store.state.accentColor;
            await this.$axios.put(`api/estudiantes/pago/${pago.idEstudianteGradoPago}/anular`).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoading=false;
        },
        async pagarmensualidadCall(mensualidad){
            this.isLoading=this.$store.state.accentColor;
            await this.$axios.put(`api/estudiantes/mensualidad/${mensualidad.idEstudianteGradoColegiatura}/pagar`).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoading=false;
        },
        async anularmensualidadCall(mensualidad){
            this.isLoading=this.$store.state.accentColor;
            await this.$axios.put(`api/estudiantes/mensualidad/${mensualidad.idEstudianteGradoColegiatura}/anular`).then((response)=>{
                response;
            }).catch((error)=>{
                error;
            });
            await this.loadAll();

            this.isLoading=false;
        },
        loadEstudiante(){return this.$axios.get(`api/estudiantes/${this.estudiante}`);},
        loadPagos(){return this.$axios.get(`api/estudiantes/inscripcion/${this.estudianteGrado}/pagos`);},
        loadMensualidades(){return this.$axios.get(`api/estudiantes/inscripcion/${this.estudianteGrado}/mensualidades`)},
        async loadAll(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.all([this.loadEstudiante(),this.loadPagos(),this.loadMensualidades()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200&&response[2].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1&&response[2].data.code==1){
                        this.estudianteData=response[0].data.data;
                        this.pagosData=response[1].data.data;
                        this.mensualidadesData=response[2].data.data;
                        
                        this.initializePagos();
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.loaded=true;
            this.isLoading=false;
        },
        initializePagos(){
            this.pagosData.map(item=>{
                if(item.idPagoNivelEstudiantil){
                    item.pago=item.idPagoNivelEstudiantilNavigation;
                    delete item.idPagoNivelEstudiantilNavigation;
                }
                else{
                    item.pago=item.idPagoNivelEstudiantilCarreraNavigation;
                    delete item.idPagoNivelEstudiantilCarreraNavigation;
                }
            });

            this.colegiaturaEspecificacion=this.pagosData.filter(item=>item.pago);
            this.colegiaturaEspecificacion=this.colegiaturaEspecificacion.filter(item=>item.pago.idPagoNavigation.colegiatura);

            if(this.colegiaturaEspecificacion.length>0)
                this.colegiaturaEspecificacion=this.colegiaturaEspecificacion[0];

            var personalizados=this.pagosData.filter(item=>!item.pago);

            this.pagosData=this.pagosData.filter(item=>item.pago);
            this.pagosData=this.pagosData.filter(item=>!item.pago.idPagoNavigation.colegiatura);

            this.pagosData=[...this.pagosData,...personalizados];

        }
    }
}
</script>
<style scoped>

</style>