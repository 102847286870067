<template>
    <div>
        <div v-if="!isLoading">
            <v-container class="custom-container" :style="'height:77vh'">
                <br>
                <v-row no-gutters justify="end">
                    <v-col align="center" cols="4" xs="12" sm="2" md="1">
                        <v-avatar size="20" :color="$vuetify.theme.dark?'white':'black'"></v-avatar>
                        <h1 class="text-caption">No iniciado</h1>
                    </v-col>
                    <v-col align="center" cols="4" xs="12" sm="2" md="1">
                        <v-avatar size="20" :color="'green'"></v-avatar>
                        <h1 class="text-caption">Iniciado</h1>
                    </v-col>
                    <v-col align="center" cols="4" xs="12" sm="2" md="1">
                        <v-avatar size="20" :color="$store.state.accentColor"></v-avatar>
                        <h1 class="text-caption">Finalizado</h1>
                    </v-col>
                </v-row>
                <br>
                <v-row no-gutters justify="center" v-if="$vuetify.breakpoint.xsOnly">
                    <v-col cols="12" xs="12" sm="3">
                        <v-select
                        dense
                        label="Bloques"
                        filled
                        rounded
                        :items="bloquesData"
                        :item-text="(item)=>`Bloque ${item.bloque.bloque}`"
                        item-value="idMateriaBloque"
                        @change="onchangeBloque"
                        return-object
                        :color="$store.state.accentColor2"
                        item-color=""
                        v-model="selectedBloque"
                        >

                        </v-select>
                    </v-col>
                </v-row>
                <div  v-if="$vuetify.breakpoint.smAndUp">
                    <v-row v-for="bloque in bloquesData" :key="bloque.bloque.idMateriaBloque">
                        <bloque-item @ondetailstarea="ondetailsTarea"
                        @ondetailsmaterial="ondetailsMaterial"
                        @ondetailstareaextra="ondetailsTareaExtra"
                        @ondetailsexamenfinal="ondetailsExamenFinal"
                        @ondetailsparcial="ondetailsParcial"
                        @ondetailsparcialextra="ondetailsParcialExtra"
                        @ondetailsexamenfinalextra="ondetailsExamenFinalExtra"
                        :bloque="bloque" @onopcion="(opcion)=>{onopcionBloque(opcion,bloque.bloque)}"></bloque-item>
                    </v-row>
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly">
                    <v-row v-for="bloque in xsBloquesData" :key="bloque.bloque.idMateriaBloque">
                        <bloque-item @ondetailstarea="ondetailsTarea" 
                        @ondetailsmaterial="ondetailsMaterial"
                        @ondetailstareaextra="ondetailsTareaExtra"
                        @ondetailsexamenfinal="ondetailsExamenFinal"
                        @ondetailsparcial="ondetailsParcial"
                        @ondetailsparcialextra="ondetailsParcialExtra"
                        @ondetailsexamenfinalextra="ondetailsExamenFinalExtra"
                        :bloque="bloque" @onopcion="(opcion)=>{onopcionBloque(opcion,bloque.bloque)}"></bloque-item>
                    </v-row>
                </div>
                <router-view
                @onloadtareaerror="onloaderror" @onloadmaterialerror="onloaderror"
                />
            </v-container>
        </div>
    </div>
</template>
<script>
import BloqueItem from '../common/BloqueItem.vue'
export default {
    props:[
        "tab"
    ],
    watch:{
        tab:function(newval,oldval){
            oldval;
            
            if(newval==1){
                this.loadAll();
            }
        }
    },
    components:{BloqueItem},
    data:function(){
        return ({
            isLoading:false,
            bloquesData:[],
            xsBloquesData:[],
            selectedBloque:""
        });
    },
    methods:{
        onloaderror(){
            this.loadAll();
        },
        ondetailsExamenFinalExtra(actividad){
            this.$router.push({name:"examen_final_extra_estudiante_detalles",params:{idexamenfinal:actividad.examenFinal.actividad.idActividadMateriaBloque}});
        },
        ondetailsParcialExtra(actividad){
            this.$router.push({name:"parcial_extra_estudiante_detalles",params:{idparcial:actividad.examenFinal.actividad.idActividadMateriaBloque}});
        },
        ondetailsParcial(actividad){
            this.$router.push({name:"parcial_estudiante_detalles",params:{idparcial:actividad.examenFinal.actividad.idActividadMateriaBloque}});
        },
        ondetailsExamenFinal(actividad){
            this.$router.push({name:"examen_final_estudiante_detalles",params:{idexamenfinal:actividad.examenFinal.actividad.idActividadMateriaBloque}});
        },
        ondetailsTareaExtra(actividad){
            this.$router.push({name:"tarea_extra_estudiante_detalles",params:{idtarea:actividad.tarea.actividad.idActividadMateriaBloque}});
        },
        ondetailsTarea(actividad){
            this.$router.push({name:"tarea_estudiante_detalles",params:{idtarea:actividad.tarea.actividad.idActividadMateriaBloque}});
        },
        ondetailsMaterial(actividad){
            this.$router.push({name:"material_estudiante_detalles",params:{idmaterial:actividad.actividad.idActividadMateriaBloque}});
        },
        onchangeBloque(val){
            this.xsBloquesData=[];
            this.xsBloquesData.push(val);
        },
        loadBloques(){return this.$axios.get(`api/estudiantil/bloques/${this.$route.params.idassignment}?actividades=true`)},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadBloques()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.bloquesData=response[0].data.data;

                        var selectedBloque=this.bloquesData.find(item=>item.fechaIniciado&&!item.fechaTerminado);
                        selectedBloque;

                        if(this.bloquesData.length>0){
                            var selectedBloqueFound=this.bloquesData.find(item=>item.fechaIniciado&&!item.fechaTerminado);

                            if(selectedBloqueFound){
                                this.selectedBloque=selectedBloque;
                            }else{
                                this.selectedBloque=this.bloquesData[0];
                            }

                            this.xsBloquesData.push(this.selectedBloque);
                        }
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        },
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>

</style>