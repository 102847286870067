<template>
    <div>
        <div v-if="allLoaded">
            <iniciar-finalizar-dialog :iniciarFinalizarDialog="iniciarFinalizarDialog" :bloque="selectedBloque" :action="action"
            @onclose="iniciarFinalizarDialog=false"
            @onend="refreshBloques"
            @onstart="refreshBloques"
            >

            </iniciar-finalizar-dialog>

            <v-container class="custom-container" :style="'height:77vh'">
                <br>
                <v-row no-gutters justify="end">
                    <v-col align="center" cols="4" xs="12" sm="2" md="1">
                        <v-avatar size="20" :color="$vuetify.theme.dark?'white':'black'"></v-avatar>
                        <h1 class="text-caption">No iniciado</h1>
                    </v-col>
                    <v-col align="center" cols="4" xs="12" sm="2" md="1">
                        <v-avatar size="20" :color="'green'"></v-avatar>
                        <h1 class="text-caption">Iniciado</h1>
                    </v-col>
                    <v-col align="center" cols="4" xs="12" sm="2" md="1">
                        <v-avatar size="20" :color="$store.state.accentColor"></v-avatar>
                        <h1 class="text-caption">Finalizado</h1>
                    </v-col>
                </v-row>
                <br>
                <v-row no-gutters justify="center" v-if="$vuetify.breakpoint.xsOnly">
                    <v-col cols="12" xs="12" sm="3">
                        <v-select
                        dense
                        label="Bloques"
                        filled
                        rounded
                        :items="bloquesData"
                        :item-text="(item)=>`Bloque ${item.bloque.bloque}`"
                        item-value="idMateriaBloque"
                        @change="onchangeBloque"
                        return-object
                        :color="$store.state.accentColor2"
                        item-color=""
                        v-model="selectedBloque"
                        >

                        </v-select>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-menu offset-y>
                        <template v-slot:activator="{on:menu}">
                            <v-btn v-on="{...menu}" rounded small :color="$store.state.accentColor2" dark><v-icon>add</v-icon> Crear actividad</v-btn>
                        </template>
                        <v-list class="pa-0" dense>
                            <v-list-item v-for="tipoactividad in getactivityList" :key="tipoactividad.idTipoActividad" @click="()=>{onselectOptionPost(tipoactividad)}">
                                <v-list-item-avatar>
                                    <v-icon :color="$store.state.accentColor2">{{tipoactividad.icono}}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{tipoactividad.nombre}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-row>
                <br>
                <v-row no-gutters class="mt-2 text-caption">
                    <v-spacer></v-spacer>
                    <div>{{getPunteosBloques}}</div>
                </v-row>
                <div class="overflow_smUp" v-if="$vuetify.breakpoint.smAndUp">
                    <v-row v-for="bloque in bloquesData" :key="bloque.bloque.idMateriaBloque">
                        <bloque-item @ondetailstarea="ondetailsTarea"
                        @onupdatetarea="updateTarea"

                        @ondetailsmaterial="ondetailsMaterial"
                        @onupdatematerial="updateMaterial"

                        @ondetailstareaextra="ondetailsTareaExtra" 
                        @onupdatetareaextra="updateTareaExtra"

                        @ondetailsexamenfinal="ondetailsExamenFinal" 
                        @onupdateexamenfinal="updateExamenFinal"

                        @ondetailsparcial="ondetailsParcial" 
                        @onupdateparcial="updateParcial"

                        @ondetailsparcialextra="ondetailsParcialExtra" 
                        @onupdateparcialextra="updateParcialExtra"

                        @ondetailsexamenfinalextra="ondetailsExamenFinalExtra" 
                        @onupdateexamenfinalextra="updateExamenFinalExtra"

                        @ondeletepost="ondeletePost"

                        :bloque="bloque" @onopcion="(opcion)=>{onopcionBloque(opcion,bloque.bloque)}"></bloque-item>
                    </v-row>
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly">
                    <v-row v-for="bloque in xsBloquesData" :key="bloque.bloque.idMateriaBloque">
                        <bloque-item @ondetailstarea="ondetailsTarea" 
                        @onupdatetarea="updateTarea"

                        @ondetailsmaterial="ondetailsMaterial"
                        @onupdatematerial="updateMaterial"

                        @ondetailstareaextra="ondetailsTareaExtra" 
                        @onupdatetareaextra="updateTareaExtra"

                        @ondetailsexamenfinal="ondetailsExamenFinal" 
                        @onupdateexamenfinal="updateExamenFinal"

                        @ondetailsparcial="ondetailsParcial" 
                        @onupdateparcial="updateParcial"

                        @ondetailsparcialextra="ondetailsParcialExtra" 
                        @onupdateparcialextra="updateParcialExtra"

                        @ondetailsexamenfinalextra="ondetailsExamenFinalExtra" 
                        @onupdateexamenfinalextra="updateExamenFinalExtra"

                        @ondeletepost="ondeletePost"

                        :bloque="bloque" @onopcion="(opcion)=>{onopcionBloque(opcion,bloque.bloque)}"></bloque-item>
                    </v-row>
                </div>
                <router-view :action="actionActivity" :tipoactividad="selectedOption" 
                @onsavetarea="onsaveActividad"
                @onupdatetarea="onupdateActividad"
                @ondeletetarea="ondeletePost"
                @onupdatetareaCall="updateTarea"

                @onsavematerial="onsaveActividad"
                @ondeletematerial="ondeletePost"
                @onupdatematerial="onupdateActividad"
                @onupdatematerialCall="updateMaterial"

                @onsaveexamenfinal="onsaveActividad"
                @onupdateexamenfinal="onupdateActividad"
                @ondeleteexamenfinal="ondeletePost"
                @onupdateexamenfinalCall="updateExamenFinal"

                @onsaveparcial="onsaveActividad"
                @onupdateparcial="onupdateActividad"
                @ondeleteparcial="ondeletePost"
                @onupdateparcialCall="updateParcial"

                @onupdatetareaextraCall="updateTareaExtra"
                @onupdateparcialextraCall="updateParcialExtra"
                @onupdateexamenfinalextraCall="updateExamenFinalExtra"
                />
            </v-container>
        </div>
    </div>    
</template>
<script>
import BloqueItem from './BloqueItem.vue';
import IniciarFinalizarDialog from './IniciarFinalizarDialog.vue';

export default {
  components: { BloqueItem, IniciarFinalizarDialog},
    props:[
        "tab"
    ],
    watch:{
        tab:function(newval,oldval){
            oldval;

            if(newval==1){
                this.loadAll();
            }
        }
    },
    computed:{
        getPunteosBloques(){
            if(this.bloquesData.length>0){
                return `Pts zona/bloque: ${this.bloquesData[0].bloque.maxZona}. Pts examen/bloque: ${this.bloquesData[0].bloque.maxExamen}`;
            }
            return "";
        },
        getactivityList(){
            return this.tiposActividadesData.filter(item=>item.nombre!="Aviso"&&item.visible);
        }
    },
    data:function(){
        return ({
            isLoading:false,
            iniciarFinalizarDialog:false,
            deleteactivityDialog:false,
            allLoaded:false,
            selectedBloque:"",
            selectedOption:"",
            action:"",
            bloquesData:[],
            xsBloquesData:[],
            tiposActividadesData:[],
            deletecontent:"",
            deletecontext:"",
            actionActivity:"",
            extraActivity:""
        });
    },
    methods:{
        refreshBloques(){
            this.loadAll();
        },
        updateExamenFinalExtra(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"examen_final_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        ondetailsExamenFinalExtra(actividad){
            this.$router.push({name:"examen_final_extra_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        updateParcialExtra(actividad){            
            this.actionActivity="edit";
            this.$router.push({name:"parcial_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        ondetailsParcialExtra(actividad){
            this.$router.push({name:"parcial_extra_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        updateParcial(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"parcial_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        ondetailsParcial(actividad){
            this.$router.push({name:"parcial_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateExamenFinal(actividad){
            this.actionActivity="edit";

            this.$router.push({name:"examen_final_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        ondetailsExamenFinal(actividad){
            this.$router.push({name:"examen_final_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateTarea(actividad){
            this.actionActivity="edit";

            this.$router.push({name:"tarea_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        ondetailsTarea(actividad){
            this.$router.push({name:"tarea_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateTareaExtra(actividad){
            this.actionActivity="edit";

            this.$router.push({name:"tarea_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        ondetailsTareaExtra(actividad){
            this.$router.push({name:"tarea_extra_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateMaterial(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"material_docente_editar",params:{idmaterial:actividad.actividad.idActividadMateriaBloque}});
        },
        ondetailsMaterial(actividad){
            this.$router.push({name:"material_docente_detalles",params:{idmaterial:actividad.actividad.idActividadMateriaBloque}});
        },
        onselectOptionPost(val){
            this.selectedOption=val;
            
            switch(val.nombre){
                case "Parcial":
                    this.actionActivity="add";
                    this.$router.push({name:"parcial_docente"});
                    break;
                case "Examen final":
                    this.actionActivity="add";
                    this.$router.push({name:"examen_final_docente"});
                    break;
                case "Tarea":
                    this.actionActivity="add";
                    this.$router.push({name:"tarea_docente"});
                    break;
                case "Material":
                    this.actionActivity="add";
                    this.$router.push({name:"material_docente"});
                    break;
                default:
                    this.selectedOption="";
            }
        },
        ondeletePost(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }

            this.loadAll();
        },
        onsaveActividad(response){
            switch (response[0]) {
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    this.selectedOption="";
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            
                default:
                    break;
            }
            this.loadAll();
        },
        onupdateActividad(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.loadAll();
        },
        onchangeBloque(val){
            this.xsBloquesData=[];
            this.xsBloquesData.push(val);
        },
        onopcionBloque(opcion,bloque){
            switch(opcion.toLowerCase()){
                case "finalizar":case "iniciar":
                    this.selectedBloque=bloque;
                    this.action=opcion.toLowerCase();
                    this.iniciarFinalizarDialog=true;
                    break;
                case "tarea extra":
                    this.actionActivity="add";
                    this.$router.push({name:"tarea_extra_docente",params:{bloque:bloque.idMateriaBloque}});
                    break;
                case "parcial extra":
                    this.actionActivity="add";
                    this.$router.push({name:"parcial_extra_docente",params:{bloque:bloque.idMateriaBloque}});
                    break;
                case "examen final extra":
                    this.actionActivity="add";
                    this.$router.push({name:"examen_final_extra_docente",params:{bloque:bloque.idMateriaBloque}});
                    break;
            }
        },
        loadTiposActividades(){return this.$axios.get(`api/docentes/tiposactividades`)},
        loadBloques(){return this.$axios.get(`api/docentes/bloques/${this.$route.params.idassignment}?actividades=true`)},
        async loadAll(){
            this.allLoaded=false;
            this.$store.commit("setLoading",!this.allLoaded);

            await this.$axios.all([this.loadBloques(),this.loadTiposActividades()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.bloquesData=response[0].data.data;
                        this.tiposActividadesData=response[1].data.data;
                        this.extraActivity=this.tiposActividadesData.filter(item=>item.nombre.toLowerCase()=="tarea extra");

                        if(this.extraActivity.length>0)
                            this.extraActivity=this.extraActivity[0];

                        this.initializeNewUpdateActivity();

                        if(this.bloquesData.length>0){
                            var selectedBloque=this.bloquesData.find(item=>item.fechaIniciado&&!item.fechaTerminado);

                            if(selectedBloque){
                                this.selectedBloque=selectedBloque;
                            }else{
                                this.selectedBloque=this.bloquesData[0];
                            }

                            this.xsBloquesData.push(this.selectedBloque);
                        }
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.allLoaded=true;
            this.$store.commit("setLoading",!this.allLoaded);
        },
        initializeNewUpdateActivity(){
            if(this.$router.currentRoute.name=="tarea_docente_editar"){
                this.actionActivity="edit";
            }
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>
    .overflow_smUp{
        overflow-y: auto;
        height: 100%;
    }
</style>