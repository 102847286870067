<template>
    <div>
        <div v-if="$router.currentRoute.name=='grados_calificaciones'">
            <v-app-bar app flat :color="!$vuetify.theme.dark?'white':''">
                <v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="logoutDialog=true" text outlined rounded :color="$store.state.accentColor2">Cerrar sesión</v-btn>
            </v-app-bar>
            <logout-dialog :logoutDialog="logoutDialog" @onclose="logoutDialog=false"></logout-dialog>
            <generic-header :text="'Calificaciones generales'"></generic-header>
            <v-container>
                <v-row justify="center">
                    <v-col v-for="grado in grados" :key="grado.idGrado" cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                        <outlined-color-grade :grado="grado" @ongocalificaciones="(grado)=>ongocalificaciones(grado)"></outlined-color-grade>
                    </v-col>
                </v-row>
            </v-container>
        </div>   
        <transition name="scroll-y-transition" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>
<script>
import GenericHeader from '../../../globalComponents/GenericHeader';
import LogoutDialog from '../../../globalComponents/LogoutDialog.vue';
import OutlinedColorGrade from '../../../common/OutlinedColorGrade.vue';
export default {
    components:{GenericHeader,LogoutDialog,OutlinedColorGrade},
    data:function(){
        return({
            grados:[],
            logoutDialog:false
        });
    },
    methods:{
        ongocalificaciones(grado){
            this.$store.commit("setLoading",true);
            this.$router.push({name:"calificaciones_generales",params:{idgrado:grado}},()=>{
                this.$store.commit("setLoading",false);
            });
        },
        loadGrados(){return this.$axios.get(`api/docentes/grados?ciclo=${this.$route.params.ciclo}`)},
        async loadAll(){
            this.$store.commit("setLoading",true);

            await this.$axios.all([this.loadGrados()]).then((response)=>{
                if(response[0].status==200)
                    if(response[0].data.code==1)
                        this.grados=response[0].data.data;
            }).catch((error)=>{
                console.log(error);
            });

            this.$store.commit("setLoading",false);
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>

</style>