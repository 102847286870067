<template>
    <v-dialog v-model="videoplayerDialog" fullscreen persistent>
        <v-card class="overlay-dialog" tile dark>
            <v-row no-gutters>
                <v-btn icon x-large dark @click="$emit('onclose')"><v-icon>arrow_back</v-icon></v-btn>
                <v-col align-self="center">
                    <h1 class="text-subtitle-1">{{getVideoTitle}}</h1>
                </v-col>
            </v-row>
            <v-card-text>
                <v-container class="fill-height" style="height:90vh" fluid>
                    <v-row no-gutters justify="center">
                        <v-col align="center">
                            <iframe v-if="videoplayerDialog" :width="getVideoWidth" :height="getVideoHeight" :src="videoURL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    props:[
        "videoplayerDialog",
        "video"
    ],
    watch:{
        videoplayerDialog:function(newval,oldval){
            oldval;

            if(newval){
                this.videoURL=`https://www.youtube.com/embed/${this.video.id}`
            }else{
                this.videoURL="";
            }
        }
    },
    computed:{
        getVideoWidth:function(){
            if(this.$vuetify.breakpoint.xsOnly){
                return 330
            }else{
                return 560
            }
        },
        getVideoHeight:function(){
            if(this.$vuetify.breakpoint.xsOnly){
                return 270
            }else{
                return 315
            }
        },
        getVideoTitle:function(){
            if(this.video){
                return this.video.snippet.title;
            }

            return "";
        }
    },
    data:function(){
        return ({
            videoURL:""
        })
    },
    beforeDestroy(){
        this.videoURL="";
    }
}
</script>
<style>

</style>