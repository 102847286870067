<template>
    <div>
        <div v-if="$router.currentRoute.name=='materias_estudiante'">
            <v-app-bar app flat :color="$vuetify.theme.dark?'':'white'">
                <v-row justify="space-between">
                    <v-col class="pa-0" cols="4"><v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn></v-col>
                    <v-col class="pa-0" cols="4" align="center"><notifications-actividades/></v-col>
                    <v-col class="pa-0" cols="4"></v-col>
                </v-row>
                <v-progress-linear 
                absolute
                bottom
                v-if="isLoading"
                indeterminate 
                :color="$store.state.accentColor2" 
                background-color="transparent"></v-progress-linear>
            </v-app-bar>
            <logout-dialog :logoutDialog="logoutDialog" @onclose="logoutDialog=false" :context="'estudiante'"></logout-dialog>
            <generic-header :text="'Mis cursos'"></generic-header>
            <v-container class="fill-height" >
                <v-row justify="center">
                    <v-col v-for="materia in materiasData" :key="materia.idMateria" cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                        <v-sheet outlined :color="$store.state.accentColor2" rounded="xl" class="pa-2">
                            <v-card flat  class="rounded-xl card-text" ripple @click="goMateria(materia)"
                                :disabled="!materia.catedratico"
                                >
                                <v-container class="fill-height" style="height:150px">
                                    <v-row no-gutters justify="center">
                                        <h1 class="text-h5 font-weight-medium" align="center">{{materia.idMateriaNavigation.nombre}}</h1>
                                    </v-row>
                                    <v-list-item dense>
                                        <v-list-item-avatar>
                                            <v-icon :color="$store.state.accentColor2">person</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Profesor
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="wrap-text">
                                                {{getNombreCatedratico(materia)}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-container>
                            </v-card>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <transition name="scroll-y-transition" mode="out-in">
            <router-view />
        </transition>
    </div>    
</template>
<script>
import GenericHeader from '../../globalComponents/GenericHeader.vue';
import LogoutDialog from '../../globalComponents/LogoutDialog.vue';
import NotificationsActividades from '../home/NotificationsActividades.vue';
export default {
  components: { GenericHeader, LogoutDialog,NotificationsActividades },
    data:function(){
    GenericHeader
        return({
            isLoading:false,
            unsubscribe:"",
            materiasData:[],
            logoutDialog:false
        });
    },
    methods:{
        goMateria(materia){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            this.$router.push({name:"escritorio_estudiante",params:{idassignment:materia.catedratico.idGradoSeccionMateriaColaborador}},()=>{
                this.isLoading=false;
                this.$store.commit("setLoading",this.isLoading);
            });
        },
        getNombreCatedratico(materia){
            var text=`Catedrático no asignado`;
            if(materia.catedratico){
                text=`${materia.catedratico.nombres}`;
            }

            return text;
        },
        loadMaterias(){return this.$axios.get(`api/estudiantil/materias/${this.$route.params.ciclo}`);},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadMaterias()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.materiasData=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.loadAll();
    },
}
</script>
<style scoped>

</style>