<template>
    <div>
        <v-card color="transparent" flat :disabled="isLoading">            
            <div>
                <iniciar-materia-dialog :iniciarmateriaDialog="iniciarmateriaDialog" :asignacionData="asignacionData" @onclose="iniciarmateriaDialog=false"
                @onstart="oniniciarMateria"
                ></iniciar-materia-dialog>
                <video-player :videoplayerDialog="videoplayerDialog" @onclose="videoplayerDialog=false"
                :video="selectedVideo"
                ></video-player>
                <delete-dialog :show="deleteactivityDialog" :content="deletecontent" :context="deletecontext" :idEntity="getpostIdentity"
                @ondelete="ondeletePost" @onclose="deleteactivityDialog=false"
                ></delete-dialog>

                <edit-aviso-dialog :action="'edit'" :editavisoDialog="editavisoDialog" :asignacionData="asignacionData" :actividad="selectedActivity"
                :tipoactividad="selectedOption"

                @onclose="editavisoDialog=false"
                @onupdate="onupdateAviso"
                ></edit-aviso-dialog>

                <edit-aviso-dialog :action="'add'" :editavisoDialog="newavisoDialog" :asignacionData="asignacionData"
                :tipoactividad="selectedOption"
                @onclose="()=>{
                    newavisoDialog=false;
                    selectedOption='';
                    }"
                @onsave="onsaveActividad"
                ></edit-aviso-dialog>
                <v-container class="custom-container">
                    <v-sheet color="transparent">
                        <v-row justify="center">
                            <v-col class="ma-0">
                                <v-card :color="$store.state.accentColor2" flat class="rounded-xl" :height="$vuetify.breakpoint.mdAndUp?'300':'295'">
                                    <v-card-text class="pa-7 white--text">
                                        <v-row class="ma-0">
                                            <h1 class="text-h5 text-xs-h5 text-sm-h4 text-md-h4 text-xl-h3 font-weight-medium">{{getMateriaNombre}}</h1>
                                        </v-row>
                                        <v-row class="ma-0">
                                            <h1 class="text-subtitle-1 text-xs-subtitle-1 text-sm-subtitle-1 text-md-h6 text-lg-h6 text-xl-h5 font-weight-normal">{{getGradoNombre}}</h1>
                                        </v-row>
                                        <br>
                                        <br>
                                        <v-row no-gutters>
                                            <meet-dialog @onsuccess="loadAll()" :asignacionData="asignacionData"></meet-dialog>
                                        </v-row>
                                        <br>
                                        <v-row v-if="getPasscodeMeet" class="pt-5" no-gutters>
                                            <v-list-item dark class="pa-0 ma-0">
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-caption">
                                                        Contraseña de meet
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle class="text-caption">
                                                        {{getPasscodeMeet}}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-row>
                                        <br>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <v-row v-if="iniciarmateriaValidation">
                        <v-col>
                            <v-card class="rounded-xl card-text" outlined ripple>
                                <v-container :style="'height:75px'" class="fill-height" @click="iniciarmateriaDialog=true">
                                    <v-row justify="center">
                                        <v-col align="center">
                                            <h1 class="text-h5 font-weight-medium">Iniciar materia</h1>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row v-if="asignacionData.inicializado">
                        <v-col cols="0" xs="0" sm="0" md="3" v-if="$vuetify.breakpoint.mdAndUp">
                            <v-card class="rounded-xl" outlined>
                                <v-card-text>
                                    <p class="text-subtitle-2 font-weight-medium">Próximas entregas:</p>
                                    <div v-if="actividadesProximas.length==0">No tienes actividades próximas a recibir.</div>
                                    <v-list dense class="pa-0">
                                        <div class="pa-0" v-for="proxima in actividadesProximas" :key="proxima.actividad.idActividadMateriaBloque">
                                            <v-btn class="px-1" @click="ondetailsTareaProxima(proxima)" small text block style="text-transform:none !important;">
                                                <v-row no-gutters justify="left">
                                                    {{getFechaTitle(proxima)}}
                                                </v-row>
                                            </v-btn>
                                        </div>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                            <br>
                            <v-card class="rounded-xl" outlined>
                                <v-card-text>
                                    <p class="text-subtitle-2 font-weight-medium">Próximos exámenes/parciales:</p>
                                    <div v-if="examenesProximos.length==0">No tienes exámenes/parciales próximos a realizar.</div>
                                    <v-list dense class="pa-0">
                                        <div class="pa-0" v-for="proximo in examenesProximos" :key="proximo.actividad.idActividadMateriaBloque">
                                            <v-btn class="px-1" @click="ondetailsExamenes(proximo)" small text block style="text-transform:none !important;">
                                                <v-row no-gutters justify="left">
                                                    {{getFechaTitle(proximo)}}
                                                </v-row>
                                            </v-btn>
                                        </div>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="9">
                            <new-info-post :tipoactividad="selectedOption" v-if="newinformacionPostValidation()"
                            @oncancel="selectedOption=''" @onsave="onsaveActividad"></new-info-post>
                            <v-menu offset-y>
                                <template v-slot:activator="{on:menu}">
                                    <v-card hover class="rounded-xl card-text" ripple>
                                        <v-container class="fill-height" :style="'height:70px'" v-on="{...menu}" v-if="!newinformacionPostValidation()">
                                            <v-row no-gutters justify="center">
                                                <v-col align="center">
                                                    <h1 class="text-h6"><v-icon>add</v-icon> Publicar algo</h1>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card>
                                </template>
                                <v-list class="pa-0" dense>
                                    <v-list-item v-for="tipoactividad in tiposActividadesData" :key="tipoactividad.idTipoActividad" @click="()=>{onselectOptionPost(tipoactividad)}">
                                        <v-list-item-avatar>
                                            <v-icon :color="$store.state.accentColor2" v-text="tipoactividad.icono"></v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{tipoactividad.nombre}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-row>
                                <v-col v-for="actividad in actividadesData" :key="actividad.actividad.idActividadMateriaBloque" cols="12">
                                    <aviso-card v-if="onlyAviso(actividad)" :actividad="actividad" @onclickvideo="(video)=>{videoplayerDialog=true;selectedVideo=video;}"
                                        @ondelete="(fecha)=>{ondeleteActividad(actividad)}"
                                        @onupdate="()=>{oneditAviso(actividad)}"
                                        ></aviso-card>
                                    <tarea-card v-if="onlyTarea(actividad)" :tarea="actividad"
                                    @ondetails="()=>{ondetailsTarea(actividad)}"
                                    @ondelete="()=>{ondeleteActividad(actividad)}"
                                    @onupdate="()=>{updateTarea(actividad)}"
                                    ></tarea-card>
                                    <material-card
                                    v-if="onlyMaterial(actividad)" :material="actividad"
                                    @ondetails="()=>{ondetailsMaterial(actividad)}"
                                    @ondelete="()=>{ondeleteActividad(actividad)}"
                                    @onupdate="()=>{updateMaterial(actividad)}">

                                    </material-card>
                                    <tarea-extra-card
                                    v-if="onlyActividadExtra(actividad)" :tarea="actividad"
                                    @ondetails="()=>{ondetailsTareaExtra(actividad)}"
                                    @ondelete="()=>{ondeleteActividad(actividad)}"
                                    @onupdate="()=>{updateTareaExtra(actividad)}">
                                        
                                    </tarea-extra-card>
                                    <examen-final-card v-if="onlyExamenfinal(actividad)" :examenfinal="actividad"
                                    @ondetails="()=>{ondetailsExamenfinal(actividad)}"
                                    @ondelete="()=>{ondeleteActividad(actividad)}"
                                    @onupdate="()=>{updateExamenfinal(actividad)}"
                                    ></examen-final-card>
                                    <parcial-card v-if="onlyParcial(actividad)" :parcial="actividad"
                                    @ondetails="()=>{ondetailsParcial(actividad)}"
                                    @ondelete="()=>{ondeleteActividad(actividad)}"
                                    @onupdate="()=>{updateParcial(actividad)}">
                                    </parcial-card>
                                    <parcial-extra-card
                                    v-if="onlyParcialExtra(actividad)" :parcial="actividad"
                                    @ondetails="()=>{ondetailsParcialExtra(actividad)}"
                                    @ondelete="()=>{ondeleteActividad(actividad)}"
                                    @onupdate="()=>{updateParcialExtra(actividad)}">
                                        </parcial-extra-card>
                                    <examen-final-extra-card
                                    v-if="onlyExamenFinalExtra(actividad)" :examenfinal="actividad"
                                    @ondetails="()=>{ondetailsExamenFinalExtra(actividad)}"
                                    @ondelete="()=>{ondeleteActividad(actividad)}"
                                    @onupdate="()=>{updateExamenFinalExtra(actividad)}">
                                    </examen-final-extra-card>
                                    <informacion-card v-if="onlyAvisoEstudiante(actividad)" :avisoEstudiante="actividad"
                                    @ondeletePost="ondeletePublicacion(actividad)"
                                    :from="'docente'"
                                    @onclickvideo="(video)=>{videoplayerDialog=true;selectedVideo=video;}"
                                    ></informacion-card>
                                </v-col>
                                <v-col cols="12" v-if="moreCondition">
                                    <v-row no-gutters justify="center">                        
                                        <v-btn text rounded small @click="loadMoreActividades">Cargar más...</v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <router-view :action="actionActivity" :tipoactividad="selectedOption" 
                    @onsavetarea="onsaveActividad"
                    @onupdatetarea="onupdateActividad"
                    @ondeletetarea="ondeletePost"
                    @onupdatetareaCall="updateTarea"

                    @onsavematerial="onsaveActividad"
                    @ondeletematerial="ondeletePost"
                    @onupdatematerial="onupdateActividad"
                    @onupdatematerialCall="updateMaterial"

                    @onsaveexamenfinal="onsaveActividad"
                    @onupdateexamenfinal="onupdateActividad"
                    @ondeleteexamenfinal="ondeletePost"
                    @onupdateexamenfinalCall="updateExamenfinal"

                    @onsaveparcial="onsaveActividad"
                    @onupdateparcial="onupdateActividad"
                    @ondeleteparcial="ondeletePost"
                    @onupdateparcialCall="updateParcial"

                    @onupdatetareaextraCall="updateTareaExtra"
                    @onupdateparcialextraCall="updateParcialExtra"
                    @onupdateexamenfinalextraCall="updateExamenFinalExtra"
                    />
                </v-container>
            </div>
        </v-card>
    </div>    
</template>
<script>
import IniciarMateriaDialog from './IniciarMateriaDialog.vue';
import NewInfoPost from '../common/aviso/NewInfoPost.vue';
import AvisoCard from '../common/aviso/AvisoCard.vue';
import VideoPlayer from '../common/VideoPlayer.vue';
import DeleteDialog from '../../../globalComponents/DeleteDialog.vue';
import EditAvisoDialog from '../common/aviso/EditAvisoDialog.vue';
import TareaCard from '../common/tarea/TareaCard.vue'
import {Days} from '../../../../utils/timeUtils.js';
import MaterialCard from '../common/material/MaterialCard.vue'
import TareaExtraCard from '../common/extra/tarea/TareaExtraCard.vue';
import ExamenFinalCard from '../common/examenFinal/ExamenFinalCard.vue';
import ParcialCard from '../common/parcial/ParcialCard.vue'
import ParcialExtraCard from '../common/extra/parcial/ParcialExtraCard.vue';
import ExamenFinalExtraCard from '../common/extra/examenfinal/ExamenFinalExtraCard.vue';
import InformacionCard from '../../../estudiantil/escritorio/common/informacion/InformacionCard.vue'
import MeetDialog from './MeetDialog.vue';
//import Loader from '../../../globalComponents/Loader.vue';
export default {
  components: { IniciarMateriaDialog, NewInfoPost, AvisoCard, VideoPlayer, DeleteDialog, EditAvisoDialog, TareaCard, MaterialCard, TareaExtraCard, ExamenFinalCard, ParcialCard, ParcialExtraCard, ExamenFinalExtraCard, InformacionCard, MeetDialog },
    props:[
        "tab"
    ],
    watch:{
        tab:function(newval,oldval){
            oldval;
            if(newval==0){
                this.currentPage=1;
                this.selectedOption="";
                this.loadAll();
            }
        },
    },
    computed:{
        getPasscodeMeet(){
            if(this.asignacionData){
                if(this.asignacionData.meetingLinkString)
                    return this.asignacionData.meetingLinkString.split(new RegExp("[$]+"))[1];
            }
            return "";
        },
        iniciarmateriaValidation(){
            if(this.asignacionData)
                if(!this.asignacionData.inicializado)
                    return true;
            return false;
        },
        ValidateSaveLink(){
            if(this.$refs.linkmeetingForm.validate())
                return true;
            return false;
        },
        getMateriaNombre:function(){
            if(this.asignacionData){
                return this.asignacionData.idMateriaNavigation.nombre;
            }

            return "Cargando..."
        },
        getpostIdentity:function(){
            if(this.selectedActivity){
                return this.selectedActivity.actividad.idActividadMateriaBloque;
            }

            return "";
        },
        moreCondition:function(){
            if((this.actividadesData.length)%5==0&&this.actividadesData.length>0){
                return true;
            }

            return false;
        },
        getGradoNombre:function(){
            var text=``;

            if(this.asignacionData){
                text=`${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.nombre} ${this.asignacionData.idGradoSeccionNavigation.idSeccionNavigation.seccion}`;

                if(this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation){
                    text+=` - ${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation.nombre}`;
                }else{
                    text+=` - ${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.idNivelEstudiantilNavigation.nombre}`;
                }
            }

            return text;
        }
    },
    data:function(){
        return({
            bloquesData:[],
            tiposActividadesData:[],
            actividadesData:[],
            actividadesProximas:[],
            examenesProximos:[],
            isLoading:false,
            isLoadingDialog:false,
            isLoadingMeetingPopup:false,
            asignacionData:"",
            iniciarmateriaDialog:false,
            videoplayerDialog:false,
            deleteactivityDialog:false,
            editavisoDialog:false,
            newavisoDialog:false,
            actionActivity:"",
            deletecontext:"",
            deletecontent:"",
            selectedActivity:"",
            gapi:"",
            myevent:"",
            selectedOption:"",
            selectedVideo:"",
            currentPage:1,
            meetingLinkData:{
                meetingLinkString:"",
                meetingLinkStringRules:[
                    v=>!!v||"El link es obligatorio."
                ],
                contraseniaMeet:""
            },
            meetingLinkPopup:false,
        });
    },
    methods:{
        onlyAvisoEstudiante(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Aviso estudiante')
                    return true;
            return false;
        },
        onlyExamenFinalExtra(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Examen final extra')
                    return true;
            return false;
        },
        onlyParcial(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Parcial')
                    return true;
            return false;
        },
        onlyExamenfinal(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Examen final')
                    return true;
            return false;
        },
        onlyActividadExtra(actividad){
            if(actividad.actividad){
                switch(actividad.actividad.tipoActividadNavigation.nombre){
                    case "Tarea extra":
                        return true;
                }
            }
            return false;
        },
        onlyParcialExtra(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Parcial extra')
                    return true;
            return false;
        },
        onlyTarea(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Tarea')
                    return true;
            return false;
        },
        onlyAviso(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Aviso')
                    return true;
            return false;
        },
        onlyMaterial(actividad){
            if(actividad.actividad)
                if(actividad.actividad.tipoActividadNavigation.nombre=='Material')
                    return true;
            return false;
        },
        getFechaTitle(actividad){
            var date=new Date(actividad.actividad.fechaLimite);
            var timeUnit=actividad.actividad.fechaLimite.split("T")[1];
            var title=actividad.title;
            var day=date.getDay();
            
            if(day==0)
                day=7;
            

            if([1,2,8,9].includes(actividad.actividad.tipoActividad)){
                if(title.length>19)
                    title=`${title.substring(0,19)}...`;
                return `${Days[day-1].substring(0,3)} - ${title}`;
            }

            if(title.length>13){
                title=`${title.substring(0,13)}...`;
            }

            return `${Days[day-1].substring(0,3)} ${timeUnit.substring(0,5)} - ${title}`;
        },
        updateExamenFinalExtra(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"examen_final_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        ondetailsExamenFinalExtra(actividad){
            this.$router.push({name:"examen_final_extra_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        updateParcialExtra(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"parcial_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        ondetailsParcialExtra(actividad){
            this.$router.push({name:"parcial_extra_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        updateParcial(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"parcial_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        ondetailsParcial(actividad){
            this.$router.push({name:"parcial_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateExamenfinal(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"examen_final_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        ondeletePublicacion(actividad){
            this.selectedActivity=actividad;
            this.deletecontext="publicacion estudiante";
            this.deletecontent=`¿Desea eliminar esta publicación de estudiante?`;
            this.deleteactivityDialog=true;
        },
        ondetailsExamenes(actividad){
            switch(actividad.actividad.tipoActividad){
                case 1:
                    this.ondetailsParcial(actividad);
                    break;
                case 2:
                    this.ondetailsExamenfinal(actividad);
                    break;
                case 9:
                    this.ondetailsParcialExtra(actividad);
                    break;
            }
        },
        ondetailsExamenfinal(actividad){
            this.$router.push({name:"examen_final_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateTarea(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"tarea_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        ondetailsTareaProxima(actividad){
            switch(actividad.actividad.tipoActividad){
                case 3:
                    this.ondetailsTarea(actividad);
                    break;
                case 6:
                    this.ondetailsTareaExtra(actividad);
                    break
            }
        },
        ondetailsTarea(actividad){
            this.$router.push({name:"tarea_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateTareaExtra(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"tarea_extra_docente_editar",params:{id:actividad.actividad.idActividadMateriaBloque,bloque:actividad.actividad.idMateriaBloque}});
        },
        ondetailsTareaExtra(actividad){
            this.$router.push({name:"tarea_extra_docente_detalles",params:{id:actividad.actividad.idActividadMateriaBloque}});
        },
        updateMaterial(actividad){
            this.actionActivity="edit";
            this.$router.push({name:"material_docente_editar",params:{idmaterial:actividad.actividad.idActividadMateriaBloque}});
        },
        ondetailsMaterial(actividad){
            this.$router.push({name:"material_docente_detalles",params:{idmaterial:actividad.actividad.idActividadMateriaBloque}});
        },
        ondeleteActividad(actividad){
            switch(actividad.actividad.tipoActividadNavigation.nombre){
                case "Parcial":
                    this.deletecontext="parcial";
                    var tituloparcial=actividad.title;
                    if(actividad.title.length>20){
                        tituloparcial=`${tituloparcial.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el parcial con título "${tituloparcial}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Examen final":
                    this.deletecontext="examen final";
                    var tituloexamenfinal=actividad.title;
                    if(actividad.title.length>20){
                        tituloexamenfinal=`${tituloexamenfinal.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el exámen con título "${tituloexamenfinal}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Aviso":
                    this.deletecontext="aviso";
                    this.deletecontent=`¿Desea eliminar el aviso con fecha de creación "${actividad.actividad.fechaCreada}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Tarea":
                    this.deletecontext="tarea";
                    var titulo=actividad.title;
                    if(actividad.title.length>20){
                        titulo=`${titulo.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar la tarea con título "${titulo}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Material":
                    this.deletecontext="material";
                    var titulomaterial=actividad.title;
                    if(actividad.title.length>20){
                        titulomaterial=`${titulomaterial.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el material con título "${titulomaterial}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Tarea extra":
                    this.deletecontext="tarea extra";
                    var tituloextra=actividad.title;
                    if(actividad.title.length>20){
                        tituloextra=`${tituloextra.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar la tarea extra con título "${tituloextra}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Parcial extra":
                    this.deletecontext="parcial extra";
                    var tituloextraParcial=actividad.title;
                    if(actividad.title.length>20){
                        tituloextraParcial=`${tituloextraParcial.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el parcial extra con título "${tituloextraParcial}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
                case "Examen final extra":
                    this.deletecontext="examen final extra";
                    var tituloextraExamenfinal=actividad.title;
                    if(actividad.title.length>20){
                        tituloextraExamenfinal=`${tituloextraExamenfinal.substring(0,15)}...`;
                    }
                    this.deletecontent=`¿Desea eliminar el exámen final extra con título "${tituloextraExamenfinal}"?`;
                    this.selectedActivity=actividad;
                    this.deleteactivityDialog=true;
                    break;
            }
        },
        oneditAviso(actividad){
            this.selectedActivity=actividad;
            this.editavisoDialog=true;
        },
        onselectOptionPost(val){
            this.selectedOption=val;
            
            switch(val.nombre){
                case "Parcial":
                    this.actionActivity="add";

                    this.$router.push({name:"parcial_docente"});
                    break;
                case "Aviso":
                    if(this.$vuetify.breakpoint.xs)
                        this.newavisoDialog=true;
                    break;
                case "Tarea":
                    this.actionActivity="add";

                    this.$router.push({name:"tarea_docente"});
                    break;
                case "Material":
                    this.actionActivity="add";
                    this.$router.push({name:"material_docente"});
                    break;
                case "Examen final":
                    this.actionActivity="add";
                    this.$router.push({name:"examen_final_docente"});
                    break;
                default:
                    this.selectedOption="";
            }
        },
        newinformacionPostValidation(){
            if(this.$vuetify.breakpoint.xs){
                return false;
            }

            if(this.selectedOption){
                if(this.selectedOption.nombre.includes("Aviso")){
                    return true;
                }
            }

            return false;
        },
        oniniciarMateria(response){
            if(response[0]==1){
                this.loadAsignacionCall();
            }
        },
        onupdateAviso(){
            this.loadActivities();
        },
        onsaveActividad(response){
            switch (response[0]) {
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    if(this.$vuetify.breakpoint.xs){
                        this.newavisoDialog=false;
                    }                    
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    this.selectedOption="";
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            
                default:
                    break;
            }
            this.loadActivities();
        },
        onupdateActividad(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    this.$emit('onclose');
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.loadActivities();
        },
        ondeletePost(response){
            switch(response[0]){
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[1],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.loadActivities();
        },
        loadTareasProximasCall(){
            this.loadTareasProximas().then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.actividadesProximas=response.data.data;
                    }
                }
            }).catch((error)=>{error});
        },
        loadTiposActividades(){return this.$axios.get(`api/docentes/tiposactividades`);},
        loadActividades(){this.currentPage=1;return this.$axios.get(`api/docentes/actividades?asignacion=${this.$route.params.idassignment}&page=${this.currentPage}`)},
        loadTareasProximas(){return this.$axios.get(`api/docentes/actividades/tareas/proximas?asignacion=${this.$route.params.idassignment}`);},
        loadExamenesProximos(){return this.$axios.get(`api/docentes/actividades/examenes/proximos?asignacion=${this.$route.params.idassignment}`);},
        loadAsignacion(){return this.$axios.get(`api/docentes/asignaciones/asignacion/${this.$route.params.idassignment}`)},
        async loadMoreActividades(){
            this.isLoading=true;
            this.$emit("isLoading2",true);

            this.currentPage+=1;
            await this.$axios.get(`api/docentes/actividades?asignacion=${this.$route.params.idassignment}&page=${this.currentPage}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        if(response.data.data.length==0){
                            this.$store.commit("showMessage",{text:"No hay más publicaciones que cargar.",color:"",time:4000});
                        }else{
                            this.actividadesData.push(...response.data.data);
                        }
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.$emit("isLoading2",false);
            this.isLoading=false;
        },
        async loadActivities(){
            this.isLoading=true;
            this.$emit("isLoading2",true);

            await this.$axios.all([this.loadActividades(),this.loadTareasProximas()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.actividadesData=response[0].data.data;
                        this.actividadesProximas=response[1].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.$emit("isLoading2",false);
            this.isLoading=false;
        },
        async loadAsignacionCall(){
            this.isLoading=true;
            await this.$axios.all([this.loadAsignacion()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.asignacionData=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });
            this.isLoading=false;
        },
        async loadAll(){
            this.isLoading=true;
            this.$emit("isLoading2",true);

            await this.$axios.all([this.loadAsignacion(),this.loadTiposActividades(),this.loadActividades(),
            this.loadTareasProximas(),this.loadExamenesProximos()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200&&response[2].status==200&&response[3].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1&&response[2].data.code==1&&response[3].data.code==1){
                        this.asignacionData=response[0].data.data;
                        this.tiposActividadesData=response[1].data.data;
                        this.actividadesData=response[2].data.data;
                        this.actividadesProximas=response[3].data.data;
                        this.examenesProximos=response[4].data.data;

                        this.tiposActividadesData=this.tiposActividadesData.filter(item=>item.visible);

                        this.initializeNewUpdateActivity();
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.$emit("isLoading2",false);
            this.isLoading=false;
        },
        initializeNewUpdateActivity(){
            if(this.$router.currentRoute.name=="tarea_docente_editar"){
                this.actionActivity="edit";
            }
        }
    },
    created(){
        this.loadAll();        
    }
}
</script>
<style scoped>

</style>
