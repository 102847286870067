<template>
    <v-card elevation="3" class="rounded-xl card-text px-5" :loading="isLoading" :disabled="isLoading">
        <video-attach-dialog :from="'estudiantes'" :videoDialog="videoDialog" @onclose="videoDialog=false" @onaddVideo="onaddVideo"></video-attach-dialog>
        <file-attach-dialog :fileDialog="fileDialog" @onclose="fileDialog=false" @onaddFile="onaddFile"></file-attach-dialog>
        <br>
        <v-row no-gutters><div class="text-subtitle-2">Contenido:</div></v-row>
        <v-card-text class="px-0">
            <rich-text-editor @onchangeContent="onchangeContent" :content="newinfoPost.contenido"></rich-text-editor>
        </v-card-text>
        <v-row no-gutters class="pb-5">
            <v-col v-for="(video,index) in newinfoPost.videos" :key="index" cols="12">
                <video-card :videoInfo="video" :action="'add'" @ondelete="ondeleteVideo"></video-card>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col v-for="(file,index) in archivos" :key="index" cols="12">
                <file-card :file="file" @ondelete="ondeleteFile"></file-card>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col v-for="(file,index) in newinfoPost.archivos" :key="index" cols="12">
                <file-card :file="file" @ondelete="ondeletesavedFile"></file-card>
            </v-col>
        </v-row>
        <v-card-actions class="px-0">
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <v-btn @click="videoDialog=true" v-on="on" icon :color="$store.state.accentColor2" large><v-icon>mdi-youtube</v-icon></v-btn>
                </template>
                <span>Agregar video</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn small text @click="$emit('oncancelar')">Cancelar</v-btn>
            <v-btn small :dark="IsValid?true:false" :color="$store.state.accentColor2" @click="onPost()" :disabled="!IsValid">{{getConfirmText}}</v-btn>
        </v-card-actions>
</v-card>    
</template>
<script>
import RichTextEditor from '../RichTextEditor.vue';
import VideoCard from '../../../../colaborador/escritorio/common/VideoCard.vue';
import VideoAttachDialog from '../../../../colaborador/escritorio/common/VideoAttachDialog.vue'
import FileAttachDialog from '../../../../colaborador/escritorio/common/FileAttachDialog.vue'
import FileCard from '../../../../colaborador/escritorio/common/FileCard.vue';

export default {
    props:[
        "newinfoPostSaved",
        "archivosSaved",
        "action",
        "idInformacion"
    ],
    components:{RichTextEditor,VideoCard,VideoAttachDialog,FileAttachDialog,FileCard},
    computed:{
        getConfirmText(){
            if(this.action=="add")
                return "Publicar";
            return "Editar";
        },
        IsValid(){
            if(this.newinfoPost.contenido.length==0||/^\s$/.test(this.newinfoPost.contenido))
                return false;
            return true;
        }
    },
    watch:{
        'newinfoPost.contenido'(newval,oldval){
            oldval;
            this.$emit("onchangecontentInfo",newval);
        },
        'newinfoPost.videos'(newval,oldval){
            oldval;
            this.$emit("onchangevideosInfo",newval);
        },
        'newinfoPost.archivos'(newval,oldval){
            oldval;
            this.$emit("onchangearchivosexistInfo",newval);
        },
        'archivos'(newval,oldval){
            oldval;
            this.$emit("onchangearchivosInfo",newval);
        }
    },
    data:function(){
        return({
            isLoading:false,
            contenido:"",
            newinfoPost:{
                contenido:"",
                videos:[],
                archivos:[]
            },
            archivos:[],
            videoDialog:false,
            fileDialog:false
        });
    },
    methods:{
        ondeletesavedFile(fileItem){
            var index=this.newinfoPost.archivos.indexOf(fileItem);
            this.newinfoPost.archivos.splice(index,1);
        },
        ondeleteFile(fileItem){
            var index=this.archivos.indexOf(fileItem);
            this.archivos.splice(index,1);
        },
        ondeleteVideo(videoItem){
            var index=this.newinfoPost.videos.indexOf(videoItem);
            this.newinfoPost.videos.splice(index,1);
        },
        onaddVideo(videoInfo){
            if(!this.newinfoPost.videos.some(item=>item.id==videoInfo.id)){
                this.newinfoPost.videos.push(videoInfo);
                this.videoDialog=false;
            }
        },
        onaddFile(files){
            var currentAmountFiles=this.newinfoPost.archivos.length+this.archivos.length;
            currentAmountFiles;
            if(currentAmountFiles<=5){
                if((currentAmountFiles+files.length)<=5){
                    Array.prototype.push.apply(this.archivos,files);
                    this.$emit("onchangearchivosInfo",this.archivos);
                    this.fileDialog=false;

                    return;
                }
            }
            this.fileDialog=false;
            alert("No se pueden agregar más de 5 archivos a la publicación.");
        },
        onchangeContent(contenido){
            this.newinfoPost.contenido=contenido;
        },
        onPost(){
            if(this.action=="add")
                this.onPostCall();
            else
                this.onUpdateCall();
        },
        async onUpdateCall(){
            this.isLoading=this.$store.state.accentColor2;

            var formData=new FormData();

            for( var counter = 0; counter < this.archivos.length; counter++ ){
                let file = this.archivos[counter];

                formData.append('archivos', file);
            }
            
            formData.append("avisoString",JSON.stringify(this.newinfoPost));

            await this.$axios.put(`api/estudiantil/actividades/avisos/${this.idInformacion}/update`,formData).then((response)=>{
                if(response.status==200)
                    if(response.data.code==1)
                        this.onpostResponse([1,response.data.message,"Información actualizada."]);
                    else
                        this.onpostResponse([0,response.data.message]);
            }).catch((error)=>{
                error;
                console.log(error);
                this.onpostResponse([2]);
            });

            this.isLoading=false;
        },
        async onPostCall(){
            this.isLoading=this.$store.state.accentColor2;

            var formData=new FormData();

            for( var counter = 0; counter < this.archivos.length; counter++ ){
                let file = this.archivos[counter];

                formData.append('archivos', file);
            }
            
            formData.append("avisoString",JSON.stringify(this.newinfoPost));

            await this.$axios.post(`api/estudiantil/actividades/avisos/${this.$route.params.idassignment}/create`,formData).then((response)=>{
                if(response.status==200)
                    if(response.data.code==1)
                        this.onpostResponse([1,response.data.message,"Información publicada."]);
                    else
                        this.onpostResponse([0,response.data.message]);
            }).catch((error)=>{
                console.log(error);
                error;
                this.onpostResponse([2]);
            });

            this.isLoading=false;
        },
        onpostResponse(response){
            switch (response[0]) {
                case 0:
                    this.$store.commit("showMessage",{text:response[1],color:"",time:4000});
                    break;
                case 1:
                    this.$store.commit("showMessage",{text:response[2],color:"green",time:4000});
                    break;
                case 2:
                    this.$store.commit("showMessage",{text:"Ha ocurrido un error, si persiste contacte a soporte.",color:"red",time:4000});
                    break;
            }
            this.$emit("onrefresh");
            this.$emit("oncancelPost");
        },
        getPublicacion(){return this.$axios.get(`api/estudiantil/actividades/avisos/${this.idInformacion}`);},
        async loadPublicacion(){
            this.isLoading=this.$store.state.accentColor2;

            await this.$axios.all([this.getPublicacion()]).then((response)=>{
                if(response[0].status==200)
                    if(response[0].data.code==1)
                        this.initialize(response[0].data.data);
                    else
                        this.onpostResponse([0,"Ha ocurrido un error y no se pudo cargar la publicación."]);
            }).catch((error)=>{
                error;
                this.onpostResponse([2]);
            });

            this.isLoading=false;
        },
        initialize(response){
            this.newinfoPost.contenido=response.contenido;
            this.newinfoPost.videos=response.videos;
            this.newinfoPost.archivos=response.archivos;
            this.newinfoPost.estudiante=response.estudiante;
        }
    },
    created(){
        if(this.action=="add"){
            this.newinfoPost.contenido=this.newinfoPostSaved.content;
            this.newinfoPost.videos=this.newinfoPostSaved.videos;
            this.newinfoPost.archivos=this.newinfoPostSaved.archivos;
            this.archivos=this.archivosSaved;
        }
        if(this.action=="edit")
            this.loadPublicacion();
    }
}
</script>
<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>