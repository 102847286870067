import Vue from "vue";
import store from '../store/store'

Vue.prototype.$updateChanges=function(target,newValue){
    Object.keys(target).forEach(keys=>{
        target[keys]=newValue[keys];
    });
};

Vue.prototype.$trimAll=function(target){
    Object.keys(target).forEach(keys=>{
        if(typeof(target[keys])=="string"){
            target[keys]=target[keys].trim();
        }
    });
}

Vue.prototype.$currentYearValidation=function(){
    return store.state.cicloEscolar.cicloEscolar1>=store.state.currentRealDate.getFullYear();
}

export const prototype=Vue.prototype;