<template>
    <v-card outlined :ripple="publico" @click="onclickFile()">
        <file-viewer :showDialog="fileViewerDialog" :file="file" @onclose="fileViewerDialog=false"></file-viewer>
        <v-list-item>
            <v-list-item-icon>
                <v-icon>insert_drive_file</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="text-subtitle-2 wrap-text">{{getFileName}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="!publico">
                <v-btn icon @click="$emit('ondelete',file)"><v-icon>clear</v-icon></v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>
<script>
import FileViewer from './FileViewer.vue'
export default {
    components:{FileViewer},
    props:[
        "file",
        "publico"
    ],
    computed:{
        getFileName:function(){
            if(this.file.name.length>30){
                var extensionSplitted=this.file.name.split(".");
                var extension=extensionSplitted[extensionSplitted.length-1];

                return `${this.file.name.substring(0,30)}....${extension}`;
            }
            return this.file.name;
        }
    },
    data:function(){
        return({
            fileViewerDialog:false
        })
    },
    methods:{
        onclickFile(){
            if(this.publico)
                this.fileViewerDialog=true;
        }
    }
}
</script>
<style scoped>

</style>