<template>
    <v-dialog max-width="600" v-model="asignarestudiantesDialog" persistent scrollable>        
        <v-dialog v-model="confirmacionDialogo" max-width="300">
            <v-card :loading="isLoadingCicloAnterior" :disabled="isLoadingCicloAnterior">
                <v-card-title>
                    Confirmación
                </v-card-title>
                <v-card-text>
                    ¿Desea inscribir a los alumnos seleccionados en la sección <strong>{{SelectedSeccion}}</strong>?
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="confirmacionDialogo=false">Cancelar</v-btn>
                    <v-btn text :color="$store.state.accentColor" @click="inscribirCall">Inscribir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card :loading="isLoadingCicloAnterior" :disabled="isLoadingCicloAnterior" outlined>
            <v-card-title>{{GetTituloCurrentGrade}}</v-card-title>
            <v-card-text>
                <v-card flat v-if="EveryHasNoOne">
                    <v-card-text align="center">No hay información de inscripciones pasadas de estudiantes en este ciclo </v-card-text>
                </v-card>
                <div v-if="!EveryHasNoOne">
                    <div  v-for="(gradoseccionAnterior,index) in gradosSeccionesAnteriores" :key="index">
                        <v-card outlined v-if="gradoseccionAnterior.estudiantesGrados.length>0">
                            <v-row no-gutters class="text-subtitle-1 font-weight-medium pa-2" justify="center">{{GetTableTitleAnteriores(gradoseccionAnterior)}}</v-row>
                            <v-row no-gutters justify="end" class="pa-2">
                                <v-menu offset-y>
                                    <template v-slot:activator="{on}">
                                        <v-btn v-on="on" rounded small :color="$store.state.accentColor" :disabled="InscribirDisableValidation(gradoseccionAnterior)">Inscribir</v-btn>
                                    </template>
                                    <v-list class="pa-0 ma-0">
                                        <v-list-item v-for="(gradoseccion,index) in selectedGrade.gradosSecciones" :key="index" @click="onInscribir(gradoseccionAnterior,gradoseccion)">
                                            <v-list-item-content>
                                                <v-list-item-title>Sección {{gradoseccion.idSeccionNavigation.seccion}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-row>
                            <v-data-table
                                hide-default-footer
                                :items-per-page="1000"
                                v-model="gradoseccionAnterior.toInscribir"
                                show-select
                                :headers="headersAnteriores"
                                :items="gradoseccionAnterior.estudiantesGrados"
                                item-key="idEstudianteGrado">
                                <template v-slot:item.idEstudianteNavigation="{item}">
                                    {{item.idEstudianteNavigation.nombre1}} {{item.idEstudianteNavigation.nombre2}} {{item.idEstudianteNavigation.apellido1}} {{item.idEstudianteNavigation.apellido2}}
                                </template>
                                <template v-slot:item.becado="{item}">
                                    <div v-if="AlreadyContains(item,gradoseccionAnterior.toInscribir)">
                                        <v-checkbox
                                        :color="$store.state.accentColor"
                                        v-model="item.becado">

                                        </v-checkbox>
                                    </div>
                                </template>
                                <template v-slot:item.aprobado="{item}">
                                    <v-chip dark small :color="GetColorAprobadoState(item.aprobado)">
                                        {{GetAprobadoState(item.aprobado)}}
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-card>
                        <br>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('oncancel')">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:["asignarestudiantesDialog","selectedGrade"],
    watch:{
        asignarestudiantesDialog(newval,oldval){
            oldval;
            if(!newval)
                this.gradosSeccionesAnteriores=[];
            else{
                this.loadGrupos();
            }
        }
    },
    computed:{
        SelectedSeccion(){
            if(this.selectedGradoseccion)
                return this.selectedGradoseccion.idSeccionNavigation.seccion;
            return "";
        },
        GetTituloCurrentGrade(){
            var cicloAnterior="";
            var ciclosAnteriores=[
                "5197428f-74a6-4ff2-9425-11d93cb47e63",
                "56f48a8c-70d8-42bc-b1b8-632ac33a52f8",
                "7d912dd2-b152-4faf-82bc-e2ab275189bd",
                "d9a6c690-9e2a-4aea-a522-523de771695c",
                "40518aa8-862e-4ca4-9f7c-d2e458aae7cd",
                "bc19e42e-154a-4574-9d68-8a5a80714ab8",
                "4b86a20f-0412-4d90-aeff-535007ca6fed",
                "c4da1ad7-2f9d-414a-9b62-a841d6bbb389",
                "bd3ddb81-cd41-4929-9489-39db41192d99",
                "e3929c51-d66e-4357-a460-2897cfb39c45",
                "d43b2fb5-a8ce-4132-996f-2ccb7f4eaba7",
                "7efafea4-979a-4a7c-b345-34bbefdd9b1d",
                "935431e0-ee50-4d73-ae05-5064bc8ccdf4"
            ];

            cicloAnterior=ciclosAnteriores.find(item=>item==this.selectedGrade.idGrado);

            if(cicloAnterior){
                var nivelEstudiantil=this.selectedGrade.idNivelEstudiantil?
                    this.selectedGrade.idNivelEstudiantilNavigation.nombre:
                    this.selectedGrade.idNivelEstudiantilCarreraNavigation.nombre;

                cicloAnterior=`${nivelEstudiantil} - ${this.selectedGrade.nombre} ${this.$store.state.cicloEscolar.cicloEscolar1}`;
            }
            return cicloAnterior;
        },
        EveryHasNoOne(){
            if(this.gradosSeccionesAnteriores)
                return this.gradosSeccionesAnteriores.every(gradoSeccion=>gradoSeccion.estudiantesGrados.length==0);
            return false;
        },
    },
    data:function(){
        return({
            confirmacionDialogo:false,
            isLoadingCicloAnterior:false,
            gradosSeccionesAnteriores:[],
            headersAnteriores:[
                {text:"Nombre",value:"idEstudianteNavigation"},
                {text:"¿Aprobado?",value:"aprobado"},
                {text:"¿Es becado?",value:"becado"}
            ],
            selectedGroup:"",
            selectedGradoseccion:""
        });
    },
    methods:{
        GetColorAprobadoState(estado){
            if(estado)
                return "green";
            return "red";
        },
        GetAprobadoState(estado){
            if(estado)
                return "A";
            return "R";
        },
        GetTableTitleAnteriores(gradoseccionAnterior){
            if(gradoseccionAnterior)
                return `${gradoseccionAnterior.idGradoNavigation.nombre} ${gradoseccionAnterior.idSeccionNavigation.seccion} - ${this.$store.state.cicloEscolar.cicloEscolar1-1}`;
            return "";
        },
        AlreadyContains(estudiante,toInscribir){
            if(toInscribir)
                if(toInscribir.some(item=>item.idEstudianteNavigation.idEstudiante==estudiante.idEstudianteNavigation.idEstudiante))
                    return true;
            return false;
        },
        InscribirDisableValidation(gradoseccion){
            if(gradoseccion.toInscribir)
                if(gradoseccion.toInscribir.length>0)
                    return false;
            return true;
        },
        async loadGrupos(){
            this.isLoadingCicloAnterior=this.$store.state.accentColor;

            await this.$axios.get(`api/grados/${this.selectedGrade.idGrado}/inscripciones?ciclo=${this.$store.state.cicloEscolar.cicloEscolar1}`).then((response)=>{
                if(response.status==200)
                    if(response.data.code==1)
                        this.gradosSeccionesAnteriores=response.data.data;
            }).catch((error)=>{
                error;
            });

            this.isLoadingCicloAnterior=false;
        },
        onInscribir(group,gradoseccion){
            this.confirmacionDialogo=true;
            this.selectedGroup=group;
            this.selectedGradoseccion=gradoseccion;
        },
        async inscribirCall(){
            if(this.selectedGroup.toInscribir){
                var newToInscribir=[];
                this.selectedGroup.toInscribir.forEach(element => {
                    newToInscribir.push({
                        idEstudiante:element.idEstudiante,
                        idCicloEscolar:this.$store.state.cicloEscolar.idCicloEscolar,
                        idGradoSeccion:this.selectedGradoseccion.idGradoSeccion,
                        becado:element.becado
                    });
                });

                this.isLoadingCicloAnterior=this.$store.state.accentColor;
                await this.$axios.post("api/grados/estudiantes/asignar",
                    newToInscribir
                ).then(async (response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            await this.loadGrupos();
                            this.$emit("onenrollment",[1,response.data.data,response.data.message]);
                        }else{
                            this.$emit("onenrollment",[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onenrollment",[2,error.response.data.message]);
                        }else{
                            this.$emit("onenrollment",[2]);
                        }
                    }
                });

                this.confirmacionDialogo=false;
                this.isLoadingCicloAnterior=false;
            }
                
        }
    },
    created(){
        
    }
}
</script>
<style scoped>

</style>