<template>
    <v-app id="inicio_colaborador_docente">
        <div v-if="$router.currentRoute.name=='inicio_colaborador_docente'">
            <v-app-bar app flat :color="$vuetify.theme.dark?'':'white'">
                <v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="logoutDialog=true" text outlined rounded :color="$store.state.accentColor2">Cerrar sesión</v-btn>
            </v-app-bar>
            <logout-dialog :logoutDialog="logoutDialog" @onclose="()=>{logoutDialog=false;}"></logout-dialog>
            <div>
                <v-row no-gutters justify="center">
                    <h1 class="font-weight-medium text-h6 text-xl-h5 text-lg-h6 text-md-h6 text-sm-h6">Ciclo escolar {{$route.params.ciclo}}</h1>
                </v-row>
                <v-row class="ma-0" justify="center">
                    <v-col cols="12" xs="12" sm="10" md="10" lg="6" xl="6">
                        <v-list-item>
                            <v-list-item-avatar :size="getPhotoSize()">
                                <v-img :src="$store.state.profileInfo.fL"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium text-xl-h5 text-lg-h6 text-md-h6 text-sm-h6 wrap-text">
                                    {{getDocenteNombre}}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{$store.state.profileInfo.uu}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    {{roles}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="regresarAdmin">
                                    <v-btn outlined rounded :color="$store.state.accentColor2" x-small class="normal-text" to="/colaborador/administrador">Ir a área administrativa</v-btn>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-container fill-height fluid :style="$vuetify.breakpoint.mdAndUp?'height:65vh':'height:65vh'">
                    <v-row justify="center">
                        <v-col v-for="(accion,index) in acciones" :key="index" align="center" cols="12" xs="12" sm="12" md="6" lg="4">
                            <colors-cards :text="accion.text" :color="accion.color" :dark="true" :icon="accion.icon" @onclick="()=>{gotoGeneric(accion)}"></colors-cards>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <transition name="scroll-y-transition" mode="out-in">
            <router-view />
        </transition>
    </v-app>  
</template>
<script>
import ColorsCards from '../../../globalComponents/ColorsCards.vue';
import LogoutDialog from '../../../globalComponents/LogoutDialog.vue';
export default {
  components: { LogoutDialog, ColorsCards },
    computed:{
        roles:function(){
            var rolesString="";
            
            if(this.docenteInfo){
                this.docenteInfo.colaboradoresCargos.forEach(element => {
                    rolesString+=`* ${element.idCargoNavigation.nombre} `;
                });
            }

            return rolesString;
        },
        getDocenteNombre(){
            if(this.docenteInfo){
                return this.docenteInfo.nombres;
            }
            return "";
        },
        regresarAdmin(){
            return [
            "Administrador 1",
            "Administrador 2",
            "Administrador 3"
            ].some(permitidos=>this.$store.state.profileInfo.cargos.some(cargo=>cargo.roles.some(rol=>permitidos.toLowerCase().includes(rol.rol.toLowerCase()))));
        }
    },
    data:()=>({
        logoutDialog:false,
        isLoading:false,
        docenteInfo:"",
        acciones:[
            {
                route:"materias_docente",
                text:"Mis cursos",
                icon:"list",
                color:"pink"
            },
            {
                route:"grados_docente",
                text:"Mis grados",
                icon:"fact_check",
                color:"blue"
            },
            {
                route:"grados_calificaciones",
                text:"Calificaciones generales",
                icon:"grade",
                color:"amber"
            }
        ]
    }),
    methods:{
        gotoGeneric(accion){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            this.$router.push({name:accion.route},()=>{
                this.isLoading=false;
                this.$store.commit("setLoading",this.isLoading);
            });
        },
        getCurrentText(asignacion){
            if(asignacion){
                var currDate=this.$store.state.currentRealDate;

                if(asignacion==currDate.getFullYear()){
                    return "Ciclo actual"
                }
            }

            return "";
        },
        getCicloEscolar(asignacion){
            if(asignacion){
                return asignacion;
            }

            return "";
        },
        getPhotoSize(){
            if(this.$vuetify.breakpoint.xsOnly){
                return 50;
            }
            if(this.$vuetify.breakpoint.smAndUp&&this.$vuetify.breakpoint.mdAndDown){
                return 70;
            }
            if(this.$vuetify.breakpoint.lgAndUp){
                return 100;
            }
        },
        loadDocenteInfo(){return this.$axios.get(`api/docentes/docentes/docente`)},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadDocenteInfo()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.docenteInfo=response[0].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>
.white-logout{
    filter: brightness(0) invert(1);
}
</style>