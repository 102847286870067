<template>
    <!--<v-app id="inicio_estudiante">-->
        <div>
        <div v-if="$router.currentRoute.name=='inicio_estudiante'">
            <v-app-bar app flat :color="$vuetify.theme.dark?'':'white'">
                <v-row justify="space-between">
                    <v-col class="pa-0" cols="4"><v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn></v-col>
                    <v-col class="pa-0" cols="4" align="center"><notifications-actividades/></v-col>
                    <v-col class="pa-0" cols="4" align="end">
                        <v-tooltip left v-if="$vuetify.breakpoint.smAndUp">
                            <template v-slot:activator="{on}">
                                <v-btn v-on="on" @click="logoutDialog=true" icon x-large><v-icon>logout</v-icon></v-btn>
                            </template>
                            <span>Cerrar sesión</span>
                        </v-tooltip>
                        <v-menu offset-y v-if="$vuetify.breakpoint.xsOnly">
                            <template v-slot:activator="{on}">
                                <v-btn icon v-on="on"><v-icon>more_vert</v-icon></v-btn>        
                            </template>
                            <v-list class="pa-0" dense>
                                <v-list-item @click="logoutDialog=true">
                                    <v-list-item-content>
                                        <v-list-item-title>Cerrar sesión</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-app-bar>
            <logout-dialog :context="'estudiante'" :logoutDialog="logoutDialog" @onclose="()=>{logoutDialog=false;}"></logout-dialog>
            <div v-if="!isLoading">
                <v-row no-gutters justify="center">
                    <h1 class="font-weight-medium text-h6 text-xl-h5 text-lg-h6 text-md-h6 text-sm-h6">Ciclo escolar {{$store.state.cicloEscolar.cicloEscolar1}}</h1>
                </v-row>
                <v-row class="ma-0" justify="center">
                    <v-col cols="12" xs="12" sm="10" md="10" lg="6" xl="6">
                        <v-list-item>
                            <v-list-item-avatar :size="getPhotoSize()" :color="$store.state.accentColor2">
                                <v-icon size="40" color="white">person</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium text-xl-h5 text-lg-h6 text-md-h6 text-sm-h6 wrap-text">
                                    {{getNombreEstudiante}}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{getUsuario}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    {{getGradoNombre}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-container fill-height fluid :style="$vuetify.breakpoint.mdAndUp?'height:65vh':'height:65vh'">
                    <v-row justify="center">
                        <v-col align="center" cols="12" xs="12" sm="12" md="6" lg="4">
                            <colors-cards :text="'Mis cursos'" :color="'pink'" :dark="true" :icon="'list'" @onclick="()=>{gotoMisCursos()}"></colors-cards>
                        </v-col>
                        <v-col align="center" cols="12" xs="12" sm="12" md="6" lg="4">
                            <colors-cards :text="'Mis calificaciones'" :color="'amber'" :dark="true" :icon="'grade'" :pathTo="''" @onclick="()=>{gotoMisCalificaciones()}"></colors-cards>
                        </v-col>
                        <v-col align="center" cols="12" xs="12" sm="12" md="6" lg="4">
                            <colors-cards :text="'Mis pagos'" :color="'primary'" :dark="true" :icon="'fact_check'" :pathTo="''" @onclick="()=>{gotoMisPagos()}"></colors-cards>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <transition name="scroll-y-transition" mode="out-in">
            <router-view />
        </transition>
        </div>
    <!--</v-app>  -->
</template>
<script>
import ColorsCards from '../../globalComponents/ColorsCards.vue';
import LogoutDialog from '../../globalComponents/LogoutDialog.vue';
import NotificationsActividades from './NotificationsActividades.vue';
import {Initialize,StopWorker} from '../../../utils/notificationWorker.js';
export default {
  components: { LogoutDialog, ColorsCards,NotificationsActividades},
    computed:{
        getNombreEstudiante(){
            if(this.asignacionData){
                return `${this.asignacionData.idEstudianteNavigation.nombre1} ${this.asignacionData.idEstudianteNavigation.nombre2} ${this.asignacionData.idEstudianteNavigation.apellido1} ${this.asignacionData.idEstudianteNavigation.apellido2}`;
            }

            return "No existe asignación para el estudiante";
        },
        getGradoNombre(){
            if(this.asignacionData){
                var text=`${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.nombre} ${this.asignacionData.idGradoSeccionNavigation.idSeccionNavigation.seccion}`;

                if(this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation){
                    text+=` - ${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation.nombre}`
                }else{
                    text+=` - ${this.asignacionData.idGradoSeccionNavigation.idGradoNavigation.idNivelEstudiantilNavigation.nombre}`
                }

                return text;
            }
            return "No existe asignación para el estudiante";
        },
        getUsuario(){
            if(this.asignacionData){
                if(this.asignacionData.idEstudianteNavigation.estudiantesUsuarios){
                    return this.asignacionData.idEstudianteNavigation.estudiantesUsuarios.usuario;
                }
                return "El estudiante no tiene usuario habilitado";
            }
            return "No existe asignación para el estudiante";
        },
    },
    data:()=>({
        logoutDialog:false,
        isLoading:false,
        isLoadingPendientes:false,
        
        estudianteData:"",
        gradoData:Object,
        asignacionData:"",
    }),
    methods:{
        gotoMisCursos(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            this.$router.push({name:'materias_estudiante'},()=>{
                this.isLoading=false;
                this.$store.commit("setLoading",this.isLoading);
            })
        },
        gotoMisCalificaciones(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            this.$router.push({name:'calificaciones_estudiante'},()=>{
                this.isLoading=false;
                this.$store.commit("setLoading",this.isLoading);
            })
        },
        gotoMisPagos(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            this.$router.push({name:'pagos_estudiante'},()=>{
                this.isLoading=false;
                this.$store.commit("setLoading",this.isLoading);
            })
        },
        getCicloEscolar(asignacion){
            if(asignacion){
                return asignacion.idCicloEscolarNavigation.cicloEscolar1;
            }

            return "";
        },
        asignacionGrado(asignacion){
            if(asignacion){
                var text=`${asignacion.idGradoSeccionNavigation.idGradoNavigation.nombre} ${asignacion.idGradoSeccionNavigation.idSeccionNavigation.seccion}`;

                if(asignacion.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation){
                    text+=` - ${asignacion.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation.nombre}`
                }else{
                    text+=` - ${asignacion.idGradoSeccionNavigation.idGradoNavigation.idNivelEstudiantilNavigation.nombre}`
                }

                return text;
            }
            return "No existe asignación para el estudiante";
        },
        getPhotoSize(){
            if(this.$vuetify.breakpoint.xsOnly){
                return 50;
            }
            if(this.$vuetify.breakpoint.smAndUp&&this.$vuetify.breakpoint.mdAndDown){
                return 70;
            }
            if(this.$vuetify.breakpoint.lgAndUp){
                return 90;
            }
        },
        loadAsignacion(){return this.$axios.get(`api/estudiantil/asignaciones/inscripcion/${this.$route.params.ciclo}`)},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadAsignacion()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.asignacionData=response[0].data.data;
                        this.$store.commit("changeCiclo",this.asignacionData.idCicloEscolarNavigation);
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.$store.commit("changeCiclo",{cicloEscolar1:this.$route.params.ciclo});
        Initialize();
        this.loadAll();
    },
    beforeDestroy(){
        StopWorker();
    }
}
</script>
<style scoped>
.white-logout{
    filter: brightness(0) invert(1);
}
</style>