<template>
    <v-app id="primaria_materias">
        <materias :title="title"></materias>
    </v-app>    
</template>
<script>
import Materias from '../Materias.vue';
export default {
  components: {  Materias },
    props:[
        "title"
    ],
    watch:{

    },
    data:function(){
        return ({
        });
    },
    methods:{

    },
    created(){

    },
}
</script>
<style scoped>

</style>