<template>
    <div>
        <div v-if="$router.currentRoute.name=='pagos_estudiante'">
            <v-app-bar app flat :color="$vuetify.theme.dark?'':'white'">
                <v-row justify="space-between">
                    <v-col class="pa-0" cols="4"><v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn></v-col>
                    <v-col class="pa-0" cols="4" align="center"><notifications-actividades/></v-col>
                    <v-col class="pa-0" cols="4"></v-col>
                </v-row>
                <v-progress-linear 
                absolute 
                bottom 
                :color="$store.state.accentColor2" 
                indeterminate
                background-color="transparent"
                v-if="isLoading"
                ></v-progress-linear>
            </v-app-bar>
            <logout-dialog :logoutDialog="logoutDialog" @onclose="logoutDialog=false" :context="'estudiante'"></logout-dialog>
            <generic-header :text="'Mis pagos'"></generic-header>
            <v-container class="custom-container">
                <v-row>
                    <v-col>
                        <v-card class="rounded-xl" elevation="5">
                            <br>
                            <v-row justify="center" no-gutters class="text-h6 font-weight-bold">Pagos</v-row>
                            <v-divider></v-divider>
                            <v-data-table
                            hide-default-footer
                            :headers="headersPagos" :items="pagosData"
                            >
                                <template v-slot:item.pago.idPagoNavigation.descripcion="{item}">
                                    <div v-if="item.pago">{{item.pago.idPagoNavigation.descripcion}}</div>
                                    <div v-if="!item.pago">{{item.descripcionPersonalizado}}</div>
                                </template>
                                <template v-slot:item.pago.cantidad="{item}">
                                    <div v-if="item.pago">{{item.pago.cantidad}}</div>
                                    <div v-if="!item.pago">{{item.montoPersonalizado}}</div>
                                </template>
                                <template v-slot:item.pagado="{item}">
                                    <v-row no-gutters justify="center">
                                        <v-chip :color="getColorPago(item)" dark>
                                            {{getPagadoText(item)}}
                                        </v-chip>
                                    </v-row>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card class="rounded-xl" elevation="5">
                            <br>
                            <v-row justify="center" no-gutters class="text-h6 font-weight-bold">Mensualidades</v-row>
                            <br>
                            <v-row class="ma-0 px-2">
                                <div class="text-subtitle-2">{{getColegiaturaTexto}}</div>
                            </v-row>
                            <br>
                            <v-row class="ma-0 px-2" justify="end">
                                <div class="text-caption">
                                    <v-avatar color="orange" size="30"></v-avatar>
                                    = Pago atrasado
                                    <v-avatar color="grey darken-3" size="30"></v-avatar>
                                    = Pago pendiente (a tiempo)
                                </div>
                            </v-row>
                            <br>
                            <v-divider></v-divider>
                            <v-data-table
                            hide-default-footer
                            :headers="headersMensualidades" :items="mensualidadesData"
                            >
                                <template v-slot:item.mes="{item}">
                                    {{getMesMensualidadText(item)}}
                                </template>
                                <template v-slot:item.fechaPagado="{item}">
                                    <div v-if="item.pagada">{{getHumanDate(item.fechaPagado)}}</div>
                                </template>
                                <template v-slot:item.cantidad="{item}">
                                    <div v-if="item.pagada">{{item.cantidad}}</div>
                                </template>
                                <template v-slot:item.pagado="{item}">
                                    <v-row no-gutters justify="center">
                                        <v-chip :color="getColorMensualidadPago(item)" dark>
                                            {{getPagadoMensualidadText(item)}}
                                        </v-chip>
                                    </v-row>
                                </template>
                                <template v-slot:item.fechaMaximaPago="{item}">
                                        {{getHumanDate(item.fechaMaximaPago)}}
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>    
</template>
<script>
import GenericHeader from '../../globalComponents/GenericHeader.vue';
import LogoutDialog from '../../globalComponents/LogoutDialog.vue';
import GetReadableDate from '../../../utils/timeUtils';
import NotificationsActividades from '../home/NotificationsActividades.vue'
export default {
    components: { GenericHeader, LogoutDialog,NotificationsActividades },
    computed:{
        getColegiaturaTexto(){
            if(this.colegiaturaEspecificacion)
                return `Colegiatura actual: Q${this.colegiaturaEspecificacion.pago.cantidad} durante ${this.colegiaturaEspecificacion.numeroMeses} meses.`;
            return "";
        },
    },
    data:function(){
    GenericHeader
        return({
            isLoading:false,
            logoutDialog:false,
            pagosData:[],
            mensualidadesData:[],
            colegiaturaEspecificacion:"",
            headersPagos:[
                {text:"Nombre",value:"pago.idPagoNavigation.descripcion"},
                {text:"Meses de pago",value:"numeroMeses"},
                {text:"Monto (Q)",value:"pago.cantidad"},
                {text:"Estado",value:"pagado"},
            ],
            headersMensualidades:[
                {text:"Mes",value:"mes"},
                {text:"Fecha límite de pago",value:"fechaMaximaPago"},
                {text:"Fecha pagada",value:"fechaPagado"},
                {text:"Monto (Q)",value:"cantidad"},
                {text:"Estado",value:"pagado"},
            ],
        });
    },
    methods:{
        getMesMensualidadText(item){
            var mes="";
            var maximoMonth=new Date(item.fechaMaximaPago);

            mes=`${maximoMonth.toLocaleString('default', { month: 'long' })}`;

            return mes;
        },
        getPagadoText(item){
            var fechaMaximaPago=new Date(item.fechaMaximaPago);
            fechaMaximaPago.setDate(fechaMaximaPago.getDate()+1);

            if(this.$store.state.currentRealDate>fechaMaximaPago){
                if(item.pagado){
                    return "Pagado";
                }else{
                    return  "Pendiente";
                }
            }else{
                if(!item.pagado){
                    return "Pendiente";
                }else{
                    return "Pagado";
                }
            }
        },
        getPagadoMensualidadText(item){
            var fechaMaximaPago=new Date(item.fechaMaximaPago);
            fechaMaximaPago.setDate(fechaMaximaPago.getDate()+1);
            
            if(this.$store.state.currentRealDate>fechaMaximaPago){
                if(item.pagada){
                    return "Pagada";
                }else{
                    return  "Pendiente";
                }
            }else{
                if(!item.pagada){
                    return "Pendiente";
                }else{
                    return "Pagada";
                }
            }
        },
        getColorPago(item){
            var fechaMaximaPago=new Date(item.fechaMaximaPago);
            fechaMaximaPago.setDate(fechaMaximaPago.getDate()+1);

            if(this.$store.state.currentRealDate>fechaMaximaPago){
                if(item.pagado){
                    return "green";
                }else{
                    return  "orange";
                }
            }else{
                if(!item.pagado){
                    return "";
                }else{
                    return "green";
                }
            }
        },
        getColorMensualidadPago(item){
            var fechaMaximaPago=new Date(item.fechaMaximaPago);
            fechaMaximaPago.setDate(fechaMaximaPago.getDate()+1);

            if(this.$store.state.currentRealDate>fechaMaximaPago){
                if(item.pagada){
                    return "green";
                }else{
                    return  "orange";
                }
            }else{
                if(!item.pagada){
                    return "";
                }else{
                    return "green";
                }
            }
        },
        getHumanDate(date){
            if(date){
                var datenotime=GetReadableDate(date);
                return datenotime.substring(0,datenotime.length-6);
            }
            return "";
        },
        loadPagos(){return this.$axios.get(`api/estudiantil/pagos`)},
        loadMensualidades(){return this.$axios.get(`api/estudiantil/pagos/mensualidades`)},
        loadCurrentDate(){return this.$axios.get(`api/estudiantil/currentdate`)},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadPagos(),this.loadMensualidades(),this.loadCurrentDate()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.pagosData=response[0].data.data;
                        this.mensualidadesData=response[1].data.data;
                        this.$store.commit("setCurrentDate",new Date(response[2].data));

                        this.initializePagos();
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        },
        initializePagos(){
            this.pagosData.map(item=>{
                if(item.idPagoNivelEstudiantil){
                    item.pago=item.idPagoNivelEstudiantilNavigation;
                    delete item.idPagoNivelEstudiantilNavigation;
                }
                else{
                    item.pago=item.idPagoNivelEstudiantilCarreraNavigation;
                    delete item.idPagoNivelEstudiantilCarreraNavigation;
                }
            });

            this.colegiaturaEspecificacion=this.pagosData.filter(item=>item.pago);
            this.colegiaturaEspecificacion=this.colegiaturaEspecificacion.filter(item=>item.pago.idPagoNavigation.colegiatura);

            if(this.colegiaturaEspecificacion.length>0)
                this.colegiaturaEspecificacion=this.colegiaturaEspecificacion[0];

            var personalizados=this.pagosData.filter(item=>!item.pago);

            this.pagosData=this.pagosData.filter(item=>item.pago);
            this.pagosData=this.pagosData.filter(item=>!item.pago.idPagoNavigation.colegiatura);
            this.pagosData=[...this.pagosData,...personalizados];
        }
    },
    created(){
        this.loadAll();
    },
}
</script>
<style scoped>

</style>