<template>
    <div>
        <div v-if="!isLoading">
            <v-container class="custom-container" :style="'height:77vh;'">
                <v-row>
                    <v-col>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium text-h6 text-xs-h6 text-sm-h5 text-md-h5 text-lg-h4 text-xl-h4">
                                    Profesores
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider :color="$vuetify.theme.dark?'white':'black'"></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon :color="$store.state.accentColor2">person</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{profesorData.nombres}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium text-h6 text-xs-h6 text-sm-h5 text-md-h5 text-lg-h4 text-xl-h4">
                                    Alumnos
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider :color="$vuetify.theme.dark?'white':'black'"></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-list>
                            <div v-for="alumno in alumnosData" :key="alumno.idEstudiante">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon :color="$store.state.accentColor2">person</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{alumno.idEstudianteNavigation.nombre1}} {{alumno.idEstudianteNavigation.nombre2}} {{alumno.idEstudianteNavigation.nombre3}} {{alumno.idEstudianteNavigation.apellido1}} {{alumno.idEstudianteNavigation.apellido2}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </div>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
export default {
  components: {  },
    props:[
        "tab",
    ],
    watch:{
        tab:function(newval,oldval){
            oldval;
            if(newval==2){
                this.loadAll();
            }
        }
    },
    data: function(){
        return({
            alumnosData:[],
            profesorData:[],
            isLoading:false,
        });
    },
    methods:{
        loadStudents(){return this.$axios.get(`api/estudiantil/asignaciones/asignacion/${this.$route.params.idassignment}/estudiantes`);},
        loadProfesor(){return this.$axios.get(`api/estudiantil/asignaciones/asignacion/${this.$route.params.idassignment}/profesor`)},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadStudents(),this.loadProfesor()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200){
                    if(response[0].data.code==1&&response[1].data.code==1){
                        this.alumnosData=response[0].data.data;
                        this.profesorData=response[1].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>

</style>