<template>
    <v-dialog v-model="colaboradorDialog" max-width="600" persistent scrollable>
        <v-card :loading="isLoading" :disabled="isLoading" outlined>
            <v-card-title>
                {{title}}
            </v-card-title>
            <v-card-text>
                * indica campo obligatorio
                <v-form ref="colaboradorForm">
                    <v-row>
                        <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                            label="Nombres *"
                            outlined
                            rounded
                            counter
                            maxlength="100"
                            v-model="colaborador.nombres"
                            :rules="colaborador.nombresRules"
                            :color="$store.state.accentColor"
                            autocomplete="off"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                            label="DPI *"
                            outlined
                            rounded
                            counter
                            maxlength="25"
                            v-model="colaborador.dpi"
                            :rules="colaborador.dpiRules"
                            :color="$store.state.accentColor"
                            autocomplete="off"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-select
                            label="Cargos *"
                            :items="cargosData"
                            item-value="idCargo"
                            item-text="nombre"
                            v-model="colaborador.colaboradoresCargos"
                            outlined
                            rounded
                            :color="$store.state.accentColor"
                            item-color=""
                            :rules="colaborador.colaboradoresCargosRules"
                            return-object
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            counter="3"
                            >

                            </v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                            autocomplete="off"
                            label="Teléfono *"
                            outlined
                            rounded
                            counter
                            maxlength="15"
                            v-model="colaborador.telefono"
                            :rules="colaborador.telefonoRules"
                            :color="$store.state.accentColor"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-menu
                                ref="fechaNacimiento"
                                v-model="colaborador.fechaNacimientoPopup"
                                :close-on-content-click="false"
                                :return-value.sync="colaborador.fechaNacimiento"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :rules="colaborador.fechaNacimientoRules"
                                    v-model="colaborador.fechaNacimiento"
                                    label="Fecha nacimiento *"
                                    prepend-inner-icon="event"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    rounded
                                    :color="$store.state.accentColor" 
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="colaborador.fechaNacimiento"
                                no-title
                                scrollable
                                @input="colaborador.fechaNacimientoPopup=false;$refs.fechaNacimiento.save(colaborador.fechaNacimiento);"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-checkbox
                            :color="$store.state.accentColor"
                            v-model="colaborador.antecedentesPenales"
                            inset
                            :label="`¿Tiene antecedentes penales?`"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-checkbox
                            :color="$store.state.accentColor"
                            v-model="colaborador.antecedentesPoliciacos"
                            inset
                            :label="`¿Tiene antecedente policiacos?`"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-checkbox
                            :color="$store.state.accentColor"
                            v-model="colaborador.renas"
                            inset
                            :label="`¿Tiene renas?`"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" justify="center">
                        <v-switch
                        label="¿Usuario?"
                        v-model="colaborador.usuarioBit"
                        :color="$store.state.accentColor"
                        inset
                        >

                        </v-switch>
                    </v-row>
                    <v-row class="pa-0 ma-0" v-if="colaborador.usuarioBit">
                        <v-col>
                            <v-text-field label="Usuario"
                            outlined
                            rounded
                            suffix="@asociacionkairosguatemala.org"
                            v-model="colaborador.colaboradoresUsuarios[0].usuario"
                            :rules="colaborador.colaboradoresUsuarios[0].usuarioRules"
                            :color="$store.state.accentColor"
                            counter
                            maxlength="50"
                            autocomplete="off"
                            >

                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose');$refs.colaboradorForm.reset()">Cancelar</v-btn>
                <v-btn text color="green" @click="save">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:[
        "colaboradorDialog",
        "action",
        "colaboradorToEdit"
    ],
    watch:{
        colaboradorDialog:async function(newVal,oldVal){
            oldVal;
            if(newVal){
                delete this.colaborador.idColaborador;
                delete this.colaborador.colaboradoresUsuarios[0].idColaboradorUsuario;
                delete this.colaborador.colaboradoresUsuarios[0].idColaborador;
                delete this.colaborador.colaboradoresUsuarios[0].idRol;
                delete this.colaborador.colaboradoresUsuarios[0].usuario;

                if(this.action=="add"){
                    this.title="Nuevo colaborador"
                }else{
                    this.title="Edición de colaborador"

                    this.colaborador.idColaborador=this.colaboradorToEdit.idColaborador;
                    this.colaborador.nombres=this.colaboradorToEdit.nombres;
                    this.colaborador.colaboradoresCargos=this.colaboradorToEdit.colaboradoresCargos;
                    this.colaborador.dpi=this.colaboradorToEdit.dpi;
                    this.colaborador.telefono=this.colaboradorToEdit.telefono;
                    this.colaborador.fechaNacimiento=this.colaboradorToEdit.fechaNacimiento.split("T")[0];
                    this.colaborador.activo=this.colaboradorToEdit.activo;
                    this.colaborador.fechaIngreso=this.colaboradorToEdit.fechaIngreso;
                    this.colaborador.antecedentesPenales=this.colaboradorToEdit.antecedentesPenales;
                    this.colaborador.antecedentesPoliciacos=this.colaboradorToEdit.antecedentesPoliciacos;
                    this.colaborador.renas=this.colaboradorToEdit.renas;
                    this.colaborador.usuarioBit=this.colaboradorToEdit.usuarioBit;

                    if(this.colaborador.usuarioBit){
                        this.colaborador.colaboradoresUsuarios[0].idColaboradorUsuario=this.colaboradorToEdit.colaboradoresUsuarios[0].idColaboradorUsuario;
                        this.colaborador.colaboradoresUsuarios[0].idColaborador=this.colaboradorToEdit.colaboradoresUsuarios[0].idColaborador;
                        this.colaborador.colaboradoresUsuarios[0].idRol=this.colaboradorToEdit.colaboradoresUsuarios[0].idRol;
                        this.colaborador.colaboradoresUsuarios[0].usuario=this.colaboradorToEdit.colaboradoresUsuarios[0].usuario;
                    }else{
                        
                        if(this.colaboradorToEdit.colaboradoresUsuarios[0]){
                            this.colaborador.colaboradoresUsuarios[0].idColaboradorUsuario=this.colaboradorToEdit.colaboradoresUsuarios[0].idColaboradorUsuario;
                            this.colaborador.colaboradoresUsuarios[0].idColaborador=this.colaboradorToEdit.colaboradoresUsuarios[0].idColaborador;
                            this.colaborador.colaboradoresUsuarios[0].idRol=this.colaboradorToEdit.colaboradoresUsuarios[0].idRol;
                            this.colaborador.colaboradoresUsuarios[0].usuario=this.colaboradorToEdit.colaboradoresUsuarios[0].usuario;
                        }
                    }
                }

                await this.loadAll();
            }
        }
    },
    data:()=>({
        title:"",
        cargosData:[],
        isLoading:false,
        colaborador:{
            nombres:"",
            nombresRules:[
                v => !!v || 'Nombres es requerido.',
                v => ( !v ||v.length <= 100) || `Nombres debe ser de tamaño ${v.length} o menos.`,
                //v=>/^[(\w)|(\s)]*$/.test(v)||'Nombre debe ser alfanumérico.',
                v=>!/^\s+$/.test(v)||'Nombres no debe ser un espacio en blanco.'
            ],
            colaboradoresCargos:"",
            colaboradoresCargosRules:[
                v => !!v&&v.length>0 || 'Cargos es requerido.',
                v=>!!v&&v.length<=3||'Solo se pueden seleccionar 3 cargos.'
            ],
            dpi:"",
            dpiRules:[
                v => !!v || 'DPI es requerido.',
                v => ( !v ||v.length <= 25) || `DPI debe ser de tamaño ${v.length} o menos.`,
                v=>(/^\d*$/.test(v)||!v)||"DPI debe ser numérico.",
                v=>(!v||v.length<=25)
            ],
            telefono:"",
            telefonoRules:[
                v => !!v || 'Teléfono es requerido.',
                v => ( !v ||v.length <= 25) || `Teléfono debe ser de tamaño ${v.length} o menos.`,
                v=>(/^\d*$/.test(v)||!v)||"Telefono debe ser numérico.",
                v=>(!v||v.length<=15)
            ],
            fechaNacimiento:"",
            fechaNacimientoPopup:false,
            fechaNacimientoRules:[
                v => !!v || 'Fecha nacimiento es requerido.',
            ],
            antecedentesPenales:false,
            antecedentesPoliciacos:false,
            renas:false,
            usuarioBit:false,
            colaboradoresUsuarios:[
                {
                    usuario:"",
                    usuarioRules:[
                        v => !!v|| 'Usuario es requerido si se activa.',
                        v => ( !v ||v.length <= 50) || `Usuario debe ser de tamaño ${v.length} o menos.`,
                        //v=>/^[(\w)|(\s)]*$/.test(v)||'Usuario debe ser alfanumérico.',
                        v=>!/^\s+$/.test(v)||'Usuario no debe ser un espacio en blanco.'
                    ]      
                }
            ]
        }
    }),
    methods:{
        loadCargos(){
            return this.$axios.get("api/cargos");
        },
        async loadAll(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.all([this.loadCargos()]).then((responses)=>{
                if(responses[0].status==200){
                    if(responses[0].data.code==1){
                        this.cargosData=responses[0].data.data;
                    }
                }
            }).catch((errors)=>{
                errors;
            });

            this.isLoading=false;
        },
        async save(){
            this.isLoading=this.$store.state.accentColor;
            if(this.action=="add"){
                await this.saveCall();
            }else{
                await this.updateCall();
            }
            this.isLoading=false;
        },
        async saveCall(){
            if(this.$refs.colaboradorForm.validate()){
                await this.$axios.post("api/colaboradores",
                    this.colaborador
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$refs.colaboradorForm.reset();
                            this.$emit("onsave",[1,response.data.data,response.data.message]);
                        }else{
                            this.$emit("onsave",[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onsave",[2,error.response.data.message]);
                        }else{
                            this.$emit("onsave",[2]);
                        }
                    }
                });

                this.$emit("onclose");
            }
        },
        async updateCall(){
            if(this.$refs.colaboradorForm.validate()){

                this.colaborador.colaboradoresCargos.forEach(element=>{
                    var temp=this.colaboradorToEdit.colaboradoresCargos.find(item=>item.idCargo==element.idCargo);

                    if(temp){
                        element.idColaboradorCargo=temp.idColaboradorCargo;
                    }
                });

                await this.$axios.put(`api/colaboradores/update/${this.colaboradorToEdit.idColaborador}`,
                    this.colaborador
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$refs.colaboradorForm.reset();
                            this.$emit("onupdate",[1,response.data.data,response.data.message]);
                        }else{
                            this.$emit("onupdate",[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onupdate",[2,error.response.data.message]);
                        }else{
                            this.$emit("onupdate",[2]);
                        }
                    }
                });

                this.$emit("onclose");
            }
        }
    },
}
</script>
<style scoped>

</style>