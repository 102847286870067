<template>
    <v-sheet min-height="150px" height="10vh" color="transparent">
        <v-container class="fill-height">
            <v-row no-gutters justify="center">
            <h1 class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-medium">{{text}}</h1>
        </v-row>
        </v-container>
    </v-sheet>
</template>
<script>
export default {
    props:{
        text:String
    }
}
</script>
<style scoped>

</style>