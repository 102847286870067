<template>
    <v-app id="home_colaborador_docente">
        <v-main>
            <div v-if="$router.currentRoute.name=='home_colaborador_docente'">
                <v-app-bar app flat :color="$vuetify.theme.dark?'':'white'">
                    <v-spacer></v-spacer>
                    <v-btn @click="logoutDialog=true" text outlined rounded :color="$store.state.accentColor2">Cerrar sesión</v-btn>
                </v-app-bar>
                <logout-dialog :logoutDialog="logoutDialog" @onclose="()=>{logoutDialog=false;}"></logout-dialog>
                <div>
                    <v-row class="ma-0" justify="center">
                        <v-col cols="12" xs="12" sm="10" md="10" lg="6" xl="6">
                            <v-list-item>
                                <v-list-item-avatar :size="getPhotoSize()">
                                    <v-img :src="$store.state.profileInfo.fL"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium text-xl-h5 text-lg-h6 text-md-h6 text-sm-h6 wrap-text">
                                        {{getDocenteNombre}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{$store.state.profileInfo.uu}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        {{roles}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="regresarAdmin">
                                        <v-btn outlined rounded :color="$store.state.accentColor2" x-small class="normal-text" to="/colaborador/administrador">Ir a área administrativa</v-btn>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <br>
                    <v-container fluid>
                        <v-row no-gutters class="text-h6 font-weight">
                            Mis ciclos escolares
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="asignacion in asignacionData" :key="asignacion.idEstudianteGrado">
                                <v-card class="rounded-lg card-text" :color="$store.state.accentColor2" dark elevation="5" flat ripple @click="goAsignacion(asignacion)" height="80">
                                    <v-card-title class="text-h4 font-weight-bold pb-0">
                                        <v-row no-gutters justify="center">
                                            {{getCicloEscolar(asignacion)}}
                                        </v-row>
                                    </v-card-title>
                                    <v-row class="text-caption px-4" no-gutters justify="center">
                                        {{getCurrentText(asignacion)}}
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>                
                </div>
            </div>
            <transition name="scroll-y-transition" mode="out-in">
                <router-view />
            </transition>
        </v-main>
        <snackbar />
        <loader :isLoading="isLoading" :accentColor="2"></loader>
    </v-app>  
</template>
<script>
import Loader from '../../../globalComponents/Loader.vue';
import LogoutDialog from '../../../globalComponents/LogoutDialog.vue';
import Snackbar from '../../../globalComponents/Snackbar.vue';
export default {
  components: { LogoutDialog, Loader, Snackbar },
    computed:{
        roles:function(){
            var rolesString="";
            
            if(this.docenteInfo){
                this.docenteInfo.colaboradoresCargos.forEach(element => {
                    rolesString+=`* ${element.idCargoNavigation.nombre} `;
                });
            }

            return rolesString;
        },
        getDocenteNombre(){
            if(this.docenteInfo){
                return this.docenteInfo.nombres;
            }
            return "";
        },
        regresarAdmin(){
            return [
            "Administrador 1",
            "Administrador 2",
            "Administrador 3"
            ].some(permitidos=>this.$store.state.profileInfo.cargos.some(cargo=>cargo.roles.some(rol=>permitidos.toLowerCase().includes(rol.rol.toLowerCase()))));
        }
    },
    data:()=>({
        logoutDialog:false,
        isLoading:false,
        asignacionData:[],
        docenteInfo:""
    }),
    methods:{
        goAsignacion(asignacion){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            this.$router.push({name:"inicio_colaborador_docente",params:{ciclo:asignacion}},()=>{
                this.isLoading=false;
                this.$store.commit("setLoading",this.isLoading);
            });
        },
        getCurrentText(asignacion){
            if(asignacion){
                var currDate=this.$store.state.currentRealDate;

                if(asignacion==currDate.getFullYear()){
                    return "Ciclo actual"
                }
            }

            return "";
        },
        getCicloEscolar(asignacion){
            if(asignacion){
                return asignacion;
            }

            return "";
        },
        getPhotoSize(){
            if(this.$vuetify.breakpoint.xsOnly){
                return 50;
            }
            if(this.$vuetify.breakpoint.smAndUp&&this.$vuetify.breakpoint.mdAndDown){
                return 70;
            }
            if(this.$vuetify.breakpoint.lgAndUp){
                return 100;
            }
        },
        loadCiclos(){return this.$axios.get(`api/docentes/asignaciones/ciclos`)},
        loadCurrentDate(){return this.$axios.get(`api/currentdate`)},
        loadDocenteInfo(){return this.$axios.get(`api/docentes/docentes/docente`)},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadCiclos(),this.loadCurrentDate(),this.loadDocenteInfo()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200&&response[2].status==200){
                    if(response[0].data.code==1&&response[2].data.code==1){
                        this.asignacionData=response[0].data.data;
                        this.$store.commit("setCurrentDate",new Date(response[1].data));
                        this.docenteInfo=response[2].data.data;
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.$store.commit("setAccentColor2","teal darken-1");
        this.loadAll();
    }
}
</script>
<style scoped>
.white-logout{
    filter: brightness(0) invert(1);
}
</style>