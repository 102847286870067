import VueRouter from 'vue-router';
import Vue from 'vue'
import store from '../store/store'
import {verifyJWTs,verifyStudentJWTs} from '../utils/api'

import PrivateLayout from '../components/PrivateLayout'

import HomeColaborador from '../components/colaborador/home/admin/HomeColaborador'
  import Inicio from '../components/colaborador/inicio/Inicio'
    import PendientesPagos from '../components/colaborador/estudiantes/PendientesPagos'
    import EstudiantesNuevos from '../components/colaborador/estudiantes/EstudiantesNuevos'
  import Grados from '../components/colaborador/grados/admin/Grados'
    import GradoDetalle from '../components/colaborador/grados/admin/GradoDetalle'
  import Estudiantes from '../components/colaborador/estudiantes/Estudiantes'
  import PreprimariaMaterias from '../components/colaborador/materias/admin/preprimaria/PreprimariaMaterias'
  import PrimariaMaterias from '../components/colaborador/materias/admin/primaria/PrimariaMaterias'
  import BasicosMaterias from '../components/colaborador/materias/admin/basicos/BasicosMaterias'
  import DiversificadoMaterias from '../components/colaborador/materias/admin/diversificado/DiversificadoMaterias'
  import Colaboradores from '../components/colaborador/colaboradores/Colaboradores'
  import PagosNiveles from '../components/colaborador/pagosNiveles/PagosNiveles'

import HomeDocente from '../components/colaborador/home/docente/HomeDocente'
  import InicioDocente from '../components/colaborador/home/docente/Inicio'
    import MateriasDocente from '../components/colaborador/materias/docente/MateriasDocente'
        import Escritorio from '../components/colaborador/escritorio/escritorio/Escritorio'
          import Tarea from '../components/colaborador/escritorio/common/tarea/Tarea'
          import TareaDetails from '../components/colaborador/escritorio/common/tarea/TareaDetails'
            import TareaEntrega from '../components/colaborador/escritorio/common/tarea/Entrega'
          import MaterialDocente from '../components/colaborador/escritorio/common/material/Material'
          import MaterialDetails from '../components/colaborador/escritorio/common/material/MaterialDetails'
          import TareaExtra from '../components/colaborador/escritorio/common/extra/tarea/TareaExtra'
          import TareaExtraDetails from '../components/colaborador/escritorio/common/extra/tarea/TareaExtraDetails'
            import TareaExtraEntrega from '../components/colaborador/escritorio/common/tarea/Entrega'
          import ExamenFinal from '../components/colaborador/escritorio/common/examenFinal/ExamenFinal'
          import ExamenFinalDetails from '../components/colaborador/escritorio/common/examenFinal/ExamenFinalDetails'
            import ExamenFinalEntrega from '../components/colaborador/escritorio/common/examenFinal/Entrega'
          import Parcial from '../components/colaborador/escritorio/common/parcial/Parcial'
          import ParcialDetails from '../components/colaborador/escritorio/common/parcial/ParcialDetails'
            import ParcialEntrega from '../components/colaborador/escritorio/common/parcial/Entrega'
          import ParcialExtra from '../components/colaborador/escritorio/common/extra/parcial/ParcialExtra'
          import ParcialExtraDetails from '../components/colaborador/escritorio/common/extra/parcial/ParcialExtraDetails'
            import ParcialExtraEntrega from '../components/colaborador/escritorio/common/parcial/Entrega'
          import ExamenFinalExtra from '../components/colaborador/escritorio/common/extra/examenfinal/ExamenFinalExtra'
          import ExamenFinalExtraDetails from '../components/colaborador/escritorio/common/extra/examenfinal/ExamenFinalExtraDetails'
            import ExamenFinalExtraEntrega from '../components/colaborador/escritorio/common/examenFinal/Entrega'
    import GradosDocente from '../components/colaborador/grados/docente/GradosDocente'
    import GradosCalificaciones from '../components/colaborador/grados/docente/GradosCalificaciones'
      import CalificacionesGenerales from '../components/colaborador/calificaciones/CalificacionesGenerales'

import HomeEstudiantil from '../components/estudiantil/home/HomeEstudiantil'
  import InicioEstudiantil from '../components/estudiantil/home/Inicio'
    import MateriasEstudiante from '../components/estudiantil/materias/MateriasEstudiante'
      import EscritorioEstudiante from '../components/estudiantil/escritorio/Escritorio'
        import TareaDetailsEstudiante from '../components/estudiantil/escritorio/common/tarea/TareaDetails'
        import MaterialDetailsEstudiante from '../components/estudiantil/escritorio/common/material/MaterialDetails'
        import TareaExtraDetailsEstudiante from '../components/estudiantil/escritorio/common/extra/tarea/TareaExtraDetails'
        import ExamenFinalDetailsEstudiante from '../components/estudiantil/escritorio/common/examenFinal/ExamenFinalDetails'
        import ParcialDetailsEstudiante from '../components/estudiantil/escritorio/common/parcial/ParcialDetails'
        import ParcialExtraDetailsEstudiante from '../components/estudiantil/escritorio/common//extra/parcial/ParcialExtraDetails'
        import ExamenFinalExtraDetailsEstudiante from '../components/estudiantil/escritorio/common/extra/examenFinal/ExamenFinalExtraDetails'
    import Pagos from '../components/estudiantil/pagos/Pagos';
    import Calificaciones from '../components/estudiantil/calificaciones/Calificaciones';



Vue.use(VueRouter)

    
const admins1=[
    "Administrador 1",
    "Administrador 2",
    "Administrador 3"
];

const admins2operators=[
    "Administrador 4",
    "Operador"
];

const routes=new VueRouter({
    base:"/",
    mode:"hash",
    routes:[
      {
        path:"/",component:PrivateLayout
      },
      {
        path:"/estudiante",component:PrivateLayout,children:[
          {path:"",component:HomeEstudiantil,name:"home_estudiante",beforeEnter:isAuthorizedStudent,children:[
            {
              path:"ciclo/:ciclo",component:InicioEstudiantil,name:"inicio_estudiante",beforeEnter:isAuthorizedStudent,children:[
                {path:"materias",component:MateriasEstudiante,name:"materias_estudiante",beforeEnter:isAuthorizedStudent,children:[
                  {path:":idassignment/escritorio",component:EscritorioEstudiante,name:"escritorio_estudiante",beforeEnter:isAuthorizedStudent,children:[
                    {path:"tarea/:idtarea/detalles",component:TareaDetailsEstudiante,name:"tarea_estudiante_detalles",beforeEnter:isAuthorizedStudent},
                    {path:"material/:idmaterial/detalles",component:MaterialDetailsEstudiante,name:"material_estudiante_detalles",beforeEnter:isAuthorizedStudent},
                    {path:"extra/tarea/:idtarea/detalles",component:TareaExtraDetailsEstudiante,name:"tarea_extra_estudiante_detalles",beforeEnter:isAuthorizedStudent},
                    {path:"examen-final/:idexamenfinal/detalles",component:ExamenFinalDetailsEstudiante,name:"examen_final_estudiante_detalles",beforeEnter:isAuthorizedStudent},
                    {path:"parcial/:idparcial/detalles",component:ParcialDetailsEstudiante,name:"parcial_estudiante_detalles",beforeEnter:isAuthorizedStudent},
                    {path:"extra/parcial/:idparcial/detalles",component:ParcialExtraDetailsEstudiante,name:"parcial_extra_estudiante_detalles",beforeEnter:isAuthorizedStudent},
                    {path:"extra/examen-final/:idexamenfinal/detalles",component:ExamenFinalExtraDetailsEstudiante,name:"examen_final_extra_estudiante_detalles",beforeEnter:isAuthorizedStudent},
                  ]}
                ]},
                {path:"pagos",component:Pagos,name:"pagos_estudiante",beforeEnter:isAuthorizedStudent},
                {path:"calificaciones",component:Calificaciones,name:"calificaciones_estudiante",beforeEnter:isAuthorizedStudent},
                {path:"pendiente/tarea/:idtarea/detalles",component:TareaDetailsEstudiante,name:"tarea_estudiante_pendiente",beforeEnter:isAuthorizedStudent},
                {path:"pendiente/extra/tarea/:idtarea/detalles",component:TareaExtraDetailsEstudiante,name:"tarea_extra_estudiante_pendiente",beforeEnter:isAuthorizedStudent},
                {path:"pendiente/examen-final/:idexamenfinal/detalles",component:ExamenFinalDetailsEstudiante,name:"examen_final_estudiante_pendiente",beforeEnter:isAuthorizedStudent},
                {path:"pendiente/parcial/:idparcial/detalles",component:ParcialDetailsEstudiante,name:"parcial_estudiante_pendiente",beforeEnter:isAuthorizedStudent},
                {path:"pendiente/extra/parcial/:idparcial/detalles",component:ParcialExtraDetailsEstudiante,name:"parcial_extra_estudiante_pendiente",beforeEnter:isAuthorizedStudent},
                {path:"pendiente/extra/examen-final/:idexamenfinal/detalles",component:ExamenFinalExtraDetailsEstudiante,name:"examen_final_extra_estudiante_pendiente",beforeEnter:isAuthorizedStudent},
              ]
            },
            {path:"*",redirect:{name:"home_estudiante"}}
          ]},
        ],
      },
      {
        path:"/colaborador/administrador",component:PrivateLayout,beforeEnter:isAuthorized,children:[
          {path:"",component:HomeColaborador,redirect:{name:"Inicio"},name:"home_colaborador_admin",children:[
            {path:"inicio",component:Inicio,name:"Inicio",beforeEnter:isAuthorized,children:[
              {path:":ciclo/estudiantes/pendientes-pagos",component:PendientesPagos,name:"Pendientes pagos",beforeEnter:isAuthorized},
              {path:":ciclo/estudiantes/nuevos",component:EstudiantesNuevos,name:"Estudiantes nuevos",beforeEnter:isAuthorized}
            ]},
            {path:"grados",component:Grados,name:"Grados",beforeEnter:isAuthorized,children:[
              {path:"grado/:id",component:GradoDetalle,name:"Grado detalle",beforeEnter:isAuthorized}
            ]},
            {path:"alumnos",component:Estudiantes,name:"Alumnos",beforeEnter:isAuthorized},
            {path:"preprimaria/materias",component:PreprimariaMaterias,name:"Materias Preprimaria",beforeEnter:isAuthorized},
            {path:"primaria/materias",component:PrimariaMaterias,name:"Materias Primaria",beforeEnter:isAuthorized},
            {path:"basicos/materias",component:BasicosMaterias,name:"Materias Basicos",beforeEnter:isAuthorized},
            {path:"diversificado/materias",component:DiversificadoMaterias,name:"Materias Diversificado",beforeEnter:isAuthorized},
            {path:"colaboradores",component:Colaboradores,name:"Colaboradores",beforeEnter:isAuthorized},
            {path:"niveles/cuotas",component:PagosNiveles,name:"Cuotas y niveles",beforeEnter:isAuthorized},
            {path:"*",redirect:{name:"Inicio"}}
          ]},
        ],
      },
      {
        path:"/colaborador/docente",component:PrivateLayout,beforeEnter:isAuthorized,children:[
          {path:"",component:HomeDocente,name:"home_colaborador_docente",beforeEnter:isAuthorized,children:[
            {path:"ciclo/:ciclo",component:InicioDocente,name:"inicio_colaborador_docente",beforeEnter:isAuthorized,children:[
              {path:"materias",component:MateriasDocente,name:"materias_docente",beforeEnter:isAuthorized,children:[
                {path:":idassignment/escritorio",component:Escritorio,name:"escritorio_docente",beforeEnter:isAuthorized,children:[
                  {path:"tarea/nueva",component:Tarea,name:"tarea_docente",beforeEnter:isAuthorized},
                  {path:"tarea/:id/editar",component:Tarea,name:"tarea_docente_editar",beforeEnter:isAuthorized},
                  {path:"tarea/:id/detalles",component:TareaDetails,name:"tarea_docente_detalles",beforeEnter:isAuthorized,children:[
                    {path:"entrega/:idparticipante/detalles",component:TareaEntrega,name:"tarea_docente_entrega"}
                  ]},
                  {path:"material/nuevo",component:MaterialDocente,name:"material_docente",beforeEnter:isAuthorized},
                  {path:"material/:idmaterial/detalles",component:MaterialDetails,name:"material_docente_detalles",beforeEnter:isAuthorized},
                  {path:"material/:idmaterial/editar",component:MaterialDocente,name:"material_docente_editar",beforeEnter:isAuthorized},
                  {path:"bloque/:bloque/tarea-extra/nueva",component:TareaExtra,name:"tarea_extra_docente",beforeEnter:isAuthorized},
                  {path:"bloque/:bloque/tarea-extra/:id/editar",component:TareaExtra,name:"tarea_extra_docente_editar",beforeEnter:isAuthorized},
                  {path:"bloque/tarea-extra/:id/detalles",component:TareaExtraDetails,name:"tarea_extra_docente_detalles",beforeEnter:isAuthorized,children:[
                    {path:"entrega/:idparticipante/detalles",component:TareaExtraEntrega,name:"tarea_extra_docente_entrega"}
                  ]},
                  {path:"examen-final/nuevo",component:ExamenFinal,name:"examen_final_docente",beforeEnter:isAuthorized},
                  {path:"examen-final/:id/detalles",component:ExamenFinalDetails,name:"examen_final_docente_detalles",beforeEnter:isAuthorized,children:[
                    {path:"entrega/:idparticipante/detalles",component:ExamenFinalEntrega,name:"examen_final_docente_entrega"}
                  ]},
                  {path:"examen-final/:id/editar",component:ExamenFinal,name:"examen_final_docente_editar",beforeEnter:isAuthorized},
                  {path:"parcial/nuevo",component:Parcial,name:"parcial_docente",beforeEnter:isAuthorized},
                  {path:"parcial/:id/editar",component:Parcial,name:"parcial_docente_editar",beforeEnter:isAuthorized},
                  {path:"parcial/:id/detalles",component:ParcialDetails,name:"parcial_docente_detalles",beforeEnter:isAuthorized,children:[
                    {path:"entrega/:idparticipante/detalles",component:ParcialEntrega,name:"parcial_docente_entrega"}
                  ]},
                  {path:"bloque/:bloque/parcial/nuevo",component:ParcialExtra,name:"parcial_extra_docente",beforeEnter:isAuthorized},
                  {path:"bloque/:bloque/parcial/:id/editar",component:ParcialExtra,name:"parcial_extra_docente_editar",beforeEnter:isAuthorized},
                  {path:"bloque/:bloque/parcial/:id/detalles",component:ParcialExtraDetails,name:"parcial_extra_docente_detalles",beforeEnter:isAuthorized,children:[
                    {path:"entrega/:idparticipante/detalles",component:ParcialExtraEntrega,name:"parcial_extra_docente_entrega"}
                  ]},
                  {path:"bloque/:bloque/examen-final/nuevo",component:ExamenFinalExtra,name:"examen_final_extra_docente",beforeEnter:isAuthorized},
                  {path:"bloque/:bloque/examen-final/:id/editar",component:ExamenFinalExtra,name:"examen_final_extra_docente_editar",beforeEnter:isAuthorized},
                  {path:"bloque/:bloque/examen-final/:id/detalles",component:ExamenFinalExtraDetails,name:"examen_final_extra_docente_detalles",beforeEnter:isAuthorized,children:[
                    {path:"entrega/:idparticipante/detalles",component:ExamenFinalExtraEntrega,name:"examen_final_extra_docente_entrega"}
                  ]},
                ]}
              ]},
              {path:"grados",component:GradosDocente,name:"grados_docente",beforeEnter:isAuthorized},
              {path:"grados-calificaciones",component:GradosCalificaciones,name:"grados_calificaciones",beforeEnter:isAuthorized,children:[
                {path:":idgrado/calificaciones-generales",component:CalificacionesGenerales,name:"calificaciones_generales",beforeEnter:isAuthorized}
              ]}
            ]},
            {path:"*",redirect:{name:"home_colaborador_docente"}}
          ]},
        ],
      },
      //{path:"*",redirect:{name:"Root Page"}}
      {path:"*",redirect:"/"}
    ]
  });

  async function isAuthorized(to,from,next){
    from;
  
    if(to.path.includes("/colaborador/"))
      store.commit("setLoading",true);
    var isOk=await verifyJWTs();
    store.commit("setLoading",false);
  
    if(to.name=="login_colaborador"){
      if(isOk){
        goCorrectly(next,to);
      }else{
        store.commit("setProfileInfo","");
  
        next();
      }
    }else{
      if(to.path.includes("/colaborador/administrador")||to.path.includes("/colaborador/docente")){
        if(isOk){
          goCorrectly(next,to);
        }else{
          window.location.href="/login/colaborador";
          //next({name:"login_colaborador"})
        }
      }else{
        next();
      }
    }
  }
  
  async function isAuthorizedStudent(to,from,next){
    from;
  
    store.commit("setLoading",true);
    var isOk=await verifyStudentJWTs();
    store.commit("setLoading",false);
  
    if(to.name=="login_estudiantil"){
      if(isOk){
        next({name:"home_estudiante"});
      }else{
        store.commit("setProfileInfo","");
  
        next();
      }
    }else{
      if(to.path.includes("/estudiante")){
        if(isOk){
          next();
        }else{
          window.location.href="/login/estudiante";
          //next({name:"login_estudiantil"});
        }
      }else{
        next();
      }
    }
  }
  
  function goCorrectly(next,to){
    if(to.path.includes('/colaborador/administrador')){
      if(store.state.profileInfo.cargos.some(cargo=>cargo.roles.some(rol=>admins1.some(toAccept=>toAccept.includes(rol.rol))))){
        next();
      }else{
        next({path:'/colaborador/docente'});
      }
    }else if(to.path.includes('/colaborador/docente')){
      if(store.state.profileInfo.cargos.some(cargo=>cargo.roles.some(rol=>admins2operators.some(toAccept=>toAccept.includes(rol.rol))))){
        next();
      }else if(store.state.profileInfo.cargos.some(cargo=>cargo.roles.some(rol=>admins1.some(toAccept=>toAccept.includes(rol.rol))))){
        next({path:'/colaborador/administrador'});
      }else
        next();
    }
  }

export default routes;
