<template>
    <v-app id="home_estudiante">
        <v-main>
            <div v-if="$router.currentRoute.name=='home_estudiante'">
                <v-app-bar app flat :color="$vuetify.theme.dark?'':'white'">
                    <v-spacer></v-spacer>
                    <v-btn @click="logoutDialog=true" text outlined rounded :color="$store.state.accentColor2">Cerrar sesión</v-btn>
                </v-app-bar>
                <logout-dialog :context="'estudiante'" :logoutDialog="logoutDialog" @onclose="()=>{logoutDialog=false;}"></logout-dialog>
                <div v-if="!isLoading">
                    <v-row class="ma-0" justify="center">
                        <v-col cols="12" xs="12" sm="10" md="10" lg="6" xl="6">
                            <v-list-item>
                                <v-list-item-avatar :size="getPhotoSize()" :color="$store.state.accentColor2">
                                    <v-icon size="40" color="white">person</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium text-xl-h5 text-lg-h6 text-md-h6 text-sm-h6 wrap-text">
                                        {{getNombreEstudiante2}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{getUsuario2}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <br>
                    <v-container fluid>
                        <v-row no-gutters class="text-h6 font-weight">
                            Mis ciclos escolares
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="asignacion in asignacionData" :key="asignacion.idEstudianteGrado">
                                <v-card class="rounded-lg card-text" elevation="5" flat ripple @click="goAsignacion(asignacion)" height="80">
                                    <v-card-title class="text-h4 font-weight-bold pb-0 ">
                                        <v-row no-gutters justify="center">
                                            {{getCicloEscolar(asignacion)}}
                                        </v-row>
                                    </v-card-title>
                                    <v-row class="text-caption px-4 " no-gutters justify="center">
                                        {{getApproveText(asignacion)}}
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>                
                </div>
            </div>
            <transition name="scroll-y-transition" mode="out-in">
                <router-view />
            </transition>
        </v-main>
        <snackbar />
        <loader :isLoading="isLoading" :accentColor="2"></loader>
    </v-app>  
</template>
<script>
import Loader from '../../globalComponents/Loader.vue';
import LogoutDialog from '../../globalComponents/LogoutDialog.vue';
import Snackbar from '../../../components/globalComponents/Snackbar.vue';

export default {
  components: { LogoutDialog, Loader, Snackbar},
    computed:{
        getNombreEstudiante2(){
            if(this.estudianteData){
                return `${this.estudianteData.nombre1} ${this.estudianteData.nombre2} ${this.estudianteData.apellido1} ${this.estudianteData.apellido2}`;
            }

            return "No existe asignación para el estudiante";
        },
        getUsuario2(){
            if(this.estudianteData){
                if(this.estudianteData.estudiantesUsuarios){
                    return this.estudianteData.estudiantesUsuarios.usuario;
                }
                return "El estudiante no tiene usuario habilitado";
            }
            return "No existe asignación para el estudiante";
        }
    },
    data:()=>({
        logoutDialog:false,
        isLoading:false,
        estudianteData:"",
        gradoData:Object,
        asignacionData:"",
    }),
    methods:{
        goAsignacion(asignacion){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            this.$router.push({name:"inicio_estudiante",params:{ciclo:asignacion.idCicloEscolarNavigation.cicloEscolar1}},()=>{
                this.isLoading=false;
                this.$store.commit("setLoading",this.isLoading);
            });
        },
        getColor(asignacion){
            var color=``;
            if(asignacion){
                if(asignacion.finalizado){
                    if(asignacion.aprobado)
                        color=this.$store.state.accentColor2;
                    else
                        color=`red lighten-1`;
                }
            }

            return color;
        },
        getDark(asignacion){
            if(asignacion){
                if(asignacion.aprobado){
                    return true;
                }else{
                    var cicloEscolar1=asignacion.idCicloEscolarNavigation.cicloEscolar1;
                    var currDate=this.$store.state.currentRealDate;

                    if(cicloEscolar1==currDate.getFullYear()){
                        if(asignacion.finalizado){
                            return true;
                        }else{
                            return false;
                        }
                    }else if(asignacion.idCicloEscolarNavigation.cicloEscolar1<currDate.getFullYear()){
                        return true;
                    }
                }
            }

            return "";
        },
        getApproveText(asignacion){
            if(asignacion){
                if(asignacion.aprobado)
                    return "Aprobado";
                else{
                    var cicloEscolar1=asignacion.idCicloEscolarNavigation.cicloEscolar1;
                    var currDate=this.$store.state.currentRealDate;

                    if(cicloEscolar1==currDate.getFullYear()||asignacion.idCicloEscolarNavigation.cicloEscolar1<currDate.getFullYear())
                        if(asignacion.finalizado)
                            return "Reprobado";
                }
            }

            return "";
        },
        getCicloEscolar(asignacion){
            if(asignacion){
                return asignacion.idCicloEscolarNavigation.cicloEscolar1;
            }

            return "";
        },
        asignacionGrado(asignacion){
            if(asignacion){
                var text=`${asignacion.idGradoSeccionNavigation.idGradoNavigation.nombre} ${asignacion.idGradoSeccionNavigation.idSeccionNavigation.seccion}`;

                if(asignacion.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation){
                    text+=` - ${asignacion.idGradoSeccionNavigation.idGradoNavigation.idCarreraNavigation.nombre}`
                }else{
                    text+=` - ${asignacion.idGradoSeccionNavigation.idGradoNavigation.idNivelEstudiantilNavigation.nombre}`
                }

                return text;
            }
            return "No existe asignación para el estudiante";
        },
        getPhotoSize(){
            if(this.$vuetify.breakpoint.xsOnly){
                return 50;
            }
            if(this.$vuetify.breakpoint.smAndUp&&this.$vuetify.breakpoint.mdAndDown){
                return 70;
            }
            if(this.$vuetify.breakpoint.lgAndUp){
                return 90;
            }
        },
        loadAsignacion(){return this.$axios.get(`api/estudiantil/asignaciones/inscripciones`)},
        loadCurrentDate(){return this.$axios.get(`api/estudiantil/currentdate`)},
        loadStudent(){return this.$axios.get(`api/estudiantil/estudiantes/estudiante`)},
        async loadAll(){
            this.isLoading=true;
            this.$store.commit("setLoading",this.isLoading);

            await this.$axios.all([this.loadAsignacion(),this.loadCurrentDate(),this.loadStudent()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200&&response[2].status==200){
                    if(response[0].data.code==1&&response[1].data&&response[2].data.code==1){
                        this.asignacionData=response[0].data.data;
                        this.$store.commit("setCurrentDate",new Date(response[1].data));
                        this.estudianteData=response[2].data.data;

                        this.$store.commit("setPersonIdentity",this.estudianteData);
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
            this.$store.commit("setLoading",this.isLoading);
        }
    },
    created(){
        this.$store.commit("setAccentColor2","blue")
        this.loadAll();
    }
}
</script>
<style>
.white-logout{
    filter: brightness(0) invert(1);
}
.wrap-text {
  white-space: unset !important;
}
</style>