<template>
    <v-dialog v-model="videoDialog" max-width="400" persistent scrollable>
        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title>
                Video de YouTube
            </v-card-title>
            <v-card-text>
                <v-text-field
                label="Link de video"
                filled
                rounded
                @input="onvideolinkChange"
                :rules="videolinkRules"
                v-model="videolink"
                autocomplete="off"
                :color="$store.state.accentColor2"
                @keypress="onkeylinkInfo"
                >

                </v-text-field>
                <v-row no-gutters v-if="videoInfo">
                    <v-card outlined width="400px">
                        <v-list-item class="pa-0">
                            <v-list-item-icon class="ma-0 mr-2">
                                <v-img :src="videoInfo.snippet.thumbnails.default.url" contain :max-width="videoInfo.snippet.thumbnails.default.width" :max-height="videoInfo.snippet.thumbnails.default.height"></v-img>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="wrap-text">
                                    {{videoInfo.snippet.title}}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Video de YouTube - {{getVideoDuration(videoInfo.contentDetails.duration)}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>   
                </v-row>
                <br>
                <v-row no-gutters justify="center">
                    <h1 class="text-subtitle-2">
                        Formatos permitidos
                    </h1>
                </v-row>
                <v-row no-gutters>
                    <h1 class="text-caption">https://youtu.be/onjPLuZp6hY</h1>
                </v-row>
                <v-row no-gutters>
                    <h1 class="text-caption">o</h1>
                </v-row>
                <v-row no-gutters>
                    <h1 class="text-caption">https://www.youtube.com/watch?v=onjPLuZp6hY</h1>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose')">Cancelar</v-btn>
                <v-btn text color="green" :disabled="!videoInfo" @click="$emit('onaddVideo',videoInfo)">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:[
        "videoDialog",
        "from"
    ],
    watch:{
        videoDialog:function(newval,oldval){
            oldval;
            newval;

            this.videolink="";
            this.videoInfo="";
        }
    },
    data:function(){
        return({
            isLoading:false,
            videolinkRules:[
                v=>(/^(https:\/\/youtu.be\/(\S+))$/.test(v)||/^(https:\/\/www.youtube.com\/watch\?v=(\S+))$/.test(v))||'Link de YouTube debe cumplir formato permitido.'
            ],
            videoInfo:"",
            videolink:"",
        })
    },
    methods:{
        getVideoDuration(durationString){
            var numbers=durationString.split("PT")[1];

            if(numbers.includes("H")){
                numbers=`${numbers.split("H")[0]} horas`;
            }else if (numbers.includes("M")){
                numbers=`${numbers.split("M")[0]} minutos`;
            }else if(numbers.includes("S")){
                numbers=`${numbers.split("S")[0]} segundos`;
            }

            return numbers;
        },
        onkeylinkInfo(key){
            if(key.key=="Enter"){
                this.detectUseCaseLink(this.videolink);
            }
        },
        onvideolinkChange(val){
            this.detectUseCaseLink(val);
        },
        detectUseCaseLink(val){
            if(val.match("^(https://youtu.be/(\\S+))$")){
                var splittedLink=val.split("/");

                var id1=splittedLink[splittedLink.length-1];
                
                this.loadVideoInfo(id1);
            }

            if(val.match("^(https://www.youtube.com/watch\\?v=(\\S+))$")){
                var splittedLink2=val.split("/");

                var lastItem2=splittedLink2[splittedLink2.length-1];

                var watchItem=lastItem2.split("v=");
                var id2=watchItem[watchItem.length-1];

                this.loadVideoInfo(id2);
            }
        },
        loadVideoInfo(id){
            this.isLoading=this.$store.state.accentColor2;
            var url=`api/docentes/youtubevideos/video?id=${id}`;
            if(this.from=="estudiantes")
                url=`api/estudiantil/youtubevideos/video?id=${id}`;

            this.$axios.get(url).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        var videoData=response.data.data;

                        if(videoData.items.length>0){
                            this.videoInfo=videoData.items[0];
                        }else{
                            this.videoInfo=""
                        }
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
    }
}
</script>
<style scoped>

</style>