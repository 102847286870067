<template>
    <v-dialog v-model="tipospagoDialog" max-width="400" persistent>
        <v-card :loading="isLoading" :disabled="isLoading" outlined>
            <v-card-title>
                {{title}}
            </v-card-title>
            <v-card-text>
                * indica campo obligatorio
                <v-row class="pa-0 ma-0 pt-2"></v-row>
                <v-form ref="tipoPagoForm">
                    <v-row no-gutters justify="center">
                        <v-text-field
                            label="Descripción *"
                            v-model="tipoPago.descripcion"
                            :rules="tipoPago.descripcionRules"
                            counter
                            maxlength="50"
                            outlined
                            rounded
                            :color="$store.state.accentColor"
                            autocomplete="off"
                            >
                            </v-text-field>
                    </v-row>
                    <v-row no-gutters justify="center">
                        <v-col>
                            <v-checkbox 
                            label="¿Es pago mensual?" 
                            v-model="tipoPago.mensual"
                            :color="$store.state.accentColor"
                            @change="onpagomensualChange"
                            >

                            </v-checkbox>
                        </v-col>
                        <v-col>
                            <v-checkbox 
                            label="¿Es colegiatura?" 
                            v-model="tipoPago.colegiatura"
                            :color="$store.state.accentColor"
                            @change="oncolegiaturaChange"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="()=>{$emit('onclose');$refs.tipoPagoForm.reset();}">Cancelar</v-btn>
                <v-btn text color="green" @click="save">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:[
        "tipospagoDialog",
        "action",
        "tipoPagoToEdit"
    ],
    watch:{
        tipospagoDialog:function(newval,oldval){
            oldval;
            if(newval){
                if(this.action=="add"){
                    this.title="Nuevo tipo de pago";
                }else{
                    this.title="Edición de tipo de pago";
                    this.tipoPago.idPago=this.tipoPagoToEdit.idPago;
                    this.tipoPago.descripcion=this.tipoPagoToEdit.descripcion;
                    this.tipoPago.mensual=this.tipoPagoToEdit.mensual;
                    this.tipoPago.colegiatura=this.tipoPagoToEdit.colegiatura;
                    this.tipoPago.activo=this.tipoPagoToEdit.activo;
                }
            }
        }
    },
    data:()=>({
        title:"Nuevo tipo de pago",
        isLoading:false,
        tipoPago:{
            descripcion:"",
            descripcionRules:[
                v => !!v || 'Descripción es requerida.',
                v => ( v &&v.length <= 50) || 'Descripcion debe ser de tamaño 50 o menos.',
                //v=>/^[(\w)|(\s)]*$/.test(v)||'Nombre debe ser alfanumérico.',
                v=>!/^\s+$/.test(v)||'Descripción no debe ser un espacio en blanco.' 
            ],
            mensual:false,
            colegiatura:false
        },
    }),
    methods:{
        oncolegiaturaChange(val){
            if(val){
                this.tipoPago.mensual=true;
            }
        },
        onpagomensualChange(val){
            if(!val){
                if(this.tipoPago.colegiatura){
                    this.tipoPago.colegiatura=false;
                }
            }
        },
        async save(){
            this.isLoading=this.$store.state.accentColor;

            if(this.action=="add"){
                await this.saveCall();
            }else{
                await this.updateCall();
            }

            this.isLoading=false;
        },
        async saveCall(){
            if(this.$refs.tipoPagoForm.validate()){
                await this.$axios.post("api/tipospagos",
                    this.tipoPago
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$refs.tipoPagoForm.reset();
                            this.$emit("onsave",[1,response.data.data,response.data.message]);
                        }else{
                            this.$emit("onsave",[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onsave",[2,error.response.data.message]);
                        }else{
                            this.$emit("onsave",[2]);
                        }
                    }
                });
                this.$emit("onclose");
            }
        },
        async updateCall(){
            if(this.$refs.tipoPagoForm.validate()){
                await this.$axios.put(`api/tipospagos/update/${this.tipoPagoToEdit.idPago}`,
                    this.tipoPago
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$refs.tipoPagoForm.reset();
                            this.$emit("onupdate",[1,response.data.data,response.data.message]);
                            this.$updateChanges(this.tipoPagoToEdit,response.data.data);
                        }else{
                            this.$emit("onupdate",[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onupdate",[2,error.response.data.message]);
                        }else{
                            this.$emit("onupdate",[2]);
                        }
                    }
                });

                this.$emit("onclose");
            }
        }
    }
}
</script>
<style scoped>

</style>