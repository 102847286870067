<template>
    <v-dialog persistent max-width="400" v-model="carreraDialog">
        <v-card :loading="isLoading" :disabled="isLoading" outlined>
            <v-card-title>
                {{title}}
            </v-card-title>
            <v-card-text>
                <v-form ref="carreraForm">
                    <v-text-field
                    :color="$store.state.accentColor"
                    outlined
                    rounded
                    label="Nombre"
                    v-model="carrera.nombre"
                    :rules="carrera.nombreRules"
                    counter
                    maxlength="100"
                    autocomplete="off"
                    >

                    </v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$refs.carreraForm.reset();$emit('onclose');">Cancelar</v-btn>
                <v-btn text color="green" @click="save">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    props:[
        "carreraDialog",
        "action",
        "carreraToEdit"
    ],
    watch:{
        carreraDialog:function(newval,oldval){
            oldval;

            if(newval){
                if(this.action=="edit"){
                    this.title="Edición de carrera";

                    this.carrera.idCarrera=this.carreraToEdit.idCarreraNavigation.idCarrera;
                    this.carrera.nombre=this.carreraToEdit.idCarreraNavigation.nombre;
                    this.carrera.fechaCreado=this.carreraToEdit.idCarreraNavigation.fechaCreado;
                    this.carrera.activo=this.carreraToEdit.idCarreraNavigation.activo;
                }else{
                    this.title="Nueva carrera";

                    delete this.carrera.idCarrera;
                    delete this.carrera.fechaCreado;
                }
            }
        }
    },
    data:function(){
        return ({
            isLoading:false,
            title:"",
            carrera:{
                nombre:"",
                nombreRules:[
                    v => !!v || 'Nombre es requerido.',
                    v => ( v &&v.length <= 100) || 'Nombre debe ser de tamaño 100 o menos.',
                    //v=>/^[(\w)|(\s)]*$/.test(v)||'Nombre debe ser alfanumérico.',
                    v=>!/^\s+$/.test(v)||'Nombre no debe ser un espacio en blanco.' 
                ]
            }
        });
    },
    methods:{
        save(){
            if(this.action=="edit"){
                this.updateCall();
            }else{
                this.saveCall();
            }
        },
        async saveCall(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.put(`api/carreras/${this.$store.state.currentNivelEstudiantil}`,this.carrera).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$refs.carreraForm.reset();
                        this.$emit("onsave",[1,response.data.data,response.data.message]);
                    }else{
                        this.$emit("onsave",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("onsave",[2,error.response.data.message]);
                    }else{
                        this.$emit("onsave",[2]);
                    }
                }
            });

            this.$emit("onclose");

            this.isLoading=false;
        },
        async updateCall(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.put(`api/carreras/update/${this.$store.state.currentNivelEstudiantil}`,this.carrera).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$refs.carreraForm.reset();

                        this.$updateChanges(this.carreraToEdit.idCarreraNavigation,response.data.data);

                        this.$emit("onupdate",[1,response.data.data,response.data.message]);
                    }else{
                        this.$emit("onupdate",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("onupdate",[2,error.response.data.message]);
                    }else{
                        this.$emit("onupdate",[2]);
                    }
                }
            });

            this.$emit("onclose");

            this.isLoading=false;
        }
    }
}
</script>
<style scoped>

</style>