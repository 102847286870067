<template>
    <div>
        <div v-if="$router.currentRoute.name=='calificaciones_estudiante'">
            <v-app-bar app flat :color="$vuetify.theme.dark?'':'white'">
                <v-row justify="space-between">
                    <v-col class="pa-0" cols="4"><v-btn icon x-large @click="$router.go(-1)"><v-icon >arrow_back</v-icon></v-btn></v-col>
                    <v-col class="pa-0" cols="4" align="center"><notifications-actividades/></v-col>
                    <v-col class="pa-0" cols="4" align-self="center" align="end"></v-col>
                </v-row>
                <v-progress-linear v-if="isLoading" indeterminate :color="$store.state.accentColor2" bottom absolute background-color="transparent"></v-progress-linear>
            </v-app-bar>
            <logout-dialog :logoutDialog="logoutDialog" @onclose="logoutDialog=false" :context="'estudiante'"></logout-dialog>
            <generic-header :text="'Mis calificaciones'"></generic-header>
            <v-tooltip top v-if="!isLoading">
                <template v-slot:activator="{on}">
                    <v-btn
                    @click="downloadCalififaciones"
                    dark
                    fixed
                    bottom
                    right
                    v-on="on"
                    fab>
                    <v-icon>download</v-icon>
                </v-btn>
                </template>
                <span>Descargar calificaciones</span>
            </v-tooltip>
            <v-container class="custom-container">
                <v-card class="pa-0 ma-0" :disabled="isLoading" flat>
                    <calificaciones-content :cuadrosnotasData="cuadrosnotasData"></calificaciones-content>
                </v-card>
            </v-container>
        </div>
    </div>    
</template>
<script>
import GenericHeader from '../../globalComponents/GenericHeader.vue';

import LogoutDialog from '../../globalComponents/LogoutDialog.vue';
import CalificacionesContent from './CalificacionesContent.vue';
import NotificationsActividades from '../home/NotificationsActividades.vue'
export default {
    components: { GenericHeader, LogoutDialog, CalificacionesContent,NotificationsActividades },
    data:function(){
    GenericHeader
        return({
            isLoading:false,
            unsubscribe:"",
            logoutDialog:false,
            cuadrosnotasData:[],
        });
    },
    methods:{
       loadCalificaciones(){return this.$axios.get(`api/estudiantil/calificaciones`)},
       async downloadCalififaciones(){
           this.isLoading=true;
           await this.$axios.get(`api/estudiantil/calificaciones/exportar-boleta`, {
                responseType: 'blob'
            }).then((response)=>{
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `boleta ${this.$route.params.ciclo}-${new Date().getTime()}.jpeg`);
                document.body.appendChild(link);
                link.click();
           }).catch((error)=>{
               error;
           });
           this.isLoading=false;
       },
       async loadAll(){
           this.isLoading=true;
           this.$store.commit("setLoading",this.isLoading);

           await this.$axios.all([this.loadCalificaciones()]).then((response)=>{
               if(response[0].status==200){
                   if(response[0].data.code==1){
                       this.cuadrosnotasData=response[0].data.data;
                   }
               }
           }).catch((error)=>{
               error;
           });
           
           this.isLoading=false;
           this.$store.commit("setLoading",this.isLoading);
       }
    },
    created(){
        this.loadAll();
    },
}
</script>
<style scoped>

</style>