<template>
    <v-dialog v-model="logoutDialog" max-width="400" persistent>
        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title>
                Cerrar sesión
            </v-card-title>
            <v-card-text>
                ¿Desea cerrar su sesión en la aplicación?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose')">Cancelar</v-btn>
                <v-btn text @click="logout" color="green">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    props:[
        "logoutDialog",
        "context"
    ],
    data:()=>({
        isLoading:false
    }),
    methods:{
        async logout(){
            this.isLoading=this.$store.state.accentColor;

            if(!this.context)
                this.context="";
                
            await this.$axios.post(`api/authentication/logout?context=${this.context}`).then((response)=>{
                response;
                
                this.$store.commit("setIsLoggued",false);

                if(this.$store.state.profileInfo.isColaborador){
                    window.location.href="/login/colaborador";
                    //this.$router.push({name:"login_colaborador"});
                }else{
                    window.location.href="/login/estudiante";
                    //this.$router.push({name:"login_estudiantil"});
                }
                
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        },
    }
}
</script>
<style scoped>

</style>