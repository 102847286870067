<template>
    <v-card outlined>
        <v-card-title>
            <v-btn class="delete_button" icon @click="$emit('ondelete')">
                <v-icon>clear</v-icon>
            </v-btn>
            {{carrera.idCarreraNavigation.nombre}}
        </v-card-title>
        <v-card-text>
            <v-row no-gutters class="text-subtitle-1" justify="center">Materias</v-row>
            <v-list dense>
                <v-list-item v-for="materia in carrera.nivelesEstudiantilesCarrerasMaterias" :key="materia.idNivelEstudiantilCarreraMateria">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{materia.idMateriaNavigation.nombre}}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-tooltip bottom>
                            <template v-slot:activator="{on}">
                                <v-btn small icon v-on="on" @click="$emit('onupdateMateria',materia)"><v-icon small>edit</v-icon></v-btn>
                            </template>
                            <span>Editar materia</span>
                        </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-action>
                        <v-tooltip bottom>
                            <template v-slot:activator="{on}">
                                <v-btn small icon v-on="on" @click="$emit('ondeleteMateria',materia)"><v-icon small>clear</v-icon></v-btn>
                            </template>
                            <span>Eliminar materia</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="$emit('onupdate')"><v-icon>edit</v-icon></v-btn>
                </template>
                <span>Editar carrera</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn text small rounded @click="$emit('onaddmateria')"><v-icon class="pr-1">add</v-icon>Agregar materia</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props:[
        "carrera"
    ]
}
</script>
<style scoped>

</style>