<template>
    <v-sheet class="pa-2" :color="'amber'" rounded="xl">
        <v-card flat class="rounded-xl card-text" ripple>
            <v-container style="height:190px" fill-height @click="()=>{gotoCalificaciones(grado)}">
                <v-row justify="center" no-gutters>
                    <h1 class="text-h5 font-weight-medium" align="center">{{grado.nombre}}</h1>
                </v-row>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon :color="'amber'">groups</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            Nivel
                        </v-list-item-title>
                        <v-list-item-subtitle class="wrap-text">
                            {{getNivelNombre}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="grado.idCarreraNavigation">
                    <v-list-item-avatar>
                        <v-icon :color="'amber'">people_alt</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            Carrera
                        </v-list-item-title>
                        <v-list-item-subtitle class="wrap-text">
                            {{getCarreraNombre}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-container>
        </v-card>
    </v-sheet>    
</template>
<script>
export default {
    props:["grado"],
    computed:{
        getCarreraNombre(){
            if(this.grado)
                if(this.grado.idCarreraNavigation)
                    return this.grado.idCarreraNavigation.nombre;
            return "";
        },
        getNivelNombre(){
            if(this.grado)
                if(this.grado.idNivelEstudiantilNavigation)
                    return this.grado.idNivelEstudiantilNavigation.nombre;
            return "";
        }
    },
    data:function(){
        return ({

        });
    },
    methods:{
        gotoCalificaciones(grado){
            this.$emit("ongocalificaciones",grado.idGrado);
        }
    }
}
</script>
<style scoped>

</style>