import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import api from './utils/api'
import store from './store/store'
import routes from './utils/routerHandler';
import {prototype} from './utils/vuePrototypeHandler';



Vue.config.productionTip = false

new Vue({
  store:store,
  router:routes,
  vuetify,
  render: h => h(App),
  beforeCreate(){
    prototype.$axios=api;
    var theme=window.localStorage.getItem("darkTheme");

    if(theme){
      if(theme.includes("true")){
        store.commit("changeTheme","dark");
        this.$vuetify.theme.dark=true;
      }else{
        store.commit("changeTheme","light");
        this.$vuetify.theme.dark=false;
      }
    }else{
      store.commit("changeTheme","light");
      this.$vuetify.theme.dark=false;
    }
  }
}).$mount('#app')
