<template>
    <v-card outlined>
        <v-card-title>
            {{materia.nombre}}
        </v-card-title>
        <v-card-text>
            <v-chip close @click:close="$emit('ondelete',grado)" small v-for="grado in materia.gradosSecciones" :key="grado.idGradoSeccion" class="mr-2 mb-2">
                {{getChipTextView(grado)}}
            </v-chip>
        </v-card-text>
        <v-card-actions>
            <v-menu v-model="menuTransfer" :close-on-content-click="false" max-width="250" offset-x :close-on-click="false">
                <template v-slot:activator="{on: menu}">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on: tooltip}">
                            <v-btn icon v-on="{...tooltip,...menu}" @click="menuTransfer=true" v-if="!materia.nuevo"><v-icon>swap_horiz</v-icon></v-btn>
                        </template>
                        <span>Transferir materias</span>
                    </v-tooltip>
                </template>
                <v-list class="pa-0" :disabled="isLoading">
                    <v-progress-linear indeterminate :color="$store.state.accentColor" v-if="isLoading"></v-progress-linear>
                    <v-row no-gutters><v-spacer></v-spacer><v-btn icon @click="menuTransfer=false;"><v-icon>clear</v-icon></v-btn></v-row>
                    <v-form ref="transferForm" class="px-2">
                        <v-select
                        label="Docentes"
                        filled
                        rounded
                        :items="docentesData"
                        v-model="transfer.docente"
                        :rules="transfer.docenteRules"
                        item-text="nombres"
                        item-value="idColaborador"
                        :color="$store.state.accentColor"
                        item-color=""
                        >
                        </v-select>
                        <v-select
                        label="Grados"
                        filled
                        rounded
                        multiple
                        return-object
                        :items="materia.gradosSecciones"
                        :item-text="(item)=>`${getChipText(item)}`"
                        :color="$store.state.accentColor"
                        item-color=""
                        v-model="transfer.grados"
                        :rules="transfer.gradosRules"
                        >
                            <template v-slot:selection="{item,index}">
                                <v-chip v-if="index===0" small>
                                    <span>{{getChipText(item)}}</span>
                                </v-chip>
                                <span
                                v-if="index === 1"
                                class="grey--text caption"
                                >
                                (+{{ transfer.grados.length - 1 }} otros)
                                </span>
                            </template>
                        </v-select>
                    </v-form>
                    <v-row no-gutters class="pa-2"><v-spacer></v-spacer><v-btn small text color="green" @click="transferir">Transferir</v-btn></v-row>
                </v-list>
            </v-menu>
            <div class="edited-new-tag text-caption green--text" v-if="materia.nuevo">
                Nuevo
            </div>
            <div class="edited-new-tag text-caption green--text" v-if="materia.editado">
                Editado
            </div>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props:[
        "colaborador",
        "materia"
    ],
    watch:{
        menuTransfer:function(newval,oldval){
            oldval;

            if(!newval){
                this.$refs.transferForm.reset();
            }else{
                this.loadAll();
            }
        }
    },
    data:function(){
        return({
            menuTransfer:false,
            isLoading:false,
            docentesData:[],
            transfer:{
                docente:"",
                docenteRules:[
                    v=>!!v||"Docente es requerido."
                ],
                grados:[],
                gradosRules:[
                    v=>!!v&&v.length>0||"Grados es requerido."
                ]
            }
        })
    },
    methods:{
        getChipTextView(grado){
            var gradoTexto=this.getChipText(grado);

            if(gradoTexto)
                if(gradoTexto.length>30)
                    return `${gradoTexto.substring(0,30)}...`;
            return gradoTexto;
        },
        getChipText(grado){
            var texto="";
            if(grado.idGradoNavigation.idCarreraNavigation){
                texto=`${grado.idGradoNavigation.idCarreraNavigation.nombre} - ${grado.idGradoNavigation.nombre} ${grado.idSeccionNavigation.seccion}`;
            }else{
                texto=`${grado.idGradoNavigation.idNivelEstudiantilNavigation.nombre} - ${grado.idGradoNavigation.nombre} ${grado.idSeccionNavigation.seccion}`;
            }

            return texto;
        },
        async transferir(){
            if(this.$refs.transferForm.validate()){
                var toTransfer=this.transfer.grados;

                toTransfer.forEach(element=>{
                    element.idColaborador=this.transfer.docente;
                });

                this.isLoading=true;

                await this.$axios.put(`api/colaboradores/materias/transferencia/${this.transfer.docente}?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`,
                toTransfer
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$emit("ontransfer",[1,response.data.message]);
                            this.menuTransfer=false;
                        }else{
                            this.$emit("ontransfer",[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("ontransfer",[2,error.response.data.message]);
                        }else{
                            this.$emit("ontransfer",[2]);
                        }
                    }
                });

                this.isLoading=false;
            }
        },
        loadMaestros(){return this.$axios.get(`api/colaboradores?type=docentes`);},
        async loadAll(){
            this.isLoading=true;

            await this.$axios.all([this.loadMaestros()]).then((response)=>{
                if(response[0].status==200){
                    if(response[0].data.code==1){
                        this.docentesData=response[0].data.data;

                        this.docentesData=this.docentesData.filter(item=>item.idColaborador!=this.colaborador.idColaborador);
                    }
                }
            }).catch((error)=>{
                error;
            });

            this.isLoading=false;
        }
    },
    created(){
        
    }
}
</script>
<style scoped>

</style>