<template>
    <v-dialog v-model="asignacionDialog" max-width="500" persistent scrollable>
        <v-card :loading="isLoading" :disabled="isLoading" outlined>
            <v-card-title>Inscripción de alumno</v-card-title>
            <v-card-text>
                <v-form ref="assignmentForm">
                    <v-row no-gutters justify="center">
                        <h1 class="text-subtitle-1 font-weight-medium">Información del alumno</h1>
                    </v-row>
                    <v-row class="pa-0 ma-0" justify="center">
                        <v-col class="pa-0 ma-0" cols="12" xs="12">
                            <v-list-item>
                                <v-list-item-icon><v-icon>face</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Alumno</v-list-item-title>
                                    <v-list-item-subtitle>{{student.nombre1}} {{student.nombre2}} {{student.nombre3}} {{student.apellido1}} {{student.apellido2}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="12" xs="12" sm="6" md="6">
                            <v-list-item>
                                <v-list-item-icon><v-icon>group</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Grado</v-list-item-title>
                                    <v-list-item-subtitle class="wrap-text">{{getGradoText(grado)}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="12" xs="12" sm="6" md="6">
                            <v-list-item>
                                <v-list-item-icon><v-icon>calendar_today</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Ciclo escolar</v-list-item-title>
                                    <v-list-item-subtitle>{{$store.state.cicloEscolar.cicloEscolar1}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <v-row no-gutters justify="center">
                        <h1 class="text-subtitle-1 font-weight-medium">Información de selección</h1>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" xs="12" sm="6">
                            <v-select label="Sección" :items="grado.gradosSecciones" item-value="idGradoSeccion" v-model="seccion" filled
                            rounded item-color="" :color="$store.state.accentColor" :rules="seccionRules">
                                <template slot="item" slot-scope="{item}">
                                    {{item.idSeccionNavigation.seccion}}
                                </template>
                                <template slot="selection" slot-scope="{item}">
                                    {{item.idSeccionNavigation.seccion}}
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" >
                            <v-checkbox
                            v-model="becado" 
                            label="¿Es Becado?"
                            :color="$store.state.accentColor"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose');">Cancelar</v-btn>
                <v-btn text color="green" @click="asignar">Asignar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:[
        "asignacionDialog",
        "student",
        "grado"
    ],
    watch:{
        asignacionDialog:function(newVal,oldVal){
            oldVal;
            
            if(newVal){
                this.seccion=this.grado.gradosSecciones[0].idGradoSeccion;

                if(this.grado.idCarreraNavigation){
                    this.isCarrera=true;
                    this.loadPagosCarrera(this.grado.idNivelEstudiantilCarrera.idNivelEstudiantilCarrera);
                }else{
                    this.isCarrera=false;
                    this.loadPagosNivel(this.grado.idNivelEstudiantil);
                }
            }else{
                this.$refs.assignmentForm.reset();
                this.pagosMensualesFiltered=[];
            }
        },
    },
    data:()=>({
        seccion:"",
        seccionRules:[
            v=>!!v||"Sección es requerida."
        ],
        pagos:[],
        pagosRules:[
            v=>!!v&&v.length>0||"Pagos son requeridos."
        ],
        becado:false,
        pagosMensualesNivelFiltered:[],
        pagosMensualesCarreraFiltered:[],
        isLoading:false,
        pagosNivel:[],
        pagosCarrera:[],
        isCarrera:false
    }),
    methods:{
        onchangePagosNivel(value){
            this.pagosMensualesNivelFiltered=value.filter(item=>item.idPagoNavigation.mensual);
        },
        onchangePagosCarrera(value){
            this.pagosMensualesCarreraFiltered=value.filter(item=>item.idPagoNavigation.mensual);
        },
        getGradoText(grado){
            var texto=``;

            if(grado.idCarreraNavigation){
                texto=`${grado.nombre} ${grado.idCarreraNavigation.nombre}`
            }else{
                if(grado.idNivelEstudiantilNavigation){
                    texto=`${grado.nombre} ${grado.idNivelEstudiantilNavigation.nombre}`;
                }else{
                    texto=`${grado.nombre}`;
                }
            }
            return texto;
        },
        async asignar(){
            this.isLoading=this.$store.state.accentColor;
            await this.asignarCall();
            this.isLoading=false;
        },
        async asignarCall(){
            if(this.$refs.assignmentForm.validate()){
                let toAsignar={
                    idEstudiante:this.student.idEstudiante,
                    idCicloEscolarNavigation:this.$store.state.cicloEscolar,
                    idCicloEscolar:this.$store.state.cicloEscolar.idCicloEscolar,
                    idGradoSeccion:this.seccion,
                    becado:this.becado
                    //estudiantesGradosPagos:this.pagos
                };

                await this.$axios.post("api/grados/estudiante/asignar",
                    toAsignar
                ).then((response)=>{
                    if(response.status==200){
                        if(response.data.code==1){
                            this.$emit("onenrollment",[1,response.data.data,response.data.message]);
                        }else{
                            this.$emit("onenrollment",[0,response.data.message]);
                        }
                    }
                }).catch((error)=>{
                    if(error.response!=null){
                        if(error.response.data!=null){
                            this.$emit("onenrollment",[2,error.response.data.message]);
                        }else{
                            this.$emit("onenrollment",[2]);
                        }
                    }
                });

                this.$emit("onclose");
            }
        },
        async loadPagosNivel(nivel){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.get(`api/nivelesestudiantiles/${nivel}?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.pagosNivel=response.data.data.pagosNivelesEstudiantiles;
                        this.pagos=this.pagosNivel;
                        this.pagosMensualesNivelFiltered=this.pagos.filter(item=>item.idPagoNavigation.mensual);

                        this.pagosMensualesNivelFiltered=this.pagosMensualesNivelFiltered.map((item)=>{
                            if(item.idPagoNavigation.colegiatura){
                                item.numeroMeses=10;
                            }

                            return item;
                        });
                    }
                }                
            }).catch((error)=>{
                error;
            });
            this.isLoading=false;
        },
        async loadPagosCarrera(carrera){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.get(`api/carreras/${carrera}?ciclo=${this.$store.state.cicloEscolar.idCicloEscolar}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.pagosCarrera=response.data.data.pagosNivelesEstudiantilesCarreras;
                        this.pagos=this.pagosCarrera;
                        this.pagosMensualesCarreraFiltered=this.pagos.filter(item=>item.idPagoNavigation.mensual);
                        this.pagosMensualesCarreraFiltered=this.pagosMensualesCarreraFiltered.map((item)=>{
                            if(item.idPagoNavigation.colegiatura){
                                item.numeroMeses=10;
                            }

                            return item;
                        });
                    }
                }                
            }).catch((error)=>{
                error;
            });
            this.isLoading=false;
        }
    },
    created(){
        
    }
}
</script>
<style scoped>

</style>