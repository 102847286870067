<template>
    <v-dialog v-model="show" max-width="400" persistent>
        <v-card :loading="loading" :disabled="loading" outlined>
            <v-card-title>
                Eliminar {{context}}
            </v-card-title>
            <v-card-text>
                {{content}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('onclose')">Cancelar</v-btn>
                <v-btn text color="green" @click="onDelete(context)">Eliminar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:{
        show:Boolean,
        content:String,
        context:String,
        idEntity:String,
    },
    data:()=>({
        loading:false
    }),
    methods:{
        async onDelete(context){
            this.loading=this.$store.state.accentColor;
            
            if(["colaborador/docente","estudiantil"].some(url=>this.$router.currentRoute.path.includes(url))){
                this.loading=this.$store.state.accentColor2;
            }

            switch (context) {
                case "grado":
                    await this.deleteGrado(this.idEntity);
                    break;
                case "materia":
                    await this.deleteMateria(this.idEntity);
                    break;
                case "colaborador":
                    await this.deleteColaborador(this.idEntity);
                    break;
                case "estudiante":
                    await this.deleteEstudiante(this.idEntity);
                    break;    
                case "tipo de pago":
                    await this.deleteTipoPago(this.idEntity);
                    break;
                case "pago de nivel":
                    await this.deletePago(this.idEntity);
                    break;
                case "carrera":
                    await this.deleteCarrera(this.idEntity);
                    break;
                case "pago de carrera":
                    await this.deletePagoCarrera(this.idEntity);
                    break;
                case "aviso":
                    await this.deleteAviso(this.idEntity);
                    break;
                case "tarea":
                    await this.deleteTarea(this.idEntity);
                    break;
                case "material":
                    await this.deleteMaterial(this.idEntity);
                    break;
                case "grado a cargo":
                    await this.deleteGradoGuiado(this.idEntity);
                    break;
                case "tarea extra":
                    await this.deleteTareaExtra(this.idEntity);
                    break;
                case "examen final":
                    await this.deleteExamenFinal(this.idEntity);
                    break;
                case "parcial":
                    await this.deleteParcial(this.idEntity);
                    break;
                case "parcial extra":
                    await this.deleteParcialExtra(this.idEntity);
                    break;
                case "examen final extra":
                    await this.deleteExamenFinalExtra(this.idEntity);
                    break;
                case "asignacion":
                    await this.deleteAsignacionCatedratico(this.idEntity);
                    break;
                case "publicacion":
                    await this.deletePublicacion(this.idEntity);
                    break;
                case "publicacion estudiante":
                    await this.deletePublicacionEstudiante(this.idEntity);
                    break;
                default:
                    break;
            }
            this.$emit("onclose");

            this.loading=false;
        },
        async deleteGrado(id){
            await this.$axios.delete(`api/grados/delete/${id}`
            ).then((response)=>{
                if(response.data.code==1){
                    this.$emit("ondelete",[1,response.data.message]);
                }else{
                    this.$emit("ondelete",[0,response.data.message]);
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteMateria(id){
            await this.$axios.delete(`api/materias/delete/${id}?nivel=${this.$store.state.currentNivelEstudiantil}`
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteColaborador(id){
            await this.$axios.delete(`api/colaboradores/delete/${id}`
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteEstudiante(id){
            await this.$axios.delete(`api/estudiantes/delete/${id}`
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });

        },
        async deleteTipoPago(id){
            await this.$axios.delete(`api/tipospagos/delete/${id}`
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deletePago(id){
            await this.$axios.delete(`api/nivelesestudiantiles/pagos/delete/${id}`
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteCarrera(id){
            await this.$axios.delete(`api/carreras/delete/${id}?nivel=${this.$store.state.currentNivelEstudiantil}`
            ).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deletePagoCarrera(id){
            await this.$axios.delete(`api/carreras/pagos/delete/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteAviso(id){
            await this.$axios.delete(`api/docentes/actividades/avisos/delete/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteTarea(id){
            await this.$axios.delete(`api/docentes/actividades/tareas/delete/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteMaterial(id){
            await this.$axios.delete(`api/docentes/actividades/material/delete/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteGradoGuiado(id){
            await this.$axios.delete(`api/colaboradores/colaborador/grado-guia/${id}/delete`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteTareaExtra(id){
            await this.$axios.delete(`api/docentes/actividades/extras/tareas/delete/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteExamenFinal(id){
            await this.$axios.delete(`api/docentes/actividades/examen-final/delete/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteParcial(id){
            await this.$axios.delete(`api/docentes/actividades/parcial/delete/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteParcialExtra(id){
            await this.$axios.delete(`api/docentes/actividades/extras/parcial/delete/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteExamenFinalExtra(id){
            await this.$axios.delete(`api/docentes/actividades/extras/examen-final/delete/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deleteAsignacionCatedratico(id){
            await this.$axios.delete(`api/colaboradores/materias/grados/${id}`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deletePublicacion(id){
            await this.$axios.delete(`api/estudiantil/actividades/avisos/${id}/delete`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        },
        async deletePublicacionEstudiante(id){
            await this.$axios.delete(`api/docentes/actividades/avisos-estudiante/${id}/delete`).then((response)=>{
                if(response.status==200){
                    if(response.data.code==1){
                        this.$emit("ondelete",[1,response.data.message]);
                    }else{
                        this.$emit("ondelete",[0,response.data.message]);
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.data!=null){
                        this.$emit("ondelete",[2,error.response.data.message]);
                    }else{
                        this.$emit("ondelete",[2]);
                    }
                }
            });
        }
    },
}
</script>
<style scoped>

</style>